<template>
  <v-layout   fill-height align-center class=" "  >     
     
    <v-layout v-if="false" fill-height> 
        <mbs-page-spinner :show="true" />  
    </v-layout>  

    <!-- <div v-else grid-list-lg  :fluid="true" >    -->
    <div v-else class="mx-  not-full-w">    
        <!-- //Print -->
        <v-layout column v-if="print_svg" align-center justify-center>
            <v-card class="px-5" v-if="true"
                id="receipt_print" 
                v-html="print_svg"
                tile> 
            </v-card>
            <v-card v-else class="pa-2" id="receipt_print" width="500" >  
                    <h2 class="text-center">m1buslimiteds</h2>
                    <div>Main Outlet</div>
                    <div>Served by: m1 bus at Main Register</div>
                    <hr>
                    <div>Receipt / Tax Invoice #5</div>
                    <div>15 Mar 2022 7:19am</div>
                    <hr>
                <v-layout column justify-center align-baseline class="ma-0">
                    
                </v-layout>
            </v-card>
            <br>
            <v-btn 
                :disabled="inputLoading"  
                class="ml-5" color="secondary">
                Scan ..
                <v-icon class="ml-2">mdi-barcode-scan</v-icon>
            </v-btn> 
        </v-layout>

        <!-- //SALE -->
        <v-layout column v-else-if="!pay_order"  class=" ma-0 pa-0 not-full-w"> 
            <!-- //HEADERS -->
            <div v-if="false">
                <v-card class="pa-3 font-weight-bol d grey darken-1 white--text mb-2" flat>
                    SELECT TILL
                </v-card>
            </div>
            <div class="mb-1" v-else-if="!click_mode">
                <!-- SEARCH --> 
                <v-toolbar height="52" v-if="!click_mode" 
                    @click="ON_SEARCH(selling_model)" rounded="" dark flat 
                    :color="LocalCashup?'primary':'grey darken-1'" 
                    class="pa-0">
                    <v-layout justify-center align-center>
                        <v-autocomplete  class="mr-2 mt-1"
                            v-model="search_item"
                            :disabled="inputLoading || !LocalCashup|| !selling_model"
                            :items="ItemsFiltered" dense
                            append-icon="search"
                            outlined :rounded="false"
                            :label="selling_model?'Search for Item':'You Must Select Selling Model First'"
                            color="blue-grey lighten-2" 
                            item-text="name"
                            item-value="key" 
                            :filter="(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                        if(!a||!b){return false}
                                        a = ''+a; b = ''+b
                                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                    } 
                                    let first = index(itemText,queryText)
                                    let second = item?index(item.code,queryText):false
                                    let third = item?index(item.brand_name,queryText):false
                                    let forth = item?index(item.category_name,queryText):false
                                    if(!item){return first}  
                                    return first || second || third || forth 
                            }"
                            >
                            <template v-slot:selection="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:item="data">
                                <template v-if="(typeof data.item !=='object')" >
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar tile color="grey" >
                                        <img :src="data.item.image" v-if="data.item.image">
                                        <v-icon v-else>local_offer</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content >
                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-html="data.item.category_name"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-html="data.item.brand_name"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="selling_model=='Retail Price'">
                                        {{ MBS.actions.money(data.item.retail_price) }}
                                    </v-list-item-action>
                                    <v-list-item-action v-else-if="selling_model=='Wholesale Price'">
                                        {{ MBS.actions.money(data.item.wholesale_price) }}
                                    </v-list-item-action>
                                </template>
                            </template>
                        </v-autocomplete>  
    
                        <!-- //IF CARD -->
                        <v-menu   v-if="SELECTED_CARD"
                            v-model="menu_selected_card"
                            :close-on-content-click="true" 
                            offset-y  offset-overflow
                            :nudge-width="90" rounded="xl"
                            >
                            <template v-slot:activator="{ on, attrs }" >   
                                <v-btn  v-on="on" outlined class="mr-2 mb-1 pr-0"  > 
                                    <v-icon>mdi-credit-card-scan </v-icon>
                                    <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                                </v-btn>  
                            </template> 
    
                            <v-card  v-if="ud"  
                                color="lighten-1" class="">  
                                <v-card-title class="secondary white--text py-" dark>
                                    Selected Card
                                </v-card-title> 
                                <v-list dense> 
                                    <div v-if="SELECTED_CARD">
                                        <v-list-item>Card Holder: {{SELECTED_CARD.customer_name}}</v-list-item> 
                                        <v-list-item>Card Number: {{SELECTED_CARD.code}}</v-list-item> 
                                        <v-list-item>Expire Date: {{SELECTED_CARD.card_expire_date}}</v-list-item> 
                                        <v-list-item>Balance: MWK{{MBS.actions.money(SELECTED_CARD.balance_amount)}}</v-list-item> 
                                    </div>
                                    <v-divider /> 
                                    <v-list-item :class="'secondary--text text-center'" @click="DESELECT_CARD">
                                        <v-layout justify-center>
                                            Clear
                                        </v-layout>
                                    </v-list-item> 
                                </v-list>  
                            </v-card>  
                        </v-menu>  
                        
                        <!-- //IF ORDER -->
                        <v-menu   v-if="item_order"
                            v-model="menu_item_order"
                            :close-on-content-click="true" 
                            offset-y  offset-overflow
                            :nudge-width="90" rounded="xl"
                            >
                            <template v-slot:activator="{ on, attrs }" >   
                                <v-btn  v-on="on" outlined class="mr-2 mb-1 pr-0"  > 
                                    <v-icon>mdi-cart</v-icon>
                                    <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                                </v-btn>  
                            </template> 
    
                            <v-card  v-if="ud"  
                                color="lighten-1" class="">  
                                <v-card-title class="secondary white--text py-" dark>
                                    Selected Order
                                </v-card-title> 
                                <v-list dense> 
                                    <!-- <v-list-item @click="EDIT_ITEM_ORDER(item_order)">
                                        <v-list-item-action><v-icon>edit</v-icon></v-list-item-action> 
                                        <v-list-item-content>Update Order</v-list-item-content> 
                                    </v-list-item>   -->
                                    <v-list-item @click="PRINT_ITEM_ORDER(item_order)">
                                        <v-list-item-action><v-icon>print</v-icon></v-list-item-action> 
                                        <v-list-item-content>Print Order</v-list-item-content> 
                                    </v-list-item> 
                                    <!-- <v-list-item @click="PAY_ITEM_ORDER(item_order)">
                                        <v-list-item-action><v-icon>mdi-cash</v-icon></v-list-item-action> 
                                        <v-list-item-content>Pay Order</v-list-item-content> 
                                    </v-list-item>   -->
                                    <v-list-item @click="DELETE_ITEM_ORDER(item_order)">
                                        <v-list-item-action><v-icon>mdi-delete</v-icon></v-list-item-action> 
                                        <v-list-item-content>Cancel Order</v-list-item-content> 
                                    </v-list-item> 
                                    <v-divider/> 
                                    <v-list-item :class="'secondary--text text-center'" @click="CLEAR_ALL_DATA()">
                                        <v-layout justify-center>
                                            Clear
                                        </v-layout>
                                    </v-list-item> 
                                </v-list>  
                            </v-card>  
                        </v-menu>  
                        
                        <v-menu  
                            v-model="menu_selling_mode"
                            :close-on-content-click="true" 
                            offset-y  offset-overflow
                            :nudge-width="90" rounded="xl"
                            >
                            <template v-slot:activator="{ on, attrs }">  
                                <v-btn height="38"  v-on="on" class=" m-1"
                                    ref="btn_selling_mode"
                                    :disabled="CompanySettings?CompanySettings.only_retail_model||CompanySettings.only_wholesale_model:false"  
                                     color="secondary">
                                    {{selling_model?selling_model:'Select'}}
                                    <v-icon class="ml-2">arrow_drop_down</v-icon>
                                </v-btn> 
                            </template> 
    
                            <v-card  v-if="ud"  
                                color="lighten-1" class="">  
                                <v-card-title class="secondary white--text py-" dark>
                                    Select Selling Mode 
                                </v-card-title> 
                                <v-list dense> 
                                    <div v-for="(model,index) in sellingModels" :key="index">
                                        <v-list-item :class="model == selling_model?'secondary--text':''" @click="selling_model=model">{{model}}</v-list-item> 
                                    </div>
                                </v-list>  
                            </v-card>  
                        </v-menu>  

                    </v-layout>
                </v-toolbar>  
            </div> 
            <!-- //LIST -->
            <v-layout class="ma-0"  >
                <!-- //--------------------------[ACTION SIDE]--------------------- -->
                <!-- CLICK MODE -->  
                <v-col v-if="click_mode && (LocalCashup) " cols="12" sm="6" class="ma-0 py-0 pl-0 pr-1"> 
                    <v-card tile class="pb-" outlined v-if="false"> 
                        <v-tabs class="not-full-w sales-dash"
                            background-color="secondary"
                            icons-and-text
                            color="primary"
                            slider-color="secondary"
                            height="65" show-arrows
                            grow :vertical="false"
                            > 
                            <v-tab :dark="false" v-for="(item,index) in AllActionItems" class="mr-1 " :class="(index==0?'ml-1 ':'ml-0 ')+(item.color?item.color:' b2')">
                                {{item.name}}
                                <v-icon>{{item.icon}}</v-icon>
                            </v-tab> 
                        </v-tabs>
                    </v-card> 
                    <mbs-click-select-items
                        @click_mode="click_mode=!click_mode"
                        @item_action="ITEM_ACTION"
                        @select_item="value=>value?ADD_ITEM(value.key):''"
                        :selected_department_codes="CurrentTill?CurrentTill.selected_department_codes:null"
                        :allow_changing_department="AllowChangingDepartments"
                        :action_items="AllActionItems"/>
                    <v-card v-if="false" flat outlined  color="" 
                    :height="vsh-140" width="100%" class="">
                        <v-layout  class="ma-0    " fill-height > 
                            <v-card height="100%" tile  outlined class=" b2"> 
                                <v-card  tile >
                                    <v-btn-toggle  elevation="20"  class="ma-0" dark> 
                                        <v-btn dark min-height="60" @click="click_mode=false"
                                            width="58" :color="'secondary'" 
                                            class="mb-"><v-icon size="25">mdi-monitor-dashboard</v-icon>
                                        </v-btn>
                                        <v-spacer/> 
                                        <v-menu     
                                            :close-on-content-click="true" 
                                            offset-y  offset-overflow
                                            :nudge-width="200" rounded="xl"
                                            >
                                            <template v-slot:activator="{ on, attrs }" >   
                                                <v-btn width="58" min-height="60" color="primary"  v-on="on"   class=""  >  
                                                    <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                                                </v-btn>  
                                            </template> 

                                            <v-card  v-if="ud"  
                                                color="lighten-1" class="">  
                                                <v-card-title class="secondary white--text py-" dark>
                                                    Selected Action <v-spacer/> <v-btn dark icon=""><v-icon>close</v-icon></v-btn> 
                                                </v-card-title> 
                                                <v-list dense class="pt-0"> 
                                                    <div v-if="AllActionItems">
                                                        <v-virtual-scroll  
                                                            :items="AllActionItems"
                                                            :height="vsh-300"
                                                            item-height="40">
                                                            <template v-slot:default="{ item }">
                                                                <v-list-item @click="ITEM_ACTION(item.action,item,{menu:true})">
                                                                    <v-list-item-action class="mr-2">
                                                                        <v-icon>{{ item.icon }}</v-icon>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content> 
                                                                        <v-layout align-center> 
                                                                            <v-avatar tile 
                                                                                :style="'--size:1px; --color:grey;'"
                                                                                size="18"
                                                                                class="mr-2 mbs-border"
                                                                                :color="item.color" > 
                                                                            </v-avatar>
                                                                            {{item.name}}
                                                                        </v-layout>
                                                                    </v-list-item-content>
                                                                    <v-list-item-action v-if="item.menu"> 
                                                                        <v-icon class="ml-2 ">mdi-chevron-down</v-icon>
                                                                    </v-list-item-action> 
                                                                </v-list-item>  
                                                            </template> 
                                                        </v-virtual-scroll>
                                                    </div>
                                                    <!-- <v-divider />  -->
                                                    <!-- <v-list-item :class="'b1 secondary--text text-center'" @click="DESELECT_CARD">
                                                        <v-layout justify-center>
                                                            Clear
                                                        </v-layout>
                                                    </v-list-item>  -->
                                                </v-list>  
                                            </v-card>  
                                        </v-menu>   
                                    </v-btn-toggle>  
                                </v-card>
                                <v-card  :height="vsh-280" class="pa-2 mbs-scroll-y-hidee" flat color=" b2">
                                    <v-layout fill-height column class="ma-0">
                                        <v-card v-if="category_current_page>1"  @click="category_current_page--" class="mb-1 primary" dark :height="category_btn_h/1.4">
                                            <v-layout class="ma-0" fill-height justify-center align-center> 
                                                <v-icon>mdi-chevron-double-left</v-icon>
                                            </v-layout>
                                        </v-card>
                                        <v-spacer></v-spacer>
                                        <span v-for="(item,index) in PaginatedItemCategories(vsh-210,'list')?
                                            PaginatedItemCategories(vsh-210,'list').list:null" :key="index">
                                            <v-card @click="SELECTED_ITEM_CATEGORY = item" 
                                                :color="SELECTED_ITEM_CATEGORY?SELECTED_ITEM_CATEGORY.key == item.key?'secondary lighten-1':'':''"  
                                                class="mb-1 pa-" :height="auto_category_btn_h" :width="category_btn_w" >
                                                <v-layout column fill-height justify-center  align-center class="ma-1 mbs-wrap-i"> 
                                                    <span class="not-f2 font-weight-bold text-center  ">{{item.name}}</span> 
                                                </v-layout>
                                            </v-card>  
                                        </span> 
                                        <v-spacer></v-spacer>
                                        <v-card rounded :height="category_btn_h/1.4" v-if="PaginatedItemCategories(vsh-210)?
                                            PaginatedItemCategories(vsh-210).pages>category_current_page:false"
                                            @click="category_current_page++" class="mb-1 primary" dark >
                                            <v-layout class="ma-0" fill-height justify-center align-center> 
                                                <v-icon>mdi-chevron-double-right</v-icon> 
                                                <!-- {{ 
                                                    PaginatedItemCategories(vsh-210)?
                                                    PaginatedItemCategories(vsh-210).pages-category_current_page:0 }} -->
                                            </v-layout>
                                        </v-card>  
                                    </v-layout>
                                </v-card> 
                            </v-card>
                            <v-col class="pa-0">  
                                <v-card  :height="vsh-142" tile outlined class="mbs-scroll-y-hide   primary lighten-4" >
                                    <v-toolbar color="primary"  class='mb-2' height="60" dark flat  v-if="MBS.actions.SIZE(ItemDepartments)" >
                                        <v-autocomplete  flat
                                            v-model="selected_departments"
                                            :disabled="!AllowChangingDepartments"
                                            :items="ItemDepartments"
                                            item-text="name"
                                            item-value="code" 
                                            chips
                                            dense :label="MBS.actions.SIZE(selected_departments)?'':'Select Department--'" 
                                            filled outlined
                                            multiple
                                            small-chips  
                                            color="" 
                                            >
                                            <template v-slot:selection="data">
                                                <v-chip :disabled="!AllowChangingDepartments"
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close color="secondary"
                                                    @click="data.select" dense small
                                                    @click:close="REMOVE_DEPARTMENT(data.item)" > 
                                                    {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </v-toolbar>
                                    <v-row :style="'--color:'+MBS.color.black+'; --size:1px; '"
                                        class="ma-1 primary lighten-4" v-if="MBS.actions.SIZE(ItemByCategory)>0">
                                        <v-col v-if="item_category_current_page>1" class=" pa-0 pr-1 pb-1" cols="12" sm="6" md="4"  >
                                            <v-card @click="item_category_current_page--" flat  class=" pa-   "   min-height="60" height="100%" 
                                            width="100%" >
                                                <v-layout fill-height justify-center align-center class="ma-0 pa-1 mbs-border">
                                                    <v-icon color="black">mdi-chevron-double-left</v-icon>
                                                </v-layout>
                                            </v-card>  
                                        </v-col>

                                        <v-col class="pa-0 pr-1 pb-1" cols="12" sm="6" md="4"  v-for="(item,index) in 
                                            PaginatedItemByCategory(vsh-210)?PaginatedItemByCategory(vsh-210).list:null" :key="index">
                                            <v-card flat @click="ADD_ITEM(item.key)"  class=" pa- " min-height="60" height="100%" width="100%" >
                                                <v-layout fill-height justify-center align-center class="ma-0 pa-1 mbs-border">
                                                    <span class="not-f2 text-center">{{item.name}}  </span>
                                                </v-layout>
                                            </v-card>  
                                        </v-col>
                                        
                                        <v-col v-if="PaginatedItemByCategory(vsh-210)?
                                            PaginatedItemByCategory(vsh-210).pages>item_category_current_page:false" 
                                            class="pa-0 pr-1 pb-1" cols="12" sm="6" md="4"  >
                                            <v-card @click="item_category_current_page++"  class=" pa-1 " min-height="60" height="100%" width="100%" >
                                                <v-layout fill-height justify-center align-center class="ma-0 pa-1">
                                                    <v-icon color="black">mdi-chevron-double-right</v-icon>
                                                </v-layout>
                                            </v-card>  
                                        </v-col>
                                    </v-row>
                                    <v-layout v-else-if="SELECTED_ITEM_CATEGORY" fill-height align-center justify-center class="ma-0  grey--text">
                                        <v-chip large color="b1" class="mx-1" >
                                            <v-icon class="mx-2" color="grey">warning</v-icon>
                                            <v-layout row  class="grey--text ma-0 mr-2 py-1" justify-centers align-center>
                                                No Items in Selected  <strong class="mx-1">{{ SELECTED_ITEM_CATEGORY.name }}</strong> Category
                                            </v-layout>
                                        </v-chip>
                                        
                                    </v-layout>
                                    <v-layout v-else fill-height align-center justify-center class="ma-0  grey--text">
                                        
                                        <v-chip  large class="px-5" >
                                            <v-icon>warning</v-icon> 
                                            <div class="not-f5 ml-2 grey--text" v-if="MBS.actions.SIZE(ItemCategories)">Select Item Category </div>
                                            <div class="not-f5 ml-2 grey--text" v-else>No Item Category !</div>
                                        </v-chip>
                                    </v-layout>
                                </v-card> 
                            </v-col> 
                        </v-layout> 
                    </v-card> 
                </v-col>

                <!-- SEARCH MODE --> 
                <v-card v-else flat color="grey lighten-2" :height="vsh-190" class="py- mr-1 ml-1">  
                    <!-- <v-card :height="'100%'" flat color="grey lighten-2" class="mbs-scroll-y-hide ">   -->
                    <v-card :height="'100%'" flat outlined class="mbs-scroll-y " color="">  
                        <!-- <v-toolbar color="primary"  class='mb-2' height="60" dark flat >
                        </v-toolbar> --> 
                        <v-autocomplete v-if="MBS.actions.SIZE(ItemDepartments)"
                            flat class="mx-2 mt-1"
                            v-model="selected_departments"
                            :items="ItemDepartments"
                            :disabled="!AllowChangingDepartments"
                            item-text="name"
                            item-value="code" 
                            chips
                            dense :label="MBS.actions.SIZE(selected_departments)?'':'Select Department...'" 
                            filled outlined
                            multiple
                            small-chips
                            color="" 
                            >
                            <template v-slot:selection="data">
                                <v-chip :disabled="!AllowChangingDepartments"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    close color="secondary"
                                    @click="data.select" dense small
                                    @click:close="REMOVE_DEPARTMENT(data.item)" > 
                                    {{ data.item.name }}
                                </v-chip>
                            </template>
                        </v-autocomplete> 
                        <mbs-actions-field  class="my-1"
                            :disabled_types="ActionTypesDisabled"
                            :items="ActionItems"
                            @ITEM_ACTION="ITEM_ACTION"/>    
                    </v-card>
                </v-card>  
    
                <!-- //----------------------[ITEM SIDE]------------------------ -->
                <!-- //RIGHT SIDE -->
                <v-col cols="12" :sm="click_mode?'6':'7'" :md="click_mode?'6':'8'" class="ma-0 pa-0">
                    <v-card :height="click_mode?vsh-140:vsh-190" class="" min-height="300" outlined> 
                        <v-row class="ma-0" v-if="click_mode">
                            <!-- SEARCH --> 
                            <v-col cols="12" sm="12" md="12" class="ma-0 py-0 pl-0 pr-0" >
                                <div :class="vs.smAndDown?'':''">
                                    <v-toolbar @click="ON_SEARCH(selling_model)" roundedd="" dark flat height="60"
                                        :color="LocalCashup?'primary':'grey darken-1'" 
                                        class="ma-">
                                        <v-autocomplete  class="mr-2"
                                            v-model="search_item" 
                                            :disabled="inputLoading || !LocalCashup|| !selling_model"
                                            :items="ItemsFiltered" dense
                                            append-icon="search"
                                            outlined :rounded="false"
                                            :label="selling_model?'Search for Item':'You Must Select Selling Model First'"
                                            color="blue-grey lighten-2" 
                                            item-text="name"
                                            item-value="key" 
                                            :filter="(item, queryText, itemText) => { 
                                                let index = (a,b)=>{
                                                        if(!a||!b){return false}
                                                        a = ''+a; b = ''+b
                                                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                    } 
                                                    let first = index(itemText,queryText)
                                                    let second = item?index(item.code,queryText):false
                                                    let third = item?index(item.brand_name,queryText):false
                                                    let forth = item?index(item.category_name,queryText):false
                                                    if(!item){return first}  
                                                    return first || second || third || forth 
                                            }"
                                            >
                                            <template v-slot:selection="data">
                                                {{ data.item.name }}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !=='object')" >
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                <v-list-item-avatar tile color="grey">
                                                    <img :src="data.item.image" v-if="data.item.image">
                                                    <v-icon v-else>local_offer</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-html="data.item.category_name"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-html="data.item.brand_name"></v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action v-if="selling_model=='Retail Price'">
                                                    {{ MBS.actions.money(data.item.retail_price) }}
                                                </v-list-item-action>
                                                <v-list-item-action v-else-if="selling_model=='Wholesale Price'">
                                                    {{ MBS.actions.money(data.item.wholesale_price) }}
                                                </v-list-item-action>
                                                </template>
                                            </template>
                                        </v-autocomplete>  
    
                                        <!-- //IF CARD -->
                                        <v-menu   v-if="SELECTED_CARD"
                                            v-model="menu_selected_card"
                                            :close-on-content-click="true" 
                                            offset-y  offset-overflow
                                            :nudge-width="90" rounded="xl"
                                            >
                                            <template v-slot:activator="{ on, attrs }" >   
                                                <v-btn  v-on="on" outlined class="mr-2 mb-1 pr-0"  > 
                                                    <v-icon>mdi-credit-card-scan </v-icon>
                                                    <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                                                </v-btn>  
                                            </template> 
    
                                            <v-card  v-if="ud"  
                                                color="lighten-1" class="">  
                                                <v-card-title class="secondary white--text py-" dark>
                                                    Selected Card
                                                </v-card-title> 
                                                <v-list dense> 
                                                    <div v-if="SELECTED_CARD">
                                                        <v-list-item>Card Holder: {{SELECTED_CARD.customer_name}}</v-list-item> 
                                                        <v-list-item>Card Number: {{SELECTED_CARD.code}}</v-list-item> 
                                                        <v-list-item>Expire Date: {{SELECTED_CARD.card_expire_date}}</v-list-item> 
                                                        <v-list-item>Balance: MWK{{MBS.actions.money(SELECTED_CARD.balance_amount)}}</v-list-item> 
                                                    </div>
                                                    <v-divider /> 
                                                    <v-list-item :class="'secondary--text text-center'" @click="DESELECT_CARD">
                                                        <v-layout justify-center>
                                                            Clear
                                                        </v-layout>
                                                    </v-list-item> 
                                                </v-list>  
                                            </v-card>  
                                        </v-menu>  
                                        
                                        <!-- //IF ORDER -->
                                        <v-menu   v-if="item_order"
                                            v-model="menu_item_order"
                                            :close-on-content-click="true" 
                                            offset-y  offset-overflow
                                            :nudge-width="90" rounded="xl"
                                            >
                                            <template v-slot:activator="{ on, attrs }" >   
                                                <v-btn  v-on="on" outlined class="mr-2 mb-1 pr-0"  > 
                                                    <v-icon>mdi-cart</v-icon>
                                                    <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                                                </v-btn>  
                                            </template> 
    
                                            <v-card  v-if="ud"  
                                                color="lighten-1" class="">  
                                                <v-card-title class="secondary white--text py-" dark>
                                                    Selected Order
                                                </v-card-title> 
                                                <v-list dense> 
                                                    <!-- <v-list-item @click="EDIT_ITEM_ORDER(item_order)">
                                                        <v-list-item-action><v-icon>edit</v-icon></v-list-item-action> 
                                                        <v-list-item-content>Update Order</v-list-item-content> 
                                                    </v-list-item>   -->
                                                    <v-list-item @click="PRINT_ITEM_ORDER(item_order)">
                                                        <v-list-item-action><v-icon>print</v-icon></v-list-item-action> 
                                                        <v-list-item-content>Print Order</v-list-item-content> 
                                                    </v-list-item> 
                                                    <!-- <v-list-item @click="PAY_ITEM_ORDER(item_order)">
                                                        <v-list-item-action><v-icon>mdi-cash</v-icon></v-list-item-action> 
                                                        <v-list-item-content>Pay Order</v-list-item-content> 
                                                    </v-list-item>   -->
                                                    <v-list-item @click="CONFIRM_DELETE_ITEM_ORDER(item_order)">
                                                        <v-list-item-action><v-icon>mdi-delete</v-icon></v-list-item-action> 
                                                        <v-list-item-content>Cancel Order</v-list-item-content> 
                                                    </v-list-item> 
                                                    <v-divider/> 
                                                    <v-list-item :class="'secondary--text text-center'" @click="CLEAR_ALL_DATA()">
                                                        <v-layout justify-center>
                                                            Clear
                                                        </v-layout>
                                                    </v-list-item> 
                                                </v-list>  
                                            </v-card>  
                                        </v-menu>  
                                        
                                        <v-menu  
                                            v-model="menu_selling_mode"
                                            :close-on-content-click="true" 
                                            offset-y  offset-overflow
                                            :nudge-width="90" rounded="xl"
                                            >
                                            <template v-slot:activator="{ on, attrs }">  
                                                <v-btn  v-on="on" class=" mb-1"
                                                    ref="btn_selling_mode"
                                                    :disabled="CompanySettings?CompanySettings.only_retail_model||CompanySettings.only_wholesale_model:false"  
                                                    color="secondary">
                                                    {{selling_model?selling_model:'Select'}}
                                                    <v-icon class="ml-2">arrow_drop_down</v-icon>
                                                </v-btn> 
                                            </template> 
    
                                            <v-card  v-if="ud"  
                                                color="lighten-1" class="">  
                                                <v-card-title class="secondary white--text py-" dark>
                                                    Select Selling Mode 
                                                </v-card-title> 
                                                <v-list dense> 
                                                    <div v-for="(model,index) in sellingModels" :key="index">
                                                        <v-list-item :class="model == selling_model?'secondary--text':''" @click="selling_model=model">{{model}}</v-list-item> 
                                                    </div>
                                                </v-list>  
                                            </v-card>  
                                        </v-menu>  
                                    </v-toolbar> 
                                </div> 
                            </v-col>  
                        </v-row> 
                        <v-card :height="vsh-205" color="transparent" flat rounded="" id="printJS-form" >
                            <!-- //Sale -->   
                            <v-layout fill-height column class="ma-0" v-if="LocalCashup">  
                                <!-- //SELECTED ITEMS -->    
                                <mbs-sales-selected-items
                                    :height="vsh_selected_items_table" 
                                    @confirm_remove_item="CONFIRM_REMOVE_ITEM"
                                    @item_multiple_select="(value)=>item_multiple_select=value"
                                    @checked_items="(value)=>checked_items=value"
                                    />  
                                <v-spacer></v-spacer>
                                <v-divider></v-divider>  
                                <!-- //PAY BUTTON -->
                                <input ref="btn_submit_order" type="submit" v-show="false">
                                <v-row v-if="item_multiple_select"
                                    class="ma-0 pl-4 pr-5 pt-"  justify-center align-center
                                    :class="vs.mdAndDown?'pr-4':'pr-1'" >  
                                    <v-col  
                                        md="12"  
                                        cols="12"   
                                        class="pa-0 ma-0" > 
                                        <v-card @click="CONFIRM_REMOVE_ITEM(checked_items)"  height="100%" min-height="45" max-height="50" width="100%"  
                                            :disabled="MBS.actions.SIZE(SELECTED_ITEMS)<1 || inputLoading" 
                                            :flat="MBS.actions.SIZE(SELECTED_ITEMS)<1" 
                                            hover  
                                            class="v-btn" 
                                            :class="vs.smAndDown?'':''"
                                            :color="MBS.actions.SIZE(SELECTED_ITEMS)<1?'grey darken-1':'primary'" dark >
                                            <v-layout class="ma-0 px-4" fill-height align-center justify-center v-if="!inputLoading">
                                                <v-icon class="mr-2">mdi-delete</v-icon>
                                                <div class="font-weight-bold not-f7">Remove</div>
                                                <div class="pl-2 pt-1 font-weight-bold not-f3">{{MBS.actions.SIZE(checked_items)}} items</div>
                                                <v-spacer></v-spacer>
                                                <div class="font-weight-bold not-f7">MK {{MBS.actions.money(CalculateItemsPrice(checked_items))}}</div> 
                                            </v-layout> 
                                            <v-layout v-else class="ma-0 pa-2" justify-center>
                                                <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                            </v-layout>
                                        </v-card> 
                                    </v-col>
                                </v-row>
                                <v-row v-else
                                    class="ma-0 pl-4 pb-2 " 
                                    :class="vs.mdAndDown?'pr-4':'pr-1'" >   

                                    <!-- //TOTAL DISCOUNTS -->
                                    <v-layout v-if="CompanySettings?CompanySettings.allow_total_discount:false"
                                        class=" ml-0 mt-1 " :class="vs.mdAndDown?'mr-0':'mr-3'"  align-center> 
                                        <v-col class="pa-0 ma-0"> 
                                            <v-text-field dense class="no-spin-button"
                                                filled outlined clearable type="number"
                                                label="Total Discount Amount"
                                                v-model="input_discount_amount" 
                                                outline 
                                            ></v-text-field> 
                                        </v-col>
                                        <v-col   sm="6" class="pa-0"> 
                                            <v-text-field dense type="number"
                                                :class="vs.mdAndDown?'ml-1':'ml-1'"
                                                filled outlined  clearable
                                                label="Total Discount %" 
                                                v-model="input_discount_percentage"
                                                outline 
                                            ></v-text-field> 
                                        </v-col> 
                                    </v-layout>


                                    <!-- // -->
                                    <v-col sm="12"  v-else class="ma-0 pa-0 b" > 
                                    </v-col >
                                    <v-col 
                                        :lg="MBS.actions.SIZE(ItemOrderOptionsData)>0?'9':'12'" 
                                        md="12"  
                                        cols="12"   
                                        class="pa-0 ma-0" > 
                                        <v-card @click="CREATE_ORDER()"  height="100%" min-height="45" max-height="55" width="100%"  
                                            :disabled="MBS.actions.SIZE(SELECTED_ITEMS)<1 || inputLoading" 
                                            :flat="MBS.actions.SIZE(SELECTED_ITEMS)<1" 
                                            hover  
                                            class="v-btn" 
                                            :class="vs.smAndDown?'':''"
                                            :color="MBS.actions.SIZE(SELECTED_ITEMS)<1?'grey darken-1':'primary'" dark >
                                            <v-layout class="ma-0 px-4" fill-height align-center justify-center v-if="!inputLoading">
                                                <div class="font-weight-bold not-f7">Pay</div>
                                                <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(SELECTED_ITEMS)}} items</div>
                                                <v-spacer></v-spacer>
                                                <div class="font-weight-bold not-f7">MK {{MBS.actions.money(TotalDiscountedPrice)}}</div> 
                                            </v-layout> 
                                            <v-layout v-else class="ma-0 pa-2" justify-center>
                                                <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                            </v-layout>
                                        </v-card> 
                                    </v-col>
                                    <v-col @click="UPDATE_ITEM_ORDER()" 
                                        cols="12" 
                                        lg="3" md="12" 
                                        class="ma-0 py-0 " 
                                        :class="vs.mdAndDown?'mt-1 pa-0':'pl-1'" 
                                        v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0">  

                                        <v-card v-if="item_order"   height="100%" width="100%"  min-height="45"
                                            :disabled="MBS.actions.SIZE(SELECTED_ITEMS)<1 || inputLoading" 
                                            :flat="MBS.actions.SIZE(SELECTED_ITEMS)<1" 
                                            hover  
                                            class=" v-btn"
                                            :class="vs.mdAndDown?'mt-1':'ml-2'" 
                                            :color="MBS.actions.SIZE(SELECTED_ITEMS)<1?'grey darken-1':'secondary'" dark >
                                            <v-layout fill-height class="ma-0" justify-center align-center v-if="!inputLoading">
                                                
                                                <!-- <v-spacer/> -->
                                                <div class="font-weight-bold not-f5">Update Order</div> 
                                                <!-- <v-spacer/>
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon> -->
                                            </v-layout> 
                                            <v-layout v-else class="ma-0 pa-2" justify-center>
                                                <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                            </v-layout>
                                        </v-card>
    
                                        <span v-else>
                                            <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0"
                                                v-model="menu_select_order_option" 
                                                :close-on-content-click="false" 
                                                offset-y  offset-overflow
                                                :nudge-width="90" rounded="xl"
                                                >
                                                <template v-slot:activator="{ on }">   
                                                    <v-card v-on="on"  height="100%" width="100%" max-height="55" min-height="45"
                                                        :disabled="MBS.actions.SIZE(SELECTED_ITEMS)<1 || inputLoading" 
                                                        :flat="MBS.actions.SIZE(SELECTED_ITEMS)<1" 
                                                        hover  
                                                        class=""
                                                        :class="vs.mdAndDown?'':''" 
                                                        :color="MBS.actions.SIZE(SELECTED_ITEMS)<1?'grey darken-1':'secondary'" dark >
                                                        <v-layout fill-height class="ma-0" justify-center align-center v-if="!inputLoading">
                                                            
                                                            <v-spacer/>
                                                            <div class="font-weight-bold  " :class="click_mode?'not-f3 pl-1':'not-f5'">Order Option</div> 
                                                            <v-spacer/>
                                                            <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                                        </v-layout> 
                                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                                        </v-layout>
                                                    </v-card>  
                                                </template> 
                
                                                <v-card>
                                                    <v-card-title primary-title class="secondary white--text"  >
                                                        Select Order Option
                                                    </v-card-title>
                                                    <v-list>
                                                        <v-list-item v-for="(order_option, index) in ItemOrderOptionsData" :key="index" 
                                                            @click="SELECT_ORDER_OPTION(order_option)">
                                                            {{order_option.name}}
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card>
                                            </v-menu>  
                                        </span> 
                                    </v-col>
                                </v-row>
                            </v-layout> 
        
                            <!-- //Open register -->
                           <!-- <form @submit.prevent="OPEN_REGISTER()" v-else> -->
                           <form @submit.prevent="ON_START_SELLING()" v-else>
                               <v-card :height="vsh-220"  flat color="transparent" outlined>
                                    <v-layout fill-height column class="ma-0 "  align-center> 
        
                                        <br>
                                        <v-icon size="100">mdi-cash-register</v-icon>
                                        <h2 class="grey--text text--darken-1  ">{{MBS.actions.TEXT_UP('Register Closed')}}</h2> 
                                        <v-card min-height="100" class="ma-2 pa-2  mbs-scroll-y" outlined width="97%">
                                            <mbs-input-fields v-if="show_input"
                                                v-model="input"
                                                :disabled_fields="DisabledCashupFields" 
                                                :field_items="DATA.FIELDS.start_selling"
                                                :select_items="SelectInputFieldsItems" 
                                                />   
                                            <mbs-page-spinner v-else
                                                :height="250" />  
                                        </v-card>  
                                        <v-spacer></v-spacer>
                                        <v-divider></v-divider> 
                                        <!-- <v-btn 
                                            type="submit" 
                                            class="my-2" 
                                            color="primary" 
                                            large>
                                            <div class="font-weight-bold not-f7 px-5">Open Register</div> 
                                        </v-btn>  -->
                                        <v-btn 
                                            type="submit" 
                                            class="my-2 mbs-z-index2" height="50"
                                            color="primary" 
                                            large>
                                            <v-layout justify-center align-center class="font-weight-bold not-f7 px-5">
                                                <v-icon size="35" class="mr-2">$printer-pos-play</v-icon>
                                                START SELLING
                                            </v-layout> 
                                        </v-btn>  
                                    </v-layout>  
                               </v-card>
                           </form>
                        </v-card> 
                    </v-card>
                </v-col>

                <!-- ACTIONS -->
                <v-col cols="12" sm="5" md="4" class="ma-0 py-0 pl-0" v-if="vs.xs">
                    <v-card outlined>
                        <v-card height="100%" :max-height="vsh-220" flat color="transparent" class="mbs-scroll-y">  
                            <mbs-actions-field  
                                :disabled_types="ActionTypesDisabled"
                                :items="ActionItems"
                                @ITEM_ACTION="ITEM_ACTION"/>   
                        </v-card>  
                    </v-card>
                </v-col>
            </v-layout> 
        </v-layout>

        <!-- //Pay -->
        <v-layout row wrap v-else class="ml-5">
            <v-flex xs12 sm6 md5 > 
                <v-card height="100%"  flat color="transparent" >
                    <v-layout fill-height column class="ma-0">
                        <v-hover v-slot="{ hover }">
                            <v-card style="--px:-18px;" class="mbs-pull-x" 
                                flat color="transparent" @click="pay_order = null">
                                <v-layout align-center>
                                    <v-btn icon><v-icon :color="hover?'secondary':''">mdi-arrow-left-bold </v-icon></v-btn>
                                    <div class="font-weight-bold not-f8 grey--text text--darken-2 ml-">
                                        <span v-if="SELECTED_CARD_OPTION=='load_money'">Card Load Money</span>
                                        <span v-if="SELECTED_CARD_OPTION=='pay'">Card Payment</span>
                                        <span v-else>Sale</span>
                                    </div> 
                                </v-layout> 
                            </v-card>   
                        </v-hover>
                        <v-divider v-if="false"></v-divider>

                        <!-- //IF SELECTED CARD -->
                        <v-layout v-if="SELECTED_CARD" column   align-center justify-center >
                            <v-card class=" px-5 py-2 mt-9 mr-3" outlined>
                                <div class="text-center font-weight-bold grey--text text--darken-2 not-f5 pt-2">{{SELECTED_CARD.customer_name}}</div>
                                <v-list class="pa-0">
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-avatar
                                                size="60"
                                                color="b2" >
                                                <v-avatar
                                                    size="35"  tile> 
                                                <v-icon>{{mdiAccountCreditCard}}</v-icon>
                                                </v-avatar>
                                            </v-avatar>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Type:{{SELECTED_CARD.card_type_name}}</v-list-item-subtitle> 
                                            <v-list-item-subtitle>No.: {{SELECTED_CARD.code}}</v-list-item-subtitle> 
                                            <v-list-item-subtitle>Exp.: {{SELECTED_CARD.card_expire_date}}</v-list-item-subtitle> 
                                            <v-list-item-subtitle>Bal.: {{SELECTED_CARD.balance_amount_}}</v-list-item-subtitle> 
    
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card> 

                            <!-- //LOAD MONEY -->
                            <v-list color="transparent" v-if="SELECTED_CARD_OPTION == 'load_money'">
                                <template>
                                    <v-list-item  >
                                        <template >  
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Card Current Balance
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Balance
                                                </v-list-item-subtitle> 
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                {{MBS.actions.money(SELECTED_CARD.balance_amount)}} 
                                            </v-list-item-action>   
                                        </template> 
                                    </v-list-item>
                                </template>
                                <v-divider class="mx-3 mt-5" ></v-divider>  
                                <v-list-item  dense   >
                                    <v-list-item-content >
                                        <div>Total After Load</div>
                                        <div>Tax</div> 
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <div>MWK {{MBS.actions.money(toNumber(SELECTED_CARD.balance_amount)+toNumber(pay_order.card_load_amount))}}</div>
                                        <div>MWK 0.00</div>
                                    </v-list-item-action>   
                                </v-list-item>
                                
                                <v-divider class="mx-3"></v-divider>
                                <v-list-item     class="grey--text text--darken-2" >
                                    <v-list-item-content>
                                        <v-layout class="ma-0 " align-center>
                                            <div class="font-weight-bold not-f6">TOTAL LOAD CASH</div> 
                                        </v-layout>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-layout class="ma-0" align-center>
                                            <div class="font-weight-bold not-f3 pt-1 ">MWK </div>
                                            <div class="font-weight-bold not-f6">{{MBS.actions.money(pay_order.card_load_amount)}}</div>
                                        </v-layout> 
                                    </v-list-item-action>   
                                </v-list-item>
                                <v-divider class="mx-3"></v-divider>
                            </v-list>
                            <v-spacer></v-spacer>
                        </v-layout>

                        
                        <v-card  v-if="SELECTED_ITEMS && SELECTED_CARD_OPTION !='load_money'" :height="'100%'" 
                        color="transparent" class="  pl- pr-4" flat target="">
                            <v-simple-table class="b1 ml-3" :height="vsh_payment_sam_table" fixed-header dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr class="font-weight-bold ">
                                            <th class=" text-left "> 
                                            </th>
                                            <th class=" text-left ">
                                                ITEM
                                            </th>
                                            <th class="text-center "> 
                                                <span  > 
                                                    PRICE
                                                </span>
                                            </th>
                                            <th class="text-center ">
                                                QTY
                                            </th> 
                                            <th class="text-center ">
                                                TOTAL <span v-if="CalculatedVAT?CalculatedVAT.total_vat:false">(EX VAT)</span>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item,index) in SELECTED_ITEMS"
                                            :key="item.key" >
                                            <td class="pl- text- with-divider-lr table-background1">{{ index+1 }}</td>
                                            <td class="pl- text- with-divider">{{ item.name }}</td>
                                            <td class=" text-right with-divider"> 
                                                <span  > 
                                                    {{ item.sold_price }}
                                                </span>
                                            </td> 
                                            <td class=" text-center with-divider">{{ item.quantity }}</td> 
                                            <td class=" text-right with-divider">{{MBS.actions.money(PriceExcludeVAT(CALCULATE_PRICE(item),item.tax_group_code))}} </td>
                                            <td class=" text-right with-divider" style="width:40px;" >{{
                                                item.tax_group_code?item.tax_group_code:"A"
                                            }}</td>
                                        </tr>
                                        <!-- <v-divider class="mx-3"></v-divider> -->
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <!-- <v-divider class="mx-3"></v-divider> -->
                            <v-list color="transparent" >
                                <template>
                                    <v-list-item   v-if="false" 
                                        :key="index"  v-for="(item, index) in SELECTED_ITEMS">
                                        <template >  
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                     {{item.name}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                     {{item.selling_model}}: {{item.quantity}} X {{item.sold_price}}
                                                </v-list-item-subtitle> 
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                {{MBS.actions.money(CALCULATE_PRICE(item))}} 
                                            </v-list-item-action>   
                                        </template> 
                                    </v-list-item>
                                </template>

                                <!-- //show discount -->
                                <div v-if="TotalDiscount||(CompanySettings?CompanySettings.calculate_vat:false)">
                                    <v-divider class="mx-3 mt-" ></v-divider>  
                                    <v-list-item  dense   >
                                        <v-list-item-content >
                                            <div class="not-f2 font-weight-bold">SUB-TOTAL</div>
                                            <div v-if="TotalDiscount" class="not-f2 font-weight-bold">DISCOUNT</div> 
                                            
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <div class="not-f2 font-weight-bold">
                                                <span v-if="CalculatedVAT">
                                                    <span v-if="CalculatedVAT.vat_exclusive">{{MBS.actions.money(toNumber(TotalPrice))}}</span>
                                                    <span v-else>{{MBS.actions.money(toNumber(TotalPrice)-toNumber(CalculatedVAT.total_vat))}}</span> 
                                                </span>
                                                <span v-else>{{MBS.actions.money(TotalPrice)}}</span>
                                            </div>
                                            <div v-if="TotalDiscount" class="not-f2 font-weight-bold">{{MBS.actions.money(TotalDiscount)}}</div>  
                                        </v-list-item-action>   
                                    </v-list-item> 
                                </div>
                                
                                <!-- //show VAT -->
                                <div v-if="CompanySettings?CompanySettings.calculate_vat:false">
                                    <v-divider class="mx-3 mb-2" ></v-divider>
                                    <span class="grey--text text--darken-2 " v-if="CalculatedVAT" > 
                                        <span v-if="CalculatedVAT.taxable_a" >
                                            <v-layout class="mx-3 ">
                                                <div class="not-f2 font-weight-bold">TAXABLE A-16.5%</div>
                                                <v-spacer/>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.taxable_a)}}</div>
                                            </v-layout> 
                                            <v-layout class="mx-3">
                                                <div class="not-f2 font-weight-bold">VAT A-16.5%</div>
                                                <v-spacer/>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.vat_a)}}</div>
                                            </v-layout>   
                                        </span>
                                        
                                        <span v-if="CalculatedVAT.taxable_b">
                                            <v-layout class="mx-3">
                                                <div class="not-f2 font-weight-bold">TAXABLE B-0%</div>
                                                <v-spacer/>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.taxable_b)}}</div>
                                            </v-layout> 
                                            <v-layout class="mx-3">
                                                <div class="not-f2 font-weight-bold">VAT B-0%</div>
                                                <v-spacer/>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.vat_b)}}</div>
                                            </v-layout>   
                                        </span>
                                        
                                        <span v-if="CalculatedVAT.taxable_c">
                                            <v-layout class="mx-3">
                                                <div class="not-f2 font-weight-bold">TAXABLE E-0%</div>
                                                <v-spacer/>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.taxable_c)}}</div>
                                            </v-layout> 
                                            <v-layout class="mx-3">
                                                <div class="not-f2 font-weight-bold">VAT E-0%</div>
                                                <v-spacer/>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.vat_c)}}</div>
                                            </v-layout> 
                                        </span>

                                        <v-layout class="mx-3 mt-2 mb-2 grey--text text--darken-3">
                                            <div class="not-f3 font-weight-bold">TOTAL VAT</div>
                                            <v-spacer/>
                                            <div class="not-f3 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.total_vat)}}</div>
                                        </v-layout>   
                                    </span>

                                    <v-list-item  dense  class="grey--text text--darken-2" v-if="false" >
                                        <v-list-item-content >
                                            <span class='mb-2'>
                                                <div class="not-f2 font-weight-bold">TAXABLE A-16.5%</div>
                                                <div class="not-f2 font-weight-bold">VAT A-16.5%</div>  
                                            </span>
                                            <span class='mb-2'>
                                                <div class="not-f2 font-weight-bold">TAXABLE B-0%</div> 
                                                <div class="not-f2 font-weight-bold">VAT B-0%</div>  
                                            </span>
                                            <span>
                                                <div class="not-f2 font-weight-bold">TAXABLE C-0%</div> 
                                                <div class="not-f2 font-weight-bold">VAT C-0%</div>  
                                            </span>
                                            
                                            <div class="not-f3 font-weight-bold grey--text text--darken-3">TOTAL VAT</div> 
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <span class='mb-2'>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.taxable_a)}}</div>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.vat_a)}}</div>  
                                            </span>
                                            <span class='mb-2'>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.taxable_b)}}</div> 
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.vat_b)}}</div>  
                                            </span>
                                            <span>
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.taxable_c)}}</div> 
                                                <div class="not-f2 font-weight-bold"> {{MBS.actions.money(CalculatedVAT.vat_c)}}</div>  
                                            </span>
                                            <div class="not-f3 font-weight-bold grey--text text--darken-3"> {{MBS.actions.money(CalculatedVAT.total_vat)}}</div> 
                                        </v-list-item-action>   
                                    </v-list-item> 
                                </div>
                                
                                <v-divider class="mx-3"></v-divider> 
                                <v-list-item     class="grey--text text--darken-3 mx- px-2" >
                                    <v-list-item-content>
                                        <v-layout class="ma-0 " align-center>
                                            <div class="font-weight-bold not-f6">TOTAL PRICE:</div>
                                            <!-- <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(SELECTED_ITEMS)}} items</div>  -->
                                        </v-layout>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-layout class="ma-0" align-center>
                                            <div class="font-weight-bold not-f3 pt-1 ">MWK </div>
                                            <div class="font-weight-bold not-f6">{{MBS.actions.money(TotalDiscountedPrice)}}</div>
                                        </v-layout> 
                                    </v-list-item-action>   
                                </v-list-item>
                                <v-divider class="mx-3"></v-divider>
                            </v-list>  
                        </v-card> 
                        <v-layout v-else-if="!SELECTED_ITEMS" fill-height align-center justify-center >
                            No Item
                        </v-layout> 
                    </v-layout> 
                </v-card>
            </v-flex>
            <v-flex xs12 sm6 md7 class="py-0 ma-0">
                <v-card height="100%" class="mr-"  rounded="sm" outlined> 
                    <v-card-title >
                        <div class="font-weight-bold not-f8 grey--text text--darken-2">Amount to Pay</div>
                        <v-spacer></v-spacer>
                        <v-card outlined>
                            <div class="font-weight-bold not-f9 pa-5 grey--text text--darken-1">
                                {{SELECTED_CARD_OPTION=='load_money'?MBS.actions.money(pay_order.card_load_amount): MBS.actions.money(TotalDiscountedPrice)}}</div>
                        </v-card>
                    </v-card-title>
                    <v-card-text>
                        <form @submit.prevent="SUBMIT_ORDER(pay_order,'on_account')">
                            <v-btn @click="SUBMIT_ORDER(pay_order,'card_payment')" v-if="SELECTED_CARD_OPTION=='pay'"    color="secondary" class="my-5" 
                                    min-width="200" height="50"  > 
                                <v-icon>mdi-credit-card-outline</v-icon>
                                <span class="pl-2">Pay</span>
                            </v-btn>  
                            <span v-else>
                                <v-btn 
                                    @click="ON_PAY()"
                                    :loading="inputLoading"
                                    color="secondary" class="my-5" 
                                    min-width="200" height="50" 
                                    large><div class="not-f6">Pay</div>
                                </v-btn>
                                <v-menu v-if="MBS.actions.SIZE(PaymentMethodsData)>0"
                                    v-model="menuPaymentOptions" 
                                    :close-on-content-click="false" 
                                    offset-y  offset-overflow
                                    :nudge-width="90" rounded="xl"
                                    >
                                    <template v-slot:activator="{ on }">  
                                        <v-btn v-on="on" 
                                            :loading="inputLoading"
                                            color="primary" class="my-5 mx-2" 
                                            min-width="200" height="50" 
                                            large><div class="not-f6">Pay Options</div> <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                        </v-btn>
                                    </template> 
    
                                    <v-card>
                                        <v-card-title primary-title class="secondary white--text"  >
                                            Select Payment Option
                                        </v-card-title>
                                        <v-list>
                                            <v-list-item v-for="(payment_option, index) in PaymentMethodsData" :key="index" @click="SELECT_PAYMENT_OPTION(payment_option)">
                                                {{payment_option.name}}
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-menu>  
                            </span>
                            
                            <v-card outlined :height="vsh-470" class=" mbs-scroll-y"> 
                                <v-card-text> 
                                    <mbs-input-fields  
                                        v-model="input"
                                        :field_items="DATA.FIELDS.sale_on_account"
                                        :select_items="SelectInputFieldsItems" 
                                        :disabled_fields="DisabledOnAccountFields" 
                                        px="1" py="1"
                                        /> 
                                </v-card-text>
                            </v-card>
                            <v-layout row wrap class="mt-5">
                                <v-col cols="12" sm="4" >
                                    <v-btn disabled large color="secondary darken-2" width="100%">Layby</v-btn>
                                </v-col>
                                <v-col cols="12" sm="4" class="px-0">
                                    <v-btn type="submit" @click="created_document_type='quotation'" large color="secondary darken-4" width="100%">Create Quotation</v-btn>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-btn type="submit" @click="created_document_type='invoice'"  large color="secondary darken-2" width="100%">On Account (Invoice)</v-btn>
                                </v-col>
                            </v-layout> 
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-flex> 
        </v-layout> 
    </div>  

    <!-- DIALOG -->
    <div>
        <!-- START SEELING -->
        <v-dialog
            v-model="dialog_start_selling"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="700px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_start_selling" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >START SELLING</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_start_selling=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <v-layout   justify-center> 
                        <v-icon size="150">$printer-pos-play</v-icon>
                    </v-layout>
                    <form @submit.prevent="CONFIRM_START_SELLING(input.password)"> 
                        <v-layout row wrap class="mx-0 my-6"  > 
                            <v-flex xs12 >
                                <div class="mb-2 ml-">Enter your password to confirm opening this register</div>
                                <v-text-field required prepend-inner-icon="lock"
                                    v-model="input.password"
                                    filled outlined
                                    label="" autofocus clearable
                                    type="password" autocomplete="one-time-code"  
                                    hint="0.00" class="mr-1"  />  
                            </v-flex> 
                            <v-flex xs12   class="my-3">
                                <v-layout row wrap class="ma-0 pr-1">
                                    
                                    <v-btn   @click="dialog_start_selling=false"
                                        :loading="inputLoading" class="mr-2"
                                           color="primary" height="50"  >
                                        <div class="not-f5">CANCEL</div>
                                    </v-btn> 
                                    <v-flex  >
                                        <v-btn   
                                            type="submit"   
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%" >
                                            <div class="not-f5">
                                                <!-- <v-icon class="pr-1 pb-1">mdi-paperclip-plus</v-icon> -->
                                                CONFIRM PASSWORD
                                            </div>
                                        </v-btn>      
                                    </v-flex>    
                                </v-layout>
                            </v-flex> 
                        </v-layout>  
                    </form>
                </v-card-text>  
            </v-card> 
        </v-dialog> 
        
        <!-- ACTION MENU -->
        <v-dialog
            v-model="dialog_action_menu"
            scrollable   
            :persistent="true" :overlay="false"
            max-width="300px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="SELECTED_ACTION_MENU" > 
                <v-card-title :class="'primary'">
                    <v-avatar tile 
                        :style="'--size:1px; --color:white;'"
                        size="20"
                        class="mr-2 mbs-border"
                        :color="SELECTED_ACTION_MENU.color" > 
                    </v-avatar>
                    <div class="font-weight-bold b2--text"> 
                        <span >{{SELECTED_ACTION_MENU.name}}</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_action_menu=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                
                <v-list dense class=" "> 
                    <div v-if="SELECTED_ACTION_MENU.menu">
                        <v-list-item 
                            v-for="(item,index) in SELECTED_ACTION_MENU.menu.items" 
                            @click="
                                ITEM_ACTION(item.action,item,{menu:true})
                                dialog_action_menu=false
                            ">
                            <v-list-item-action><v-icon>{{ item.icon }}</v-icon></v-list-item-action>
                            <v-list-item-content> 
                                {{item.name}}
                            </v-list-item-content>
                            <v-list-item-action v-if="item.menu"> 
                                <v-icon class="ml-2 ">mdi-chevron-down</v-icon>
                            </v-list-item-action> 
                        </v-list-item>  
                    </div> 
                </v-list> 
                <v-card-text>
                     
                </v-card-text>  
            </v-card> 
        </v-dialog> 

        <!-- DRAWER BEFORE SEELING -->
        <v-dialog
            v-model="dialog_open_drawer_before_selling"
            scrollable   
            :persistent="true" :overlay="false"
            max-width="700px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_open_drawer_before_selling" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >CASHUP</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_open_drawer_before_selling=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <v-layout   justify-center> 
                        <v-btn :loading="inputLoading" icon class="my-9" @click="OPEN_DRAWER()">
                            <v-icon size="150">mdi-archive-arrow-down-outline</v-icon> 
                        </v-btn>
                    </v-layout>
                    <form @submit.prevent="CONFIRM_OPENING_REGISTER()"> 
                        <v-layout row wrap class="mx-0 my-6"  > 
                            <v-flex xs12 >
                                <div class="mb-2 ml-3">
                                    You must count cash before selling. This helps monitor the flow of cash. 
                                </div>
                                
                            </v-flex> 
                            <v-layout >
                                <v-card outlined >
                                    <v-simple-table  fixed-header   >
                                        <template v-slot:default>
                                            <thead class="mbs-one-line" > 
                                                <tr >
                                                    <th class="text-uppercase primary--text b2">
                                                    </th>
                                                    <th class="text-uppercase black--text center-text b2 " > 
                                                        Notes/Coins (CASH) 
                                                    </th>
                                                    <th class="text-uppercase text-center black--text b2">
                                                        QTY (No.)
                                                    </th>
                                                    <th class="text-uppercase text-center black--text b2 ">
                                                        AMOUNT (MWK)
                                                    </th>  
                                                </tr>  
                                                <br>
                                            </thead>
                                            <tbody class="mt-5">
                                                <tr class="mt-5"
                                                    v-for="(item,index) in currency_list"
                                                    :key="index" >
                                                     
                                                    <td> 
                                                        
                                                    </td>
                                                    <td class="text-right"> 
                                                        {{item.name}}
                                                    </td>
                                                    <td class="text-center pa-0"> 
                                                        <v-text-field   full-width
                                                            :required="false"
                                                            prepend-inner-icon="mdi-pound"
                                                            v-model="item.quantity"
                                                            filled  outlined  v-mask="currencyMask"
                                                            label=""  
                                                            type=""
                                                            hint=""   />  
                                                    </td>
                                                    <td class="pa">
                                                        <v-text-field disabled prepend-inner-icon="mdi-cash-multiple" 
                                                            filled outlined  :value="MBS.actions.money(toNumber(item.value )* toNumber(item.quantity))"
                                                            label=""   
                                                            class="mr-1"  />   
                                                    </td>  
                                                </tr>
                                                <br>
                                                <tr > 
                                                    <td class="b1 darken-1">  
                                                    </td>
                                                    <td class="text-right b1 darken-1"> 
                                                         
                                                    </td>
                                                    <td class="text-center  font-weight-bold b1 darken-1">
                                                        TOTAL AMOUNT
                                                    </td>
                                                    <td class="text-center font-weight-bold b1 darken-2">
                                                         {{ MBS.actions.money(TotalCurrency) }}
                                                    </td>  
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table> 
                                </v-card> 
                            </v-layout>
                            <v-flex xs12   class="my-3">
                                <v-layout row wrap class="ma-0 pr-1">
                                    
                                    <v-btn   @click="dialog_open_drawer_before_selling=false"
                                        :loading="inputLoading" class="mr-2"
                                           color="primary" height="50"  >
                                        <div class="not-f5">CANCEL</div>
                                    </v-btn> 
                                    <v-flex  >
                                        <v-btn   
                                            type="submit"   
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%" >
                                            <div class="not-f5">
                                                <!-- <v-icon class="pr-1 pb-1">mdi-paperclip-plus</v-icon> -->
                                                START SELLING
                                            </div>
                                        </v-btn>      
                                    </v-flex>    
                                </v-layout>
                            </v-flex> 
                        </v-layout>  
                    </form>
                </v-card-text>  
            </v-card> 
        </v-dialog>  

        <!-- OPEN DRAWER -->
        <v-dialog
            v-model="dialog_open_drawer"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="700px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_open_drawer" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >OPEN DRAWER</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_open_drawer=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <v-layout   justify-center> 
                        <v-icon size="150">mdi-archive-arrow-down-outline</v-icon>
                    </v-layout>
                    <form @submit.prevent="CONFIRM_OPEN_DRAWER(input.password)" > 
                        <v-layout row wrap class="mx-0 my-6"  > 
                            <v-flex xs12 >
                                <div class="mb-2 ml-">Enter your password to confirm opening drawer</div>
                                <v-text-field required prepend-inner-icon="lock"
                                    v-model="input.password"
                                    filled outlined
                                    label="" autofocus clearable
                                    type="password" 
                                    hint="0.00" class="mr-1"  
                                    autocomplete="one-time-code"  
                                    />    
                            </v-flex> 
                            <v-flex xs12   class="my-3">
                                <v-layout row wrap class="ma-0 pr-1">
                                    
                                    <v-btn   @click="dialog_open_drawer=false"
                                        :loading="inputLoading" class="mr-2"
                                           color="primary" height="50"  >
                                        <div class="not-f5">CANCEL</div>
                                    </v-btn> 
                                    <v-flex  >
                                        <v-btn   
                                            type="submit"   
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%" >
                                            <div class="not-f5">
                                                <!-- <v-icon class="pr-1 pb-1">mdi-paperclip-plus</v-icon> -->
                                                OPEN CASH DRAWER
                                            </div>
                                        </v-btn>      
                                    </v-flex>    
                                </v-layout>
                            </v-flex> 
                        </v-layout>  
                    </form>
                </v-card-text>  
            </v-card> 
        </v-dialog>  
        
        <!-- View ORDERS list Option DIALOG -->
        <v-dialog
            v-model="dialog_view_orders_list"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="900px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_view_orders_list" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >ORDER(S)</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_view_orders_list=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <mbs-page-tab
                    v-model="currentOrderOptionTab"
                    color="b2"
                    text_color="black" dark
                    :items="TabItems"/> 
                <v-card-text>
                    <br> 
                    <v-card outlined class="mbs-scroll-y"  :height="vsh-355">
                        <v-list class=""
                            :bench="2"
                            :items="PageItemOrders"
                           
                            item-height="70" >
                            <!-- <template v-slot="{ item,index }"> -->
                            <template v-for="(item,index) in PageItemOrders">
                                 
                                <v-list-item @click="ON_VIEW_ORDER(item,index)"> 
                                    <v-list-item-action>  
                                        <v-layout row wrap> 
                                            <v-avatar :class="item.expand?'':''"
                                                size="40"   
                                                rounded="lg" class="ml-2"
                                                color="grey"  >
                                                <v-img v-if="item.image"></v-img>
                                                <v-icon v-else>shopping_cart</v-icon>
                                            </v-avatar> 
                                        </v-layout>
                                    </v-list-item-action>  
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.customer_name}}</v-list-item-title>
                                        <v-list-item-subtitle>Items: {{item.total_quantity_}}</v-list-item-subtitle>
                                        
                                    </v-list-item-content>
                                        
                                    <v-list-item-action><v-card color="transparent" flat width="100">
                                        <v-layout class="ma-0">
                                            <v-spacer></v-spacer>
                                            {{item.total_sold_price_}}
                                        </v-layout>
                                    </v-card></v-list-item-action>  
                                    <v-list-item-action >
                                        <!-- <v-btn @click="CONFIRM_REMOVE_ITEM(item,index)" 
                                        :disabled="inputLoading" small icon><v-icon>delete</v-icon></v-btn> -->
                                        <v-btn  icon> 
                                            <v-icon >chevron_right</v-icon>
                                        </v-btn> 
                                    </v-list-item-action> 
                                </v-list-item> 
                                <v-divider  /> 
                            </template>
                        </v-list> 
                    </v-card> 
                    <v-layout row wrap class="ma-0 mt-3"> 
                        <v-btn   @click="dialog_view_orders_list=false"
                            :loading="inputLoading" class="mr-2"
                               color="primary" height="50"  >
                            <div class="not-f5 mx-5">EXIT</div>
                            <v-icon>mdi-exit-to-app</v-icon>
                        </v-btn> 
                        <v-flex  >
                            <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER()" 
                                type="submit"   
                                :loading="inputLoading"
                                   color="secondary" height="50" width="100%" >
                                <div class="not-f5">
                                    <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                    Clear All
                                </div>
                            </v-btn>      
                        </v-flex>    
                    </v-layout>
                </v-card-text>  
            </v-card> 
        </v-dialog>  
        
        <!-- View Customers ORDERS DIALOG -->
        <v-dialog
            v-model="dialog_view_customers_orders"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="900px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_view_customers_orders" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >CUSTOMERS ORDER(S)</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_view_customers_orders=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <!-- <mbs-page-tab
                    v-model="currentOrderOptionTab"
                    color="b2"
                    text_color="black" dark
                    :items="TabItems"/>  -->
                <v-card-text>
                    <br>  
                    <v-card-title>
                        {{MBS.actions.TEXT_UP(DATA.ITEMS.CUSTOMERS.names)}}  
                        <v-spacer></v-spacer> 
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            single-line 
                            clearable
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card outlined class="mbs-scroll-y"  :height="vsh-355"> 
                        <v-list v-if="MBS.actions.SIZE(CustomerItemOrders)!=0" class=""
                            :bench="2"
                            :items="CustomerItemOrders" 
                            item-height="70" >
                            <!-- <template v-slot="{ item,index }"> -->
                            <template v-for="(item,index) in CustomerItemOrders"> 
                                <v-list-item @click="ON_VIEW_CUSTOMER_ORDER(item,index)"> 
                                    <v-list-item-action>  
                                        <v-layout row wrap> 
                                            <v-avatar :class="item.expand?'':''"
                                                size="40"   
                                                rounded="lg" class="ml-2"
                                                color="grey"  >
                                                <v-img v-if="item.image"></v-img>
                                                <v-icon v-else>mdi-account</v-icon>
                                            </v-avatar> 
                                        </v-layout>
                                    </v-list-item-action>  
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.name}} </v-list-item-title>
                                        <v-list-item-subtitle>Order(s): {{item.orders_size}}</v-list-item-subtitle>
                                        
                                    </v-list-item-content>
                                        
                                    <v-list-item-action><v-card color="transparent" flat width="100">
                                        <v-layout class="ma-0" align-baseline justify-end>
                                            <v-spacer></v-spacer>  
                                            <span class="not-f2 pr-1">MWK</span>
                                            <div class=" not-f5 font-weight-bold grey--text text--darken-2">{{MBS.actions.money(item.total_sold_price)}}</div>
                                            
                                        </v-layout>
                                    </v-card></v-list-item-action>  
                                    <v-list-item-action > 
                                        <v-btn  icon> 
                                            <v-icon >chevron_right</v-icon>
                                        </v-btn> 
                                    </v-list-item-action> 
                                </v-list-item> 
                                <v-divider  /> 
                            </template>
                        </v-list> 
                        <v-layout v-else fill-height justify-center align-center>
                            <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                <v-icon>warning</v-icon> 
                                <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                            </v-chip>
                            <v-chip v-else large class="px-5">
                                <v-icon>warning</v-icon> 
                                <div class="not-f6 ml-2 grey--text" >No Orders</div>
                            </v-chip>
                        </v-layout>
                    </v-card> 
                    <v-layout row wrap class="ma-0 mt-3"> 
                        <v-btn   @click="dialog_view_customers_orders=false"
                            :loading="inputLoading" class="mr-2"
                               color="primary" height="50"  >
                            <div class="not-f5 mx-5">EXIT</div>
                            <v-icon>mdi-exit-to-app</v-icon>
                        </v-btn> 
                        <v-flex  >
                            <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER()" 
                                type="submit"   
                                :loading="inputLoading"
                                   color="secondary" height="50" width="100%" >
                                <div class="not-f5">
                                    <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                    Clear All
                                </div>
                            </v-btn>      
                        </v-flex>    
                    </v-layout>
                </v-card-text>  
            </v-card> 
        </v-dialog>  

        <!-- View Options ORDERS DIALOG -->
        <v-dialog
            v-model="dialog_view_options_orders"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="900px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_view_options_orders" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >OPTIONS ORDER(S)</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_view_options_orders=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <!-- <mbs-page-tab
                    v-model="currentOrderOptionTab"
                    color="b2"
                    text_color="black" dark
                    :items="TabItems"/>  -->
                <v-card-text>
                    <br>  
                    <v-card-title>
                        {{MBS.actions.TEXT_UP("OPTIONS")}}  
                        <v-spacer></v-spacer> 
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            single-line 
                            clearable
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card outlined class="mbs-scroll-y"  :height="vsh-355"> 
                        <v-list v-if="MBS.actions.SIZE(OptionItemOrders)!=0" class=""
                            :bench="2"
                            :items="OptionItemOrders" 
                            item-height="70" >
                            <!-- <template v-slot="{ item,index }"> -->
                            <template v-for="(item,index) in OptionItemOrders"> 
                                <v-list-item @click="ON_VIEW_OPTION_ORDER(item,index)"> 
                                    <v-list-item-action>  
                                        <v-layout row wrap> 
                                            <v-avatar :class="item.expand?'':''"
                                                size="40"   
                                                rounded="lg" class="ml-2"
                                                color="grey"  >
                                                <v-img v-if="item.image"></v-img>
                                                <v-icon v-else>shopping_cart</v-icon>
                                            </v-avatar> 
                                        </v-layout>
                                    </v-list-item-action>  
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.name}} </v-list-item-title>
                                        <v-list-item-subtitle>Order(s): {{item.orders_size}}</v-list-item-subtitle>
                                        
                                    </v-list-item-content>
                                        
                                    <v-list-item-action><v-card color="transparent" flat width="100">
                                        <v-layout class="ma-0" align-baseline justify-end>
                                            <v-spacer></v-spacer>  
                                            <span class="not-f2 pr-1">MWK</span>
                                            <div class=" not-f5 font-weight-bold grey--text text--darken-2">{{MBS.actions.money(item.total_sold_price)}}</div>
                                            
                                        </v-layout>
                                    </v-card></v-list-item-action>  
                                    <v-list-item-action > 
                                        <v-btn  icon> 
                                            <v-icon >chevron_right</v-icon>
                                        </v-btn> 
                                    </v-list-item-action> 
                                </v-list-item> 
                                <v-divider  /> 
                            </template>
                        </v-list> 
                        <v-layout v-else fill-height justify-center align-center>
                            <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                <v-icon>warning</v-icon> 
                                <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                            </v-chip>
                            <v-chip v-else large class="px-5">
                                <v-icon>warning</v-icon> 
                                <div class="not-f6 ml-2 grey--text" >No Orders</div>
                            </v-chip>
                        </v-layout>
                    </v-card> 
                    <v-layout row wrap class="ma-0 mt-3"> 
                        <v-btn   @click="dialog_view_options_orders=false"
                            :loading="inputLoading" class="mr-2"
                            color="primary" height="50" >
                            <v-layout  justify-center align-center class="not-f5 mx-5">EXIT <v-icon>mdi-exit-to-app</v-icon></v-layout>
                        </v-btn> 
                        <v-flex  >
                            <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER()" 
                                type="submit"   
                                :loading="inputLoading"
                                   color="secondary" height="50" width="100%" >
                                <div class="not-f5">
                                    <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                    Clear All
                                </div>
                            </v-btn>      
                        </v-flex>    
                    </v-layout>
                </v-card-text>  
            </v-card> 
        </v-dialog>  
        
        <!-- View ORDER  DIALOG -->
        <v-dialog
            v-model="dialog_view_order"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="700px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_view_order" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >ORDER</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_view_order=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>    
                <v-card-text class="b1">
                    <br>
                    <v-card outlined>
                        <v-card-text> 
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-receipt</v-icon>
                                <div>Customer:</div>
                                <v-spacer/>
                                <div class="ml-2 font-weight-bold">{{ SELECTED_ORDER.customer_name }}</div>
                            </v-layout>
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-receipt</v-icon>
                                <div>Total Amount:</div>
                                <v-spacer/>
                                <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ SELECTED_ORDER.total_sold_price_ }}</div>
                            </v-layout>
                        </v-card-text> 
                    </v-card>

                    <v-card outlined class="mt-2">
                        <v-list>
                            <v-list-item @click="
                                    SELECTED_ORDER.expand_items=!SELECTED_ORDER.expand_items 
                                    if (SELECTED_ORDER.expand_details) {
                                        SELECTED_ORDER.expand_details = false
                                    }
                                ">
                                <v-list-item-content><div class="font-weight-bold ">{{MBS.actions.SIZE(SELECTED_ORDER.joined_items)}} Item(s)</div></v-list-item-content>
                                <v-list-item-action>
                                    <v-btn  icon>
                                        <v-icon v-if="SELECTED_ORDER.expand_items">mdi-chevron-up</v-icon>
                                        <v-icon v-else>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list> 
                        <!-- <v-divider></v-divider> -->
                        <v-simple-table v-if="SELECTED_ORDER.expand_items"
                            fixed-header
                            :height="vsh-590">
                            <template v-slot:default>
                            <thead>
                                <tr class=" table-background ">
                                    <th class="text-left with-divide font-weight-bold">
                                        Name
                                    </th>
                                    <th class="text-left with-divide  ">
                                        price
                                    </th>
                                    <th class="text-left with-divide  ">
                                        Quantity
                                    </th>
                                    <th class="text-left with-divide ">
                                        Total Price
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in SELECTED_ORDER.joined_items"  >
                                    <td class="with-divider ">{{ item.name }}</td>
                                    <td class="with-divider">{{ MBS.actions.money(item.sold_price) }}</td>
                                    <td class="with-divider">{{ item.quantity }}</td>
                                    <td class="with-">{{ MBS.actions.money(item.quantity*item.sold_price) }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>

                    <v-card class="pa-2 mt-2" outlined>
                        <v-list>
                            <v-list-item @click="
                                        SELECTED_ORDER.expand_details=!SELECTED_ORDER.expand_details 
                                        if (SELECTED_ORDER.expand_items) {
                                            SELECTED_ORDER.expand_items=false
                                        }
                                    ">
                                <v-list-item-content><div class="font-weight-bold">Order Details</div></v-list-item-content>
                                <v-list-item-action>
                                    <v-btn  icon>
                                        <v-icon v-if="SELECTED_ORDER.expand_details">mdi-chevron-up</v-icon>
                                        <v-icon v-else>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list> 
                        <div v-if="SELECTED_ORDER.expand_details"> 
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-receipt</v-icon>
                                <div>Order Number:</div>
                                <div class="ml-2">{{ SELECTED_ORDER.key }}</div>
                            </v-layout>
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-cash-register</v-icon>
                                <div>Initiated By:</div>
                                <div class="ml-2">{{ SELECTED_ORDER.teller_name }}</div>
                            </v-layout>
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-clipboard-text-clock-outline</v-icon>
                                <div>Initiated Time:</div>
                                <div class="ml-2">{{ SELECTED_ORDER.created_at_ }}</div>
                            </v-layout>
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-timer-settings-outline</v-icon>
                                <div>Estimated Time:</div>
                                <div class="ml-2">{{ SELECTED_ORDER.estimated_time?SELECTED_ORDER.estimated_time:"..." }}</div>
                            </v-layout>
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-list-status</v-icon>
                                <div>Status:</div>
                                <div class="ml-2">{{ SELECTED_ORDER.estimated_time }}</div>
                            </v-layout>
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">home</v-icon>
                                <div>Description:</div>
                                <div class="ml-2">{{ SELECTED_ORDER.description }}</div>
                            </v-layout> 
                        </div>
                    </v-card>
                </v-card-text>  
                <v-card-actions>
                    <v-spacer/>
                    <!-- <v-btn color="primary" ><v-icon>close</v-icon>Cancel</v-btn>
                    <v-btn color="primary" ><v-icon>edit</v-icon>Edit</v-btn>
                    <v-btn color="primary" ><v-icon>mdi-cash</v-icon>Pay</v-btn> -->

                    <v-btn-toggle  dark  class="mb-2 mr-2" >
                        <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                            <span class="hidden-sm-and-down mx-3">EXIT</span>
                            <v-icon>mdi-exit-to-app</v-icon> 
                        </v-btn>
                        
                        <v-btn  @click="CONFIRM_DELETE_ITEM_ORDER(SELECTED_ORDER)"  color="primary" value="cancel">
                            <span class="hidden-sm-and-down">Cancel</span> 
                            <v-icon right>
                                mdi-delete
                            </v-icon>
                        </v-btn>

                        <v-btn @click="EDIT_ITEM_ORDER(SELECTED_ORDER)"  color="primary" value="center">
                            <span class="hidden-sm-and-down">Update</span> 
                            <v-icon right>
                                edit
                            </v-icon>
                        </v-btn>
                        
                        <v-btn @click="PRINT_ORDER_OPTION(SELECTED_ORDER,'selected_order')"  color="primary" value="center">
                            <span class="hidden-sm-and-down">Print</span> 
                            <v-icon right>
                                print
                            </v-icon>
                        </v-btn>

                        <v-btn  @click="PAY_ITEM_ORDER(SELECTED_ORDER)"  color="primary" value="right">
                            <span class="hidden-sm-and-down">Pay</span> 
                            <v-icon right>
                                mdi-cash
                            </v-icon>
                        </v-btn> 
                    </v-btn-toggle>
                </v-card-actions>
            </v-card> 
        </v-dialog>  
        
        <!-- View Selected Customer ORDER  DIALOG -->
        <v-dialog
            v-model="dialog_view_selected_customer_order"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="700px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="LiveSelectedCustomerOrder" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span>SELECTED CUSTOMER ORDER</span> 
                    </div> 
                    <v-chip small color="" class="ml-3">{{LiveSelectedCustomerOrder.orders_size}}</v-chip>
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_view_selected_customer_order=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>    
                
                <v-card-text class="b1">
                    <v-card outlined class="mt-3">
                        <v-card-text> 
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-receipt</v-icon>
                                <div>Customer:</div>
                                <v-spacer/>
                                <div class="ml-2 font-weight-bold">{{ LiveSelectedCustomerOrder.name }}</div>
                            </v-layout>
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-receipt</v-icon>
                                <div>Total Amount:</div>
                                <v-spacer/>
                                <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ MBS.actions.money(LiveSelectedCustomerOrder.total_sold_price) }}</div>
                            </v-layout>
                        </v-card-text> 
                    </v-card>
                    <div class="pa-2">
                        <div class="font-weight-bold">Order list</div>
                        <v-card outlined class="mbs-scroll-y"  :height="vsh-415">
                            <v-list class=""
                                :bench="2"
                                :items="PageItemOrders" 
                                item-height="70" > 
                                <template v-for="(item,index) in LiveSelectedCustomerOrder.orders">
                                    
                                    <v-list-item @click="ON_VIEW_ORDER(item,index)"> 
                                        <v-list-item-action>  
                                            <v-layout row wrap> 
                                                <v-avatar :class="item.expand?'':''"
                                                    size="40"   
                                                    rounded="lg" class="ml-2"
                                                    color="grey"  >
                                                    <v-img v-if="item.image"></v-img>
                                                    <v-icon v-else>shopping_cart</v-icon>
                                                </v-avatar> 
                                            </v-layout>
                                        </v-list-item-action>  
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.order_option?item.order_option.name:'...'}}</v-list-item-title>
                                            <v-list-item-subtitle>Items: {{item.total_quantity_}}</v-list-item-subtitle> 
                                        </v-list-item-content>
                                            
                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                            <v-layout class="ma-0">
                                                <v-spacer></v-spacer>
                                                <div>
                                                    {{item.total_sold_price_}} 
                                                </div>
                                            </v-layout>
                                        </v-card></v-list-item-action>  
                                        <v-list-item-action > 
                                            <v-btn  icon> 
                                                <v-icon >chevron_right</v-icon>
                                            </v-btn> 
                                        </v-list-item-action> 
                                    </v-list-item> 
                                    <v-divider  /> 
                                </template>
                            </v-list> 
                        </v-card> 
                    </div>
                </v-card-text>  
                <v-card-actions>
                    <v-spacer/> 

                    <v-btn-toggle  dark  class="mb-2 mr-2" >
                        <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                            <span class="hidden-sm-and-down mx-3">EXIT</span>  
                            <v-icon>mdi-exit-to-app</v-icon>
                        </v-btn>
                        
                        <v-btn  @click="CONFIRM_DELETE_CUSTOMER_ITEM_ORDER(LiveSelectedCustomerOrder)"  color="primary" value="left">
                            <span class="hidden-sm-and-down">Cancel All</span> 
                            <v-icon right>
                                mdi-delete
                            </v-icon>
                        </v-btn> 
                        
                        <v-btn @click="PRINT_ORDER_OPTION(LiveSelectedCustomerOrder,'customer_orders')"  color="primary" value="center">
                            <span class="hidden-sm-and-down">Print Summary</span> 
                            <v-icon right>
                                print
                            </v-icon>
                        </v-btn>

                        <v-btn  @click="PAY_ORDER_OPTION(LiveSelectedCustomerOrder)"  color="primary" value="right">
                            <span class="hidden-sm-and-down">Pay All</span> 
                            <v-icon right>
                                mdi-cash
                            </v-icon>
                        </v-btn> 
                    </v-btn-toggle>
                </v-card-actions>
            </v-card> 
        </v-dialog>

        <!-- View Selected OPTION ORDER  DIALOG -->
        <v-dialog
            v-model="dialog_view_selected_option_order"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="700px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="LiveSelectedOptionOrder" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span>SELECTED OPTION ORDER</span> 
                    </div> 
                    <v-chip small color="" class="ml-3">{{LiveSelectedOptionOrder.orders_size}}</v-chip>
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_view_selected_option_order=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>    
                
                <v-card-text class="b1">
                    <v-card outlined class="mt-3">
                        <v-card-text> 
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-receipt</v-icon>
                                <div>Option Order:</div>
                                <v-spacer/>
                                <div class="ml-2 font-weight-bold">{{ LiveSelectedOptionOrder.name }}</div>
                            </v-layout>
                            <v-layout align-center class="my-1">
                                <v-icon class="mr-2">mdi-receipt</v-icon>
                                <div>Total Amount:</div>
                                <v-spacer/>
                                <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ MBS.actions.money(LiveSelectedOptionOrder.total_sold_price) }}</div>
                            </v-layout>
                        </v-card-text> 
                    </v-card>
                    <div class="pa-2">
                        <div class="font-weight-bold">Order list</div>
                        <v-card outlined class="mbs-scroll-y"  :height="vsh-415">
                            <v-list class=""
                                :bench="2"
                                :items="PageItemOrders" 
                                item-height="70" > 
                                <template v-for="(item,index) in LiveSelectedOptionOrder.orders">
                                    
                                    <v-list-item @click="ON_VIEW_ORDER(item,index)"> 
                                        <v-list-item-action>  
                                            <v-layout row wrap> 
                                                <v-avatar :class="item.expand?'':''"
                                                    size="40"   
                                                    rounded="lg" class="ml-2"
                                                    color="grey"  >
                                                    <v-img v-if="item.image"></v-img>
                                                    <v-icon v-else>mdi-account</v-icon>
                                                </v-avatar> 
                                            </v-layout>
                                        </v-list-item-action>  
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.customer?item.customer.name:'...'}}</v-list-item-title>
                                            <v-list-item-subtitle>Items: {{item.total_quantity_}}</v-list-item-subtitle> 
                                        </v-list-item-content>
                                            
                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                            <v-layout class="ma-0">
                                                <v-spacer></v-spacer>
                                                <div>
                                                    {{item.total_sold_price_}} 
                                                </div>
                                            </v-layout>
                                        </v-card></v-list-item-action>  
                                        <v-list-item-action > 
                                            <v-btn  icon> 
                                                <v-icon >chevron_right</v-icon>
                                            </v-btn> 
                                        </v-list-item-action> 
                                    </v-list-item> 
                                    <v-divider  /> 
                                </template>
                            </v-list> 
                        </v-card> 
                    </div>
                </v-card-text>  
                <v-card-actions>
                    <v-spacer/> 

                    <v-btn-toggle  dark  class="mb-2 mr-2" >
                        <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                            <span class="hidden-sm-and-down mx-3">EXIT</span>  
                            <v-icon>mdi-exit-to-app</v-icon>
                        </v-btn>
                        
                        <v-btn  @click="CONFIRM_DELETE_OPTION_ITEM_ORDER(LiveSelectedOptionOrder)"  color="primary" value="cancel">
                            <span class="hidden-sm-and-down">Cancel All</span> 
                            <v-icon right>
                                mdi-delete
                            </v-icon>
                        </v-btn> 
                        
                        <v-btn @click="PRINT_ORDER_OPTION(LiveSelectedOptionOrder,'option_orders')"  color="primary" value="center">
                            <span class="hidden-sm-and-down">Print Summary</span> 
                            <v-icon right>
                                print
                            </v-icon>
                        </v-btn>

                        <v-btn  @click="PAY_ORDER_OPTION(LiveSelectedOptionOrder)"  color="primary" value="right">
                            <span class="hidden-sm-and-down">Pay All</span> 
                            <v-icon right>
                                mdi-cash
                            </v-icon>
                        </v-btn> 
                    </v-btn-toggle>
                </v-card-actions>
            </v-card> 
        </v-dialog>  

        <!-- PUT ON ORDER OPTIONS -->
        <v-dialog
            v-model="dialog_put_on_order_option"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="800px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_put_on_order_option"> 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text">ORDER OPTION</div> 
                    <v-spacer></v-spacer>
                    <div class="font-weight-bold b1--text not-f7">mwk{{MBS.actions.money(TotalDiscountedPrice)}}</div>
                </v-card-title>
                <v-card-text>
                    <br>
                    <v-layout justify-center align-center v-if="toNumber(input.given_amount)>TotalDiscountedPrice">
                        <v-card class="text-center px-5 py-2" outlined>
                            <div class="font-weight-bold text_color1--text">Give change</div>
                            <div class="text-center  text_color--text not-f6">mwk {{MBS.actions.money(toNumber(input.given_amount)-TotalDiscountedPrice)}}</div>
                        </v-card>
                    </v-layout>
                    <div class="mb-2">Order details <strong v-if="paying_option">{{ }}</strong></div>
                    <form @submit.prevent="PUT_ON_ORDER_OPTION()"> 
                        <mbs-input-fields class="mx-3"
                            v-model="input"
                            :outlined="true"
                            :dense="false"
                            py="1" px="1"
                            :field_items="DATA.FIELDS.put_on_order_options"
                            :select_items="SelectInputFieldsItems"  
                            />  
                        <v-layout row wrap class="ma-0 " >
                            <!-- <v-flex xs6>
                                <div class="mb-2">Attach a Customer</div>
                                <v-autocomplete  
                                    filled outlined autofocus 
                                    prepend-inner-icon="mdi-account" 
                                    :items="Customers" 
                                    item-text="name"
                                    item-value="key" 
                                    v-model="input.customer_key"
                                    class="mr-1"
                                />  
                            </v-flex>
                            <v-flex xs6>
                                <div class="mb-2">Location</div>
                                <v-text-field  prepend-inner-icon="mdi-map" 
                                    v-model="input.location_name"
                                    filled outlined   clearable
                                    class="m-1"
                                ></v-text-field> 
                            </v-flex>
                            <v-flex xs12>
                                <div class="mb-2">Description</div> 
                                <v-textarea  
                                    v-model="input.description"
                                    filled outlined 
                                    class="m-1"
                                ></v-textarea> 
                            </v-flex> -->
                            <v-flex sm8>
                                <v-btn type="submit" large  
                                    @click="PUT_AND_PRINT = false"
                                    :loading="inputLoading"
                                    class="mt-3 "  color="primary" height="60" width="99%">
                                    <div class="not-f5">Put on {{ SELECTED_ORDER_OPTION?SELECTED_ORDER_OPTION.name:'..' }}</div>  
                                </v-btn>   
                            </v-flex> 
                            <v-flex sm4>
                                <v-btn type="submit" large  
                                    :loading="inputLoading"
                                    @click="PUT_AND_PRINT = true"
                                    class="mt-3 "  color="secondary" height="60" width="99%">
                                    <div class="not-f5">Put  & Print</div>  
                                </v-btn>   
                            </v-flex> 
                        </v-layout>  
                    </form>
                </v-card-text> 
            </v-card> 
        </v-dialog> 

        <!-- PRINT ORDERS -->
        <v-dialog
            v-model="dialog_print_order"
            :scrollable="true"  
            :persistent="false" :overlay="true"
            max-width="500px"
            transition="dialog-transition" >
            <v-card height="100%" rounded="sm" color="b1" v-if="dialog_print_order">
                <v-card-title class="primary pa-2 white--text" >
                    <div class="font-weight-bold ">SELECT PRINTER</div> 
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog_print_order=false"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <div outlined class="mbs-borders mx-2 mt-2 mb-2 pa-2" :style="'--size:1px; --color:grey; --conner:3px'">
                    <form @submit.prevent="PRINT_ITEM_ORDER(PRINTING_ORDER)" autofocus> 
                        <v-layout row wrap class="ma-0  mt-4"> 
                            <v-flex sm12 md12>
                                <v-card height="100%" hover>
                                    <v-layout column  class="ma-0" fill-height >
                                        <v-autocomplete  width="100%"
                                            solo outlined   dense autofocus
                                            :required ="true" 
                                            v-model="selected_printer_name"  
                                            :items="AllPrinters"
                                            item-text="name" 
                                            item-value="name" prepend-inner-icon="mdi-printer"
                                            label="Select Printer" 
                                            :filter="(item, queryText, itemText) => { 
                                                let index = (a,b)=>{
                                                    if(!a||!b){return false}
                                                    a = ''+a; b = ''+b
                                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                } 
                                                let first = index(itemText,queryText) 
                                                return first  
                                            }"
                                            class="mbs-border-0 mt-">
                                            
                                            <template v-slot:item="data" v-if="true">
                                                <template v-if="(typeof data.item !=='object')" >
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
    
                                                <!-- //ITEM ICON -->
                                                <template >
                                                    <v-list-item-avatar 
                                                        :tile="true"
                                                        :color="'grey'" > 
                                                        <v-icon v-if="data.item.isDefaultPrinter">mdi-printer-check</v-icon>
                                                        <v-icon v-else-if="data.item.is_active">mdi-printer</v-icon>
                                                        <v-icon v-else-if="data.item.is_company_printers">mdi-printer-pos-cog</v-icon>
                                                        <v-icon v-else>mdi-printer-alert</v-icon>
                                                    </v-list-item-avatar> 
                                                </template>
                                                <v-list-item-content>
                                                    <!-- //ITEM TITLES -->
                                                    <template >
                                                        <v-list-item-title >
                                                            {{data.item['name']}}
                                                        </v-list-item-title> 
                                                    </template>
                                                    <!-- //ITEM SUBTITLES -->
                                                    <template >
                                                        <v-list-item-subtitle >
                                                            <span v-if="data.item.name =='Other Installed Printer'">Use web browser config to print 
                                                            </span>
                                                            <span v-else-if="data.item.is_active">Configured & available</span>
                                                            <span v-else-if="data.item.is_company_printers">Configured but not available</span>
                                                            <span v-else>Only available</span> 
    
                                                            <strong class="secondary--text ml-5" v-if="data.item.printer_type_name">{{data.item.printer_type_name}}</strong> 
                                                            <!-- <strong class="secondary--text ml-5"  v-else-if="data.item.name =='Other Installed Printer'">{{BrowserData?BrowserData.name:'...'}}</strong>  -->
                                                            <!-- <strong class="secondary--text ml-5" v-else>{{BrowserOS?BrowserOS.name:'...'}}</strong>  -->
                                                        </v-list-item-subtitle> 
                                                    </template> 
                                                </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>    
                                        <v-card class="mx-2 text-center px-5 py-2" outlined v-if="toNumber(given_amount)>TotalDiscountedPrice">
                                            <div class="font-weight-bold text_color1--text">Give change</div>
                                            <div class="text-center  text_color--text not-f6"> {{MBS.actions.money(toNumber(given_amount)-TotalDiscountedPrice)}}</div>
                                        </v-card>
                                        <v-spacer></v-spacer>
                                        <v-btn type="submit"  
                                            :loading="inputLoading" class="my-2 font-weight-bold"
                                            :class="vs.smAndDown?'':'ml-2'"  
                                            color="secondary" :height="vs.smAndDown?'60':'90'" width="96%">
                                            <v-layout justify-center align-center class="not-f6"> 
                                                Print
                                                <v-icon class="ml-2">mdi-printer</v-icon> 
                                            </v-layout>
                                        </v-btn>   
                                    </v-layout> 
                                </v-card>
                            </v-flex> 
                        </v-layout>   
                    </form>
                </div> 
            </v-card> 
        </v-dialog> 
        
        <!-- SELECTED CARD -->
        <v-dialog
            v-model="dialog_selected_card"
            scrollable   
            :persistent="true" :overlay="false"
            max-width="800px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="SELECTED_CARD">
                <v-card-title class="primary">
                    <div>
                        <div class="font-weight-bold b2--text">
                            <span v-if="!SELECTED_CARD.card_customer_key">BLANK CARD</span>
                            <span v-else>CARD</span> 
                        </div> 
                        <small class="secondary--text">{{ MBS.actions.showCard(SELECTED_CARD.code) }}</small>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="font-weight-bold secondary--text not-f7 ">Bal: mwk{{MBS.actions.money(SELECTED_CARD.balance_amount)}}</div>
                    <v-btn class="ml-9" @click="DESELECT_CARD" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br>
                    <v-layout column class="py-2" justify-center align-center v-if="SELECTED_CARD.customer"> 
                        <v-card class=" px-5 " outlined>
                            <div class="text-center font-weight-bold grey--text text--darken-2 not-f5 pt-2 pb-1">{{SELECTED_CARD.customer_name}}</div>
                            <v-divider></v-divider>
                            <v-alert v-if="!SELECTED_CARD.active"
                                text class="ma-0 mt-3 py-2" outlined
                                color="error"
                                icon="error" >
                                    Card not Active.
                            </v-alert>
                            <v-alert  v-else
                                text class="ma-0 mt-3 py-2" outlined
                                color="success"
                                icon="mdi-credit-card-check" >
                                    Card is Active.
                            </v-alert>
                            <v-list class="pa-0">
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-avatar
                                            size="90"
                                            color="b2" > 
                                            <v-avatar
                                                size="40" tile  > 
                                                <v-icon>{{mdiAccountCreditCard}}</v-icon>
                                            </v-avatar> 
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Type:{{SELECTED_CARD.card_type_name}}</v-list-item-subtitle> 
                                        <v-list-item-subtitle>No.: {{SELECTED_CARD.code}}</v-list-item-subtitle> 
                                        <v-list-item-subtitle>Exp.: {{SELECTED_CARD.card_expire_date}}</v-list-item-subtitle> 
                                        <v-list-item-subtitle>Bal.: mwk{{MBS.actions.money(SELECTED_CARD.balance_amount)}}</v-list-item-subtitle>  
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        
                    </v-layout> 
                    <v-layout v-if="!SELECTED_CARD.card_customer_key" justify-center>
                        <v-avatar out class="mt-5"
                            color="b2" size="100" > 
                            <v-avatar  color="white"
                                size="98"  >
                                <VAvatar
                                    size="50"  tile> 
                                    <v-icon>{{mdiAccountCreditCard}}</v-icon>
                                </VAvatar>
                            </v-avatar> 
                        </v-avatar>
                    </v-layout>
                    <form @submit.prevent="!SELECTED_CARD.card_customer_key?
                        CARD_ATTACH_CUSTOMER(SELECTED_CARD,input.card_customer_key):
                        CREATE_CARD_ORDER(input.card_load_amount)">
                        
                        <v-layout row wrap class="mx-0 mb-3 " v-if="!SELECTED_CARD.card_customer_key"> 
                            <v-flex xs12 >
                                <div>Card Holder</div>
                                <v-autocomplete required 
                                    v-model="input.card_customer_key"
                                    filled outlined
                                    label="Select Customer"
                                    v-mask="currencyMask"
                                    hint="0.00" class="mr-1" 
                                    :item-text="'name'"
                                    :item-value="'key'"
                                    :items="CustomersData"/>  
                            </v-flex>  
                            <v-flex xs12   class="mt-3 mb-3">
                                <v-layout row wrap class="ma-0">
                                    <v-flex  >
                                        <v-btn v-if="!SELECTED_CARD.card_customer_key"  type="submit"   
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%" >
                                            <div class="not-f5"><v-icon class="pr-1 pb-1">mdi-paperclip-plus</v-icon> ATTACH CUSTOMER</div>
                                        </v-btn>   
                                        <v-btn v-else type="submit"   
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%" >
                                            <div class="not-f5">CONTINUE & PAY</div>
                                        </v-btn>    
                                    </v-flex> 
                                    <v-btn   @click="DESELECT_CARD"
                                        :loading="inputLoading" class="ml-2" 
                                           color="primary" height="50"  >
                                        <div class="not-f5">CANCEL</div>
                                    </v-btn>    
                                </v-layout>
                            </v-flex> 
                        </v-layout> 

                        <!-- //IF CUSTOMER -->
                        <v-layout row wrap class="mx-0 my-3 " v-else> 
                            <v-flex xs12 v-if="SELECTED_CARD_OPTION=='load_money'">
                                <div>Load Amount</div>
                                <v-text-field required 
                                    v-model="input.card_load_amount"
                                    filled outlined autofocus
                                    v-mask="currencyMask"
                                    hint="0.00" class="mr-1"
                                ></v-text-field>  
                            </v-flex> 
                            <v-flex xs12 v-if="SELECTED_CARD_OPTION!='load_money'">
                                <v-btn :disabled="!SELECTED_CARD.active"  @click="ON_CARD_LOAD_MONEY"
                                    :loading="inputLoading"   color="secondary" height="50" width="100%">
                                    <div class="not-f5">LOAD MONEY</div>
                                </v-btn>   
                            </v-flex> 
                            <v-flex xs12 v-if="SELECTED_CARD_OPTION!='load_money'">
                                <v-btn  :disabled="!SELECTED_CARD.active"   @click="CARD_PAY"
                                    class="my-3" 
                                    :loading="inputLoading"
                                       color="secondary" height="50" width="100%">
                                    <div class="not-f5">PAY</div>
                                </v-btn>   
                            </v-flex> 
                            <v-flex xs12 v-if="SELECTED_CARD_OPTION!='load_money'">
                                <v-layout row wrap class="ma-0">
                                    <v-flex  >   
                                        <v-btn   @click="VIEW_CARD_HISTORY"
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%">
                                            <div class="not-f5">CARD HISTORY</div>
                                        </v-btn>   
                                    </v-flex> 
                                    <!-- <v-btn   @click="DESELECT_CARD"
                                        :loading="inputLoading" class="ml-2" 
                                           color="primary" height="50"  >
                                        <div class="not-f5">CANCEL</div>
                                    </v-btn>     -->
                                </v-layout>
                            </v-flex> 
                             
                            <v-flex xs12 v-if="SELECTED_CARD_OPTION=='load_money'" class="mt-3 mb-3">
                                <v-layout row wrap class="ma-0">
                                    <v-flex >
                                        <v-btn type="submit"   
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%" >
                                            <div class="not-f5">CONTINUE & PAY</div>
                                        </v-btn>    
                                    </v-flex> 
                                    <v-btn   @click="DESELECT_CARD"
                                        :loading="inputLoading" class="ml-2" 
                                           color="primary" height="50"  >
                                        <div class="not-f5">CANCEL</div>
                                    </v-btn>    
                                </v-layout>
                            </v-flex> 
                        </v-layout> 
                    </form>
                </v-card-text> 
                <v-divider></v-divider>
                <v-card-actions class="b1 pa-0"  v-if="SELECTED_CARD.card_customer_key">
                    <v-btn @click="DESELECT_CARD" height="50" color="primary" text width="100%">CLOSE</v-btn>
                </v-card-actions>
            </v-card> 
        </v-dialog> 
        
        <!-- PAY RECEIPT -->
        <v-dialog
            v-model="dialog_given_amount"
            :scrollable="true"  
            :persistent="false" :overlay="true"
            max-width="800px"
            transition="dialog-transition" >
            <v-card height="100%" rounded="sm" color="b1" v-if="dialog_given_amount">
                <v-card-title class="primary pa-2">
                    <div class="font-weight-bold grey--text">BILL NO. {{ pay_order.key }}</div> 
                    <v-spacer></v-spacer>
                    <v-card outlined class="primary  px-4"> 
                        <span class="font-weight-bold secondary--text mr-1">TOTAL</span>
                        <span class="font-weight-bold b1--text not-f7">mwk{{MBS.actions.money(TotalDiscountedPrice)}}</span>
                    </v-card>
                </v-card-title>
                <div outlined class="mbs-borders mx-2 mt-2 mb-2 pa-2" :style="'--size:1px; --color:grey; --conner:3px'">
                    <form @submit.prevent="SUBMIT_ORDER(pay_order,'multiple')">
                        <v-card hover>
                            
                            <v-simple-table dense>
                                <template v-slot:default>
                                <thead>
                                    <tr class="primary ">
                                        <th class="text-left primary darken-1 white--text">
                                            Pay Option
                                        </th>
                                        <th class="text-left white--text  ">
                                            Amount
                                        </th>
                                        <th class="text-left white--text primary lighten-1 ">
                                            Description (Or Transaction ID)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  class="pa-0"  >
                                        <td class="b2   pa-0" >
                                            <v-layout class="mbs-input ma-0 px-2"  align-center>  
                                                CASH
                                            </v-layout>
                                        </td>
                                        <td class="pa-0">
                                            <div  class="mbs-input" > 
                                                <input type="number"
                                                    autofocus 
                                                    @input="DISPLAY_TOTAL_PRICE(MBS.actions.money(given_amount-TotalDiscountedPrice))"
                                                    ref="ref_payment_cash"
                                                    v-model="input.payment_cash"  
                                                    class=""
                                                    @focus="FOCUS_INPUT({value:'payment_cash',ref:'ref_payment_cash'})"
                                                    />
                                            </div>
                                        </td> 
                                        <td class="pa-0">
                                            <div class="mbs-input" > 
                                                <input class=""
                                                    ref="ref_payment_description_cash"
                                                    v-model="input.payment_description_cash"  
                                                    class=""
                                                    @focus="FOCUS_INPUT({value:'payment_description_cash',ref:'ref_payment_description_cash'})"/>
                                            </div>
                                        </td> 
                                    </tr>
                                    <tr  class="pa-0"
                                        v-for="item in PaymentMethodsData"
                                        :key="item.name" >
                                        <td class="b2   pa-0" >
                                            <v-layout class="mbs-input  ma-0 px-2" align-center ju >  
                                                {{ item.name }}
                                            </v-layout>
                                        </td>
                                        <td class="pa-0">
                                            <div  class="mbs-input" > 
                                                <input @input="DISPLAY_TOTAL_PRICE(MBS.actions.money(given_amount-TotalDiscountedPrice))"
                                                    :ref="'ref_payment_'+item.key" type="number"
                                                    v-model="input['payment_'+item.key]"  
                                                    @focus="FOCUS_INPUT({value:'payment_'+item.key,ref:'ref_payment_'+item.key})"
                                                    class=""/>
                                            </div>
                                        </td> 
                                        <td class="pa-0">
                                            <div class="mbs-input" > 
                                                <input class=""
                                                :ref="'ref_payment_description_'+item.key"
                                                v-model="input['payment_description_'+item.key]"  
                                                @focus="FOCUS_INPUT({value:'payment_description_'+item.key,ref:'ref_payment_description_'+item.key})"/>
                                            </div>
                                        </td> 
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table> 
                        </v-card>

                        <v-layout row wrap class="ma-0  mt-4">
                            <v-flex sm12 md6> 
                                <v-card height="100%" hover class="mr-2 "> 
                                    <v-layout justify-center align-center fill-height class="mbs-border primary lighten-5  pa-2" :style="'--size:1px; --color:grey; --conner:3px'"> 
                                        <mbs-keyboard 
                                            :type="'number'"
                                            :row="'5'"
                                            :key_function="true"
                                            />
                                    </v-layout>
                                </v-card>
                            </v-flex>
                            <v-flex sm12 md6>
                                <v-card height="100%" hover>
                                    <v-layout column  class="ma-0" fill-height >
                                        <v-autocomplete  width="100%"
                                            solo outlined   dense
                                            :required ="true" :disabled="!AllowChoosingPrinter"
                                            v-model="selected_printer_name"  
                                            :items="AllPrinters"
                                            item-text="name" 
                                            item-value="name" prepend-inner-icon="mdi-printer"
                                            label="Select Printer" 
                                            :filter="(item, queryText, itemText) => { 
                                                let index = (a,b)=>{
                                                    if(!a||!b){return false}
                                                    a = ''+a; b = ''+b
                                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                } 
                                                let first = index(itemText,queryText) 
                                                return first  
                                            }"
                                            class="mbs-border-0 mt-">
                                            
                                            <template v-slot:item="data" v-if="true">
                                                <template v-if="(typeof data.item !=='object')" >
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
    
                                                <!-- //ITEM ICON -->
                                                <template >
                                                    <v-list-item-avatar 
                                                        :tile="true"
                                                        :color="'grey'" > 
                                                        <v-icon v-if="data.item.isDefaultPrinter">mdi-printer-check</v-icon>
                                                        <v-icon v-else-if="data.item.is_active">mdi-printer</v-icon>
                                                        <v-icon v-else-if="data.item.is_company_printers">mdi-printer-pos-cog</v-icon>
                                                        <v-icon v-else>mdi-printer-alert</v-icon>
                                                    </v-list-item-avatar> 
                                                </template>
                                                <v-list-item-content>
                                                    <!-- //ITEM TITLES -->
                                                    <template >
                                                        <v-list-item-title >
                                                            {{data.item['name']}}
                                                        </v-list-item-title> 
                                                    </template>
                                                    <!-- //ITEM SUBTITLES -->
                                                    <template >
                                                        <v-list-item-subtitle >
                                                            <span v-if="data.item.name =='Other Installed Printer'">Use web browser config to print 
                                                            </span>
                                                            <span v-else-if="data.item.is_active">Configured & available</span>
                                                            <span v-else-if="data.item.is_company_printers">Configured but not available</span>
                                                            <span v-else>Only available</span> 
    
                                                            <strong class="secondary--text ml-5" v-if="data.item.printer_type_name">{{data.item.printer_type_name}}</strong> 
                                                            <!-- <strong class="secondary--text ml-5"  v-else-if="data.item.name =='Other Installed Printer'">{{BrowserData?BrowserData.name:'...'}}</strong>  -->
                                                            <!-- <strong class="secondary--text ml-5" v-else>{{BrowserOS?BrowserOS.name:'...'}}</strong>  -->
                                                        </v-list-item-subtitle> 
                                                    </template> 
                                                </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>   
                                        <v-card class="mx-2 text-center px-5 py-2" outlined v-if="toNumber(given_amount)>TotalDiscountedPrice">
                                            <div class="font-weight-bold text_color1--text">Give change</div>
                                            <div class="text-center  text_color--text not-f6"> {{MBS.actions.money(toNumber(given_amount)-TotalDiscountedPrice)}}</div>
                                        </v-card>
                                        <v-spacer></v-spacer>
                                        <v-btn type="submit"
                                            :loading="inputLoading" class="my-2 font-weight-bold"
                                            :class="vs.smAndDown?'':'ml-2'" 
                                            :disabled="(toNumber(given_amount)<TotalDiscountedPrice)||!given_amount" 
                                            color="secondary" :height="vs.smAndDown?'60':'90'" width="96%">
                                            <v-layout justify-center align-center> 
                                                <div class="not-f5 mt-1">Collect  MWK</div>
                                                <div class="not-f7">{{MBS.actions.money(toNumber(given_amount))}} </div>
                                            </v-layout>
                                        </v-btn>   
                                    </v-layout> 
                                </v-card>
                            </v-flex> 
                        </v-layout>   
                    </form>
                </div> 
            </v-card> 
        </v-dialog> 
        
        <!-- backup GIVEN AMOUNT -->
        <div v-if="false"> 
            <v-dialog
                v-model="dialog_given_amount"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_given_amount">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">Amount to Pay----</div> 
                        <v-spacer></v-spacer>
                        <div class="font-weight-bold b1--text not-f7">mwk{{MBS.actions.money(TotalDiscountedPrice)}}</div>
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <v-layout justify-center align-center v-if="toNumber(input.given_amount)>TotalDiscountedPrice">
                            <v-card class="text-center px-5 py-2" outlined>
                                <div class="font-weight-bold text_color1--text">Give change</div>
                                <div class="text-center  text_color--text not-f6">mwk {{MBS.actions.money(toNumber(input.given_amount)-TotalDiscountedPrice)}}</div>
                            </v-card>
                        </v-layout>
                        <div class="mb-2">Amount Given by Customer</div>
                        <form @submit.prevent="SUBMIT_ORDER(pay_order,'cash')">
                            <v-layout row wrap class="ma-0 ">
                                <v-flex sm12 md6>
                                    <v-text-field required  
                                        @input="DISPLAY_TOTAL_PRICE(MBS.actions.money(toNumber(input.given_amount)-TotalDiscountedPrice))"
                                        v-model="input.given_amount" 
                                        @focus="FOCUS_INPUT({value:'given_amount',ref:'ref_given_amount'})"
                                        prepend-inner-icon="mdi-account-cash"
                                        filled outlined autofocus 
                                         
                                        hint="0.00" class=" not-f6"
                                    ></v-text-field> 
                                </v-flex> 
                            </v-layout> 
                            <v-layout row wrap class="ma-0 ">
                                <v-flex sm12 md6>
                                    <v-card outlined class="pa-2 ">
                                        <v-layout justify-center >
                                            
                                            <mbs-keyboard 
                                                :type="'number'"
                                                :row="'5'"
                                                :key_function="true"
                                                />
                                        </v-layout>
                                        
                                    </v-card> 
                                </v-flex>
                                <v-flex sm12 md6>
                                    <v-btn type="submit"
                                        :loading="inputLoading"
                                        :class="vs.smAndDown?'':'ml-2'" :disabled="(toNumber(input.given_amount)<TotalDiscountedPrice)||!input.given_amount" color="secondary" :height="vs.smAndDown?'60':'89%'" width="100%">
                                        <div class="not-f5">Collect mwk{{MBS.actions.money(toNumber(input.given_amount))}} </div>
                                    </v-btn>   
                                </v-flex> 
                            </v-layout> 
                            
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog> 
        </div>
        
        <!-- PAID AMOUNT -->
        <v-dialog
            v-model="dialog_paid_amount"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="800px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_paid_amount"> 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text">Amount to Pay</div> 
                    <v-spacer></v-spacer>
                    <div class="font-weight-bold b1--text not-f7">mwk{{MBS.actions.money(TotalDiscountedPrice)}}</div>
                </v-card-title>
                <v-card-text>
                    <br>
                    <v-layout justify-center align-center v-if="toNumber(input.given_amount)>TotalDiscountedPrice">
                        <v-card class="text-center px-5 py-2" outlined>
                            <div class="font-weight-bold text_color1--text">Give change</div>
                            <div class="text-center  text_color--text not-f6">mwk {{MBS.actions.money(toNumber(input.given_amount)-TotalDiscountedPrice)}}</div>
                        </v-card>
                    </v-layout>
                    <div class="mb-2">Paying with <strong v-if="paying_option">{{paying_option.name}}</strong></div>
                    <form @submit.prevent="SUBMIT_ORDER(pay_order,'payment_option',paying_option)">
                        <v-layout row wrap class="ma-0 ">
                            <v-flex xs6>
                                <div class="mb-2">Paid Amount</div>
                                <v-text-field required
                                    filled outlined
                                    v-mask="currencyMask" prepend-inner-icon="mdi-account-cash" 
                                    disabled 
                                    v-model="input.given_amount"
                                    hint="0.00" class="mr-1"
                                ></v-text-field> 
                            </v-flex>
                            <v-flex xs6>
                                <div class="mb-2">Transaction ID</div>
                                <v-text-field required prepend-inner-icon="mdi-pound" 
                                    v-model="input.transaction_id"
                                    filled outlined autofocus clearable
                                    hint="0.00" class="m-1"
                                ></v-text-field> 
                            </v-flex>
                            <v-flex xs12>
                                <div class="mb-2">Description</div> 
                                <v-textarea  
                                    v-model="input.description"
                                    filled outlined 
                                    hint="0.00" class="m-1"
                                ></v-textarea> 
                            </v-flex>
                            <v-flex xs12>
                                <v-btn type="submit" large  
                                    :loading="inputLoading"
                                    class="mt-3" :disabled="(toNumber(input.given_amount) < toNumber(TotalDiscountedPrice))" color="secondary" height="60" width="100%">
                                    <div class="not-f5">Collect mwk{{MBS.actions.money(toNumber(input.given_amount))}} </div>  
                                </v-btn>   
                            </v-flex> 
                        </v-layout> 
                    </form>
                </v-card-text> 
            </v-card> 
        </v-dialog> 
        
        <!-- CHANGE iTEM PRICE -->
        <v-dialog
            v-model="dialog_change_item_price"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="800px"
            transition="dialog-transition" >
            <v-card rounded="lg">
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text">Amount to Pay</div> 
                    <v-spacer></v-spacer>
                    <div class="font-weight-bold b1--text not-f7">mwk{{MBS.actions.money(TotalDiscountedPrice)}}</div>
                </v-card-title>
                <v-card-text>
                    <br>
                    <v-layout justify-center align-center v-if="toNumber(input.given_amount)>TotalDiscountedPrice">
                        <v-card class="text-center px-5 py-2" outlined>
                            <div class="font-weight-bold text_color1--text">Give change</div>
                            <div class="text-center  text_color--text not-f6">mwk {{MBS.actions.money(toNumber(input.given_amount)-TotalDiscountedPrice)}}</div>
                        </v-card>
                    </v-layout>
                    <div class="mb-2">Amount Given by Customer</div>
                    <form @submit.prevent="SUBMIT_ORDER(pay_order,'cash')">
                        <v-layout row wrap class="ma-0 ">
                            <v-flex xs6>
                                <v-text-field required
                                    v-model="input.given_amount"
                                    filled outlined
                                    v-mask="currencyMask"
                                    hint="0.00" class="mr-1"
                                ></v-text-field> 
                            </v-flex>
                            <v-flex xs6>
                                <v-btn type="submit"
                                    :loading="inputLoading"
                                    class="ml-1" :disabled="(toNumber(input.given_amount)<TotalDiscountedPrice)||!input.given_amount" color="secondary" height="89%" width="100%">
                                    <div class="not-f5">Collect mwk{{MBS.actions.money(toNumber(input.given_amount))}} </div>
                                </v-btn>   
                            </v-flex> 
                        </v-layout> 
                    </form>
                </v-card-text> 
            </v-card> 
        </v-dialog> 
        
        <!-- CLOSE REGISTER -->
        <v-dialog
            v-model="dialog_close_register"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="800px"
            transition="dialog-transition" >
            <v-card rounded="lg">
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text">Amount to Pay</div> 
                    <v-spacer></v-spacer>
                    <div class="font-weight-bold b2--text not-f7">Expect: mwk{{MBS.actions.money(TotalDiscountedPrice)}}</div>
                    <v-btn dark icon class="ml-5" @click="dialog_close_register=false" ><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <form @submit.prevent="CLOSE_CASHUP()">
                         <div class="ma-5" v-if="dialog_close_register">
                             <!-- :disabled_fields="DisabledFields" -->
                            <mbs-input-fields 
                                v-model="input"
                                :field_items="DATA.FIELDS.close_cashups"
                                :select_items="SelectInputFieldsItems" 
                                
                                />   
                            <v-layout class="ma-0 mt-5">
                                <v-spacer></v-spacer>
                                <v-btn type="" large color="primary">Close Register</v-btn>
                            </v-layout>
                        </div> 
                    </form>
                </v-card-text> 
            </v-card> 
        </v-dialog> 
        
        <!--Z READ -->
        <v-dialog
            v-model="dialog_z_read"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="800px"
            transition="dialog-transition" >
            <v-card rounded="lg">
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text">Z-READ REPORT</div> 
                    <v-spacer></v-spacer> 
                    <v-btn dark icon class="ml-5" @click="dialog_z_read=false" ><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <form @submit.prevent="OPEN_Z_READ()">
                         <div class="ma-5" v-if="dialog_z_read">
                             <!-- :disabled_fields="DisabledFields" -->
                            <mbs-input-fields 
                                v-model="input"
                                :field_items="DATA.FIELDS.open_z_read"
                                :select_items="SelectInputFieldsItems"  
                                />   
                            <div class=" mt-5">Make sure you select the day shift and open Z-Read</div>
                            <!-- <input type="submit" value="" v-show="false"> -->
                            <v-layout class="ma-0">
                                <v-spacer></v-spacer>
                                <v-btn type="submit" large color="primary">Open Z-Read</v-btn>
                            </v-layout>
                        </div> 
                    </form>
                </v-card-text> 
            </v-card> 
        </v-dialog> 
        
        <!--X READ -->
        <v-dialog
            v-model="dialog_x_read"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="800px"
            transition="dialog-transition" >
            <v-card rounded="lg">
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text">X-READ REPORT</div> 
                    <v-spacer></v-spacer> 
                    <v-btn dark icon class="ml-5" @click="dialog_x_read=false" ><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <form @submit.prevent="OPEN_X_READ()">
                         <div class="ma-5" v-if="dialog_x_read">
                             <!-- :disabled_fields="DisabledFields" -->
                            <mbs-input-fields 
                                v-model="input"
                                :field_items="DATA.FIELDS.open_z_read"
                                :select_items="SelectInputFieldsItems"  
                                />   
                            <div class=" mt-5">Make sure you select the day shift and open X-Read</div>
                            <!-- <input type="submit" value="" v-show="false"> -->
                            <v-layout class="ma-0">
                                <v-spacer></v-spacer>
                                <v-btn type="submit" large color="primary">Open X-Read</v-btn>
                            </v-layout>
                        </div> 
                    </form>
                </v-card-text> 
            </v-card> 
        </v-dialog> 
        
        <!--MANAGE SHIFT-->
        <v-dialog
            v-model="dialog_manage_shift"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="800px"
            transition="dialog-transition" >
            <v-card rounded="lg">
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text">MANAGE DAY SHIFT</div> 
                    <v-spacer></v-spacer> 
                    <v-btn dark icon class="ml-5" @click="dialog_manage_shift=false" ><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <form @submit.prevent="OPEN_MANAGE_SHIFT()">
                         <div class="ma-5" v-if="dialog_manage_shift">
                             <!-- :disabled_fields="DisabledFields" -->
                            <mbs-input-fields 
                                v-model="input"
                                :field_items="DATA.FIELDS.open_z_read"
                                :select_items="SelectInputFieldsItems"  
                                />   
                            <div class=" mt-5">Make sure you select the day shift before continue</div>
                            <!-- <input type="submit" value="" v-show="false"> -->
                            <v-layout class="ma-0">
                                <v-spacer></v-spacer>
                                <v-btn type="submit" large color="primary">Manage Shift</v-btn>
                            </v-layout>
                        </div> 
                    </form>
                </v-card-text> 
            </v-card> 
        </v-dialog> 
        
        <!--SEARCH AND SELECT ITEMS-->
        <v-dialog
            v-model="dialog_search_and_select_items"
            scrollable   tile
            :persistent="false" :overlay="false"
            max-width="800px" :height="vsh" 
            transition="dialog-transition" >
            <v-card   tile v-if="dialog_search_and_select_items">
                <form @submit.prevent="OPEN_MANAGE_SHIFT()">
                    <v-card-title dark class="primary pb-1 ">
                        <v-text-field dense dark autofocus
                            v-model="search_key"
                            append-icon="search"
                            outlined :rounded="false"
                        /> 
                        <v-btn outlined @click="dialog_search_and_select_items = false" class="ml-2 mb-1" dark icon><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                   <mbs-search-select-items
                        :height="vsh-200" 
                        :search_key="search_key"
                        :show_toolbar="false"
                        :show_table="true"
                        :outlet_code="CurrentTill?CurrentTill.outlet_code:null"
                        @item_selected=""
                        @selected="ON_SEARCH_AND_SELECT"
                    />
                </form> 
            </v-card> 
        </v-dialog> 

        <div v-if="ADDING_ITEM">
            <mbs-item-manager 
                :show="false" 
                :add_item_action="ADDING_ITEM.add_item_action"
                :name_items="ADDING_ITEM.names"
                :name_item="ADDING_ITEM.name"
                :value_items="ADDING_ITEM.values"
                :value_item="ADDING_ITEM.value"
                :field_items="ADDING_ITEM.fields"   
                :path_item="ADDING_ITEM.path"  
                :select_items="SelectInputFieldsItems"  
                @ADDED="ADDED" 
            /> 
        </div>

        <div v-if="auto_print.printing">
            <mbs-auto-print 
                @finish_print="FINISH_PRINT"
                :show="true"  
                :auto_print="auto_print.auto"
                :auto_printer_name="auto_print.auto_printer_name"
                :com_printer="auto_print.com_printer"  
                :data="auto_print.print_data"
                :type="auto_print.print_type"
                :title="auto_print.print_title"
                :printing_action="auto_print.printing_action"
                :display_option="auto_print.display_option" 
                 />  
        </div>  
    </div>  
  </v-layout> 
</template>

<script> 
import DATA from '../../../plugins/DATA'
import DB from '../../../plugins/DB'
import {mapState} from "vuex" 
import { mdiAccountCreditCard } from '@mdi/js';
import { mdiPrinterPosOff  } from '@mdi/js';
import { mdiPrinterPosRemove  } from '@mdi/js';
import { mdiPrinterPosPause   } from '@mdi/js';
// import { mdiPrinterPosAlert    } from '@mdi/js';
 
let PAGE_NAME = "SALE"
    export default { 
        name:PAGE_NAME, 
        data() {
            return {
                selectedRows: [],
                lastSelectedRow: -1,
                editingCell: null,

                category_current_page:1, 
                category_btn_h:60, 
                category_btn_w:100, 
                item_category_current_page:1, 
                item_category_btn_h:60, 
                item_category_btn_w:100, 
                focused_field:null,

                item_multiple_select:false, 
                click_mode:false, 
                PAGE_SHOW:false, 
                DATA:DATA,
                search:'', 
                search_item:'', 
                search_key:'', 
                search_input:null, 
                selected_departments:null, 
                currentOrderOptionTab:null,  
                checked_items:null,
                inputLoading:false,
                input:{},
                input_discount_amount:null,
                input_discount_percentage:null,
                input_currency:{},  
                show_input:true,
                mask_money:"### ### ###", 
                console:console,
                pay:false,
                pay_order:null,
                item_order:null,
                print_svg:null,
                dialog_print_order:false, 
                dialog_start_selling:false, 
                dialog_action_menu:false, 
                dialog_open_drawer_before_selling:false, 
                dialog_selected_card:false, 
                dialog_paid_amount:false, 
                dialog_given_amount:false, 
                dialog_change_item_price:false, 
                dialog_close_register:false, 
                dialog_open_drawer:false, 
                dialog_view_orders_list:false, 
                dialog_view_customers_orders:false, 
                dialog_view_options_orders:false, 
                dialog_view_selected_customer_order:false, 
                dialog_view_selected_option_order:false, 
                dialog_view_order:false, 
                dialog_put_on_order_option:false, 
                dialog_search_and_select_items:false, 
                menu_selling_mode:false, 
                menu_selected_card:false, 
                menu_select_order_option:false, 
                menu_item_order:false, 
                menuPaymentOptions:false, 
                dialog_z_read:false, 
                dialog_x_read:false, 
                dialog_manage_shift:false, 

                NAME_ITEMS:DATA.ITEMS.CUSTOMERS.names,
                NAME_ITEM:DATA.ITEMS.CUSTOMERS.name,
                VALUE_ITEMS:DATA.ITEMS.CUSTOMERS.values,
                VALUE_ITEM:DATA.ITEMS.CUSTOMERS.value,   
                ADDING_ITEM:null, 

                sellingModels:["Retail Price","Wholesale Price"], 
                selling_model:null,
                paying_option:null,

                auto_print:{
                    auto:false,
                    printing:false, 
                    print_data:null,
                    print_type:null,
                    com_printer:false, 
                    print_title:null, 
                    auto_printer_name:null,
                    printing_action:null, 
                    display_option:'',  
                },
                

                this_day_shift_key:null,
                this_till_key:null, 
                SELECTED_CARD_KEY:null, 
                SELECTED_CARD_OPTION:null, 
                SELECTED_ORDER_OPTION:null, 
                SELECTED_ORDER:null, 
                SELECTED_CUSTOMER_ORDER:null, 
                SELECTED_OPTION_ORDER:null, 
                SELECTED_ITEM_CATEGORY:null, 
                SELECTED_ACTION_MENU:null, 
                PUT_AND_PRINT:false, 
                PRINTING_ORDER:null,
                mdiAccountCreditCard:mdiAccountCreditCard,  
                currency_list:[
                    {value:1,name:"1.00",icon:"mdi-cash"},
                    {value:5,name:"5.00",icon:"mdi-cash"},
                    {value:10,name:"10.00",icon:"mdi-cash"},
                    {value:20,name:"20.00",icon:"mdi-cash"},
                    {value:50,name:"50.00",icon:"mdi-cash"},
                    {value:100,name:"100.00",icon:"mdi-cash"},
                    {value:200,name:"200.00",icon:"mdi-cash"},
                    {value:500,name:"500.00",icon:"mdi-cash"},
                    {value:1000,name:"1,000.00",icon:"mdi-cash"},
                    {value:2000,name:"2,000.00",icon:"mdi-cash"},
                    {value:5000,name:"5,000.00",icon:"mdi-cash"},
                ], 
                selected_printer_name:0,
                created_document_type:'',
            }
        },
        async created() {
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);   
                this.MBS.events.$on('ON_KEY_DOWN', this.ON_KEY_DOWN);   
                this.MBS.events.$on('MBS_KEYBOARD', this.MBS_KEYBOARD); 
                this.UPDATE_K_CONNECTOR() 
                // this.MBS.events.$on('CLOSE_REGISTER', this.CLOSE_REGISTER);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() { 
            window.addEventListener('keydown', (event) => {
                this.$root.shiftKey = event.shiftKey;
            });

            window.addEventListener('keyup', () => {
                this.$root.shiftKey = false;
            });

            this.MBS.actions.header({
                show:false,
                type:'subToolbar', 
                
            }) 
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500) 
            this.INITIALIZE_CLICK_MODE()
            this.INITIALIZE_ITEMS_CATEGORIES()
            this.INITIALIZE_ITEMS_DEPARTMENTS()
            this.INITIALIZE_SELLING_MODE()
            this.INITIATE_SELECTED_SALES_ITEMS()
            this.COMPANY_QUICK_ACTIONS(this.CompanyQuickActions) 

            DB.deleteItems({
                name:DATA.ITEMS.DAYS_SHIFTS_SALES.values
            })
        },
        computed: {
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            vsh_selected_items_table(){
                let vs = this.vs
                let vsh = this.vsh
                let mdAndDown = vs?vs.mdAndDown:null
                let order_options = this.ItemOrderOptionsData
                let CompanySettings = this.CompanySettings
                let allow_total_discount = CompanySettings?CompanySettings.allow_total_discount:false
                let height = vsh-250

                if (mdAndDown) {
                    height = height-50
                } 
                if (allow_total_discount) {
                    height = height-50
                } 
                return height
            },
            vsh_payment_sam_table(){
                let vs = this.vs
                let vsh = this.vsh
                let CalculatedVAT = this.CalculatedVAT
                let TotalDiscount = this.TotalDiscount
                let mdAndDown = vs?vs.mdAndDown:null 

                let height = vsh-250   
                //if sub-total
                if (CalculatedVAT || TotalDiscount) {
                    height = height-30 
                    if (TotalDiscount) {
                        height = height-20 
                    }
                    if (CalculatedVAT) {
                        height = height-40 
                        if (CalculatedVAT.taxable_a>0) {
                            height = height-40
                        }
                        if (CalculatedVAT.taxable_b>0) {
                            height = height-40 
                        }
                        if (CalculatedVAT.taxable_c>0) {
                            height = height-40 
                        }
                        
                    }
                }  
                return height
            },
            fullscreen(){
                return this.MBS.actions.Fullscreen
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                ItemDepartments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 
                Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                JoinedCustomers: state=> state.join[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                CustomerGroups: state=> state.items[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
                JoinedCustomerGroups: state=> state.join[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()], 
                JoinedSuppliers: state=> state.join[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()], 
                SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()], 
                JoinedSupplierCategories: state=> state.join[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()], 
                TaxGroups: state=> state.app[(DATA.ITEMS.TAX_GROUPS.values).toUpperCase()], 
                
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalDaysShifts: state=> state.locals[(DATA.ITEMS.LOCAL_DAYS_SHIFTS.values).toUpperCase()], 
                LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()], 
                
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],   
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()], 
                Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                Cards: state=> state.items[(DATA.ITEMS.CARDS.values).toUpperCase()],
                JoinedCards: state=> state.join[(DATA.ITEMS.CARDS.values).toUpperCase()],
                MoneyAccounts: state=> state.items[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()],
                JoinedMoneyAccounts: state=> state.join[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()],
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 

                LocalSelectedSalesItems: state=> state.locals[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()],
                JoinedLocalSelectedSalesItems: state=> state.join[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()], 
            }), 
            DataInvoicePayments(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            DevicesDataItems(){
                return this.JoinedDevices?this.JoinedDevices:this.Devices
            },
            Printers(){
                let devices = this.DevicesDataItems  
                let printers = []
                if (devices) {
                    printers = devices.filter(item=>{
                        return this.MBS.actions.TEXT_UP(item.device_type)
                         == this.MBS.actions.TEXT_UP(DATA.ITEMS.PRINTERS.value)
                    })  
                }
                return [
                    {
                        code:0,
                        name:"Other Installed Printer",
                        isDefaultPrinter:true,
                        priority:1
                    },
                    ...printers
                ]
            },
            AllPrinters(){
                let printers = this.Printers  
                let connected_printers = this.ConnectedPrinters  
                if(!printers && connected_printers){return null}
                let new_printers = []
                let find_printer=(name,printers)=>{
                    if(!printers){return null}
                    return printers.find(printer=>printer.name==name)
                }
                
                
                if(connected_printers){
                    connected_printers.forEach(printer => {
                        let company_printer = find_printer(printer.name,printers)
                        if (company_printer) {
                            new_printers.push({
                                ...company_printer,
                                ...printer,
                                is_installed:true,
                                is_company_printers:true,
                                is_active:true,
                                priority:printer.isDefaultPrinter?1:2
                            })
                        }else{
                            new_printers.push({ 
                                ...printer,
                                is_installed:true, 
                                priority:printer.isDefaultPrinter?1:4
                            })
                        }
                    });
                }

                if(printers){
                    printers.forEach(printer => {
                        if (!find_printer(printer.name,connected_printers)) {
                            new_printers.push({
                                ...printer,
                                is_company_printers:true,
                                priority:printer.isDefaultPrinter?1:3
                            })
                        }
                    });
                } 
                let list = [...new_printers]
                list.sort(this.MBS.actions.dynamicSort("priority",true))   
                return list  
            },
            SelectedPrinter(){
                let Printers = this.AllPrinters 
                let selected_printer_name = this.selected_printer_name 
                if(!Printers){return null}
                let printer = Printers.find(item=>{
                    return item.name == selected_printer_name
                }) 
                return printer
            },
            ReceiptSettings(){
                let settings = this.CompanySettings 
                let template_settings = settings?settings.template_settings:null
                let receipt = template_settings?template_settings.receipt:null 
                return receipt
            },



            CardsDataItems(){
                return this.JoinedCards?this.JoinedCards:this.Cards
            },
            MoneyAccountsData(){
                return this.JoinedMoneyAccounts?this.JoinedMoneyAccounts:this.MoneyAccounts
            },
            PaymentMethodsData(){
                return this.JoinedPaymentMethods?this.JoinedPaymentMethods:this.PaymentMethods
            },

            //orders
            ItemOrderOptionsData(){   
                return this.JoinedItemOrderOptions?this.JoinedItemOrderOptions:this.ItemOrderOptions
            },
            ItemOrdersData(){   
                return this.JoinedItemOrders?this.JoinedItemOrders:this.ItemOrders
            },  
            LocalItemOrdersData(){  
                return this.JoinedLocalItemOrders?this.JoinedLocalItemOrders:this.LocalItemOrders
            },  
            SortedItemCategories(){  
                let items =this.ItemCategories
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true))   
                return list  
            }, 
            FilteredItemCategories(){  
                let items =this.SortedItemCategories
                let selected =this.selected_departments
                if(!items){return null}
                if(!this.MBS.actions.SIZE(selected)){return items}
                let filtered = items.filter(item=>{
                    let index = selected?selected.indexOf(item.item_department_code):-1
                    return index != -1
                })
                return filtered  
            }, 
            PaginatedItemCategories(){  
                let current_page =this.category_current_page
                let items =this.FilteredItemCategories
                return (size,type)=>{
                    let page_size = Math.trunc(size/73)
                    if (current_page == 1) {
                        page_size=page_size+1
                    } 
                    if(!items){return null}
                    let list = this.MBS.actions.pagination(items,current_page,page_size)  
                    return list  
                }
            },  
            AllItemOrders(){  
                const local = this.LocalItemOrdersData
                const live = this.ItemOrdersData 
                const find = key=>{
                    if(!live){return null}
                    return live.find(order=>order.key == key)
                }
                let items = []
                if (this.MBS.actions.SIZE(live)>0) {
                    items.push(...live)
                }
                if (this.MBS.actions.SIZE(local)>0) {
                    local.forEach(element => {
                        let uploaded_order = find(element.key)
                        if (!uploaded_order) {
                            items.push(element)
                        }
                    });
                }
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("key",false))    
                return list 
            }, 
            CustomerItemOrders(){  
                const orders = this.AllItemOrders
                const search = this.search
                if(!orders){return null}
                let customers = []
                orders.forEach(element => {
                    const customer = element.customer?element.customer:{name:"Unknown",key:"unknown"}
                    const customer_name = customer?customer.name:''
                    const total_sold_price = this.toNumber(element.total_sold_price)
                    const index = customers.findIndex(item=>item.key==customer.key)
                    let include = true
                    if (search && customer_name) {
                        let name_index = this.MBS.actions.TEXT_UP0(customer_name).indexOf(this.MBS.actions.TEXT_UP0(search))
                        if (name_index == -1) {
                            include = false
                        } 
                    }

                    if (include) {
                        if (index==-1) {
                            let customer_orders = {
                                ...customer,
                                total_sold_price:total_sold_price,
                                orders_size:1,
                                orders:[]
                            }
                            customer_orders.orders.push(element)
                            customers.push(customer_orders)
                        } else {
                            customers[index].orders.push(element)
                            customers[index].orders_size++
                            customers[index].total_sold_price = customers[index].total_sold_price+total_sold_price  
                        } 
                    }
                }); 
                return customers
                
            }, 
            OptionItemOrders(){  
                const orders = this.AllItemOrders
                const search = this.search
                if(!orders){return null}
                let orders_options = [] 
                orders.forEach(element => {
                    const order_option = element.order_option?element.order_option:{name:"Unknown",key:"unknown"}
                    const option_name = order_option?order_option.name:''
                    const total_sold_price = this.toNumber(element.total_sold_price)
                    const index = orders_options.findIndex(item=>item.key==order_option.key)
                    let include = true
                    if (search && option_name) {
                        let name_index = this.MBS.actions.TEXT_UP0(option_name).indexOf(this.MBS.actions.TEXT_UP0(search))
                        if (name_index == -1) {
                            include = false
                        } 
                    }

                    if (include) {
                        if (index==-1) {
                            let customer_orders = {
                                ...order_option,
                                total_sold_price:total_sold_price,
                                orders_size:1,
                                orders:[]
                            }
                            customer_orders.orders.push(element)
                            orders_options.push(customer_orders)
                        } else {
                            orders_options[index].orders.push(element)
                            orders_options[index].orders_size++
                            orders_options[index].total_sold_price = orders_options[index].total_sold_price+total_sold_price  
                        } 
                    }
                }); 
                return orders_options
                
            }, 
            SelectedDepartments(){  
                const selected = this.selected_departments
                const departments = this.ItemDepartments
                if(!selected){return null}
                let items = []
                selected.forEach(department_code => {
                    const department = departments?departments.find(depart=>depart.code == department_code):null
                    if(department){
                        items.push(department)
                    }
                }); 
                return items
            }, 
            
            LiveSelectedCustomerOrder(){  
                const selected = this.SELECTED_CUSTOMER_ORDER
                const orders = this.CustomerItemOrders
                if(!selected || !orders){
                    this.dialog_view_selected_customer_order = false
                    return null
                }
                const live_order = orders.find(order=>order.key == selected.key)
                if (!live_order) {
                    this.dialog_view_selected_customer_order = false
                }
                return live_order
            },
            LiveSelectedOptionOrder(){  
                const selected = this.SELECTED_OPTION_ORDER
                const orders = this.OptionItemOrders
                if(!selected || !orders){
                    this.dialog_view_selected_option_order = false
                    return null
                }
                const live_order = orders.find(order=>order.key == selected.key)
                if (!live_order) {
                    this.dialog_view_selected_option_order = false
                }
                return live_order
            },  

             
            TabItems(){  
                const order_options = this.ItemOrderOptionsData
                if(!order_options){return null}
                let tab = null 
                const findOrder = key=>{
                    if(!this.AllItemOrders){return null}
                    return this.AllItemOrders.filter(item=>item.order_option_key == key)
                }
                order_options.forEach(element => {
                    if(!tab){tab=[]}
                    tab.push({
                        ...element,
                        indicator:{
                            value:this.MBS.actions.SIZE(findOrder(element.key)),
                            color:'primary'
                        }
                    })
                });
                return tab
            }, 
            PageItemOrders(){  
                const tab = this.currentOrderOptionTab
                const orders = this.AllItemOrders
                const order_options = this.ItemOrderOptionsData
                let current_option = null
 
                if(order_options){
                    current_option = order_options[tab]
                } 
                if(!orders){return null}
                if(!current_option){return orders}
                const filtered = orders.filter(item=>item.order_option_key == current_option.key) 
                
                return filtered
            }, 
            Scales(){
                let devices = this.DevicesDataItems  
                if(!devices){return null}
                let printers = devices.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.device_type)
                     == this.MBS.actions.TEXT_UP(DATA.ITEMS.SCALES.value)
                }) 
                return printers
            },
            VFD_Displays(){
                let devices = this.DevicesDataItems  
                if(!devices){return null}
                let printers = devices.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.device_type)
                     == this.MBS.actions.TEXT_UP(DATA.ITEMS.VFD_DISPLAYS.value)
                }) 
                return printers
            },
            
            auto_category_btn_h(){
                let vsh = this.vsh
                let height = 55
                if (vsh<500) {
                    height = 46
                }else if (vsh<550) {
                    height = 50
                }else if (vsh<600) {
                    height = 51
                }else if (vsh<700) {
                    height = 55
                }else if (vsh<800) {
                    height = 56
                }else if (vsh<880) {
                    height = 57
                }else if (vsh<900) {
                    height = 58
                } 
                return height
            },
            SELECTED_CARD(){
                let cards = this.CardsDataItems
                let card_key = this.SELECTED_CARD_KEY
                if(!cards){return null}
                let thisCard = cards.find(card=>card.key==card_key)
                return thisCard
            }, 
            //
            
            DaysShiftsData(){ 
                let DaysShifts = this.DaysShifts
                let JoinedDaysShifts = this.JoinedDaysShifts
                let items = JoinedDaysShifts?JoinedDaysShifts:DaysShifts
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
                return list 
            },   
            // AllDaysShifts(){
            //     let locals = this.MBS.actions.JOIN_DAY_SHIFTS(this.LocalDaysShifts)
            //     let dayShifts = this.DaysShiftsData
            //     let data = []
            //     if(!dayShifts && !locals){return null}
            //     if (locals) {
            //         locals.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
            //         data = [...locals]
            //     }if (dayShifts) {
            //         data = [...data,...dayShifts]
            //     } 
            //     return data
            // }, 
            AllDaysShifts(){
                let locals = this.MBS.actions.JOIN_DAY_SHIFTS(this.LocalDaysShifts)
                let dayShifts = this.DaysShiftsData

                let data = []
                if(!dayShifts && !locals){return null}
                
                if (dayShifts) {
                    data = [...dayShifts] 
                }if (locals) {
                    locals.forEach(local => {
                        let index = data.findIndex(item=>item.key == local.key)
                        if (index ==-1 ) {
                            data.push(local)
                        } else {
                            data[index] = {...data[index],...local}
                        }
                        
                    }); 
                 }
                return data
            }, 
            ThisDayShift(){  
                let day_shift_key = this.this_day_shift_key
                let AllDaysShifts = this.AllDaysShifts
                if(!AllDaysShifts || !day_shift_key){return null}
                let shift = AllDaysShifts.find(item=>{
                    return item.key == day_shift_key
                }) 
                return shift
            },  
            UNCLOSED_DayShifts(){ 
                let items = this.AllDaysShifts
                
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return !item.archive && !item.local_close_at && !item.total_closing_cash
                })  
                
                return filtered_items
            }, 
            DayShiftsTills(){ 
                let items = this.UNCLOSED_DayShifts 
                if(!items){return null} 
                let tills = []
                items.forEach(element => {
                    let this_tills = element.joined_tills
                    if (this_tills) {
                        tills = [
                            ...tills,
                            ...this_tills
                        ]
                    }
                });  
                return tills
            },  
            getDayShiftTill(){
                let dayShiftsTills = this.DayShiftsTills
                return (day_shift_and_till_key)=>{
                    if(!dayShiftsTills){return null}
                    return dayShiftsTills.find(item=>{
                        return item.day_shift_and_till_key == day_shift_and_till_key
                    }) 
                }
            },
            AllCashupsData(){  
                const ThisDayShift = this.ThisDayShift
                if(!ThisDayShift){return null} 
                let items = ThisDayShift.joined_cashups   
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            thisCashupsData(){ 
                let cashups = this.AllCashupsData   
                
                let day_shift_key = this.this_day_shift_key 
                let till_key = this.this_till_key
                if(!day_shift_key||!cashups){return null}
                let filtered_items = cashups.filter(item=>{
                    return item.day_shift_key == day_shift_key && 
                        item.till_key == till_key
                })    
                return filtered_items  
            }, 
            AllSaleOrdersData(){  
                const SaleOrders = this.SaleOrders
                const JoinedSaleOrders = this.JoinedSaleOrders
                let items = JoinedSaleOrders?JoinedSaleOrders:SaleOrders  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            AllLocalSaleOrdersData(){  
                const LocalSaleOrders = this.LocalSaleOrders
                const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
                let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            UnUploadedLocalSoldItems(){  
                const orders = this.AllLocalSaleOrdersData
                let items = []
                if(!orders){return null}
                orders.forEach(order => {
                    let order_items = order.items
                    if (order_items && !order.uploaded) {
                        order_items.forEach(item => {
                            let index = items.findIndex(it=>{
                                return it.item_key  == item.item_key
                            }) 
                            let sold_quantity = this.toNumber(item.quantity)
                            if (index == -1) { 
                                items.push({
                                    ...item, 
                                    sold_quantity:sold_quantity, 
                                })
                            } else {
                                items[index].sold_quantity = items[index].sold_quantity+sold_quantity 
                            }   
                        }); 
                    } 
                }); 
                return items
            }, 
            getSoldItem(){  
                const sold_items = this.UnUploadedLocalSoldItems  
                return (key)=>{ 
                    if(!sold_items){return null}
                    return sold_items.find(item=>item.item_key == key)

                }
            }, 
            SaleOrdersData(){ 
                let us = this.us 
                let uid = us?us.uid:null
                let items = this.AllLocalSaleOrdersData
                if(!items){return null}
                let mys = items.filter(sale=>{
                    return sale.created_uid == uid
                })
                if(!mys){return null}
                let list = [...mys]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            },  
            ActionTypesDisabled(){   
                let localCashup = this.LocalCashup
                if (localCashup) {
                    return null
                }
                return  ["sales",]
            }, 
             
             
            LocalCashup(){    
                let cashups = this.LocalCashups
                let local_cashup = cashups?cashups.find(item=>{
                    return !item.closed
                }):null 
                return local_cashup
            }, 
            OutletsData(){
                return this.JoinedOutlets?this.JoinedOutlets:this.Outlets
            }, 
            TillsData(){
                return this.JoinedTills?this.JoinedTills:this.Tills
            }, 
            CurrentTill(){
                let tills = this.TillsData
                let cashup = this.LocalCashup  
                if(!tills || !cashup){return null}
                let filled = tills.find(item=>{
                    return item.key == cashup.till_key
                })
                console.log(filled,'filled.......t');
                return filled
            },
            CurrentOutlet(){
                let outlets = this.OutletsData
                let till = this.CurrentTill   
                if(!outlets || !till){return null}
                let filled = outlets.find(outlet=>outlet.code==till.outlet_code)
                return filled
            }, 
            AssignedPrinter(){
                let till = this.CurrentTill 
                let old_printer = till?till.printer:null
                let assigned_printers = this.MBS.actions.JOIN_PRINTERS(till?till.assigned_printers:null) 
                return (legal,drawer)=>{
                    if(!assigned_printers){return old_printer}
                    let printers = [...assigned_printers]
                    

                    if (legal==true) {
                        printers = printers.filter(printer=>printer.printer_option=='print_legal_receipt'||printer.printer_option=='print_all') 
                    }else if (legal==false) {
                        printers = printers.filter(printer=>printer.printer_option=='print_nonlegal_receipt'||printer.printer_option=='print_all') 
                    }
                    
                    if (drawer==true) {
                        printers = printers.filter(printer=>printer.connect_cash_drawer) 
                    }

                    printers.sort(this.MBS.actions.dynamicSort("printer_priority",true))    
                    return printers[0]
                }
            },


            CurrentVFT_Display(){
                let till = this.CurrentTill 
                return till?till.vfd_display:null
            },
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            },  
            ReceivableAccounts(){ 
                return this.$store.getters.getReceivables
            },  


            CustomersData(){
                const Customers = this.Customers
                const JoinedCustomers = this.JoinedCustomers  
                return JoinedCustomers?JoinedCustomers:Customers
            }, 
            CustomerGroupsData(){
                const CustomerGroups = this.CustomerGroups
                const JoinedCustomerGroups = this.JoinedCustomerGroups  
                return JoinedCustomerGroups?JoinedCustomerGroups:CustomerGroups
            },
            SuppliersData(){
                const Suppliers = this.Suppliers
                const JoinedSuppliers = this.JoinedSuppliers  
                return JoinedSuppliers?JoinedSuppliers:Suppliers
            }, 
            SupplierCategoriesData(){
                const SupplierCategories = this.SupplierCategories
                const JoinedSupplierCategories = this.JoinedSupplierCategories  
                return JoinedSupplierCategories?JoinedSupplierCategories:SupplierCategories
            }, 
            ItemsData(){
                const Items = this.Items
                const JoinedItems = this.JoinedItems  
                return JoinedItems?JoinedItems:Items
            }, 
            ItemsFiltered(){
                const items = this.ItemsData
                const departments = this.selected_departments
                const categories = this.FilteredItemCategories 
                if(!items){return null}
                if(!this.MBS.actions.SIZE(departments)){return items}
                let filtered = items.filter(item=>{
                    let index = departments?departments.indexOf(item.item_department_code):0
                    let index2 = categories?categories.findIndex(category=>category.code == item.category_code):0
                    return (index!=-1)||(index2!=-1)
                })
                return filtered

            },    
            ItemByCategory(){  
                const category = this.SELECTED_ITEM_CATEGORY 
                this.item_category_current_page = 1
                const items = this.ItemsFiltered
                if(!category || !items){return null}
                const filtered = items.filter(item=>item.category_code==category.code)
                if(!filtered){return null}
                const list = [...filtered]
                list.sort(this.MBS.actions.dynamicSort("name",true))   
                return list  
            },  
            PaginatedItemByCategory(){  
                let vs = this.vs
                let xs = vs?vs.xs:false 
                let sm = vs?vs.sm:false 
                let md = vs?vs.mdAndUp:false
                let rows = 1
                if (sm) {
                    rows = 2  
                }if (md) {
                    rows = 3
                } 
                const current_page =this.item_category_current_page
                const items =this.ItemByCategory
                return (size,type)=>{
                    let row_size = 73 / rows
                    let page_size = Math.trunc(size/row_size)
                    if (current_page == 1) {
                        page_size=page_size+1
                    } 
                    if(!items){return null}
                    const list = this.MBS.actions.pagination(items,current_page,page_size)  
                    return list  
                }
            }, 
            LocalSelectedSalesItemsData(){
                const Items = this.LocalSelectedSalesItems
                const JoinedItems = this.JoinedLocalSelectedSalesItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            ReversedLocalSelectedSalesItemsData(){   
                const items = this.LocalSelectedSalesItemsData 
                if(!items) {return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("selecting_list_key",true))
                return list.reverse()
            },
            SELECTED_ITEMS(){
                let settings = this.CompanySettings
                let selling_list_descend = settings?settings.selling_list_descend:null
                let items = this.LocalSelectedSalesItemsData 
                let reversed = this.ReversedLocalSelectedSalesItemsData  
                return selling_list_descend?reversed:items
            },
            
            CalculateItemsPrice(){
                return (items)=>{ 
                    try {   
                            let total_price = 0 
                            if (!items) {return 0}
                            items.forEach(item => {
                                let sold_price = this.toNumber(item.sold_price)
                                let discount = this.toNumber(item.discount)
                                let quantity = this.toNumber(item.quantity)
                                total_price = total_price + (quantity*(sold_price-discount))
                            });  
                            // return total_price
                            return total_price.toFixed(2) 
                        
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'TotalPrice',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    }  
                }
            },
            TotalPrice(){
                try { 
                    if (this.SELECTED_CARD_OPTION=='load_money') { 
                       return this.toNumber(this.pay_order?this.pay_order.card_load_amount:0)
                    } else {
                        let items = this.SELECTED_ITEMS
                        let total_price = 0 
                        if (!items) {return 0}
                        items.forEach(item => {
                            let sold_price = this.toNumber(item.sold_price)
                            let discount = this.toNumber(item.discount)
                            let quantity = this.toNumber(item.quantity)
                            total_price = total_price + (quantity*(sold_price-discount))
                        });  
                        // return total_price
                        return total_price.toFixed(2) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            TotalDiscount(){
                try { 
                    let amount = this.toNumber(this.input_discount_amount)
                    let percentage = this.toNumber(this.input_discount_percentage)
                    let total = this.toNumber(this.TotalPrice)
                    if (percentage) {
                        return (total*percentage/100)
                    } 
                    return amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },
            TotalDiscountedPrice(){
                try {  
                    let discount = this.toNumber(this.TotalDiscount)
                    let CalculatedVAT = this.CalculatedVAT
                    let total_vat = this.toNumber(CalculatedVAT?CalculatedVAT.total_vat:0)
                    let total_price = this.toNumber(this.TotalPrice)
 
                    const settings = this.CompanySettings
                    const items_are_vat_exclusive = settings?settings.items_are_vat_exclusive:null
                    const calculate_vat = settings?settings.calculate_vat:null

                    if (items_are_vat_exclusive && calculate_vat) {
                        total_price = total_price+total_vat
                    }
                     
                    return total_price-discount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            CustomerPath(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.customers,true) 
                return path
            }, 
            CustomerGroups(){
                return this.$store.getters.getCustomerGroups
            },     
            SelectInputFieldsItems(){
                const Items = this.ItemsData  
                const ItemCategories = this.ItemCategories  
                const ItemDepartments = this.ItemDepartments  
                const Brands = this.Brands  
                const Manufacturers = this.Manufacturers  
                const TaxGroups = this.TaxGroups 

                const Outlets = this.OutletsData  
                const Tills = this.DayShiftsTills 
                const DayShifts = this.UNCLOSED_DayShifts  
                const Customers = this.CustomersData  
                const CustomerGroups = this.CustomerGroups  
                const Suppliers = this.SuppliersData
                const SupplierCategories = this.SupplierCategoriesData
                const ReceivableAccounts = this.ReceivableAccounts  
                const SellingModels = this.sellingModels   
                const AllPrinters = this.AllPrinters   
                return {
                    Items:Items, 
                    ItemCategories:ItemCategories, 
                    ItemDepartments:ItemDepartments, 
                    Brands:Brands, 
                    Manufacturers:Manufacturers, 
                    TaxGroups:TaxGroups, 

                    Outlets:Outlets, 
                    Tills:Tills, 
                    DayShifts:DayShifts, 
                    Customers:Customers,
                    CustomerGroups:CustomerGroups,
                    Suppliers:Suppliers,
                    SupplierCategories:SupplierCategories,
                    ReceivableAccounts:ReceivableAccounts,
                    SellingModels:SellingModels,
                    AllPrinters:AllPrinters,
                }
            },
            AllActionItems(){  
                return [
                    // {
                    //     icon:"mdi-barcode-scan",
                    //     action:"scan_items", 
                    //     name:"Waiting List",
                    //     color:"secondary",
                    //     type:"sales"
                    // },
                    {
                        icon:"mdi-gesture-tap-hold",
                        action:"click_mode", 
                        name:"Click Mode",
                        color:"green",
                        type:"sales"
                    },{
                        icon:"mdi-refresh",
                        action:"refresh_data", 
                        name:"Refresh",
                        color:"secondary",
                        type:""
                    },
                    { 
                        icon:"remove_shopping_cart",
                        action:"clear_sales", 
                        name:"Clear",
                        color:"red",
                        type:"sales"
                    },{ 
                        icon:"shopping_cart",
                        action:"view_orders", 
                        name:"Order(s)",
                        color:"yellow",
                        type:"sales",
                        menu:{
                            name:"Orders",
                            items:[
                                {icon:"mdi-cart",action:"view_orders",name:"Orders List"}, 
                                {icon:"mdi-cart-heart",action:"view_customers_orders",name:"Customers Orders"},
                                {icon:"mdi-cart-variant",action:"view_options_orders",name:"Options Orders"},
                            ]
                        }
                    },{ 
                        icon:"mdi-archive-arrow-down-outline",
                        action:"open_drawer", 
                        name:"Drawer",
                        color:"blue",
                        type:"sales"
                    },
                    // { 
                    //     action:"settings", 
                    //     name:"Settings",
                    //     color:"",
                    //     type:"sales"
                    // }, 
                    { 
                        icon:"mdi-clipboard-text-clock",
                        action:"history", 
                        name:"History", 
                        color:"", 
                        type:"sales"
                    }, 
                    { 
                        icon:"content_copy",
                        action:"last_print", 
                        name:"Duplicate", 
                        color:"", 
                        type:"sales",
                        no_fiscal_printer:true
                    },  
                    { 
                        icon:"mdi-cart-arrow-up",
                        action:"sales_return", 
                        name:"Sales Return", 
                        color:"", 
                        type:"sales"
                    }, 
                     
                    { 
                        icon:"mdi-account",
                        action:"add_new_customer", 
                        name:"Customers", 
                        color:"", 
                        menu:{
                            name:"Customers",
                            items:[
                                {icon:"mdi-account-plus",action:"add_new_customer",name:"Add New Customer"},
                                {icon:"mdi-account",action:"manage_customers",name:"Manage Customers"}, 
                            ]
                        }
                    }, 
                    { 
                        icon:"mdi-tag",
                        action:"add_new_customer", 
                        name:"Items", 
                        color:"", 
                        menu:{
                            name:"Items",
                            items:[
                                {icon:"mdi-tag-plus",action:"add_new_item",name:"Add New Items"}, 
                                {icon:"mdi-tag",action:"manage_items",name:"Manage Items"},  
                            ]
                        }
                    }, 
                    { 
                        icon:"mdi-group",
                        action:"add_item_kit", 
                        name:"Item Kit", 
                        color:"", 
                        menu:{
                            name:"Item Kit",
                            items:[ 
                                {icon:"mdi-plus-box-outline",action:"add_new_item_kit",name:"Add New Kit/Service"},  
                                {icon:"mdi-group",action:"manage_item_kit",name:"Manage Kit/Services"}, 
                            ]
                        }
                    }, 
                    { 
                        icon:"$printer-pos-pause",
                        action:"close_register", 
                        name:"Close Register",
                        color:"secondary", 
                        type:"sales"
                    }, 
                    {
                        icon:"$printer-pos-plus",
                        action:"start_day", 
                        name:"Start Day",
                        color:"secondary",
                    },
                    {
                        icon:"mdi-order-bool-ascending-variant",
                        action:"manage_shift", 
                        name:"Manage Shift",
                        color:"secondary",
                    },
                    {
                        icon:"$printer-pos-alert" ,
                        action:"x_read", 
                        name:"X Read",
                        color:"secondary",
                        type:"day_shift"
                    },
                    
                    {
                        icon:"$printer-pos-remove",
                        action:"z_read", 
                        name:"Z Read",
                        color:"secondary",
                        type:"day_shift"
                    },
                    { 
                        icon:"mdi-cash-clock",
                        action:"cashup", 
                        name:"Cashup", 
                        color:"", 
                        type:"sales"
                    }, 
                    { 
                        icon:"mdi-sun-clock",
                        action:"days_shifts", 
                        name:"Days Shifts", 
                        color:"", 
                    }, 
                ]
            },
            ActionItems(){ 
                let actions = this.AllActionItems
                let printer = this.AssignedPrinter(null) 
                let isPrinter = printer?true:false
                if(!actions){return null}
                let newActions = actions.filter(item=>{
                    // if (isPrinter) {
                    //     return !item.no_fiscal_printer
                    // }
                    return true
                })
                return newActions
            },
            DisabledFields(){
                let order_code = this.order_code  
                if (order_code) {
                    return ['supplier_code','payment_method']
                }
                return null
            }, 
            DisabledCashupFields(){ 
                let settings = this.CompanySettings  
                let edit_opening_cash = settings?settings.edit_opening_cash:null
                if (!edit_opening_cash) {
                    return ['opening_cash','expected_cash']
                }
                return null
            },   

            //
            DayShiftSaleOrders(){ 
                let sales = this.AllSaleOrdersData 
                
                let day_shift_key = this.this_day_shift_key 
                let till_key = this.this_till_key
                if(!day_shift_key||!sales){return null}
                let filtered_items = sales.filter(item=>{
                    return item.day_shift_key == day_shift_key && 
                        item.till_key == till_key
                })    
                return filtered_items  
            }, 
            DayShiftSaleOrdersItems(){
                let sales = this.DayShiftSaleOrders 
                let items = this.ORDER_ITEMS(sales)  
                return items
            },
            CashDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems 
                let retail_items = this.CASH_ITEMS(items) 
                return retail_items 
            },

            ThisInvoicePayments(){
                let payments = this.DataInvoicePayments
                let LocalCashup = this.LocalCashup
                let cashup_key = LocalCashup?LocalCashup.key:null
                let day_shift_key = LocalCashup?LocalCashup.day_shift_key:null
                if(!payments||!day_shift_key){return null}
                let filled_items = payments.filter(item=>{
                    return item.cashup_key == cashup_key && item.day_shift_key == day_shift_key
                }) 
                return filled_items
            },
            CashInvoicePayments(){
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return !item.payment_option_code
                }) 
                return filled_items
            },
            OtherInvoicePayments(){
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return item.payment_option_code
                }) 
                return filled_items
            },
            TotalPayments(){
                try { 
                    let items = this.ThisInvoicePayments
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            TotalCurrency(){
                try { 
                    let currencies = this.currency_list
                    if(!currencies){0}
                    let total = 0
                    currencies.forEach(currency => {
                        total = total+(this.toNumber(currency.quantity)*this.toNumber(currency.value))
                    });
                    return total
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
            CalculatedVAT(){
                try { 
                    const settings = this.CompanySettings
                    const items_are_vat_exclusive = settings?settings.items_are_vat_exclusive:null
                    const calculate_vat = settings?settings.calculate_vat:null
                    const SELECTED_ITEMS = this.SELECTED_ITEMS
                    if(!calculate_vat){return null}
                    let vat = {
                        taxable_a:0,
                        vat_a:0,
                        taxable_b:0,
                        vat_b:0,
                        taxable_c:0,
                        vat_c:0,
                        total_vat:0,
                        vat_exclusive:items_are_vat_exclusive
                    }
                    if(SELECTED_ITEMS){ 
                        SELECTED_ITEMS.forEach(item => {
                            let tax_group_code = item.tax_group_code?item.tax_group_code:"A"
                            let sold_price = this.toNumber(item.sold_price)*this.toNumber(item.quantity)
                            
                            let item_vat = 0
                            
                            if (tax_group_code=="A") {
                                vat.taxable_a = vat.taxable_a + sold_price
                                if (items_are_vat_exclusive) {
                                    item_vat = sold_price*16.5/100
                                } else {
                                    let not_vat_price = sold_price / (1+(16.5/100))
                                    item_vat = sold_price-not_vat_price 
                                }
                                vat.vat_a = vat.vat_a+item_vat
                            }if (tax_group_code=="B") {
                                vat.taxable_b = vat.taxable_b + sold_price
                            }if (tax_group_code=="E") {
                                vat.taxable_c = vat.taxable_c + sold_price
                                
                            }
                            
                        }); 
                        vat.total_vat = vat.vat_a + vat.vat_b+vat.vat_c
                        if (!items_are_vat_exclusive) {
                            vat.taxable_a = vat.taxable_a - vat.vat_a
                            vat.taxable_b = vat.taxable_b - vat.vat_b
                            vat.taxable_c = vat.taxable_c - vat.vat_c
                        }
                    } 
                    return vat
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
            PriceExcludeVAT(){
                try { 
                    const settings = this.CompanySettings
                    const items_are_vat_exclusive = settings?settings.items_are_vat_exclusive:null
                    const calculate_vat = settings?settings.calculate_vat:null
                    return (price,tax_group_code)=>{
                        price = this.toNumber(price) 
                        if (calculate_vat && !items_are_vat_exclusive) {
                            if (tax_group_code=="B"||tax_group_code=="E") {
                                return price
                            }
                            return price / (1+(16.5/100))
                        }
                        return price

                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PriceExcludeVAT',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            given_amount(){
                try {  
                    const payments = this.inputPayments
                    let input_pay = 0
                    if (payments) {
                        payments.forEach(pay => {
                            let this_pay = this.toNumber(pay.given_amount)
                            input_pay = input_pay+this_pay
                        });
                    }
                    return input_pay
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'given_amount',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
            inputPayments(){
                try { 
                    const input = this.input
                    const payment_cash = this.toNumber(input.payment_cash)
                    const payment_description_cash = input.payment_description_cash
                    const payments = this.PaymentMethodsData
                    let input_pay = []
                    input_pay.push({
                        key:'cash',
                        given_amount:payment_cash,
                        payment_description:payment_description_cash,
                    })
                    if (payments) {
                        payments.forEach(pay => {
                            let this_pay = this.toNumber(input['payment_'+pay.key])
                            let this_description = input['payment_description_'+pay.key]
                            input_pay.push({
                                ...pay,
                                given_amount:this_pay,
                                payment_description:this_description
                            })
                        });
                    }
                    return input_pay
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'given_amount',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
            DisabledOnAccountFields(){
                try { 
                    const till = this.CurrentTill
                    const allow_choosing_printer = till?till.allow_choosing_printer:null
                    let fields = null
                    if (!allow_choosing_printer) {
                        fields = ["selected_printer_name"] 
                    }  
                    return fields
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'given_amount',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  

            //Permissions / settings
            AllowChangingDepartments(){
                const till = this.CurrentTill
                const change = till?till.allow_Cashier_to_change_department:false 
                return change 
            },
            AllowChoosingPrinter(){
                const till = this.CurrentTill
                const change = till?till.allow_choosing_printer:false 
                return change 
            }
        },
        methods: {  
            async ITEM_ACTION(action,item,option){
                try {    
                    const isMenu = option?option.menu:false 
                    const hasMenu = item?item.menu:false  
                    if (isMenu && hasMenu) {
                        this.SELECTED_ACTION_MENU=item
                        this.dialog_action_menu=true
                        return
                    }
                    if (action == "start_day") {
                        let link = "/sale/start-day/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "manage_shift") {
                        let day_shift_key = this.LocalCashup?this.LocalCashup.day_shift_key:null
                        if (day_shift_key) {
                            let link = "/manage-shift/"+day_shift_key
                            const path = this.MBS.actions.COMPANY_LINK(link,true) 
                            this.MBS.actions.go(path)  
                        } else {
                            // let link = "/sale/days-shifts/"
                            // const path = this.MBS.actions.COMPANY_LINK(link,true) 
                            // this.MBS.actions.go(path) 
                            this.dialog_manage_shift = true
                        }
                    }else if (action == "x_read") { 
                        let day_shift_key = this.LocalCashup?this.LocalCashup.day_shift_key:null
                        if (day_shift_key) {
                            let link = "/x-read/"+day_shift_key
                            const path = this.MBS.actions.COMPANY_LINK(link,true) 
                            this.MBS.actions.go(path)  
                        } else {
                            // let link = "/sale/days-shifts/"
                            // const path = this.MBS.actions.COMPANY_LINK(link,true) 
                            // this.MBS.actions.go(path) 
                            this.dialog_x_read = true
                        }
                    }else if (action == "z_read") {
                        let day_shift_key = this.LocalCashup?this.LocalCashup.day_shift_key:null
                        if (day_shift_key) {
                            let link = "/z-read/"+day_shift_key
                            const path = this.MBS.actions.COMPANY_LINK(link,true) 
                            this.MBS.actions.go(path)  
                        }else{
                            // let link = "/sale/days-shifts/"
                            // const path = this.MBS.actions.COMPANY_LINK(link,true) 
                            // this.MBS.actions.go(path) 
                            this.dialog_z_read = true
                        }
                    }else if (action == "clear_sales") {
                        let mbs_text = this.MBS.text.item_action("Items","Clear") 
                        let settings = this.CompanySettings
                        let require_void_to_remove_item = settings?settings.require_void_to_remove_item:null 

                        if (require_void_to_remove_item) {
                            this.MBS.actions.dialogConfirmPassword({
                                show:true,
                                fixed:true,
                                icon:"mdi-shield-lock-outline",
                                title:"VOID REQUIRED TO CLEAR ITEMS", 
                                text:"Clearing items require admin permission. Enter your admin phone, and password to authorize this action.",
                                action:{
                                    code:PAGE_NAME+"=CLEAR-SALES",
                                    all_users:true,
                                    
                                }
                            })
                        } else {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes,
                                btnNo:mbs_text.btnNo,
                                action:{
                                    code:PAGE_NAME+"=CLEAR-SALES"
                                }
                            })
                        } 
                    }else if (action == "scan_items") {
                        let mbs_text = this.MBS.text.item_action("Register Items","Clear")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"WAITING LIST",
                            text:'Currently, not available.',
                            btnYes:"OK", 
                            action:{
                                // code:PAGE_NAME+"=CLEAR-SALES"
                            }
                        })
                    }else if (action == "settings") {
                        let link = "/sale/settings/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "open_drawer") {
                        let settings = this.CompanySettings
                        let allow_drawer = settings?settings.open_drawer_without_password:false
                        if (allow_drawer) {
                            this.OPEN_DRAWER()
                        } else {
                            this.RESET_ALL_DATA()
                            this.dialog_open_drawer = true 
                        }
                    }else if (action == "view_orders") {
                        this.ON_VIEW_ORDERS_LIST() 
                    }else if (action == "view_customers_orders") {
                        this.ON_VIEW_CUSTOMERS_ORDERS() 
                    }else if (action == "view_options_orders") {
                        this.ON_VIEW_OPTIONS_ORDERS() 
                    }else if (action == "close_register") {
                        this.CLOSE_REGISTER()
                    }else if (action == "days_shifts") {
                         let link = "/sale/days-shifts/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "cashup") {
                         let link = "/sale/cashup/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "history") {
                        let link = "/sale/history/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "last_print") {
                        let sales = this.SaleOrdersData 
                        let lastSale =  null
                        if (sales) {
                            lastSale = sales.slice(-1)[0]
                        } 
                        if (lastSale) { 
                            this.RECEIPT_PRINTER({
                                order:lastSale,
                                duplicate:true, 
                            })
                        } else {
                            
                        } 
                        
                    }else if (action == "discount") {
                        this.MBS.actions.dialog(null,true,false,"Coming Soon!..","Current not working. Try again later.",null,"OK") 
                    }else if (action == "sales_return") {
                        let link = "/stock/stock-control/sales-return"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path)
                    }else if (action == "add_new_customer") {
                         this.ADD_CUSTOMER()
                    }else if (action == "manage_customers") {
                        let link = "/office/people/customers/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path)
                    }else if (action == "add_new_item") {
                         this.CREATE_ITEM()
                    }else if (action == "add_new_item_kit") {
                         this.CREATE_ITEM_KIT()
                    }else if (action == "manage_items") {
                        let link = "/stock/items/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path)
                    }else if (action == "manage_item_kit") {
                        let link = "/stock/item-kit/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path)
                    }else if (action == "refresh_data") {
                        this.UPDATE_K_CONNECTOR()
                        let mbs_text = this.MBS.text.item_action("DATA","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-DATA", 
                            } 
                        })
                    }else if (action == "click_mode") {
                        this.click_mode=true
                    }else{
                        this.MBS.actions.dialog(null,true,false,"Coming Soon!..","Current not working. Try again later.",null,"OK") 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            FOCUS_INPUT(field){
                this.focused_field = field 
            },
            REMOVE_DEPARTMENT (item) { 
                const index = this.selected_departments.indexOf(item.code)
                if (index >= 0) this.selected_departments.splice(index, 1)
            },
            ADDED(res){
                try { 
                    this.RELOAD_DATA()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            async COMPANY_QUICK_ACTIONS(actions){
                try {    
                    if (actions) {
                        let current_till = this.CurrentTill
                        let till_key = current_till?current_till.key:null
                        actions.forEach(action => {
                            if (till_key && till_key==action.till_key) { 
                                if (action.action == "open_drawer") {
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"OPENED DRAWER",
                                        text:"Admin, opened drawer.",
                                        btnYes:"OKAY", 
                                    })
                                    this.OPEN_DRAWER()
                                    this.DELETE_COMPANY_QUICK_ACTION(action)
                                }
                                if (action.action == "print_duplicate") {
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"DUPLICATE",
                                        text:"Admin, print duplicate.",
                                        btnYes:"OKAY", 
                                    })
                                    this.ITEM_ACTION('last_print')
                                    this.DELETE_COMPANY_QUICK_ACTION(action)
                                }
                            }
                        });   
                    } 
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETE_COMPANY_QUICK_ACTION(action){ 
                try { 
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_quick_actions+action.key,true) 
                    this.$store.dispatch("fi_delete",{
                        action:DATA.ITEMS.COMPANY_QUICK_ACTIONS.values,
                        path:path, 
                    }).then(res=>{ 
                    }).catch(error=>{
                        console.log(error,'error.....'); 
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_COMPANY_QUICK_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            FINISH_PRINT(action,payload){
                try {  
                    let canceled = payload?payload.canceled:null
                    if(!action){return}
                    if (action.code == PAGE_NAME+"=OPEN-CASHUP") {
                        this.OPEN_CASHUP(action.data)
                        if (canceled) {
                            
                        }else{
                             
                        }  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            async CLEAR_SELECTED_ITEMS(){
                try {  
                    let res =  await DB.deleteItems({ 
                        name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                        update_locals:true, 
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLEAR_SELECTED_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RESET_ALL_DATA(rest_selected_items){ 
                let response ={
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }
                return new Promise( async(resolve,reject)=>{
                    try { 
                        let selling_model = this.selling_model
                        let auto_print = this.auto_print
                        setTimeout( async () => {
                            if(rest_selected_items){
                               this.CLEAR_SELECTED_ITEMS() 
                            }
                            Object.assign(this.$data, this.$options.data()) 
                            this.FEEDBACKS(500) 
                            this.INITIALIZE_SELLING_MODE()
                            this.INITIALIZE_CLICK_MODE()
                            this.INITIALIZE_ITEMS_CATEGORIES()
                            this.INITIALIZE_ITEMS_DEPARTMENTS() 
                            this.INITIATE_SELECTED_SALES_ITEMS() 
                            
                            //restore data
                            this.selling_model = selling_model
                            this.auto_print = auto_print
                            resolve({success:true})
                        }, 50);
                    } catch (error) {
                        response = {...response,error:error}
                        this.MBS.actions.error(response) 
                        reject(response)
                    } 
                })
            },
            async CLEAR_ALL_DATA(){ 
                try { 
                    this.RESET_ALL_DATA(true)
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLEAR_ALL_DATA',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async UPDATE_K_CONNECTOR(){ 
                return new Promise(async(resolve,reject)=>{
                    try { 
                        let res = await this.MBS.actions.K_CONNECT() 
                        resolve(res)
                    } catch (error) { 
                        reject(error)
                    } 
                })
            },


            // GET_LOCAL_CASHUP(){
            //     try {    
            //         this.inputLoading = true
            //         DB.getItems({
            //             name:DATA.ITEMS.LOCAL_CASHUPS.values
            //         }).then(res=>{
            //             this.inputLoading = false
            //             let allLocalCashups = res?res.data:null
            //             let localCashup = allLocalCashups?allLocalCashups.find(item=>{
            //                 return !item.closing_cash
            //             }):null 
            //             this.MBS.actions.UPDATE_LOCAL_CASHUP(localCashup,allLocalCashups)
            //             this.MBS.events.$emit("SUBMIT_CASHUPS", allLocalCashups,PAGE_NAME)
            //         }).catch(error=>{ 
            //             this.inputLoading = false
            //             this.MBS.actions.error({
            //                 error:error,
            //                 from:'GET_LOCAL_CASHUP',
            //                 page:PAGE_NAME, 
            //             }) 
            //         })  
            //     } catch (error) {
            //         this.MBS.actions.error({
            //             error:error,
            //             from:'GET_LOCAL_CASHUP',
            //             page:PAGE_NAME, 
            //         }) 
            //     } 
            // },


            SELECT_PAYMENT_OPTION(payment_option){
                try {     
                    this.paying_option = payment_option 
                    this.input.given_amount = this.TotalDiscountedPrice 
                    this.dialog_paid_amount = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_PAYMENT_OPTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_SEARCH(selling_model){
                try {    
                    if (!selling_model) { 
                        this.menu_selling_mode = true
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SEARCH',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_CHANGE_PRINTER(regal){
                try {    
                    let assigned_printer = this.AssignedPrinter(regal)
                    console.log(assigned_printer,'assigned_printer....******---------*******');

                    let AllPrinters = this.AllPrinters
                    if (assigned_printer) {
                        this.selected_printer_name = assigned_printer.name
                        return assigned_printer
                    } else {
                        this.selected_printer_name =  AllPrinters?AllPrinters[0].name:''
                        return AllPrinters[0]
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_CHANGE_PRINTER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_PAY(){
                try {    
                    this.dialog_given_amount = !this.dialog_given_amount 
                    this.ON_CHANGE_PRINTER(true) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PAY',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            SELECT_ORDER_OPTION(option){
                try {    
                    this.SELECTED_ORDER_OPTION = option
                    this.menu_select_order_option = false
                    this.dialog_put_on_order_option = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_ORDER_OPTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            PUT_ON_ORDER_OPTION(){
                try {   
                    let PUT_AND_PRINT = this.PUT_AND_PRINT 

                    let option = this.SELECTED_ORDER_OPTION 
                    let input = this.input
                    let customer_key = input?input.customer_key:null 
                    let location = input?input.location:null 
                    let estimated_time = input?input.estimated_time:null 
                    let description = input?input.description:null 
                    this.inputLoading = true
                    let order = this.CREATE_ORDER(true)
                    if (customer_key) {
                        order.customer_key = customer_key
                    }
                    if (location) {
                        order.location = location
                    }if (estimated_time) {
                        order.estimated_time = estimated_time
                    }if (description) {
                        order.description = description
                    }
                    if (option) {
                        order.order_option = option
                        order.order_option_key = option.key 
                    }

                    DB.addItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                        data:order
                    }).then(res=>{
                        this.inputLoading = false
                        this.dialog_put_on_order_option = false
                        this.MBS.events.$emit("SUBMIT_ITEM_ORDERS")
                        this.CLEAR_ALL_DATA()
                        if (PUT_AND_PRINT) {
                            setTimeout(() => { 
                                this.PRINT_ORDER_OPTION(order,'selected_order')
                            }, 500);
                        }
                    }).catch(error=>{
                        this.inputLoading = false

                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PUT_ON_ORDER_OPTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            UPDATE_ITEM_ORDER(){
                try {     
                    this.inputLoading = true
                    let order = this.CREATE_ORDER(true)  
                    DB.addItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                        data:order
                    }).then(res=>{
                        this.inputLoading = false
                        this.dialog_put_on_order_option = false
                        this.dialog_view_order = false
                        this.MBS.events.$emit("SUBMIT_ITEM_ORDERS")
                        this.CLEAR_ALL_DATA()
                    }).catch(error=>{
                        this.inputLoading = false
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PUT_ON_ORDER_OPTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            async PAY_ITEM_ORDER(order){
                try {    
                    await DB.addItems({
                        data:order.joined_items,
                        name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                        clear:true,
                        update_locals:true,
                         
                    }) 
                    this.input_discount_amount = order.total_discount 
                    // this.pay_order = order
                    this.item_order = order
                    this.dialog_view_orders_list = false
                    this.dialog_view_order = false
                    this.CREATE_ORDER()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAY_ITEM_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM_ORDER(order){
                try {     
                    this.item_order = order
                    this.input_discount_amount = order.total_discount 
                    // console.log(order,'or......................')
                    // return
                    DB.addItems({
                        data:order.joined_items,
                        name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                        clear:true,
                        update_locals:true,
                         
                    })
                    this.dialog_view_orders_list = false
                    this.dialog_view_order = false
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            JOIN_MULTIPLE_ORDERS(order){
                try {
                    const orders = order?order.orders:null
                    let order_type = order?order.order_type:null
                    const customer_key = order?order.key:null
                    let customer = order

                    let printer = this.AssignedPrinter(false) 
                    let till = this.CurrentTill 
                    let till_code = till?till.code:null
                    let outlet_code = till?till.outlet_code:null
                    let selectedCompany = this.selectedCompany 

                    let company_key = selectedCompany?selectedCompany.key:null
                    let localCashup = this.LocalCashup 
                    let cashup_key = localCashup?localCashup.key:null
                    let day_shift_key = localCashup?localCashup.day_shift_key:null 
                    let us = this.us
                    let uid = us?us.uid:null 
                    let moment = this.MBS.date.moment
                    let key =  moment?moment().valueOf():null

                    if(!orders){return null}
                    let total_price = 0
                    let total_cost_price = 0
                    let total_sold_price = 0
                    let total_discount = 0 
                    let items = []
                    orders.forEach(order => {
                        total_price = total_price+this.toNumber(order.total_price)
                        total_cost_price = total_cost_price+this.toNumber(order.total_cost_price)
                        total_sold_price = total_sold_price+this.toNumber(order.total_sold_price)
                        total_discount = total_discount+this.toNumber(order.total_discount)
                        if (order.items) {
                            items.push(...order.items)
                        } 
                    });
                    let joined_items = this.MBS.actions.JOIN_ITEMS(items)
                    let new_order ={
                        sold:false,
                        created_uid:uid,
                        key:key,
                        till_code:till_code,
                        outlet_code:outlet_code,
                        cashup_key:cashup_key,
                        day_shift_key:day_shift_key,
                        company_key:company_key,  
                        local_created_at:key, 
                        order_type:order_type, 
                        customer:customer, 
                        customer_key:customer_key, 

                        items:items,
                        joined_items:joined_items,
                        total_price:total_price,
                        total_cost_price:total_cost_price,
                        total_discount:total_discount,
                        orders:orders
                    }
                    return new_order 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'JOIN_MULTIPLE_ORDERS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            PRINT_ITEM_ORDER(order){
                try {  
                    let order_type = order?order.order_type:null
                    if (order_type!='selected_order') {
                        order = this.JOIN_MULTIPLE_ORDERS(order)
                    }   

                    console.log(order,'order...........888');
                    this.EXIT_DIALOG()
                    this.RECEIPT_PRINTER({
                        order:order,
                        duplicate:true, 
                        title:"ORDERS RECEIPT"
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PRINT_ITEM_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            PRINT_ORDER_OPTION(order,order_type){
                try {   
                    
                    let CurrentTill = this.CurrentTill
                    let allow_choosing_printer = CurrentTill?CurrentTill.allow_choosing_printer:false 
                    this.ON_CHANGE_PRINTER(false) 
                    this.PRINTING_ORDER = {
                        ...order,
                        order_type:order_type
                    }
                    if (allow_choosing_printer) {
                        this.dialog_print_order = true 
                    } else {
                        this.PRINT_ITEM_ORDER(this.PRINTING_ORDER)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PRINT_ORDER_OPTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            async PAY_ORDER_OPTION(order){
                try {   
                    let order_type = order?order.order_type:null
                    if (order_type!='selected_order') {
                        order = this.JOIN_MULTIPLE_ORDERS(order)
                    }   
                    this.item_order = order
                    this.input_discount_amount = order.total_discount 
                    await  DB.addItems({
                        data:order.joined_items,
                        name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                        clear:true,
                        update_locals:true, 
                    }) 

                    // this.input_discount_amount = order.total_discount 
                    // // this.pay_order = order
                    // this.item_order = order
                    this.dialog_view_orders_list = false
                    this.dialog_view_order = false
                    this.CREATE_ORDER() 
                    this.EXIT_DIALOG()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAY_ORDER_OPTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_DELETE_ALL_ITEM_ORDER(){
                try {    
                    let mbs_text = this.MBS.text.item_action("Order(s)","Clear All")
                    this.MBS.actions.dialogConfirmPassword({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        icon:"mdi-delete-empty",
                        text:mbs_text.text+" Enter your password to confirm clearing all the order. This action can not be reversed.",
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:PAGE_NAME+"=DELETE-ALL-ITEM-ORDER", 
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_DELETE_ITEM_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_DELETE_ITEM_ORDER(order){
                try {    
                    let mbs_text = this.MBS.text.item_action("Order","Delete")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:PAGE_NAME+"=DELETE-ITEM-ORDER",
                            data:order
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_DELETE_ITEM_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_DELETE_CUSTOMER_ITEM_ORDER(customer){
                try {    
                    let customer_name = customer?customer.name:'...' 
                    let orders = customer?customer.orders:null
                    let mbs_text = this.MBS.text.item_action(customer_name+" Orders","Delete")
                    this.MBS.actions.dialogConfirmPassword({
                        show:true,
                        fixed:true,
                        icon:"mdi-delete-empty",
                        title:mbs_text.title,
                        text:mbs_text.text+" Enter your password to confirm this action.",
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:PAGE_NAME+"=DELETE-MULTIPLE-ITEM-ORDERS",
                            data:orders
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_DELETE_CUSTOMER_ITEM_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_DELETE_OPTION_ITEM_ORDER(option){
                try {    
                    let option_name = option?option.name:'...' 
                    let orders = option?option.orders:null
                    let mbs_text = this.MBS.text.item_action(option_name+" Orders","Delete")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:PAGE_NAME+"=DELETE-MULTIPLE-ITEM-ORDERS",
                            data:orders
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_DELETE_OPTION_ITEM_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETE_ALL_ITEM_ORDER(){
                try {  
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.item_orders,true)
                    DB.deleteItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values, 
                    })
                    .then(res=>{
                        this.RESET_ALL_DATA(true)
                        this.MBS.events.$emit("SUBMIT_ITEM_ORDERS")
                        return this.$store.dispatch("fi_delete",{
                            path:path,
                            action:DATA.ITEMS.ITEM_ORDERS.values
                        })
                    })
                    .then(res=>{ 

                    }).catch(error=>{
                        console.log(error,'error.....');
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ALL_ITEM_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETE_ITEM_ORDER(order){
                try {  
                    let order_key = order.key
                    let day_shift_key = order.day_shift_key
                    let company_key = order.company_key 
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.item_orders+day_shift_key+"/"+order_key,true)
                    DB.deleteItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                        key:order.key
                    }).then(res=>{
                        this.MBS.events.$emit("SUBMIT_ITEM_ORDERS")
                        this.CLEAR_ALL_DATA()
                        return this.$store.dispatch("fi_delete",{
                            path:path,
                            action:DATA.ITEMS.ITEM_ORDERS.values
                        })
                    }).then(res=>{ 
                        this.dialog_view_order = false 
                    }).catch(error=>{
                        console.log(er,'er............');
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ITEM_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            async DELETE_MULTIPLE_ITEM_ORDERS(orders){
                try {  
                    if(!orders){return}
                    for (const order of orders) {
                        let order_key = order.key
                        let day_shift_key = order.day_shift_key
                        let company_key = order.company_key 
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.item_orders+day_shift_key+"/"+order_key,true)
                        try {
                            await DB.deleteItems({
                                name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                                key:order.key
                            }) 
                            this.RESET_ALL_DATA(true)
                            await this.$store.dispatch("fi_delete",{
                                path:path,
                                action:DATA.ITEMS.ITEM_ORDERS.values
                            }) 
                        } catch (error) {
                            console.log(error,'er............'); 
                        }  
                    }
                    this.MBS.events.$emit("SUBMIT_ITEM_ORDERS")
                    this.dialog_view_order = false
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_MULTIPLE_ITEM_ORDERS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_VIEW_ORDERS_LIST(){
                try {    
                    this.dialog_view_orders_list = !this.dialog_view_orders_list
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_VIEW_ORDERS_LIST',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_VIEW_CUSTOMERS_ORDERS(){
                try {    
                    this.dialog_view_customers_orders = !this.dialog_view_customers_orders
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_VIEW_CUSTOMERS_ORDERS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_VIEW_OPTIONS_ORDERS(){
                try {    
                    this.dialog_view_options_orders = !this.dialog_view_options_orders
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_VIEW_OPTIONS_ORDERS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_VIEW_ORDER(order,index){
                try {    

                    this.SELECTED_ORDER = {...order,expand_items:true,expand_details:false}
                    this.dialog_view_order = !this.dialog_view_order
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_VIEW_CUSTOMER_ORDER(order,index){
                try {     
                    this.SELECTED_CUSTOMER_ORDER = order
                    this.dialog_view_selected_customer_order = !this.dialog_view_selected_customer_order
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_VIEW_OPTION_ORDER(order,index){
                try {     
                    this.SELECTED_OPTION_ORDER = order
                    this.dialog_view_selected_option_order = !this.dialog_view_selected_option_order
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_VIEW_OPTION_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EXIT_DIALOG(){
                try {     
                    this.dialog_view_order = false 
                    this.dialog_view_orders_list = false 
                    this.dialog_view_customers_orders = false 
                    this.dialog_view_selected_customer_order = false 
                    this.dialog_view_options_orders = false 
                    this.dialog_view_selected_option_order = false 
                    this.dialog_print_order = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            OPEN_Z_READ(){
                try {    
                    let day_shift_key = this.input?this.input.day_shift_key:null
                    if (day_shift_key) {
                        let link = "/z-read/"+day_shift_key
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path)  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OPEN_Z_READ',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            OPEN_X_READ(){
                try {    
                    let day_shift_key = this.input?this.input.day_shift_key:null
                    if (day_shift_key) {
                        let link = "/x-read/"+day_shift_key
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path)  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OPEN_X_READ',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            OPEN_MANAGE_SHIFT(){
                try {    
                    let day_shift_key = this.input?this.input.day_shift_key:null
                    if (day_shift_key) {
                        let link = "/manage-shift/"+day_shift_key
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path)  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OPEN_MANAGE_SHIFT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            OPEN_DRAWER(){
                try {    
                    //check connector 
                    let K_CONNECTOR = this.K_CONNECTOR
                    let settings = this.CompanySettings
                    let Sell_without_printer = settings?settings.Sell_without_printer:null
                    let printer = this.AssignedPrinter(false,true) 
                    if (K_CONNECTOR) { 
                        this.inputLoading = true
                        this.MBS.actions.EPSON_OPEN_DRAWER({printer:printer})
                        .then(res=>{
                            this.inputLoading = false
                            this.dialog_open_drawer = false
                        }).catch(error=>{
                            this.inputLoading = false
                            this.MBS.actions.dialog()
                            setTimeout(() => {
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:"ERROR",
                                    text:'Unable to open drawer. ('+error.message+')',
                                    btnYes:"TRY AGAIN",  
                                    btnNo:"CANCEL",  
                                    action:{
                                        code:PAGE_NAME+"=OPEN-DRAWER"
                                    }
                                })  
                                
                            }, 100);
                        })
                    }else if (Sell_without_printer) {
                        
                    } else {
                        this.inputLoading = false
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"CONNECTOR IS OFF",
                            text:'Kipcount Connector is offline. Please make sure to start the K-Connector and try again.',
                            btnYes:"TRY AGAIN",  
                            btnNo:"CANCEL",  
                            action:{
                                code:PAGE_NAME+"=K-CONNECT"
                            }
                        })  
                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OPEN_DRAWER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_OPEN_DRAWER(password){
                try {    
                    password = password?this.MBS.crypt.encrypt(password):null 
                    if (!password) { 
                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                    }else if (password!==this.ud.p_uid) { 
                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                    } else { 
                        this.OPEN_DRAWER()
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_OPEN_DRAWER',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 

            //ADDING ITEMS
            ADD_CUSTOMER(input){
                try {  
                    this.ADDING_ITEM = {...DATA.ITEMS.CUSTOMERS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.customers,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.customers
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Add New',
                        action_doing:'Adding New'
                    }  
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.CUSTOMERS.name,
                            action:"add_item",
                            input:{name:input}
                        }) 
                        
                    }, 50);

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_CUSTOMER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CREATE_ITEM(input,barcode){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"add_item",
                            input:{
                                name:input,
                                code:barcode,
                            }
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CREATE_ITEM_KIT(input){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEM_KITS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items_kit
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEM_KITS.name,
                            action:"add_item",
                            input:{name:input}
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },

            //SELLING
            ON_START_SELLING(){
                try {    
                    const settings = this.CompanySettings
                    const till_require_no_password = settings?settings.till_require_no_password:null

                    //
                    let input = this.input
                    let day_shift_and_till_key = input?input.day_shift_and_till_key:null
                    let day_shift_and_till_key_ = day_shift_and_till_key?day_shift_and_till_key.split('/'):null
                    let day_shift_key = day_shift_and_till_key_?day_shift_and_till_key_[0]:null
                    let till_key = day_shift_and_till_key_?day_shift_and_till_key_[1]:null
                    let tills = this.DayShiftsTills

                    //
                    let till = this.getDayShiftTill(day_shift_and_till_key) 
                    let floating_cash = till?till.floating_cash:null  
                    let cashups = this.thisCashupsData  
                    let till_cashups = cashups?cashups.filter(c=>c.till_key==till_key):null
                    let last_cashup = till_cashups?till_cashups.pop():null 
                    this.input.expected_opening_cash = this.toNumber(
                        last_cashup?last_cashup.closing_cash:floating_cash)
                    this.input.expected_opening_cashup_key = last_cashup?last_cashup.key:null 

                    let selected_till = tills?tills.find(till=>till.key==till_key):null
                    let isRunning = selected_till?selected_till.running:null
                    if (isRunning) {
                        let link = "/manage-shift/"+day_shift_key
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        let mbs_text = this.MBS.text.till_is_busy(selected_till)
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:"=GO",
                                to:path
                            }
                        })
                    }else if(!selected_till) {
                        let mbs_text = this.MBS.text.error_0
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title, 
                            text:mbs_text.text, 
                            btnYes:mbs_text.btnYes, 
                        })
                        this.CLEAR_ALL_DATA()
                    } else {
                        // this.dialog_start_selling = true 
                        if(till_require_no_password){
                            this.OPEN_DRAWER_BEFORE_SELLING()
                        }else{
                            this.MBS.actions.dialogConfirmPassword({
                                show:true,
                                fixed:true,
                                icon:"$printer-pos-play",
                                title:"START SELLING", 
                                text:"Enter your password to confirm opening this register.", 
                                btnYes:"CONFIRM PASSWORD", 
                                btnNo:"CANCEL", 
                                action:{
                                    code:PAGE_NAME+"=START-SELLING",
                                    data:this.input
                                }
                            }) 
                        }
                       
                        
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_START_SELLING',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_START_SELLING(password){
                try {    
                    password = password?this.MBS.crypt.encrypt(password):null 
                    if (!password) { 
                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                    }else if (password!==this.ud.p_uid) { 
                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                    } else { 
                        this.dialog_start_selling = false
                        this.OPEN_DRAWER_BEFORE_SELLING()
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_START_SELLING',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            OPEN_DRAWER_BEFORE_SELLING(){
                try {   
                    const settings = this.CompanySettings
                    const cashup_before_selling = settings?settings.cashup_before_selling:null
                    if (cashup_before_selling) {
                        this.dialog_open_drawer_before_selling = true
                        setTimeout(() => { 
                            this.OPEN_DRAWER()
                        }, 100); 
                    } else {
                        this.CONFIRM_OPENING_REGISTER()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OPEN_DRAWER_BEFORE_SELLING',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_OPENING_REGISTER(){
                try {  
                    let mbs_text = this.MBS.text.item_action("Selling","Start") 
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text+" Print your opening Cashup and start selling.",
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:PAGE_NAME+"=OPEN-REGISTER"
                        }
                    }) 
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_CLOSING_REGISTER',
                        page:PAGE_NAME, 
                    })
                }  
            }, 
            OPEN_REGISTER(){
                try {     
                    const settings = this.CompanySettings
                    const print_opening_cashup = settings?settings.print_opening_cashup:null

                    let selectedCompany = this.selectedCompany
                    let company_key = selectedCompany?selectedCompany.key:null
                    let us = this.us
                    let uid = us?us.uid:null
                    let input = this.input
                    let day_shift_and_till_key = input?input.day_shift_and_till_key:null
                    let day_shift_and_till_key_ = day_shift_and_till_key?day_shift_and_till_key.split('/'):null
                    let day_shift_key = day_shift_and_till_key_?day_shift_and_till_key_[0]:null
                    let till_key = day_shift_and_till_key_?day_shift_and_till_key_[1]:null
                    // let opening_cash = input?input.opening_cash:null
                    let expected_opening_cash = input?input.expected_opening_cash:null
                    let expected_opening_cashup_key = input?input.expected_opening_cashup_key:null
                    let opening_cash = this.TotalCurrency
                    let currencies = this.currency_list
                    let description = input?input.description:'' 
                    let dayShiftTill = this.getDayShiftTill(day_shift_and_till_key)
                    let floating_cash = dayShiftTill?dayShiftTill.floating_cash:null
                    let moment = this.MBS.date.moment
                    let key = moment?moment().valueOf():null
                    let data = {
                        key:key,
                        day_shift_key:day_shift_key,
                        till_key:till_key,
                        opening_cash:opening_cash,
                        opening_currencies:currencies,
                        expected_opening_cash:expected_opening_cash,
                        floating_cash:floating_cash, 
                        local_created_at:key,
                        uid:uid,
                        company_key:company_key, 
                    }  
                    if(description){
                        data.description = description
                    }
                    if(expected_opening_cashup_key){
                        data.expected_opening_cashup_key = expected_opening_cashup_key
                    }
 
                    // if (uid && day_shift_key && till_key && opening_cash && key && company_key  ) {
                    if (uid && day_shift_key && till_key && key && company_key  ) { 
                        this.dialog_open_drawer_before_selling = false
                        console.log(settings,'settings,.....');
                        console.log(print_opening_cashup,'print_opening_cashup,.....');
                        console.log(this.auto_print,'this.auto_print');

                        if(print_opening_cashup){
                            let printer = this.AssignedPrinter(false,true)
                            let printer_name = printer?printer.name:null
                            let printer_type_name = printer?printer.printer_type_name:null
                            this.auto_print.printing = false
                            setTimeout(() => { 
                                this.auto_print.auto_printer_name = printer_name
                                this.auto_print.print_data = data 
                                this.auto_print.printing = true   
                                this.auto_print.auto = false   
                                this.auto_print.print_type = 'cashup'   
                                this.auto_print.printing_action={
                                    code:PAGE_NAME+"=OPEN-CASHUP",
                                    data:data
                                }
                            }, 5);  
                        }else{
                            this.OPEN_CASHUP(data)
                        } 
                    }else {  
                        this.inputLoading = false
                        this.MBS.actions.error({
                            error:'no data',
                            payload:{input:this.input},
                            from:'OPEN_REGISTER',
                            page:PAGE_NAME, 
                        }) 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OPEN_REGISTER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            OPEN_CASHUP(data){
                try {    
                    this.inputLoading = true
                    if (data) {
                        DB.addItems({
                            name:DATA.ITEMS.LOCAL_CASHUPS.values,
                            data:data,
                            update_locals:true,   
                        }).then(res=>{  
                            this.inputLoading = false 
                        }).catch(error=>{ 
                            this.inputLoading = false 
                            this.MBS.actions.error({
                                error:error,
                                from:'OPEN_CASHUP',
                                page:PAGE_NAME, 
                            })
                        })  
                    }else {
                        this.inputLoading = false
                        this.MBS.actions.error({
                            error:'no data',
                            from:'OPEN_CASHUP',
                            page:PAGE_NAME, 
                        }) 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OPEN_CASHUP',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CLOSE_REGISTER(){
                try {    
                    this.MBS.events.$emit("ON_CLOSE_REGISTER") 
                    return
                    let localCashup = this.LocalCashup 
                    this.input.opening_cash = localCashup?localCashup.opening_cash:'' 
                    this.input.description = localCashup?localCashup.description:''
                    this.input.key = localCashup?localCashup.key:null
                    this.input.till_key = localCashup?localCashup.till_key:null  
                    setTimeout(() => {
                        this.dialog_close_register = true 
                    }, 1);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLOSE_REGISTER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CLOSE_CASHUP(){
                 try {    
                    let us = this.us
                    let uid = us?us.uid:null
                    let input = this.input
                    let till_key = input?input.till_key:null
                    let closing_cash = input?input.closing_cash:null
                    let description = input?input.description:''
                    let moment = this.MBS.date.moment
                    let key = moment?moment().valueOf():null
                    let data = {
                        ...this.LocalCashup,
                        closing_cash:closing_cash, 
                        local_updated_at:key, 
                    } 
                    if(description){
                        data.description = description
                    }
                    this.inputLoading = true
                    if (uid && till_key && closing_cash && key) { 
                        DB.addItems({
                            name:DATA.ITEMS.LOCAL_CASHUPS.values,
                            data:data,
                            update_locals:true,   
                        }).then(res=>{  
                            this.inputLoading = false
                            this.dialog_close_register = false 
                        }).catch(error=>{ 
                            this.inputLoading = false 
                            this.MBS.actions.error({
                                error:error,
                                from:'CLOSE_CASHUP',
                                page:PAGE_NAME, 
                            })
                        })  
                    }else {
                        this.inputLoading = false
                            this.MBS.actions.error({
                            error:"no data",
                            from:'CLOSE_CASHUP',
                            page:PAGE_NAME, 
                    }) 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLOSE_CASHUP',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            PRINT(order){
                try {   
                    this.print_svg = this.CREATE_RECEIPT(order); 
                    setTimeout(() => {
                        this.printJS('receipt_print',"html" ) 
                        setTimeout(() => {
                            this.print_svg = null
                        }, 500);
                        
                    }, 1);

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'receiptline',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            
             
            async DISPLAY_TO_VFD(payload){
                let response = {
                    from:'DISPLAY_TO_VFD',
                    page:PAGE_NAME,
                    payload:payload
                }
                return new Promise((resolve,reject)=>{
                    try { 
                        let vfd_display = this.CurrentVFT_Display
                        let text = payload?payload.text:false
                        let text2 = payload?payload.text2:null 
                        if (vfd_display) {
                            let com_port = vfd_display.com_port
                            let baud_rate = vfd_display.baud_rate
                            let url = vfd_display.url
                            let params = {
                                action:"sales",
                                com_port:com_port,
                                baud_rate:baud_rate,
                                url:url,
                                text:text,
                                text2:text2,
                            }   
                            this.MBS.actions.VFD_DISPLAY_TEXT(params)
                            .then(res=>{
                                let data = res?res.data:null 
                                resolve({
                                    success:true, 
                                })
                            }).catch(error=>{
                                let message = error.message  
                                response = {
                                    ...response,
                                    message:message,
                                    error:error
                                }
                                reject(response)
                            }) 
                        } else {  
                            resolve({success:true}) 
                        }  
                    } catch (error) {
                        response = {
                            ...response,
                            error:error
                        }
                        this.MBS.actions.error(response) 
                        reject(response)
                    }  
                }) 
            },
            async RECEIPT_PRINTER(payload){
                let response = {
                    from:'RECEIPT_PRINTER',
                    page:PAGE_NAME,
                    payload:payload
                }
                return new Promise((resolve,reject)=>{
                    try { 
                        let printer = this.SelectedPrinter?this.SelectedPrinter:this.AssignedPrinter(true,true) 
                        let duplicate = payload?payload.duplicate:false
                        let receipt_title = payload?payload.title:null
                        let order = payload?payload.order:null
                        let order_account_type = order?order.account_type:null
                        let order_type = order?order.order_type:null
                        let joined_order = this.MBS.actions.JOIN_SALE_ORDER(order)
                        let submit_order = payload?payload.submit_order:null 

                        let printer_code = printer?printer.code:0
                        let printer_type_code = printer?printer.printer_type_code:0
                        let print_title = '' 


                        //---------[document title]
                        if (duplicate) {
                            if (order_account_type=="on_account") {
                                print_title = "DUPLICATE INVOICE"
                            } else {
                                print_title = "DUPLICATE RECEIPT" 
                            } 
                        } else {
                            if (order_type=="quotation") {
                                print_title = "SALES QUOTATION"
                            }else if (order_account_type=="on_account") {
                                print_title = "SALES INVOICE"
                            }else {
                                print_title = "SALES RECEIPT" 
                            }
                        }
                        this.auto_print.print_title = print_title

                        console.log(this.auto_print,'this.auto_print......');
 
                        if(!printer_code||this.toNumber(printer_type_code) == 11){
                            if (submit_order) {
                                resolve({success:true,order:order}) 
                            } else {
                                this.auto_print.printing = false
                                setTimeout(() => {
                                    this.auto_print.auto = true  
                                    this.auto_print.printer = printer  
                                    this.auto_print.printing = true  
                                    this.auto_print.print_data = order
                                    this.auto_print.print_title = print_title
                                    this.auto_print.print_type ='sale_order'
                                    this.auto_print.display_option =this.toNumber(printer_type_code) == 11?'pdf': '' 
                                }, 5); 
                                // this.auto_print.com_printer = true 
                                resolve({success:true}) 
                            }
                        }else{
                            let printer_name = printer.name
                            let printer_type_code = printer.printer_type_code
                            let paper_size = printer.paper_size
                            let com_port = printer.com_port
                            let baud_rate = printer.baud_rate
                            let url = printer.url
                            let params = {
                                printer:printer,
                                printer_code:printer_code,
                                printer_name:printer_name,
                                printer_type_code:printer_type_code,
                                paper_size:paper_size,

                                action:"sales",
                                SaleOrder:order,
                                duplicate:duplicate,
                                com_port:com_port,
                                baud_rate:baud_rate,
                                url:url,
                            }  
                            if (!submit_order&&!duplicate) {
                                resolve({success:false})
                                return
                            }

                            // fiscal printers
                            if (this.toNumber(printer_type_code) == 1) {
                                this.MBS.actions.FISCAL_PRINT(params)
                                .then(res=>{
                                    let data = res?res.data:null
                                    let allReceiptCount = data?data.allReceiptCount:null
                                    let fiscalReceiptCount = data?data.fiscalReceiptCount:null
                                    let order_key =allReceiptCount?allReceiptCount+"/"+fiscalReceiptCount:null
                                    if (order_key && order) {
                                        order.order_key = order_key
                                    } 
                                    resolve({
                                        success:true,
                                        fiscalReceiptCount:fiscalReceiptCount,
                                        allReceiptCount:allReceiptCount,
                                        order_key:order_key,
                                        order:order,
                                    })
                                }).catch(error=>{
                                    let message = error.message  
                                    if (message) {
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:"PRINTING ERROR",
                                            text:message,
                                            btnYes:"OK", 
                                            action:{
                                                // code:PAGE_NAME+"=CLEAR-SALES"
                                            }
                                        }) 
                                    } else {
                                        message = 'Kipcount Connecter is offline. Please make to start the connecter and try again.'
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:"PRINTING ERROR",
                                            text:message,
                                            btnYes:"OK", 
                                            action:{
                                                // code:PAGE_NAME+"=CLEAR-SALES"
                                            }
                                        }) 
                                    }
                                    response = {
                                        ...response,
                                        message:message,
                                        error:error
                                    }
                                    reject(response)
                                })  
                            } else { 
                                this.MBS.actions.EPSON_SALE_ORDER_PRINT_REQUEST({
                                    ...params,
                                    SaleOrder:joined_order
                                })
                                .then(res=>{ 
                                    let print_request = res?res.data:null 
                                    // console.log(joined_order,'joined_order....');
                                    // console.log(print_request,'print_request....');
                                    // console.log(order_account_type,'order_account_type....');
                                    // reject()
                                    // return
                                    if (duplicate) {
                                        // let title = "DUPLICATE"
                                        // if (order_account_type=="on_account") {
                                        //     title = "DUPLICATE INVOICE"
                                        // } else {
                                        //     title = "DUPLICATE RECEIPT" 
                                        // }
                                        return this.MBS.actions.EPSON_PRINT({...print_request,title:receipt_title?receipt_title:this.auto_print.print_title})
                                    }else if(order_account_type=="on_account") {
                                        return this.MBS.actions.EPSON_PRINT({...print_request,title:receipt_title?receipt_title:"INVOICE"})
                                    }else if(order_account_type=="payment_option") {
                                        return this.MBS.actions.EPSON_PRINT({...print_request})
                                    }else {
                                        return this.MBS.actions.EPSON_PRINT({...print_request,open_drawer:true})
                                        
                                    }
                                })
                                .then(res=>{
                                    let data = res?res.data:null 
                                    let allReceiptCount = data?data.allReceiptCount:null
                                    let fiscalReceiptCount = data?data.fiscalReceiptCount:null
                                    let order_key =allReceiptCount?allReceiptCount+"/"+fiscalReceiptCount:null
                                    if (order_key && order) {
                                        order.order_key = order_key
                                    } 
                                    resolve({
                                        success:true,
                                        fiscalReceiptCount:fiscalReceiptCount,
                                        allReceiptCount:allReceiptCount,
                                        order_key:order_key,
                                        order:order,
                                    })
                                }).catch(error=>{
                                    let message = error.message 
                                    console.log(error,'error...ZZZ ');
                                    if (message) {
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:"PRINTING ERROR",
                                            text:message,
                                            btnYes:"OK", 
                                            action:{
                                                // code:PAGE_NAME+"=CLEAR-SALES"
                                            }
                                        }) 
                                    } else {
                                        message = 'Kipcount Connecter is offline. Please make to start the connecter and try again.'
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:"PRINTING ERROR",
                                            text:message,
                                            btnYes:"OK", 
                                            action:{
                                                // code:PAGE_NAME+"=CLEAR-SALES"
                                            }
                                        }) 
                                    }
                                    response = {
                                        ...response,
                                        message:message,
                                        error:error
                                    }
                                    reject(response)
                                })   
                            }
                        } 
                        //upload sales
                        this.MBS.events.$emit("SUBMIT_SALES") 
                    } catch (error) {
                        response = {
                            ...response,
                            error:error
                        }
                        this.MBS.actions.error(response) 
                        reject(response)
                    }  
                }) 
            }, 
            CONNECT_K(){
                return new Promise((resolve,reject)=>{
                    try { 
                        let K_CONNECTOR = this.K_CONNECTOR
                        let settings = this.COMPANY_SETTINGS
                        let Sell_without_printer = settings?settings.Sell_without_printer:null
                        let printer = this.AssignedPrinter()

                        if (K_CONNECTOR||Sell_without_printer) {
                            
                        } else {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"CONNECTOR IS OFF",
                                text:'Kipcount Connecter is offline. Please make to start the K-Connecter and try again.',
                                btnYes:"TRY AGAIN",  
                                btnYes:"CANCEL",  
                                action:{
                                    code:PAGE_NAME+""
                                }
                            })  
                        }

                         
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CONNECT_K',
                            page:PAGE_NAME, 
                        }) 
                        reject({success:false,error:error})
                    }  
                })
            },
            CREATE_RECEIPT(order){
                try {  
                    order = this.MBS.actions.JOIN_SALE_ORDER(order)
                    let receiptline = this.receiptline 
                    let doc = '' 
                    if(!order){

                    }else{
                        let items = order?order.joined_items:null
                        let receipt_header = `

                            ^^^^ 
                            ^^^`+order.outlet_name+`
                            ^^Served By: `+order.teller_name+`
                            -

                            ^^^Receipt Number: "`+order.key+`"
                            ^^Date
                            _^^`+order.local_created_at_+`_

                            - 

                        `
                        let receipt_items=""
                        if (items) {
                            items.forEach(item => {
                                receipt_items = receipt_items+`
                                    ^^^`+item.item_name+` | ^^^mk"`+item.sold_price_+`"
                                `
                            });

                            receipt_items = receipt_items+`
                                ^^^TOTAL (4ITEM) | \`^^^"`+order.total_sold_price_+`"^^
                                -
                            ` 
                        }else{
                            receipt_items="No items"
                        }

                        let receipt_footer=`
                            {code:`+order.key+`; option:code128,4,100,hri}


                            ^^Powered by Mbera Solutions

                            =

                        `
                        doc = receipt_header+receipt_items+receipt_footer
                    }

                    const docs = ` 
                        ^^^^ 
                        ^^^Main Outlet
                        ^^Served By: User name
                        -

                        ^^^Receipt Number: "1000"
                        ^^Date
                        _^^11/1/2022_

                        - 

                        ^^^Items | ^^^mk"99.00"
                        ^^^Items | ^^^mk"99.00"
                        ^^^Items | ^^^mk"99.00"
                        ^^^Items | ^^^mk"99.00"

                        ^^^TOTAL (4ITEM) | \`^^^"199,000.00"^^
                        -


                        {code:1234; option:code128,4,100,hri}


                        ^^Powered by Mbera Solutions

                        =`
                    // printer example
                    const printer = {
                        cpl: 42,
                        encoding: 'multilingual',
                        upsideDown: false,
                        gamma: 1.8,
                        command: 'escpos'
                    };

                    // display example
                    const display = {
                        cpl: 42,
                        encoding: 'multilingual'
                    };
                    let receipt = receiptline.transform(doc, display);
                    return receipt  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'receiptline',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            INITIALIZE_CLICK_MODE(){
                try {
                    const CompanySettings = this.CompanySettings
                    const cashup = this.LocalCashup 
                    if (!cashup) {
                        
                    }else if (!CompanySettings) {
                        
                    } else {
                        if (CompanySettings.only_click_mode) {
                            this.click_mode = true
                        }else{
                            this.click_mode = false
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_CLICK_MODE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            INITIALIZE_ITEMS_CATEGORIES(){
                try { 
                    const categories = this.FilteredItemCategories
                    const first_category = categories?categories[0]:null
                    // console.log(categories,'ca...........')
                    // console.log(first_category,'f...........')
                    // console.log(this.SELECTED_ITEM_CATEGORY,'S...........')
                    // if (!this.SELECTED_ITEM_CATEGORY && first_category) {
                    //     this.SELECTED_ITEM_CATEGORY = first_category 
                    // } 
                     if (first_category) {
                        // this.console.log('change..............c**')
                        this.SELECTED_ITEM_CATEGORY = first_category 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_ITEMS_CATEGORIES',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            INITIALIZE_ITEMS_DEPARTMENTS(){
                try { 
                    const till =  this.CurrentTill 
                    const selected_department_codes = till?till.selected_department_codes:null
                    if(selected_department_codes){
                        this.selected_departments = [...selected_department_codes]
                    }else{} 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_ITEMS_DEPARTMENTS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            INITIALIZE_SELLING_MODE(){
                try {
                    let CompanySettings = this.CompanySettings
                    if (!CompanySettings) {
                        
                    } else {
                        if (CompanySettings.both_selling_mode) {
                            this.selling_model = ""
                        }if (CompanySettings.only_retail_model) {
                            this.selling_model = "Retail Price"
                        }if (CompanySettings.only_wholesale_model) {
                            this.selling_model = "Wholesale Price"
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_SELLING_MODE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            INITIATE_SELECTED_SALES_ITEMS(){
                try {
                    let company_key = this.selectedCompany?this.selectedCompany.key:null
                    this.$store.dispatch('initiate_locals',{company_key:company_key})  
                    this.$store.dispatch('initiate_company_locals',{company_key:company_key})  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIATE_SELECTED_SALES_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            
            ON_SEARCH_AND_SELECT(items){
                try { 
                    this.dialog_search_and_select_items = false
                    if(!items){return null}
                    items.forEach(item => {
                        this.ADD_ITEM(item.key)
                    });
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SEARCH_AND_SELECT',
                        page:PAGE_NAME, 
                    })  
                }  
            },
            ADD_ITEM(item_key, item_code){
                try { 
                    this.FULLSCREEN()
                    let company_key = this.selectedCompany?this.selectedCompany.key:null
                    const settings = this.CompanySettings 
                    const activate_stock_level = settings?settings.activate_stock_level:null
                    const duplicate_selling_item = settings?settings.duplicate_selling_item:null


                    const till = this.CurrentTill
                    const outlet_code = till?till.outlet_code:null
                    let quantity = null
                    let retail_price = null
                    let wholesale_price = null
                    let scales = this.Scales  
                    
                    const items = this.ItemsData
                    const selling_model = this.selling_model
                    const moment = this.MBS.date.moment
                    const selecting_list_key = moment?moment().valueOf():null

                    
                    if (!selling_model) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"SELECT SELLING",
                            text:"You must select selling model first",
                            btnYes:"Okay",
                            action:{
                                code:PAGE_NAME+"=SELECT-SELLING-MODEL"
                            }
                        })
                        return
                    }
                    //-----[CONNECT NEW ITEM SELECTOR ]
                    // this.MBS.events.$emit("SELECT_SELLING_ITEM",{
                    //     item_key:item_key,
                    //     item_code:item_code
                    // })
                    // return
                    //
                    if (items) {
                        setTimeout(() => {
                            this.search_item=null 
                        }, 500);
                        let item_selected = items.find(item=>{
                            return  item_key?item.key == item_key:item.code == item_code
                        })

                        // scale items
                        if (!item_selected && item_code && scales) {
                            scales.forEach(scale => {
                                let prefix = scale?scale.barcode_prefix:null
                                let prefix_size = this.MBS.actions.SIZE(prefix)
                                let c_size = scale?scale.decimal_length:null
                                let barcode_role = scale?scale.barcode_role:null
                                let code_prefix = item_code.slice(0,prefix_size)

                                if (prefix===code_prefix) {
                                    item_code = ""+item_code
                                    let abc = item_code.slice(prefix_size)
                                    let c = this.toNumber(item_code.slice(-c_size) )
                                    let ab = abc.slice(0,-c_size)
                                    let a = 0
                                    let b = 0
                                    let bc = 0
                                    if (ab) {
                                        let cut_start = 0
                                        let first_number = 0
                                        while (first_number === 0) {
                                            first_number = this.toNumber(ab.slice(cut_start,cut_start+1))
                                            cut_start++
                                        }
                                        if (first_number) {
                                            a = this.toNumber(ab.slice(cut_start-1,cut_start+2))
                                        }
                                        if (a) {
                                            b = this.toNumber(ab.slice(cut_start+2))
                                        } 
                                    }

                                    if (a) {
                                        item_selected = items.find(item=>{
                                            return  this.toNumber(item.code) == a
                                        }) 
                                        retail_price = item_selected?item_selected.retail_price:null
                                        wholesale_price = item_selected?item_selected.wholesale_price:null
                                    }
                                    if (b||code_prefix) {
                                        bc = this.toNumber(b+"."+c) 
                                    }

                                    if (bc && item_selected ) { 
                                        let price = selling_model=='Retail Price'?retail_price:wholesale_price 
                                        if (barcode_role === "prefix_code_quantity") {
                                            quantity = bc
                                        } else {
                                            quantity = bc/this.toNumber(price) 
                                        }
                                        quantity = quantity.toFixed(3)
                                    }

                                    

                                    // console.log(item_selected,"item_selected....");
                                    // console.log(item_code,"Item_code....");
                                    // console.log(prefix,"prefix....");
                                    // console.log(a,"a....");
                                    // console.log(b,"b....");
                                    // console.log(ab,"ab....");
                                    // console.log(abc,"abc....");
                                    // console.log(bc,"bc....");
                                    // console.log(quantity,"quantity....");
                                    // console.log(c,"c....");
                                    // console.log(retail_price,"retail_price....");
                                    // console.log(wholesale_price,"wholesale_price....");
                                    
                                } 
                            });
                        }
 

                        if (!item_selected) {
                            this.MBS.actions.dialog()
                            setTimeout(() => {
                                
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:'ITEM NOT FOUND',
                                    text:"Item ("+item_code+") not found. Make sure Items are loaded and you have refreshed.",
                                    btnYes:"OKAY",
                                    btnNo:"CREATE AS NEW ITEM",
                                    action:{
                                        code:PAGE_NAME+'=CREATE_AS_NEW_ITEM',
                                        item_code:item_code,
                                    }
                                }) 
                            }, 500);
                            return 
                        } 
                        
                        //check stock level
                        if (activate_stock_level) {
                            let stock_settings = this.CHECK_ITEM_STOCK_SETTINGS({
                                item:item_selected,
                                outlet_code:outlet_code,
                                notify:true
                            })
                            let allow_sell = stock_settings?stock_settings.allow_sell:null 
                            if (!allow_sell) {
                                return
                            } 
                        }


                        /// if array
                        let newItem = {
                            ...item_selected, 
                            selling_model:selling_model,
                            sold_price:selling_model=='Retail Price'?item_selected.retail_price:item_selected.wholesale_price,
                            // discount:0,
                            quantity:quantity?quantity:1,
                        }

                       
                        // console.log(newItem,'newItem......');

                        //display selected item
                        try {
                            let displayName = newItem.name
                            displayName = displayName.slice(0,12)
                            this.DISPLAY_TO_VFD({
                                text:"ITEM: "+displayName,
                                text2:"PRICE: MWK"+newItem.sold_price,
                            }) 
                        } catch (error) {
                            
                        } 

                        this.MBS.events.$emit("ADD_SELECTED_SELLING_ITEMS",{
                            item_key:item_key,
                            item_code:item_code,
                            duplicate_selling_item:duplicate_selling_item,
                            company_key:company_key,
                            selected_item:newItem,
                        }) 
                        return 
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            

            //CARD ACTIONS
            SELECT_CARD(card_key, card_code){
                try { 
                    let cards = this.CardsDataItems
                    if(!cards){
                        this.MBS.actions.dialog({
                            show:true,
                            title:"CARD ERROR",
                            text:"No card found. Please, make sure you are connect to the internet and have active cards.",
                            btnYes:"OKAY"
                        })
                        return
                    }
                    let thisCard = cards.find(card=>this.MBS.actions.showCard(card.code)==this.MBS.actions.showCard(card_code))
                    if (!thisCard) {
                        this.MBS.actions.dialog({
                            show:true,
                            title:"ERROR",
                            text:"Card not found. Please, make sure you are connect to the internet and try again.",
                            btnYes:"OKAY"
                        })
                        return
                    } else { 
                        if (thisCard.customer) {
                            this.input.customer_key = thisCard.customer.key
                        }
                        this.SELECTED_CARD_KEY = thisCard.key
                        this.dialog_selected_card = true 
                        
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_CARD',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            VIEW_CARD_HISTORY(){
                try { 
                    this.MBS.actions.dialog({
                        show:true,
                        title:"CARD HISTORY",
                        text:"Card history option, is currently not available.",
                        btnYes:"OKAY"
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_CARD_HISTORY',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_CARD_LOAD_MONEY(){
                try { 
                    this.SELECTED_CARD_OPTION='load_money'  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_CARD_LOAD_MONEY',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CARD_LOAD_PAY_MONEY(){
                return new Promise(async (resolve,reject)=>{
                    let response = { 
                        from:'CARD_LOAD_PAY_MONEY',
                        page:PAGE_NAME, 
                    }
                    try { 
                        let order = this.pay_order 
                        let card_option = this.SELECTED_CARD_OPTION
                        let card = this.SELECTED_CARD
                        let card_key = card?card.key:null
                        let card_load_amount = this.toNumber(card?card.loaded_amount:null )
                        let card_used_amount = this.toNumber(card?card.used_amount:null )
                        let card_balance_amount = this.toNumber(card?card.balance_amount:null )
                        let paid_amount = this.toNumber(order?order.paid_amount:null ) 
                        if (!order) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"ERROR",
                                text:"Something went wrong. please try again.",
                                btnYes:"Okay"
                            })
                            response.error="no order"
                            reject({response})
                        }

                        if (card_option == "load_money") {
                            this.MBS.actions.progressDialog({
                                show:true,
                                title:"Loading Cash..."
                            })
                            let res = await this.MBS.actions.COMPANY_CARD_ADD_AMOUNT(order.card_key,order.card_load_amount,order.company_key)  
                            this.MBS.actions.progressDialog()
                            this.DESELECT_CARD()
                            resolve(res)  
                        }else if (card_option == "pay") {
                            this.pay_order.selected_card_option = card_option
                            this.pay_order.card_key = card_key
                            this.pay_order.card = {
                                key:card_key,
                                load_amount:card_load_amount,
                                used_amount:card_used_amount,
                                balance_amount:card_balance_amount,
                            }
                            if (card_balance_amount>paid_amount) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    title:"Paying..."
                                })
                                let res = await this.MBS.actions.COMPANY_CARD_REMOVE_AMOUNT(order.card_key,paid_amount,order.company_key)  
                                this.MBS.actions.progressDialog()
                                this.DESELECT_CARD()
                                resolve(res)  
                            }else{
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:"ERROR",
                                    text:"No enough funds in selected card. Please, Reload and try again.",
                                    btnYes:"OKAY"
                                })
                                response.error="no enough funds."
                                this.inputLoading = false
                                this.DESELECT_CARD()
                                return
                                reject({response})
                            } 
                        }else {
                            resolve() 
                        }
                    } catch (error) {
                        response.error=error
                        this.MBS.actions.error(response) 
                        reject(response)
                    } 
                })
                
            },
            CREATE_CARD_ORDER(amount){
                try {  
                    let card = this.SELECTED_CARD
                    let localCashup = this.LocalCashup 
                    let cashup_key = localCashup?localCashup.key:null
                    let day_shift_key = localCashup?localCashup.day_shift_key:null
                    let company = this.SELECTED_COMPANY
                    let card_key = card?card.key:null
                    let company_key = company?company.key:null
                    let input = this.input  
                    let customer_key = input?input.customer_key:null 
                    let us = this.us
                    let uid = us?us.uid:null
                    let moment = this.MBS.date.moment
                    let key = moment?moment().valueOf():null

                    this.dialog_selected_card=false
                    this.pay_order={
                        sold:true,
                        created_uid:uid,
                        key:key,
                        selected_card_option : this.SELECTED_CARD_OPTION,  
                        card_key : card_key,
                        cashup_key:cashup_key,
                        day_shift_key:day_shift_key,
                        company_key : company_key,
                        card_load_amount : amount,
                        paid_amount : amount,
                        local_created_at:key, 
                        order_type:DATA.ITEMS.SALE_ORDERS.value, 
                    }  
                    if (this.SELECTED_CARD) {
                        this.pay_order.selected_card = {
                            balance_amount:this.toNumber(this.SELECTED_CARD.balance_amount),
                            loaded_amount:this.toNumber(this.SELECTED_CARD.loaded_amount),
                            used_amount:this.toNumber(this.SELECTED_CARD.used_amount),
                            code:this.SELECTED_CARD.code,
                            key:this.SELECTED_CARD.key,
                        }
                    }
                    if (customer_key) {
                        this.pay_order.customer_key = customer_key
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_CARD_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            DESELECT_CARD(){
                try { 
                    this.SELECTED_CARD_KEY=null
                    this.SELECTED_CARD_OPTION=null
                    this.dialog_selected_card=false
                    this.menu_selected_card=false
                    this.pay_order=null 

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DESELECT_CARD',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CARD_PAY(){
                try { 
                    this.SELECTED_CARD_OPTION='pay'
                    this.dialog_selected_card=false
                    let SELECTED_ITEMS = this.SELECTED_ITEMS
                    if (SELECTED_ITEMS) {
                        this.CREATE_ORDER() 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CARD_PAY',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CARD_ATTACH_CUSTOMER(card,card_customer_key){
                try { 
                    if (!card||!card_customer_key) { 
                        return
                    }
                    let mbs_text = this.MBS.text.item_action("Customer","Attach")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CARD-ATTACH-CUSTOMER",
                            data:{
                                key:card.key,
                                card_customer_key:card_customer_key,
                                active:true
                            } 
                        } 
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CARD_ATTACH_CUSTOMER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CONFIRM_REMOVE_ITEM(selected_data,index){
                try { 
                    const settings = this.CompanySettings
                    const require_void_to_remove_item = settings?settings.require_void_to_remove_item:null 
                    let data = []
                    if(!selected_data){console.log('No selected data...'); return}
                    if (Array.isArray(selected_data)) {
                        selected_data.forEach((item,index) => {
                            data.push({...item,index:index} )
                        });
                    } else {
                        data.push({...selected_data,index:index} )
                    } 
                    
                    if (require_void_to_remove_item) {
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-shield-lock-outline",
                            title:"VOID REQUIRED TO REMOVE ITEM(S)",
                            text:"Removing item(s) require admin permission. Enter your admin phone, and password to authorize this action.",
                            action:{
                                code:PAGE_NAME+'=REMOVE-USER',
                                data:data,
                                all_users:true, 
                            }
                        })
                    } else {
                        this.REMOVE_ITEM(data)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            async REMOVE_ITEM(selected_data){
                try {  
                    if (selected_data) {  
                        await DB.deleteItems({
                            name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values,
                            items:selected_data,
                            update_locals:true,
                        }) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            CREATE_ORDER(return_order){
                try {
                    //initiate card
                    let card = this.SELECTED_CARD
                    if (card) {
                        this.SELECTED_CARD_OPTION = "pay"
                    }

                    //initiate item order
                    let item_order = this.item_order
                    if (item_order) {
                        if (item_order.customer_key) {
                            this.input.customer_key=item_order.customer_key
                        }
                    }
                    //
                    let printer = this.ON_CHANGE_PRINTER(true) 
                    let till = this.CurrentTill 
                    let till_code = till?till.code:null
                    let outlet_code = till?till.outlet_code:null
                    let selectedCompany = this.selectedCompany 

                    let company_key = selectedCompany?selectedCompany.key:null
                    let localCashup = this.LocalCashup 
                    let cashup_key = localCashup?localCashup.key:null
                    let day_shift_key = localCashup?localCashup.day_shift_key:null
                    let input = this.input  
                    let customer_key = input?input.customer_key:null 
                    let TotalPrice = this.TotalPrice
                    let TotalDiscount = this.TotalDiscount
                    let TotalDiscountedPrice = this.TotalDiscountedPrice
                    let us = this.us
                    let uid = us?us.uid:null
                    let SELECTED_ITEMS = this.SELECTED_ITEMS
                    let items_size = this.MBS.actions.SIZE(SELECTED_ITEMS)
                    let moment = this.MBS.date.moment
                    let key = item_order?item_order.key: moment?moment().valueOf():null
                    let CalculatedVAT = this.CalculatedVAT

                    if (items_size<1) {
                        console.log("error..");
                    }
                    let order_details = {
                        sold:true,
                        created_uid:uid,
                        key:key,
                        till_code:till_code,
                        outlet_code:outlet_code,
                        cashup_key:cashup_key,
                        day_shift_key:day_shift_key,
                        company_key:company_key, 
                        paid_amount:TotalDiscountedPrice,
                        total_price:TotalPrice,
                        total_discount:TotalDiscount,
                        local_created_at:key, 
                        order_type:DATA.ITEMS.SALE_ORDERS.value, 
                    }
                    if (item_order) {
                        console.log(item_order,'item order.........');
                        if (item_order.key) {
                            order_details.key=item_order.key
                        }if (item_order.order_option_key) {
                            order_details.order_option_key=item_order.order_option_key
                        }if (item_order.created_uid) {
                            order_details.order_created_uid=item_order.created_uid
                        }if (item_order.till_code) {
                            order_details.order_original_till_code=item_order.till_code
                        }if (item_order.cashup_key) {
                            order_details.order_original_cashup_key=item_order.cashup_key
                        }if (item_order.day_shift_key) {
                            order_details.order_original_day_shift_key=item_order.day_shift_key
                        }
                        order_details = { 
                            uploaded:false,
                            ...order_details
                        }
                    }
                    if (customer_key) {
                        order_details.customer_key = customer_key
                    }if (CalculatedVAT) {
                        order_details.calculated_vat = CalculatedVAT
                    }
                    let order_items = []
 
                    SELECTED_ITEMS.forEach(element => {
                        let average_cost_price = this.toNumber(element.average_cost_price)
                        let cost_price = this.toNumber(element.cost_price)
                        let sold_cost_price = average_cost_price?average_cost_price:cost_price
                        order_items.push({ 
                            sold_price:this.toNumber(element.sold_price),
                            sold_cost_price:sold_cost_price,
                            quantity:this.toNumber(element.quantity),
                            discount:this.toNumber(element.discount),
                            order_key:key,
                            selling_model:element.selling_model,
                            item_key:element._key?element._key:element.key,
                            key:element.key,
                            _key:element._key?element._key:element.key,
                        })
                    }); 
                    order_details.items = order_items

                    //check connector
                    let K_CONNECTOR = this.K_CONNECTOR
                    let settings = this.CompanySettings
                    let Sell_without_printer = settings?settings.Sell_without_printer:null
                    let activate_stock_level = settings?settings.activate_stock_level:null

                    // CHECK STOCK SETTINGS
                    if (activate_stock_level) {
                        let stock_settings = this.CHECK_ITEMS_STOCK_SETTINGS({
                            items:SELECTED_ITEMS,
                            outlet_code:outlet_code,
                            notify:true
                        })
                        let stop_selling = stock_settings?stock_settings.stop_selling:null
                        if (stop_selling) {
                            return
                        }   
                    }

                    if (return_order) {
                        return order_details 
                    } else if (K_CONNECTOR||Sell_without_printer) {
                        this.pay_order = order_details 
                        console.log(this.pay_order,'this.pay_order.....****')
                    } else{
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"CONNECTOR IS OFF",
                            text:'Kipcount Connector is offline. Please make to start the K-Connector and try again.',
                            btnYes:"TRY AGAIN",  
                            btnNo:"CANCEL",  
                            action:{
                                code:PAGE_NAME+"=K-CONNECT-CREATE-ORDER",
                                return_order:return_order
                            }
                        })  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 

            SUBMIT_ORDER(order,account_type,payment_option){
                try { 
                    if (!order) {
                        console.log(order,'order error');
                        return
                    } 
                    let input = this.input
                    let customer_key = input?input.customer_key:null
                    let receivable_code = input?input.receivable_code:null
                    let transaction_id = input?input.transaction_id:null
                    let description = input?input.description:null
                    let given_amount = input?this.toNumber(this.given_amount):null
                    let payment_option_code = payment_option?payment_option.code:null
                    let item_order = this.item_order
                    let input_payments = this.inputPayments 
                    
                    order.account_type = account_type
                    if (customer_key) {
                        order.customer_key = customer_key
                    }if (receivable_code) {
                        order.receivable_code = receivable_code
                    }if (given_amount) {
                        order.given_amount = given_amount
                    }if (transaction_id) {
                        order.transaction_id = transaction_id
                    }if (description) {
                        order.description = description
                    }if (payment_option) {
                        order.payment_option_code = payment_option_code
                    }if (input_payments) {
                        order.input_payments = []
                        input_payments.forEach(element => {
                            if (element.key) {
                                order.input_payments.push({
                                    key:element.key,
                                    code:element.code?element.code:'',
                                    given_amount:element.given_amount?element.given_amount:0,
                                    payment_description:element.payment_description?element.payment_description:'',
                                }) 
                            }
                        });
                    }

                    if (this.created_document_type=='quotation') {
                        delete order.account_type
                        delete order.input_payments
                        order.order_type = "quotation"  
                        DB.addItems({
                            name:DATA.ITEMS.LOCAL_QUOTATIONS.values,
                            data:order,
                            update_locals:true,
                            // company_key:order.company_key,
                        }).then(res=>{
                            return this.RECEIPT_PRINTER({
                                order:order
                            }) 
                        }).then(res=>{ 
                            return this.RESET_ALL_DATA(true)
                        }).then(res=>{ 
                            console.log(res,'res. success......');
                        }).catch(error=>{
                            console.log(error,'error.......');
                        })
                        return
                    }else if (account_type=='cash') {
                        
                    }else if(account_type=='on_account'){
                        if (!customer_key && !receivable_code) {
                            console.log("no receivable...........");
                            return
                        }  
                    }else if(account_type=='payment_option'){
                        console.log(order,'......');
                        if (!payment_option) {
                            console.log("no payment_option...........");
                            return
                        }  
                    }
                    
                    // console.log(order,'orders.............');
                    //  return
                    this.inputLoading = true   
                    this.CARD_LOAD_PAY_MONEY()
                    .then(res=>{
                        return this.RECEIPT_PRINTER({
                            order:order,
                            submit_order:true,
                        })
                    })
                    .then(res=>{
                        this.dialog_given_amount = false
                        this.dialog_paid_amount = false
                        this.MBS.actions.progressDialog() 
                        
                        let submit_order = res?res.order:null
                        let success = res?res.success:null 
 
                        if (!success) { 
                            return {success:false} 
                        }
                        this.CLEAR_SELECTED_ITEMS()
                        this.input = {} 
                        this.pay_order=null 
                        return DB.addItems({
                            name:DATA.ITEMS.LOCAL_SALE_ORDERS.values,
                            data:submit_order
                        })
                    })
                    .then(res=>{  
                        //upload sales
                        this.MBS.events.$emit("SUBMIT_SALES") 
                        let success = res?res.success:false
                        this.inputLoading = false
                        this.dialog_given_amount = false
                        this.MBS.actions.progressDialog() 
                        if (item_order) {
                            this.DELETE_ITEM_ORDER(item_order)
                        }
                        if (success) {
                            this.CLEAR_SELECTED_ITEMS()
                            this.input = {}  
                            this.pay_order=null  
                        } else {
                            
                        }  
                        this.RECEIPT_PRINTER({
                            order:order
                        })
                        let selling_model = this.selling_model
                        this.RESET_ALL_DATA(true)
                        this.selling_model = selling_model
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false 
                        this.dialog_given_amount = false
                        this.dialog_paid_amount = false
                        this.MBS.actions.progressDialog()  
                        let error_message = error?error.message:null 
                        let error1 = error?error.error:null 
                        let printer_error = error1?error1.message:null 

                        let mbs_text = this.MBS.text.item_action_fail("SALE Order","Create")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text+"("+(printer_error?printer_error:error_message)+")",
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            ON_SELECT_SHIFT(value){
                try {  
                    this.show_input = false  
                    let TotalPayments = this.TotalPayments    
                    let cashups = this.thisCashupsData  
                    let lastCashup = cashups?cashups.slice(-1)[0]:null
                    let till = this.getDayShiftTill(value.day_shift_and_till_key) 

                    let closing_cash = lastCashup?lastCashup.closing_cash:null
                    let floating_cash = till?till.floating_cash:null  
                    
                    this.input = { 
                        ...this.input, 
                        expected_cash:closing_cash?(closing_cash+TotalPayments):floating_cash,
                        opening_cash:closing_cash?closing_cash:floating_cash,
                    } 
                    setTimeout(() => {
                        this.show_input = true  
                    }, 1000); 
                } catch (error) {
                    this.show_input = true
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT_SHIFT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            //---------------[SALES CALCULATIONS]------------ 
            CHECK_ITEMS_STOCK_SETTINGS(payload){
                try { 
                    let response = {
                        stop_selling:false, 
                        ordering_remind:false, 
                        stock_settings:null,
                        stop_sell_items:null,
                        ordering_remind_items:null,
                        payload:payload
                    }  


                    let items = payload?payload.items:null  
                    let outlet_code = payload?payload.outlet_code:null   
                    let notify = payload?payload.notify:null   
                    if (!items) { 
                        return response
                    }

                    let stock_settings = []
                    let stop_sell_items = []
                    let ordering_remind_items = []
                    items.forEach(item => {
                        let stock_setting = this.CHECK_ITEM_STOCK_SETTINGS({
                            outlet_code:outlet_code,
                            item:item,
                            quantity:item.quantity, 
                        }) 
                        stock_settings.push(stock_setting)
                        if (stock_setting) {
                            if (!stock_setting.allow_sell) {
                               stop_sell_items.push({
                                    ...stock_setting,
                                    name:item.name
                                }) 
                               response.stop_selling = true
                            }
                            if (stock_setting.ordering_remind) {
                                ordering_remind_items.push({
                                    ...stock_setting,
                                    name:item.name
                                }) 
                                response.ordering_remind = true
                            }
                        }
                    });

                    if (notify) {
                        let stop_sell_size = this.MBS.actions.SIZE(stop_sell_items)
                        let ordering_remind_size = this.MBS.actions.SIZE(ordering_remind_items)
                        let stop_sell_names = ''
                        let ordering_remind_names = ''
                        stop_sell_items.forEach(item => {
                            stop_sell_names = stop_sell_names+"\n \n Item: "+item.name+"\n Quantity: "+item.quantity+"\n Stop selling level: "+item.stop_selling_level
                        });
                        ordering_remind_items.forEach(item => {
                            ordering_remind_names = ordering_remind_names+"\n \n Item: "+item.name+"\n Quantity: "+item.quantity+"\n Ordering level: "+item.ordering_remind_level
                        });

                        if (stop_sell_size&&ordering_remind_size) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"OUT OF STOCK",
                                text:"Some of the selected item(s) are out of stock. \n\n OUT OF STOCK ITEM(S): "+stop_sell_names
                                    +" \n\n ORDERING REMIND ITEM(S): "+ordering_remind_names    
                                    +".",
                                btnYes:"OKAY"
                            })
                        }else if (stop_sell_size) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"OUT OF STOCK",
                                text:"Some of the selected item(s) are out of stock. \n\n OUT OF STOCK ITEM(S): "+stop_sell_names  
                                    +".",
                                btnYes:"OKAY"
                            })
                        }else if (ordering_remind_size) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"LOW ON STOCK LEVEL",
                                text:"Some of the selected item(s) are below order remind level. \n\n ORDERING REMIND ITEM(S): "+ordering_remind_names    
                                    +".",
                                btnYes:"OKAY"
                            })
                        }  
                    } 
                    return {
                        ...response, 
                        allow_sell:!response.stop_selling,
                        stock_settings:stock_settings,
                        stop_sell_items:stop_sell_items,
                        ordering_remind_items:ordering_remind_items,
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_ITEMS_STOCK_SETTINGS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CHECK_ITEM_STOCK_SETTINGS(payload){
                try { 
                    let response = {
                        allow_sell:false,
                        ordering_remind:false,
                        quantity:null,
                        stop_selling_level:null,
                        ordering_remind_level:null,
                        active_settings:null,
                        payload:payload
                    }   
                    let item_selected = payload?payload.item:null 
                    let item_selected_name = item_selected?item_selected.name:null 
                    let item_kits = item_selected?item_selected.item_kits:null 
                    let outlet_code = payload?payload.outlet_code:null  
                    let quantity = this.toNumber(payload?payload.quantity:null)
                    let notify = payload?payload.notify:null  
                    quantity = quantity?quantity:1
                    if (!item_selected) { 
                        return response
                    }
                    //
                    let sold_item = this.getSoldItem(item_selected.key)
                    let sold_quantity = this.toNumber(sold_item?sold_item.sold_quantity:null) 
                    let settings = this.CompanySettings
                    let stock_settings = settings?settings.stock_settings:null
                    let outlets_settings = stock_settings?stock_settings.outlets:null
                    let outlet_stock_settings = outlets_settings?outlets_settings[outlet_code]:null

                    let item_stock_settings = item_selected?item_selected.stock_settings:null 
                    let active_settings = item_stock_settings?item_stock_settings:outlet_stock_settings?outlet_stock_settings:stock_settings

                    response.active_settings = active_settings  

                    if (active_settings && item_selected) {
                        let stop_selling_level = this.toNumber(active_settings.stop_selling_level)
                        let ordering_remind_level = this.toNumber(active_settings.ordering_remind_level)
                        let item_quantity = this.toNumber(item_selected['number_quantity'])
                        if (outlet_code) {
                            item_quantity = this.toNumber(item_selected[outlet_code+'_number_quantity'])
                        }
                        item_quantity = item_quantity-sold_quantity
                        response.sold_quantity = sold_quantity
                        response.quantity = item_quantity
                        response.stop_selling_level = stop_selling_level
                        response.ordering_remind_level = ordering_remind_level
                        
                        if ((item_quantity-quantity) < stop_selling_level) {
                            response.allow_sell = false
                            if (notify && !item_kits) {
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:"OUT OF STOCK",
                                    text:"NAME: "+item_selected_name 
                                        +"\n ITEM QUANTITY: "+item_quantity
                                        +"\n STOP SELLING LEVEL: "+stop_selling_level
                                        +"\n \n   The selected item is below selling level.",
                                    btnYes:"OKAY"
                                })  
                            }
                        }else if ((item_quantity-quantity) < ordering_remind_level) {
                            response.allow_sell = true
                            response.ordering_remind = true
                            if (notify && !item_kits) {
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:"LOW ON STOCK LEVEL",
                                    text:"NAME: "+item_selected_name 
                                        +"\n ITEM QUANTITY: "+item_quantity
                                        +"\n ORDER REMIND LEVEL: "+ordering_remind_level
                                        +"\n \n   The selected item is below ordering level.", 
                                    btnYes:"OKAY"
                                }) 
                            }
                        }else{
                            response.allow_sell = true
                        }  

                        if (item_kits) {
                            // console.log(item_selected,'item_selected....');
                            response.allow_sell = true
                        }
                    }
                    if (!active_settings) {
                        response.allow_sell = true
                    }
                    return response
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_ITEM_STOCK_SETTINGS',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 

            //---------------[SALES CALCULATIONS]------------ 
            CALCULATE_PRICE(item){
                try {  
                    if (!item) {return 0}
                    const sold_price = this.toNumber(item.sold_price)
                    const discount = this.toNumber(item.discount)
                    const quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            ORDER_ITEMS(orders){  
                try { 
                    if(!orders){return null} 
                    let items = []
                    orders.forEach(element => {
                        let joined_items = element.joined_items
                        if (joined_items) {
                            joined_items.forEach(item => {
                                items.push({
                                    ...item,
                                    account_type:element.account_type
                                }) 
                            });
                            
                        }
                    }); 
                    return items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ORDER_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RETAIL_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("retail price")
                    });
                    return filtered_items
                } catch (error) { 
                    this.MBS.actions.error({
                        error:error,
                        from:'RETAIL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            WHOLESALE_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("wholesale price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'WHOLESALE_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CASH_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "cash"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CASH_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_ACCOUNT_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "on_account"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ACCOUNT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            TOTAL_SOLD(items){
                try {  
                    let total = 0
                    if (items) {
                        items.forEach(element => {
                            let sold_price = this.toNumber(element.sold_price)
                            let quantity = this.toNumber(element.quantity)
                            let discount = this.toNumber(element.discount)
                            total = total+((sold_price-discount)*quantity)
                        }); 
                    }
                    return total
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_SOLD',
                        page:PAGE_NAME, 
                    })
                }  
            },   
            async RELOAD_DATA(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    let res_item = await this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEMS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }) 
                    let res_item_categories = await this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEM_CATEGORIES.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    })
                    let res_item_departments = await this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEM_DEPARTMENTS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }) 
                    let res_sales = await this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    })  
                    let res_ITEM_ORDERS = await this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEM_ORDERS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    })  
                    let res_ITEM_ORDER_OPTIONS = await this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEM_ORDER_OPTIONS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    })  
                    // console.log(res_item,'res_item.....');
                    // console.log(res_sales,'res_sales.....');
                    // console.log(res_item_categories,'res_item_categories.....');
                    // console.log(res_item_departments,'res_item_departments.....');
                    // console.log(res_ITEM_ORDERS,'res_ITEM_ORDERS.....');
                    // console.log(res_ITEM_ORDER_OPTIONS,'res_ITEM_ORDER_OPTIONS.....');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_success("DATA","Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                } catch (error) {
                    console.log(error,'error.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_fail("DATA","Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    }) 
                }

            }, 
            DISPLAY_TOTAL_PRICE(){  
                try {  
                    let input = this.input
                    let pay_order = this.pay_order
                    if(!pay_order){return }
                    let paid_amount = this.toNumber(pay_order?pay_order.paid_amount:null)
                    let total = this.MBS.actions.money(paid_amount)
                    let total_size = total.length

                    let given_amount = this.toNumber(input.given_amount)
                    let change = given_amount-paid_amount
                    let balance = this.MBS.actions.money(change)
                    let balance_size = balance.length
                    
                    let total_initial = "TOTAL: MWK"
                    let balance_initial = "BALANCE: MWK"

                    if (total_size) {  
                        if(total_size > 10){
                            total_initial = "T: MK"
                        }else if(total_size > 9){
                            total_initial = "TOTAL: MK"
                        }
                    }if (balance_size) {  
                        if(balance_size > 10){
                            balance_initial = "B: MK"
                        }else if(balance_size > 9){
                            balance_initial = "BALANCE:MK"
                        }else if(balance_size > 8){
                            balance_initial = "BALANCE: MK"
                        }
                    } 

                    this.DISPLAY_TO_VFD({
                        text:total_initial+total,
                        text2:change>0?balance_initial+balance:'BALANCE:',
                    }).then(res=>{

                    }).catch(error=>{

                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DISPLAY_TOTAL_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            DISPLAY_KIPCOUNT(){  
                try {    
                    this.DISPLAY_TO_VFD({
                        text:"KIPCOUNT",
                        text2:"www.kipcount.com",
                    }).then(res=>{

                    }).catch(error=>{

                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DISPLAY_TOTAL_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            
            FULLSCREEN(activate=true){  
                try {    
                    let always_on_fullscreen = true
                    let fullscreen = this.fullscreen
                    if (always_on_fullscreen) {
                        this.MBS.actions.SCREEN_FULL_MODE(true) 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FULLSCREEN',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {   
                        if (action.code ===PAGE_NAME+'=OPEN-DRAWER') {
                            if (action.YES) {
                                setTimeout(() => {   
                                    this.OPEN_DRAWER()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=OPEN-REGISTER') {
                            if (action.YES) {
                                setTimeout(() => {   
                                    this.OPEN_REGISTER()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=START-SELLING') {
                            if (action.YES) {
                                setTimeout(() => {   
                                    this.OPEN_DRAWER_BEFORE_SELLING()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=REMOVE-USER') {
                            if (action.YES) {
                                setTimeout(() => {   
                                    let company = this.SELECTED_COMPANY
                                    let company_key = company?company.company_key:null
                                    let user = action.ud
                                    let us_company = user?user.companies?user.companies[company_key]:null:null
                                    let company_user_group_code = us_company?us_company.company_user_group_code:null
                                    if (company_user_group_code == 1) {
                                        this.REMOVE_ITEM(action.data)
                                        
                                    }else{
                                        this.MBS.actions.dialog(null,true,false,"ERROR","Access Denied!...",null,"OK") 
                                    } 
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=K-CONNECT') {
                            if (action.YES) {
                                setTimeout(() => {   
                                    this.UPDATE_K_CONNECTOR()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=K-CONNECT-CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(async() => {    
                                    this.inputLoading = true
                                    try {
                                        await this.UPDATE_K_CONNECTOR()
                                        this.CREATE_ORDER(action.return_order)
                                        this.inputLoading = false
                                    } catch (error) {
                                        this.inputLoading = false
                                    }
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=CLEAR-SALES') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.CLEAR_ALL_DATA()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }  
                        if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEM-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_ALL_ITEM_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }   
                        if (action.code ===PAGE_NAME+'=DELETE-ITEM-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_ITEM_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=DELETE-MULTIPLE-ITEM-ORDERS') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_MULTIPLE_ITEM_ORDERS(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }   
                        if (action.code ===PAGE_NAME+'=RELOAD-DATA') {
                            if (action.YES) {
                                this.RELOAD_DATA()
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=CARD-ATTACH-CUSTOMER') {
                            if (action.YES) {
                                    setTimeout(() => {  
                                        let link = DATA.PATHS.cards+action.data.key+"/"
                                        let f_detail = {
                                            action:DATA.ITEMS.ITEMS.values,
                                            path:this.MBS.actions.COMPANY_LINK(link,true),
                                            data:action.data,
                                            us:this.us
                                        } 
                                        this.input.loading = true 
                                        this.inputLoading = true 
                                        this.$store.dispatch("fi_update",f_detail)
                                        .then(res=>{ 
                                            let mbs_text = this.MBS.text.update_item_success(DATA.ITEMS.CARDS.name)
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes,  
                                            }) 
                                            this.input.loading = false 
                                            this.inputLoading = false 
                                            this.MBS.actions.progressDialog() 
                                            this.MBS.actions.restartResponses() 
                                        })
                                        .catch(error=>{ 
                                            let mbs_text = this.MBS.text.update_item_fail(DATA.ITEMS.CARDS.name)
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes, 
                                            })  
                                            this.input.loading = false
                                            this.inputLoading = false
                                            this.MBS.actions.progressDialog() 
                                            this.MBS.actions.restartResponses()

                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);     
                                } else {
                                    
                                }
                        } 
                        if (action.code ===PAGE_NAME+'=SELECT-SELLING-MODEL') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.menu_selling_mode = true
                                }, this.MBS.data.ACTION_REFRESH_TIME);     
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=CREATE_AS_NEW_ITEM') {
                            if (action.YES) {
                            } else {
                                setTimeout(() => {  
                                    this.CREATE_ITEM('',action.item_code)
                                }, this.MBS.data.ACTION_REFRESH_TIME);     
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            ON_QR_SCAN(code,spacial_keys,time){
                try {
                    if (code) { 
                        this.FULLSCREEN()
                        //check cashup
                        if (!this.LocalCashup) { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"OPEN REGISTER FIRST",
                                text:"You must open the Register first before you can scan.",
                                btnYes:"OK"
                            })
                            return
                        }
                        
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{
                            this.MBS.actions.dialog()
                            if (this.selling_model) {
                                let band1 = code.indexOf("%")!=-1?true:false
                                let band2 = code.indexOf(";")!=-1?true:false
                                let band3 = code.indexOf("+")!=-1?true:false
                                let close = code.slice(-1)
                                // let first = code.slice(0,1)
                                // let last = code.slice(-1)
                                // let mid = code.slice(1,-1) 
                                
                                
                                if ((band1||band2||band3) && close=="?") {
                                    let first_string = code.split("?")[0]  
                                    code = first_string.slice(1)  
                                    this.DESELECT_CARD()
                                    this.SELECT_CARD(null,code)  
                                } else {  
                                    this.ADD_ITEM(null,(code)) 
                                }  
                            } else {
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:"SELECT SELLING MODEL",
                                    text:"You must select selling mode before scanning items.",
                                    btnYes:"OK",
                                    action:{
                                        code:PAGE_NAME+"=SELECT-SELLING-MODEL"
                                    }
                                })
                            }
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            ON_KEY_DOWN(key,time){
                try {
                    this.FULLSCREEN()
                    if (key=='F1') {
                        this.dialog_search_and_select_items = true
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_KEY_DOWN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            MBS_KEYBOARD(key){ 
                this.FULLSCREEN()
                let focused_field = this.focused_field
                const field_value = focused_field?focused_field.value:null

                const ref_inputs = this.$refs
                if (key ) {
                    let value = key.value 
                    if (field_value) { 
                        if (field_value == "inputPhone") {
                            if (value=="BACK_SPACE") {
                                this.inputPhone = (""+this.inputPhone).slice(0,-1)
                            } else {
                                this.inputPhone =this.inputPhone+value 
                            }
                        }else{
                            if(!this.input){this.input={}}
                            if (value=="BACK_SPACE") {
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                            }else{
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value 
                            }
                            setTimeout(() => {
                                this.input = {...this.input}
                            }, 1); 
                        }
                        
                    }
                }
            }, 
            EXIT_PAGE(){
                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    this.PAGE_SHOW = true
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                this.MBS.events.$off('ON_KEY_DOWN', this.ON_KEY_DOWN);  
                this.MBS.events.$off('MBS_KEYBOARD', this.MBS_KEYBOARD);  
                this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch: {
            search_item(value){ 
                if (value) { 
                    this.ADD_ITEM(value) 
                }
            }, 
            input(value){    
                if (value) { 
                    // get day shift key
                    let day_shift_and_till_key = value.day_shift_and_till_key
                    let day_shift_key = null
                    let till_key = null
                    if (day_shift_and_till_key) {
                        let day_shift_and_till_key_ = day_shift_and_till_key?day_shift_and_till_key.split('/'):null
                        day_shift_key = day_shift_and_till_key_?day_shift_and_till_key_[0]:null
                        till_key = day_shift_and_till_key_?day_shift_and_till_key_[1]:null
                    }
                    if (day_shift_key != this.this_day_shift_key || till_key != this.this_till_key) {
                        this.this_day_shift_key = day_shift_key
                        this.this_till_key = till_key
                        this.ON_SELECT_SHIFT({
                            day_shift_and_till_key:day_shift_and_till_key,
                            day_shift_key:day_shift_key,
                            till_key:till_key
                        })
                    }  
                } 
            },
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            selectedCompany(value){
                if (value) {
                    this.INITIATE_SELECTED_SALES_ITEMS()  
                }
            }, 
            CompanySettings(value){
                if (value) {
                    this.INITIALIZE_CLICK_MODE()  
                    this.INITIALIZE_SELLING_MODE()  
                    this.INITIALIZE_ITEMS_CATEGORIES()
                    this.INITIALIZE_ITEMS_DEPARTMENTS()
                }
            },  
            CurrentVFT_Display(value){
                if (value) {
                    this.DISPLAY_TO_VFD({
                        text:"KIPCOUNT",
                        text2:"www.kipcount.com",
                    }).then(res=>{}).catch(error=>{})  
                }
            }, 
            pay_order(value){
                if (value) { 
                    if (value.card_load_money) { 
                    } else {
                        this.DISPLAY_TOTAL_PRICE()   
                    }
                }else{
                    this.DISPLAY_KIPCOUNT()  
                }
            }, 
            dialog_selected_card(value){
                // if (value==false) {
                //     this.DESELECT_CARD()
                // }else{
                //     this.DISPLAY_KIPCOUNT()  
                // }
            }, 
            CompanyQuickActions(actions){
                this.COMPANY_QUICK_ACTIONS(actions)
            }, 
            CurrentTill(actions){
                this.COMPANY_QUICK_ACTIONS(this.CompanyQuickActions)
                this.INITIALIZE_ITEMS_DEPARTMENTS()
            }, 
            click_mode(click){
                if (click) {
                    this.INITIALIZE_ITEMS_CATEGORIES()
                } 
            }, 
            LocalCashup(value){
                if (value) {
                    this.INITIALIZE_CLICK_MODE()  
                    this.INITIALIZE_SELLING_MODE()  
                    this.INITIALIZE_ITEMS_CATEGORIES()
                } 
            }, 
            FilteredItemCategories(value){ 
                this.INITIALIZE_ITEMS_CATEGORIES()
            }, 
            dialog_given_amount(value){
                if (value) { 
                    setTimeout(() => { 
                        this.$refs.ref_payment_cash.focus();
                    }, 50);

                } 
            }, 

            // input_discount_percentage(value){
            //     if (this.toNumber(this.input_discount_percentage)>1  && this.toNumber(this.input_discount_amount)>1) {
            //         setTimeout(() => {
            //             this.input_discount_amount = 0 
            //         }, 100);
            //     }
            // },  
            // input_discount_amount(value){
            //     if (this.toNumber(this.input_discount_amount)>1 && this.toNumber(this.input_discount_percentage)>1) {
            //         setTimeout(() => {
            //             this.input_discount_percentage = 0 
                        
            //         }, 100);
            //     }
            // },  
        },
    }
</script>

<style>
.selected {
  background-color: #a8dadc;
}
</style>
 
