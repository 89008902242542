<template>
    <div> 
        <v-container grid-list-xl> 
            <!-- <v-btn @click="EXPORT_EXCEL()" color="success">text</v-btn> -->
            <!-- <v-btn @click="UPDATE_ALL_EXCEL_ITEMS()" color="success">UPDATE ITEMS</v-btn> -->
            <v-card color="primary" dark> 
               <mbs-page-tab 
                   :items="MY_LINKS"/>  
               <v-layout v-if="thisStockTaking"   class="ma-0 pa-5" justify-center align-center>
                   <v-card width="85%" color="secondary" outlined >
                       <v-card  color="primary"  outlined> 
                           <v-progress-linear 
                               color="secondary"
                               height="25"
                               :value="percentageProgress" 
                               stream buffer-value="0"
                               >
                               <template v-slot:default="{ value }">
                                   <!-- <strong>{{ Math.ceil(value) }}%</strong> -->
                                   <!-- <strong>{{ Math.round(value) }}%</strong> -->
                                   <strong>{{ value.toFixed(2) }}%</strong>
                               </template>
                           </v-progress-linear>
                       </v-card> 
                   </v-card>
                   <v-spacer></v-spacer>
                   <mbs-live-stock-take></mbs-live-stock-take> 
                   <v-menu  
                        v-model="menu_action"
                        :close-on-content-click="true" 
                        offset-y  offset-overflow
                        :nudge-width="70" rounded="xl"
                        >
                        <template v-slot:activator="{ on, attrs }">  
                            <v-btn  v-on="on"  
                                class="" icon outlined> 
                                <v-icon class="">mdi-dots-vertical</v-icon> 
                            </v-btn> 
                        </template> 

                        <v-card  v-if="ud"  
                            color="lighten-1" class="">  
                            <v-card-title class="secondary white--text py-" dark>
                                Select Action
                            </v-card-title> 
                            <v-layout class="ma-0">
                                <v-list dense> 
                                    <div v-for="(action,index) in menuActions" :key="index">
                                        <v-list-item :class="''" 
                                            @click="MENU_ACTION(action,action.action)">
                                            <v-list-item-action><v-icon>{{action.icon}}</v-icon></v-list-item-action> 
                                            <v-list-item-content>{{action.name}}</v-list-item-content>
                                    </v-list-item> 
                                    </div>
                                </v-list>   
                            </v-layout>
                        </v-card>  
                    </v-menu>  
               </v-layout>
               <div v-else>
                   <v-layout v-if="true"   class="ma-0 pa-5" justify-center align-center> 
                       <v-spacer></v-spacer>
                       <v-btn @click="ADD_STOCK_TAKING_ORDER()" color="secondary">Start Stock Taking</v-btn>
                   </v-layout> 
               </div>
           </v-card>
           <br>  
           <v-layout fill-height justify-center column class="ma-0" align-center>
               <v-card outlined max-width="500" class="mx-3" v-if="!thisStockTaking">
                   <v-layout row wrap class="ma-0" justify-center align-center column>
                       <v-icon size="100">home</v-icon> 
                       <h2 class="grey--text text--darken-1  ">{{MBS.actions.TEXT_UP('JOIN A STOCK TAKE')}}</h2>
                   </v-layout>
                   <v-card outlined class="mx-5 my-5">
                       <form @submit.prevent="JOIN_SOCK_TAKING(input)">
                           <v-layout row wrap class="mx-3 my-5">
                               <mbs-input-fields  
                                   v-model="input" 
                                   :select_items="SelectItems"
                                   :field_items="DATA.FIELDS.join_stock_taking"  
                                   />   
                           </v-layout> 
                           <v-layout class="ma-0" justify-center align-center> 
                               <v-btn :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 px-9">JOIN</v-btn> 
                               
                           </v-layout>
                       </form> 
                   </v-card>
               </v-card>  
               <v-card  width="100%" flat v-else> 
                   <v-card class="pb-1"  width="100%"  outlined tile>
                       <v-card class="mb-1" tile>
                           <v-toolbar flat tile class="mb-" rounded="" @click="menu_searching_mode=(!searching_model?true:false)">
                               <v-autocomplete   :menu="true"
                                   :disabled="!searching_model"
                                   v-model="search_item" 
                                   :items="SearchingItems" dense
                                   append-icon="search"
                                   outlined :rounded="false"
                                   :label="'Search for Item'"
                                   color="blue-grey lighten-2" 
                                   item-text="name"
                                   item-value="key" 
                                   :filter="(item, queryText, itemText) => { 
                                       let index = (a,b)=>{
                                               if(!a||!b){return false}
                                               a = ''+a; b = ''+b
                                               return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                           } 
                                           let first = index(itemText,queryText)
                                           let second = item?index(item.code,queryText):false
                                           let third = item?index(item.brand_name,queryText):false
                                           let forth = item?index(item.manufacturer_name,queryText):false
                                           if(!item){return first}  
                                           return first || second || third || forth 
                                   }"
                                   >
                                   <template v-slot:selection="data">
                                       {{ data.item.name }}
                                   </template>
                                   <template v-slot:item="data">
                                       <template v-if="(typeof data.item !=='object')" >
                                       <v-list-item-content v-text="data.item"></v-list-item-content>
                                       </template>
                                       <template v-else>
                                       <v-list-item-avatar tile color="grey">
                                           <img :src="data.item.image" v-if="data.item.image">
                                           <v-icon v-else>local_offer</v-icon>
                                       </v-list-item-avatar>
                                       <v-list-item-content>
                                           <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                           <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                           <v-list-item-subtitle v-html="data.item.brand_name"></v-list-item-subtitle>
                                           <v-list-item-subtitle v-html="data.item.manufacturer_name"></v-list-item-subtitle>
                                       </v-list-item-content>
                                       </template>
                                   </template>
                               </v-autocomplete> 

                               <span v-if="!selected_excel_items">
                                   <v-menu  
                                       v-model="menu_searching_mode"
                                       :close-on-content-click="true" 
                                       offset-y  offset-overflow
                                       :nudge-width="50" rounded="xl"
                                       >
                                       <template v-slot:activator="{ on, attrs }">  
                                           <v-btn  v-on="on" 
                                               ref="btn_selling_mode"
                                               :disabled="false"  
                                               class="ml-1 mb-1" color="secondary">
                                               {{searching_model?searching_model:'Select'}}
                                               <v-icon class="ml-2">arrow_drop_down</v-icon>
                                           </v-btn> 
                                       </template> 
            
                                       <v-card  v-if="ud"  
                                           color="lighten-1" class="">  
                                           <v-card-title class="secondary white--text py-" dark>
                                               Select  
                                           </v-card-title> 
                                           <v-list dense> 
                                                <!-- <v-subheader>Searching Mode</v-subheader> -->
                                               <div v-for="(model,index) in searchingModels" :key="index">
                                                   <v-list-item :class="model == searching_model?'secondary--text':''" @click="searching_model=model">{{model}}</v-list-item> 
                                               </div>
                                               <v-divider> </v-divider>
                                               <v-list-item  @click="IMPORT_EXCEL_FILE()"> <v-icon class="mr-2">mdi-microsoft-excel</v-icon> Import Excel</v-list-item> 
                                               <v-list-item  @click="EXPORT_EXCEL()"><v-icon class="mr-2">mdi-file-download-outline</v-icon> Download Excel Template</v-list-item> 
                                           </v-list>  
                                       </v-card>  
                                   </v-menu> 
                                   <v-menu  
                                       v-model="menu_item_sorting"
                                       :close-on-content-click="true" 
                                       offset-y  offset-overflow
                                       :nudge-width="70" rounded="xl"
                                       >
                                       <template v-slot:activator="{ on, attrs }">  
                                           <v-btn  v-on="on" 
                                               ref="btn_selling_mode"
                                               :disabled="false"  
                                               class="ml-1 mb-1 pl-0 pr-1" color="secondary"> 
                                               <v-icon class="ml-2">sort_by_alpha</v-icon>
                                               <v-icon class="ml-">arrow_drop_down</v-icon>
                                           </v-btn> 
                                       </template> 
            
                                       <v-card  v-if="ud"  
                                           color="lighten-1" class="">  
                                           <v-card-title class="secondary white--text py-" dark>
                                               Sort Items 
                                           </v-card-title> 
                                           <v-layout class="ma-0">
                                               <v-list dense> 
                                                   <div v-for="(model,index) in itemSorting" :key="index">
                                                       <v-list-item :class="model.code == item_sorting.code?'secondary--text':''" @click="item_sorting=model">{{model.name}}</v-list-item> 
                                                   </div>
                                               </v-list>   
                                           </v-layout>
                                       </v-card>  
                                   </v-menu>    
                               </span>
                               <span v-else>
                                    <v-btn class="ml-1 mb-1" @click="SUBMIT_EXCEL_ITEMS()" color="primary"><v-icon class="mr-1">mdi-file-document-check-outline</v-icon> Upload Excel Items</v-btn>
                                    <v-btn class="ml-1 mb-1" @click="CANCEL_EXCEL()" color="secondary"><v-icon class="mr-1">mdi-file-document-remove-outline</v-icon> Cancel Excel</v-btn>
                                    <v-menu  
                                       v-model="menu_item_sorting"
                                       :close-on-content-click="true" 
                                       offset-y  offset-overflow
                                       :nudge-width="70" rounded="xl"
                                       >
                                       <template v-slot:activator="{ on, attrs }">  
                                           <v-btn  v-on="on" 
                                               ref="btn_selling_mode"
                                               :disabled="false"  
                                               class="ml-1 mb-1 pl-0 pr-1" color="secondary"> 
                                               <v-icon class="ml-2">sort_by_alpha</v-icon>
                                               <v-icon class="ml-">arrow_drop_down</v-icon>
                                           </v-btn> 
                                       </template> 
            
                                       <v-card  v-if="ud"  
                                           color="lighten-1" class="">  
                                           <v-card-title class="secondary white--text py-" dark>
                                               Sort Items 
                                           </v-card-title> 
                                           <v-layout class="ma-0">
                                               <v-list dense> 
                                                   <div v-for="(model,index) in itemSorting" :key="index">
                                                       <v-list-item :class="model.code == item_sorting.code?'secondary--text':''" @click="item_sorting=model">{{model.name}}</v-list-item> 
                                                   </div>
                                               </v-list>   
                                           </v-layout>
                                       </v-card>  
                                   </v-menu> 
                                </span> 
                           </v-toolbar> 
                           <mbs-page-tab 
                                v-model="PAGE_CURRENT"
                               :items="selected_excel_items?tableTabsExcel:tableTabs"
                               color="white darken-1" 
                               dark
                           />   
                       </v-card>

                       <!-- //LOAD EXCEL -->
                       <v-card v-if="excel_loading" :height="vsh-450" tile flat color="transparent" >
                        <v-layout fill-height justify-center align-center>
                            <v-progress-circular
                                :rotate="360"
                                :size="100"
                                :width="15"
                                :value="loadingValue"
                                color="primary"
                                >
                                {{ loadingValue }}
                                </v-progress-circular>
                        </v-layout>
                       </v-card>
                       <!-- //SHOW ITEM LIST -->
                       <div v-else> 
                            <!-- <mbs-stock-taking-selected-items
                                :item_data="TabPageItems"
                            />  -->
                           <div v-if="MBS.actions.SIZE(TabPageItems)>0">
                                <v-card v-if="item_sorting_loading" :height="vs.height-520" flat> 
                                    <mbs-page-spinner 
                                       />  
                                </v-card>
                                <v-list two-line class="my-0 py-0" v-else>
                                     
                                   <v-virtual-scroll
                                       :items="TabPageItems"
                                       :height="vs.height-262"
                                       item-height="75"
                                       >
                                       <template v-slot:default="{ item,index }">
                                           <v-list-group   
                                               :style="item.expand?'border-left: 5px solid '+MBS.data.color.primary:''" 
                                               v-model="item.expand"
                                               :no-action ="true" 
                                               :sub-group ="false"
                                               append-icon="" >
                                               <template v-slot:activator> 
                                                   <v-list-item-action>  
                                                       <v-layout row wrap>
                                                           <v-btn  icon>
                                                               <v-icon v-if="item.expand">expand_more</v-icon>
                                                               <v-icon v-else>chevron_right</v-icon>
                                                           </v-btn> 
                                                           <v-avatar :class="item.expand?'':''"
                                                               size="40"   
                                                               rounded="sm"
                                                               color="grey"  >
                                                               <v-img v-if="item.image"></v-img>
                                                               <v-icon v-else-if="item.item_not_counted">{{"local_offer"}}</v-icon>
                                                               <v-icon v-else>{{item.uploaded?"mdi-cloud-check":"mdi-progress-upload"}}</v-icon>
                                                           </v-avatar> 
                                                       </v-layout>
                                                   </v-list-item-action>  
                                                   <v-list-item-content>{{item.name}}</v-list-item-content>
                                                   <v-list-item-action v-if="!item.item_not_counted"><v-card color="transparent" flat width="100">
                                                       <v-layout class="ma-0">
                                                           <v-spacer></v-spacer>
                                                           {{item.new_quantity}}
                                                       </v-layout>
                                                   </v-card></v-list-item-action>  
                                                   <v-list-item-action v-if="!item.item_not_counted">
                                                       <v-btn @click="REMOVE_SELECTED_ITEM(item,index)" 
                                                       :disabled="inputLoading" small icon><v-icon>delete</v-icon></v-btn>
                                                   </v-list-item-action> 
                                               </template>
                                               <v-card   flat   class="mbs-z-index2 mx-" color="">
                                                   <v-card-text class="b1">
                                                       <v-row>
                                                           <v-col cols="12" sm="4" class="py-0 pr-0"> 
                                                               <div>Retail Price</div> 
                                                               <v-text-field dense color="primary"   
                                                                   :disabled="true"
                                                                   filled outlined v-mask="currencyMask"
                                                                   v-model="item.retail_price"  
                                                                   outline
                                                               ></v-text-field>  
                                                           </v-col>
                                                           <v-col cols="12" sm="4" class="py-0 px-1" >
                                                               <div>Wholesale Price</div>
                                                                   <v-text-field dense
                                                                       filled outlined  disabled
                                                                       v-model="item.wholesale_price"
                                                                       outline
                                                                   ></v-text-field> 
                                                           </v-col>
                                                           <v-col cols="12" sm="4" class="py-0 pl-0">
                                                               <div>Cost Price</div>
                                                                   <v-text-field dense
                                                                       filled outlined 
                                                                       :disabled="true"
                                                                       v-model="item.cost_price"
                                                                       outline v-mask="currencyMask"
                                                                   ></v-text-field> 
                                                           </v-col>
                                                           <v-col cols="12" sm="6" class="py-0 pl-3">
                                                               <div>System Quantity</div>
                                                                   <v-text-field dense
                                                                       filled outlined 
                                                                       :disabled="true" 
                                                                       v-model="item.number_quantity"
                                                                       outline v-mask="numberMask"
                                                                   ></v-text-field> 
                                                           </v-col>
                                                           <v-col cols="12" sm="6" class="py-0 pl-0">
                                                               <div>Stock Quantity</div>
                                                                   <v-text-field dense
                                                                       filled outlined 
                                                                       :disabled="true" 
                                                                       v-model="item.new_quantity"
                                                                       outline v-mask="numberMask"
                                                                   ></v-text-field> 
                                                           </v-col>
                                                           <v-col cols="12" sm="12" class="py0">
                                                               <div>Notes</div>
                                                               <v-textarea dense
                                                                   filled outlined 
                                                                   rows="2"
                                                                   v-model="item.note"
                                                                   outline /> 
                                                           </v-col>
                                                       </v-row> 
                                                   </v-card-text>  
                                               </v-card> 
                                           </v-list-group>
                                           <v-divider :key="'d'+index" ></v-divider>  
                                       </template> 
                                   </v-virtual-scroll>
                                </v-list>   
                           </div>
                           <v-card :height="vsh-450"  v-else tile flat color="transparent">
                               <v-layout fill-height align-center justify-center>
                                   <v-icon >warning</v-icon>
                                   <div class="pl-2 pt-1">No items</div>
                               </v-layout> 
                           </v-card>
                       </div>
                   </v-card> 
               </v-card>
           </v-layout>  
        </v-container>  

        <mbs-item-manager
            :show="false" 
            :name_items="NAME_ITEMS"
            :name_item="NAME_ITEM"
            :value_items="VALUE_ITEMS"
            :value_item="VALUE_ITEM"
            :field_items="DATA.FIELDS.start_stock_taking"   
            :path_item="SOCK_ORDER_PATH"  
            :select_items="SelectItems"   
        />

        <!-- DIALOGS -->
        <div>
            <!-- STOCK DETAILS -->
            <div v-if="dialog_stack_details">
                <v-dialog 
                    v-model="dialog_stack_details"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="800px"
                    transition="dialog-transition" >
                    <v-card rounded="lg">
                        <v-card-title class="primary">
                            <div class="font-weight-bold b2--text">STOCK TAKING DETAILS</div> 
                            <v-spacer></v-spacer>
                            <v-btn icon  dark @click="dialog_stack_details=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text > 
                            <form @submit.prevent="START_SOCK_TAKING(input)">
                                <v-layout row wrap class="ma-3"> 
                                    <mbs-input-fields  
                                        v-model="input" 
                                        :select_items="SelectItems"
                                        :field_items="DATA.FIELDS.start_stock_taking"  
                                        />   
                                </v-layout> 
                                <v-layout class="ma-0">
                                    <v-spacer></v-spacer> 
                                    <v-btn  :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 mr-1">Start</v-btn> 
                                </v-layout>
                            </form>
                        </v-card-text> 
                    </v-card> 
                </v-dialog>  
            </div>  

            <!-- SELECTED ITEM QUANTITY -->
            <div v-if="dialog_selected_item_quantity">
                <v-dialog 
                    v-model="dialog_selected_item_quantity"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="800px"
                    transition="dialog-transition" >
                    <v-card rounded="lg">
                        <v-card-title class="primary">
                            <div class="font-weight-bold b2--text">
                                <span v-if="just_selected_item.submitted">EDIT</span> 
                                ITEM QUANTITY
                            </div> 
                            <v-spacer></v-spacer>
                            <v-btn icon  dark @click="dialog_selected_item_quantity=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text > 
                            <v-list v-if="just_selected_item">
                                <v-list-item>
                                    <v-list-item-action>  
                                        <v-avatar  
                                            size="40"   
                                            rounded="sm"
                                            color="grey"  > 
                                            <v-icon >local_offer</v-icon>
                                        </v-avatar>  
                                    </v-list-item-action>  
                                    <v-list-item-content>{{just_selected_item.name}}</v-list-item-content> 
                                    <v-list-item-action>{{just_selected_item.new_quantity}}</v-list-item-action> 
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                            <br>
                            <form @submit.prevent="ADD_SELECTED_ITEM(just_selected_item)">
                                <v-layout column class="ma-3"> 
                                    <div>
                                        
                                    </div>
                                    <div v-if="just_selected_item.old_quantity">
                                        <div>Old Item Quantity</div>
                                        <v-text-field 
                                            filled disabled
                                            label=" Quantity"
                                            v-mask="numberMask"
                                            required 
                                            v-model="just_selected_item.old_quantity"
                                        ></v-text-field>  
                                    </div>
                                    <div>
                                        <div>New Item Quantity</div>
                                        <v-text-field 
                                            filled autofocus
                                            label="Enter New Quantity"
                                            v-mask="numberMask"
                                            required
                                            v-model="just_selected_item.new_quantity"
                                        ></v-text-field>  
                                    </div>
                                     
                                </v-layout> 
                                <v-layout class="ma-0">
                                    <v-spacer></v-spacer> 
                                    <v-btn  :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 mr-1" >
                                        <span v-if="just_selected_item.submitted">EDIT</span>
                                        <span v-else>SUBMIT</span>
                                    </v-btn> 
                                </v-layout>
                            </form>
                        </v-card-text> 
                    </v-card> 
                </v-dialog>  
            </div> 

            <!-- EXCEL EXPORT -->
            <div v-if="EXCEL_EXPORTER">
                <mbs-excel-export 
                    :file_name="EXCEL_EXPORTER.file_name"
                    :sheet_name="EXCEL_EXPORTER.sheet_name"
                    :data_items="EXCEL_EXPORTER.data_items"
                    :header_items="EXCEL_EXPORTER.headers"
                />  
            </div>

            <!-- EXCEL_FILE_SELECTED -->
            <mbs-excel-selector
                :notShow="false"
                @onFileSelected="EXCEL_FILE_SELECTED" 
                @onNoFileSelected="NO_EXCEL_FILE_SELECTED" 
                @onLoadingFile="LOADING_FILE" 
                @onError="ON_FILE_ERROR" 
                />  
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import DB from '../../../plugins/DB'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.STOCK_TAKING_ORDERS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:0,
                PAGE_SIZE:10,
                excel_loading:false,
                loadingValue:25,
                
                NAME_ITEMS:DATA.ITEMS.STOCK_TAKING_ORDERS.names,
                NAME_ITEM:DATA.ITEMS.STOCK_TAKING_ORDERS.name,
                VALUE_ITEMS:DATA.ITEMS.STOCK_TAKING_ORDERS.values,
                VALUE_ITEM:DATA.ITEMS.STOCK_TAKING_ORDERS.value,    
                EXCEL_EXPORTER:null,

                input:{},  
                menu_searching_mode:false,
                menu_item_sorting:false,
                selected_items:null,
                just_selected_item:null,
                inputLoading:false,  
                dialog_stack_details:false,  
                dialog_selected_item_quantity:false,  
                search_item:'', 
                DATA:DATA, 
                searching_model:null, 
                searchingModels:["All Items","Remaining","Submitted"],
                item_sorting:{code:1}, 
                item_sorting_loading:false, 
                itemSorting:[
                    {code:1,name:"Sort by Name (A-Z)"},
                    {code:2,name:"Sort by Name (Z-A)"},
                    {code:3,name:"Sort by Quantity (Low-High)"},
                    {code:4,name:"Sort by Quantity (High-Low)"},
                    {code:5,name:"Counted (First-Last)"},
                    {code:6,name:"Counted (Last-Fist)"}
                ],
                selected_excel_items:null,
                tableTabsExcel:[
                    {name:"Selected Excel",show_if:item=>item.balance>0},
                    {name:"Submitted",show_if:item=>item.balance>0},
                    {name:"Uploading...",show_if:item=>item.balance<=0}, 
                    {name:"Remaining",show_if:item=>item.balance<=0}, 
                ],
                tableTabs:[
                    {name:"Submitted",show_if:item=>item.balance>0},
                    {name:"Uploading...",show_if:item=>item.balance<=0}, 
                    {name:"Remaining",show_if:item=>item.balance<=0}, 
                ],
                menu_action:false,
                menuActions:[
                    {icon:"mdi-refresh",name:"Refresh Items",action:"refresh_items"}, 
                    {icon:"mdi-logout",name:"Leave (Exit) Stock Take",action:"leave_stock_take"}, 
                    {icon:"mdi-check-outline",name:"Close & Finish Stock Take",action:"close_stock_take"}, 
                    {icon:"mdi-microsoft-excel",name:"Import Excel",action:"import_excel"}, 
                    {icon:"mdi-file-download-outline",name:"Download Excel Template",action:"download_excel"}, 
                ]
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$on('ITEM_ACTION', this.ITEM_ACTION);  
                this.REMOVED_FROM_STOCK_TAKE(this.thisUser) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
                this.FEEDBACKS(1000)  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },   
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("stock",1,true) 
                return thisLinks
            }, 
            /////////
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses, 
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                online: state=> state.users[(DATA.ITEMS.ONLINE_STATUSES.value).toUpperCase()],
            }),
            ...mapState({
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()], 
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()],  
                StockTakingOrders: state=> state.items[(DATA.ITEMS.STOCK_TAKING_ORDERS.values).toUpperCase()],
                JoinedStockTakingOrders: state=> state.join[(DATA.ITEMS.STOCK_TAKING_ORDERS.values).toUpperCase()], 
                LocalJoinedStockTaking: state=> state.items[(DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values).toUpperCase()],
                LocalStockTakingItems: state=> state.items[(DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values).toUpperCase()],
                
            }), 
            StockOrdersData(){ 
                let StockOrders = this.StockOrders
                let JoinedStockOrders = this.JoinedStockOrders
                let items = JoinedStockOrders?JoinedStockOrders:StockOrders 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                return list
            }, 
            StockOutletsData(){  
                let items = this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets  
                return items
            },  
            StockTakingOrdersData(){  
                let items = this.JoinedStockTakingOrders?this.JoinedStockTakingOrders:this.StockTakingOrders   
                if(!items){return null}  
                items = items.filter(item=>!item.closed)
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("operating_date",false)) 
                return list
            }, 
            thisStockTaking(){  
                let StockTakings = this.StockTakingOrdersData
                let LocalJoinedStockTaking = this.LocalJoinedStockTaking  
                if(!LocalJoinedStockTaking||!StockTakings){return null}
                let item = StockTakings.find(stock=>{
                    return stock.key == LocalJoinedStockTaking.key
                })  
                return item
            },  
            StockTakingSubmittedItems(){  
                let stockTakings = this.thisStockTaking
                if(!stockTakings){return null}
                return this.MBS.actions.OBJECT_ITEMS(stockTakings.items) 
            }, 
            StockTakingUsers(){  
                let StockTakings = this.thisStockTaking 
                if(!StockTakings){return null}
                let users_obj = StockTakings.users
                let user_list = this.MBS.actions.OBJECT_ITEMS(users_obj) 
                if(!user_list){return null}
                let users = []
                user_list.forEach(us => {
                    let usd = this.$store.getters.getUserByUid(us.uid)
                    if (usd) {
                        users.push({
                            ...usd,
                            ...us,
                            joined_at_:this.MBS.actions.toDateTime(us.joined_at),
                            left_at_:us.left?this.MBS.actions.toDateTime(us.left_at):"Running.."
                        })
                    }else{
                        users.push({...us})
                    }
                }); 
                return users
            },
            thisUser(){  
                let users = this.StockTakingUsers 
                if(!users||!this.us){return null}
                let user = users.find(user=>user.uid == this.us.uid) 
                return user
            },   

            //ITEMS
            ItemsData(){  
                let items = this.JoinedItems?this.JoinedItems:this.Items   
                return items
            },  
            ItemsKits(){  
                let items = this.ItemsData 
                if(!items){return null}
                let filtered = items.filter(item=>!item.item_kits) 
                return filtered
            },  
            ItemsSubmitted(){
               const items = this.StockTakingSubmittedItems 
               if(!items){ return null}
               let all = [] 
               items.forEach(item => {
                   all.push({
                       ...item,
                       new_quantity:this.toNumber(item.new_quantity),
                       uploaded:true
                   })
               });   
               return all
            },
            ItemsOnLoading(){
               const items = this.LocalStockTakingItems 
               if(!items){ return null}   
               return items
            },
            ItemsCounted(){
               const submitted = this.ItemsSubmitted
               const onLoading = this.ItemsOnLoading 
               let all = []

                if (onLoading) {
                    onLoading.forEach(items => {
                        all.push({
                            ...items,
                            new_quantity:this.toNumber(items.new_quantity),
                        })
                    });  
                }

                if (submitted) { 
                    submitted.forEach(items => {
                        all.push({
                            ...items,
                            new_quantity:this.toNumber(items.new_quantity),
                            uploaded:true
                        })
                    }); 
                } 
               return all
            },
            ItemsNotSubmitted(){
               const ItemsSubmitted = this.ItemsSubmitted 
               const items = this.ItemsData 
               if(!items){ return null}
               let all = [] 
               items.forEach(item => {
                    let submitted = (key)=>{
                        if(!ItemsSubmitted){return null}
                        return ItemsSubmitted.find(item=>item.key==key)
                    }
                    if (!submitted(item.key)) {
                        all.push({
                            ...item,
                            new_quantity:this.toNumber(item.new_quantity),
                            not_submitted:true
                        }) 
                    }
               });   
               return all
            }, 
            ItemsNotCounted(){
               const ItemsCounted = this.ItemsCounted 
               const items = this.ItemsData 
               if(!items){ return null}
               let all = [] 
               items.forEach(item => {
                    let counted = (key)=>{
                        if(!ItemsCounted){return null}
                        return ItemsCounted.find(item=>item.key==key)
                    }
                    if (!counted(item.key)) {
                        all.push({
                            ...item,
                            new_quantity:item.new_quantity?this.toNumber(item.new_quantity):null,
                            item_not_counted:true
                        }) 
                    }
               });   
               return all
            }, 
            ItemsAll(){
               const ItemsCounted = this.ItemsCounted 
               const items = this.ItemsData 
               if(!items){ return null}
               let all = [] 
               items.forEach(item => {
                    let counted = (key)=>{
                        if(!ItemsCounted){return null}
                        return ItemsCounted.find(item=>item.key==key)
                    }
                    let sub_item = counted(item.key)
                    if (sub_item) {
                        all.push({
                            ...item,
                            new_quantity:this.toNumber(sub_item.new_quantity)
                        }) 
                    }else{
                        all.push({
                            ...item,
                            new_quantity:item.new_quantity?this.toNumber(item.new_quantity):null 
                        }) 

                    }
               });   
               return all
            }, 

            TabPageItems(){
                const selected_excel_items = this.selected_excel_items
                const item_sorting = this.item_sorting
                const Items = this.ItemsData
                const ItemsSubmitted = this.ItemsSubmitted
                const ItemsOnLoading = this.ItemsOnLoading 
                const ItemsCounted = this.ItemsCounted 
                const ItemsNotSubmitted = this.ItemsNotSubmitted
                const ItemsNotCounted = this.ItemsNotCounted
                const PAGE_CURRENT = this.PAGE_CURRENT 
                let all = []

                if (selected_excel_items) {
                    if (PAGE_CURRENT==0) {
                            all = selected_excel_items 
                    }else if (PAGE_CURRENT==1) {
                            all = ItemsSubmitted 
                    }else if (PAGE_CURRENT == 2) { 
                            all = ItemsOnLoading
                    }else { 
                            all = ItemsNotCounted
                    } 
                }else{
                    if (PAGE_CURRENT==0) {
                            all = ItemsSubmitted 
                    }else if (PAGE_CURRENT == 1) { 
                            all = ItemsOnLoading
                    }else { 
                            all = ItemsNotCounted
                    }  
                }
                if (this.MBS.actions.SIZE(all)==0) {return null}

 
                //sort
                if (item_sorting) {
                    if(item_sorting.code==1){
                        all.sort(this.MBS.actions.dynamicSort("name",true))   
                    }else if(item_sorting.code==2){
                        all.sort(this.MBS.actions.dynamicSort("name",false))    
                    }else if(item_sorting.code==3){
                        all.sort(this.MBS.actions.dynamicSort("new_quantity",true))    
                    }else if(item_sorting.code==4){
                        all.sort(this.MBS.actions.dynamicSort("new_quantity",false))    
                    }else if(item_sorting.code==5){
                        if (this.PAGE_CURRENT == 1) {
                            all.sort(this.MBS.actions.dynamicSort("selecting_list_key",true))  
                        } else {
                            all.sort(this.MBS.actions.dynamicSort("updated_at_server",true))  
                        }
                    }else if(item_sorting.code==6){
                        if (this.PAGE_CURRENT == 1) {
                            all.sort(this.MBS.actions.dynamicSort("selecting_list_key",false))  
                        } else {
                            all.sort(this.MBS.actions.dynamicSort("updated_at_server",false))  
                        } 
                    }
                    
                } 
                return all
            },
            
            SearchingItems(){
                const item_sorting = this.item_sorting
                const Items = this.ItemsAll
                const ItemsSubmitted = this.ItemsSubmitted
                const ItemsOnLoading = this.ItemsOnLoading 
                const ItemsCounted = this.ItemsCounted 
                const ItemsNotSubmitted = this.ItemsNotSubmitted
                const ItemsNotCounted = this.ItemsNotCounted
                const searching_model = this.searching_model  
                if(!searching_model){return Items} 
                let search_items = []
                if (searching_model == "All Items") {
                    search_items = Items
                }else if (searching_model == "Submitted") {
                    search_items = ItemsSubmitted
                }else{
                    search_items = ItemsNotCounted
                }  

                //sort
                let sort_property = null
                if (item_sorting) { 
                    if(item_sorting.code==1){
                        search_items.sort(this.MBS.actions.dynamicSort("name",true))   
                    }else if(item_sorting.code==2){
                        search_items.sort(this.MBS.actions.dynamicSort("name",false))    
                    }else if(item_sorting.code==3){
                        if (searching_model == "All Items") {
                            sort_property = 'quantity'
                        }else{sort_property="new_quantity"}
                        search_items.sort(this.MBS.actions.dynamicSort(sort_property,true))    
                    }else if(item_sorting.code==4){
                        search_items.sort(this.MBS.actions.dynamicSort(sort_property,false))    
                    }else if(item_sorting.code==5){
                        if (this.PAGE_CURRENT == 1) {
                            search_items.sort(this.MBS.actions.dynamicSort("selecting_list_key",true))  
                        } else {
                            search_items.sort(this.MBS.actions.dynamicSort("updated_at_server",true))  
                        }
                    }else if(item_sorting.code==6){
                        if (this.PAGE_CURRENT == 1) {
                            search_items.sort(this.MBS.actions.dynamicSort("selecting_list_key",false))  
                        } else {
                            search_items.sort(this.MBS.actions.dynamicSort("updated_at_server",false))  
                        } 
                    }
                    
                } 
                if (this.MBS.actions.SIZE(search_items)==0) {return null} 
                return search_items
            },
            percentageProgress(){
               let submitted = this.ItemsSubmitted
               let items = this.ItemsData 
               let sizeSubmitted = this.MBS.actions.SIZE(submitted)
               let sizeItems = this.MBS.actions.SIZE(items) 
               let percentage = sizeSubmitted*100/sizeItems 
               return percentage
            }, 
            SOCK_ORDER_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders,true) 
                return path
            }, 
            SelectItems(){ 
                let Outlets = this.StockOutletsData
                let StockTakingOrders = this.StockTakingOrdersData  
                return { 
                    Outlets:Outlets,
                    StockTakingOrders:StockTakingOrders, 
                }
            },   
            HEADER_ITEMS(){
                return [ 
                    // {id:1,name:"General",title:true}, 
                    // {id:0,name:"No.",value:'no',show:false},   
                    // {id:0,name:"key",value:"key",align:"center",show:true},   
                    {id:0,name:"code",value:"code",align:"center",show:true},   
                    {id:0,name:"name",value:"name",show:true}, 
                    {id:0,name:"cost_price",value:"cost_price",rename_value:"new_cost_price",show:false}, 
                    {id:0,name:"retail_price",value:"retail_price",rename_value:"new_retail_price",show:false},  
                    {id:0,name:"wholesale_price",value:"wholesale_price",rename_value:"new_wholesale_price",show:false}, 
                    {id:0,name:"quantity",value:"quantity",rename_value:"new_quantity",show:true,is_number:true},  
                ]
            }, 
        },
        methods: { 
            async UPDATE_ALL_EXCEL_ITEMS(){
                try {
                    // let items = this.ItemsData
                    let items = this.selected_excel_items
                    let size = this.MBS.actions.SIZE(items)
                    let progress = 0

                    console.log(items,size,"no items.......");
                    // return
                     
                    if(!items){
                    } 
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Updating ("+size+"/"+progress+") ..."
                    })

                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)

                    
                    for (const item of items) {
                        let res = await this.$store.dispatch('fi_update',{
                            action:DATA.ITEMS.ITEMS.values+"__", 
                            path:path+item.key, 
                            data:{
                                retail_price:item.new_quantity
                            }
                        }) 
                        progress = progress+1
                        this.MBS.actions.progressDialog({ 
                            show:true,
                            text:"udate ("+size+"/"+progress+") ..."
                        })
                        console.log(res,'res....');
                    }
                    this.MBS.actions.progressDialog()
                } catch (error) {
                    
                }

            },
            EXPORT_EXCEL(){
                try {   
                    console.log(this.TabPageItems,'TabPageItems....');
                    this.MBS.events.$emit("EXPORT_EXCEL")
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXPORT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            ITEM_ACTION(item,action){
                try {   
                    if (action == "start_stock_taking") {
                        this.dialog_stack_details = true
                    } 


                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            MENU_ACTION(item,action){
                try {   
                    if (action == "refresh_items") {
                        this.MBS.events.$emit('LIVE_STOCK_TAKE_REFRESH_ITEMS')  
                    }else if (action == "leave_stock_take") {
                        this.MBS.events.$emit('LEAVE_STOCK_TAKING')
                    }else if (action == "close_stock_take") {
                        this.MBS.events.$emit('CLOSE_STOCK_TAKING') 
                    }else if (action == "import_excel") {
                        this.IMPORT_EXCEL_FILE() 
                    }else if (action == "download_excel") {
                        this.EXPORT_EXCEL()  
                    } 


                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'MENU_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            START_SOCK_TAKING(input){
                try {
                    let outlet_code = input.outlet_code
                    let operating_date = input.operating_date
                    let description = input.description
                     
                    let details={
                        outlet_code:outlet_code,
                        operating_date:operating_date, 
                    }
                    if (description) {
                        details.description = description
                    } 
                     
                    let mbs_text = this.MBS.text.item_action("Stock Taking","Start")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=START-STOCK-TAKING",
                            data:details
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'START_SOCK_TAKING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            JOIN_SOCK_TAKING(input){
                try { 
                    let stock_taking_order_key = input.stock_taking_order_key
                    let description = input.description
                    let moment = this.MBS.date.moment
                    let joined_at = moment?moment().valueOf():null 
                    let details={
                        stock_taking_order_key:stock_taking_order_key,
                        joined_at:joined_at, 
                        left:false,
                        uid:this.us.uid
                    }
                    if (description) {
                        details.description = description
                    } 
                     
                    let mbs_text = this.MBS.text.item_action("Stock Taking","Join")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=JOIN-STOCK-TAKING",
                            data:details
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'START_SOCK_TAKING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            LEAVE_SOCK_TAKING(input){
                try { 
                    let stock_taking_order_key = input.stock_taking_order_key
                    let description = input.description
                    let moment = this.MBS.date.moment
                    let local_created_at = moment?moment().valueOf():null 
                    let details={
                        stock_taking_order_key:stock_taking_order_key,
                        local_updated_at:local_created_at, 
                        left:true,
                        uid:this.us.uid
                    }
                    if (description) {
                        details.description = description
                    } 
                     
                    let mbs_text = this.MBS.text.item_action("Stock Taking","Leave")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=LEAVE-STOCK-TAKING",
                            data:details
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'START_SOCK_TAKING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ADD_STOCK_TAKING_ORDER(){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.STOCK_TAKING_ORDERS.name,
                        action:"add_item", 
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_STOCK_TAKING_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            REMOVED_FROM_STOCK_TAKE(us){
                try {    
                    let stock_taking = this.thisStockTaking
                    if (us) {
                        if (us.left && stock_taking) {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"REMOVED FROM STOCK TAKING",
                                text:"You have been removed from this stock taking.",
                                btnYes:"LEAVE STOCK TAKE",
                                action:{
                                    code:PAGE_NAME+"=REMOVED-FROM-STOCK-TAKING", 
                                }
                            })
                        }
                    } else {
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVED_FROM_STOCK_TAKE',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
 
            
            SELECT_ITEM(item_key, item_code){
                try { 
                    let company_key = this.SelectedCompany?this.SelectedCompany.key:null
                    let stock_taking_order_key = this.LocalJoinedStockTaking?this.LocalJoinedStockTaking.key:null
                    let companySettings = this.CompanySettings
                    let duplicate_selling_item = companySettings?companySettings.duplicate_selling_item:null
                    let items = this.SearchingItems   
                    if (!items) {
                        this.MBS.actions.dialog({
                            show:true,
                            title:'ERROR',
                            text:"No item list",
                            btnYes:"OKAY"
                        }) 
                    }

                    setTimeout(() => {
                        this.search_item=null 
                    }, 500); 

                    let item_selected = items.find(item=>{
                        return  item_key?item.key == item_key:item.code == item_code
                    })

                    if (!item_selected) {
                        this.MBS.actions.dialog({
                            show:true,
                            title:'ERROR',
                            text:"Item ("+item_code+") not found",
                            btnYes:"OKAY"
                        }) 
                        return 
                    }
                     
                     
                    this.just_selected_item = {
                        ...item_selected, 
                        company_key:company_key,
                        stock_taking_order_key:stock_taking_order_key   
                    }

                    if (item_selected.submitted) {
                        this.just_selected_item.old_quantity = item_selected.new_quantity
                    } 


                    this.dialog_selected_item_quantity = true

                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_SELECTED_ITEM(data){
                try { 
                    this.dialog_selected_item_quantity = false
                    let companySettings = this.CompanySettings
                    let duplicate_selling_item = companySettings?companySettings.duplicate_selling_item:null 
                    let stock_taking = this.thisStockTaking
                    let stock_taking_order_key = stock_taking?stock_taking.key:null 
                    let moment = this.MBS.date.moment
                    let selecting_list_key = moment?moment().valueOf():null
                      
                    
                    if (data) {   
                        if (false) { 
                            //todo, add settings
                             
                        }else{
                            let findStockItem = key=> this.LocalStockTakingItems?this.LocalStockTakingItems.find(it=>{
                                return it.key == key
                            }):null
                            
                            let item_data = null
                            if (Array.isArray(data)) {
                                item_data = []
                                data.forEach(item => {
                                    selecting_list_key = selecting_list_key+1
                                    let stock_item = findStockItem(item.key)
                                    if (stock_item) {
                                        item_data.push({
                                            ...stock_item,
                                            stock_taking_order_key:stock_taking_order_key,
                                            new_quantity:this.toNumber(item.new_quantity),
                                            selecting_list_key:selecting_list_key
                                        })
                                    } else {
                                        item_data.push({
                                            ...item, 
                                            item_not_counted:false,
                                            stock_taking_order_key:stock_taking_order_key,
                                            selecting_list_key:selecting_list_key
                                        })
                                    }
                                    
                                });
                            }else{ 
                                let stock_item = findStockItem(data.key)
                                if (stock_item) { 
                                    item_data = {
                                        ...stock_item,
                                        stock_taking_order_key:stock_taking_order_key,
                                        new_quantity:this.toNumber(data.new_quantity),
                                        selecting_list_key:selecting_list_key
                                    }
                                } else {
                                    item_data = {
                                        ...data,
                                        stock_taking_order_key:stock_taking_order_key,
                                        item_not_counted:false,
                                        selecting_list_key:selecting_list_key
                                    }  
                                }  
                            }

                            console.log(item_data,'item_data.........');


                            
                            //save
                            DB.addItems({
                                data:item_data,
                                name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values, 
                                clear:false
                            }).then(res=>{ 
                                this.MBS.events.$emit("SUBMIT_STOCK_TAKING_ITEMS",PAGE_NAME)
                            }).catch(error=>{
                                this.MBS.events.$emit("SUBMIT_STOCK_TAKING_ITEMS",PAGE_NAME)
                            }) 
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            REMOVE_SELECTED_ITEM(selected_item,index){
                try {
                    let online = this.online 
                    let stock_taking_order_key = this.LocalJoinedStockTaking?this.LocalJoinedStockTaking.key:null  
                    if(!selected_item){return}
                    if (selected_item.uploaded) {
                        if(!online){
                            let mbs_text = this.MBS.text.action_require_internet("Deleting Submitted Items")
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes, 
                                btnNo:mbs_text.btnNo, 
                                action:{
                                    code:PAGE_NAME+"=DELETE-AGAIN",
                                    data:selected_item
                                }
                            })
                            return
                        }
                        let mbs_text = this.MBS.text.item_action("Submitted Item","Delete")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=DELETE-SUBMITTED-ITEM",
                                data:{
                                    ...selected_item,
                                    stock_taking_order_key:stock_taking_order_key
                                }
                            }
                        }) 
                    } else {
                        let mbs_text = this.MBS.text.item_action("Item","Delete")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=DELETE-COUNTED-ITEM",
                                data:{
                                    ...selected_item,
                                    stock_taking_order_key:stock_taking_order_key
                                }
                            }
                        })  

                        // DB.deleteItems({ 
                        //     name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values, 
                        //     key:selected_item.key
                        // }).then(async ress=>{ 
                        //     const res = await DB.getItems({
                        //         name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values
                        //     })  
                        //     const data = res?res.data:null    
                        //     await this.$store.dispatch("add_items",{
                        //         name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values,
                        //         data:null
                        //     })  
                        //     // await this.$store.dispatch("add_items",{
                        //     //     name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values,
                        //     //     data:data
                        //     // })  
                            
                        //     this.MBS.events.$emit("SUBMIT_STOCK_TAKING_ITEMS",PAGE_NAME)
                        // }).catch(error=>{
                        //     this.MBS.events.$emit("SUBMIT_STOCK_TAKING_ITEMS",PAGE_NAME)
                        // }) 
                    }

                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_EXCEL_ITEMS(){
                try {
                    let selected_excel_items = this.selected_excel_items
                    if (selected_excel_items) {
                        this.ADD_SELECTED_ITEM(selected_excel_items)
                        this.selected_excel_items = null
                        this.excel_loading = false
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CANCEL_EXCEL(){
                try {
                    this.selected_excel_items = null
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            EXPORT_EXCEL(){
                try { 
                    let stock_taking = this.thisStockTaking
                    let outlet_code = stock_taking?stock_taking.outlet_code:null
                    let outlet_name = stock_taking?stock_taking.outlet_name:null
                    let items = this.ItemsData
                    let data_items = []
                    if (items) {
                        items.forEach(item => {
                            data_items.push({
                                code:item.code,
                                name:item.name,
                                cost_price:item.cost_price,
                                retail_price:item.retail_price,
                                wholesale_price:item.wholesale_price,
                                quantity:item[outlet_code+'_number_quantity'],
                            })
                            
                        });
                    }
                    this.EXCEL_EXPORTER = {}
                    this.EXCEL_EXPORTER.headers = this.HEADER_ITEMS
                    this.EXCEL_EXPORTER.file_name = this.MBS.actions.TEXT_UP(outlet_name+" (current stock)")
                    this.EXCEL_EXPORTER.sheet_name = DATA.ITEMS.ITEMS.values
                    this.EXCEL_EXPORTER.data_items = data_items
                    console.log(stock_taking,"stock_taking......");
                    console.log(this.EXCEL_EXPORTER,'this.EXCEL_EXPORTER......');

                    setTimeout(() => {
                        this.MBS.events.$emit("EXPORT_EXCEL") 
                    }, 500);

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXPORT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            IMPORT_EXCEL_FILE(){  
                try { 
                    this.excel_loading = false
                    this.loadingValue = 0
                    this.MBS.events.$emit('SELECT_EXCEL')
                    setTimeout(() => {
                        this.loadingValue = 25 
                    }, 10);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'IMPORT_EXCEL_FILE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            LOADING_FILE(file){
                try {
                    this.excel_loading = true 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            ON_FILE_ERROR(error){
                try {
                    this.excel_loading = false 

                    console.log(error);

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            EXCEL_FILE_SELECTED(file,file_details,res){
                try {
                    this.excel_loading = true
                    this.loadingValue = 50
                    setTimeout(() => {
                        this.loadingValue = 80
                        setTimeout(async() => { 
                            this.loadingValue = 90 
                            this.WB = file
                            this.WB_SHEETS = file?file.SheetNames:null
                            this.WB_PROPS = file?file.Props:null
                            this.WB_FILE_DETAILS = file_details
                            this.JSON_SHEETS = res?res.data:null 
                            let sheets = this.JSON_SHEETS
                            console.log(sheets,'sheets.......');
                            let itemsJS = null
                            if (sheets) {
                                for (const element of sheets) {
                                    if (element.name == DATA.ITEMS.ITEMS.values ) {
                                        let res = await this.MBS.actions.CREATE_ARRAY_DATA({
                                            data:element.json,
                                            headers:this.HEADER_ITEMS,
                                            attach_items:this.ItemsData,
                                            attach_value:'code',
                                            // attach_value:'key',
                                            add_number:false,
                                            add_created_date:false,
                                            add_uid:false,
                                        })  
                                        console.log(res,'res.....');
                                        itemsJS = res?res.only_attached_data:null
                                    } 
                                } 
                                this.excel_loading = false
                            }   
                            this.loadingValue = 100
                            this.excel_loading = false
                            console.log(itemsJS,'itemsJS........'); 
                            this.selected_excel_items = itemsJS  
                        }, 500);
                    }, 1000);
                } catch (error) {
                    this.excel_loading = false
                    this.MBS.actions.error({
                        error:error,
                        from:'EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },
            NO_EXCEL_FILE_SELECTED(file){
                try {
                    this.excel_loading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NO_EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },  
            
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=START-STOCK-TAKING') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    // return
                                    this.$store.dispatch("fi_add",{
                                        action:this.VALUE_ITEM,
                                        path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders,true),
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false
                                        this.dialog_stack_details = false 
                                        this.MBS.actions.progressDialog() 
                                        this.input = {}
                                        let key = res?res.data?res.data.key:null:null 
                                        
                                        let mbs_text = this.MBS.text.item_action_success("Stock Taking","Starting")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{ 
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Stock Taking","Starting")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=JOIN-STOCK-TAKING') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    if (this.LocalJoinedStockTaking) {
                                        DB.deleteItems({
                                            name:DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values 
                                        }) 
                                    }
                                    let stock_taking_order_key = action.data?action.data.stock_taking_order_key:null
                                    let uid = action.data?action.data.uid:null 
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    })  
                                    this.$store.dispatch("fi_update",{
                                        action:this.VALUE_ITEM,
                                        path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders+stock_taking_order_key+"/users/"+uid,true),
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{ 
                                        let key = res?res.data?res.data.key:null:null 
                                        return DB.addItems({
                                            name:DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values,
                                            data:{
                                                key:stock_taking_order_key,
                                                ...action.data
                                            } 
                                        })  
                                    })
                                    .then(res=>{  
                                        this.MBS.events.$emit("SUBMIT_JOINED_STOCK_TAKING",PAGE_NAME)
                                        this.inputLoading = false
                                        this.dialog_stack_details = false 
                                        this.input = {} 
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_success("Stock Taking","Joining")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{  
                                        this.MBS.actions.error({
                                            error:error,
                                            from:'JOIN-STOCK-TAKING',
                                            page:PAGE_NAME, 
                                        })  
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Stock Taking","Joining")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes, 
                                        })  
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=LEAVE-STOCK-TAKING') {
                            if (action.YES) {
                                setTimeout(() => {
                                    let stock_taking_order_key = action.data?action.data.stock_taking_order_key:null
                                    let uid = action.data?action.data.uid:null 
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    })  
                                    this.$store.dispatch("fi_update",{
                                        action:this.VALUE_ITEM,
                                        path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders+stock_taking_order_key+"/users/"+uid,true),
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false
                                        this.dialog_stack_details = false 
                                        this.MBS.actions.progressDialog() 
                                        this.input = {}
                                        let key = res?res.data?res.data.key:null:null 
                                        DB.deleteItems({
                                            name:DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values 
                                        }).then(res=>{  
                                            this.MBS.events.$emit("SUBMIT_JOINED_STOCK_TAKING",PAGE_NAME)
                                            this.inputLoading = false
                                            this.MBS.actions.progressDialog() 
                                            let mbs_text = this.MBS.text.item_action_success("Stock Taking","Leaving")
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes,
                                                btnNo:mbs_text.btnNo, 
                                            })
                                        }).catch(error=>{  
                                            this.MBS.actions.error({
                                                error:error,
                                                from:'JOIN-STOCK-TAKING',
                                                page:PAGE_NAME, 
                                            })  
                                            this.inputLoading = false
                                            this.MBS.actions.progressDialog() 
                                            let mbs_text = this.MBS.text.item_action_fail("Stock Taking","Leaving")
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes, 
                                            })  
                                        })
                                        
                                        
                                    }).catch(error=>{ 
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Stock Taking","Joining")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=REMOVED-FROM-STOCK-TAKING') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:true,
                                        text:"Leaving...."
                                    }) 
                                    DB.deleteItems({
                                        name:DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values 
                                    })
                                    .then(res=>{  
                                        this.MBS.events.$emit("SUBMIT_JOINED_STOCK_TAKING",PAGE_NAME)
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_success("Stock Taking","Leaving")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                    .catch(error=>{  
                                        this.MBS.actions.error({
                                            error:error,
                                            from:'REMOVED-FROM-STOCK-TAKING',
                                            page:PAGE_NAME, 
                                        })  
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Stock Taking","Leaving")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes, 
                                        })  
                                    })
                                        
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=DELETE-SUBMITTED-ITEM') {
                            if (action.YES) {
                                setTimeout(() => {
                                    let item_key = action.data?action.data.key:null
                                    let stock_taking_order_key = action.data?action.data.stock_taking_order_key:null 
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"deleting..."
                                    })   
                                   
                                    this.$store.dispatch("fi_delete",{
                                        action:this.VALUE_ITEM,
                                        path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders+stock_taking_order_key+"/items/"+item_key,true),
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false
                                        this.dialog_stack_details = false 
                                        this.MBS.actions.progressDialog() 
                                        this.input = {} 
                                        
                                    }).catch(error=>{ 
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Stock Taking","Joining")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=DELETE-COUNTED-ITEM') {
                            if (action.YES) {
                                setTimeout(() => {
                                    let item_key = action.data?action.data.key:null
                                    let stock_taking_order_key = action.data?action.data.stock_taking_order_key:null 
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"deleting..."
                                    })   
                                   
                                    DB.deleteItems({ 
                                        name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values, 
                                        key:item_key
                                    }).then(res=>{    
                                        return this.$store.dispatch("add_items",{
                                            name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values,
                                            data:null
                                        })   
                                    }).then(res=>{ 
                                        this.MBS.events.$emit("SUBMIT_STOCK_TAKING_ITEMS",PAGE_NAME)
                                        this.inputLoading = false
                                        this.dialog_stack_details = false 
                                        this.MBS.actions.progressDialog() 
                                    }).catch(error=>{
                                        this.MBS.events.$emit("SUBMIT_STOCK_TAKING_ITEMS",PAGE_NAME)
                                        this.inputLoading = false 
                                        this.MBS.actions.progressDialog() 
                                    }) 

                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=DELETE-AGAIN') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.$store.dispatch("connectUser")
                                    this.REMOVE_SELECTED_ITEM(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: { 
            us:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            search_item(value){ 
                if (value) {
                    this.SELECT_ITEM(value) 
                }
            }, 
            item_sorting(value){ 
                this.item_sorting_loading = true
                setTimeout(() => {
                    this.item_sorting_loading = false
                }, 100);
            }, 
            thisUser(us){ 
                this.REMOVED_FROM_STOCK_TAKE(us)
            }, 
        },
    }
</script>
 
