<template>
  <v-layout fill-height align-center class=""  >     
    <v-layout v-if="false" fill-height> 
        <mbs-page-spinner :show="true" />  
    </v-layout>   

    <div v-else grid-list-lg 
        class="py-0 mx-2  not-full-w" 
        >   

        <!-- //SALE -->
        <v-layout column v-if="!pay_order"  class=" ma-0 pa-0 not-full-w"> 
            <!-- //HEADERS -->
            <div v-if="false">
                <v-card class="pa-3 font-weight-bol d grey darken-1 white--text mb-2" flat>
                    SELECT TILL
                </v-card>
            </div>
            <div class="mb-1" v-else-if="!click_mode">
                
                <!-- SEARCH --> 
                <mbs-search-select-items  v-if="!click_mode" 
                    v-model="search_select"                                                                                                                            
                    :bar_type="'warehouse'"
                    :show_toolbar="true"
                    :show_table="false"
                    @imported_items="ON_SEARCH_AND_SELECT"
                    @searched_item_key="ADD_ITEM"
                    @selected="ON_SEARCH_AND_SELECT"
                />

                <v-toolbar height="52" v-if="false" 
                    @click="ON_SEARCH(warehouse_model)" rounded="" dark flat 
                    :color="WarehouseSession?'primary':'grey darken-1'" 
                    class="pa-0">
                    <v-layout justify-center align-center>
                        <v-autocomplete  class="mr-2"
                            v-model="search_item"
                            :disabled="inputLoading || !WarehouseSession|| !warehouse_model"
                            :items="ItemSorted" dense
                            append-icon="search"
                            outlined :rounded="false"
                            :label="warehouse_model?'Search for Item':'You Must Select Warehouse Action First'"
                            color="blue-grey lighten-2" 
                            item-text="name"
                            item-value="key" 
                            :filter="(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                        if(!a||!b){return false}
                                        a = ''+a; b = ''+b
                                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                    } 
                                    let first = index(itemText,queryText)
                                    let second = item?index(item.code,queryText):false
                                    let third = item?index(item.brand_name,queryText):false
                                    let forth = item?index(item.category_name,queryText):false
                                    if(!item){return first}  
                                    return first || second || third || forth 
                            }"
                            >
                            <template v-slot:selection="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:item="data">
                                <template v-if="(typeof data.item !=='object')" >
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                <v-list-item-avatar tile color="grey">
                                    <img :src="data.item.image" v-if="data.item.image">
                                    <v-icon v-else>local_offer</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                    <v-list-item-subtitle v-html="data.item.category_name"></v-list-item-subtitle>
                                    <v-list-item-subtitle v-html="data.item.brand_name"></v-list-item-subtitle>
                                </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>  

                        <v-menu  
                            v-model="menu_warehouse_mode"
                            :close-on-content-click="true" 
                            offset-y  offset-overflow
                            :nudge-width="90" rounded="xl"
                            >
                            <template v-slot:activator="{ on, attrs }">  
                                <v-btn  height="38"  v-on="on" class=" m-1"
                                    ref="btn_selling_mode"
                                    :disabled="!WarehouseSession?true:false"  
                                     color="secondary">
                                    <!-- {{warehouse_model?warehouse_model.name:'Select  Action'}} -->
                                    <span v-if="warehouse_model"> <v-icon>{{warehouse_model.icon}}</v-icon> {{ warehouse_model.name }}</span>
                                    <span v-else><v-icon>mdi-select</v-icon> Select Action</span>
                                    <v-icon class="ml-2">arrow_drop_down</v-icon>
                                </v-btn> 
                            </template> 
    
                            <v-card  v-if="ud"  
                                color="lighten-1" class="">  
                                <v-card-title class="secondary white--text py-" dark>
                                    Select Warehouse Action 
                                </v-card-title> 
                                <v-list dense> 
                                    <div v-for="(model,index) in WarehouseModes" :key="index">
                                        <v-list-item 
                                            :class="model == menu_warehouse_mode?'secondary--text':''" 
                                            @click="warehouse_model=model">
                                            <v-list-item-action class="ma-3 ml-1">
                                                <v-icon>{{model.icon}}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                {{model.name}}
                                            </v-list-item-content>
                                        </v-list-item> 
                                    </div>
                                </v-list>  
                            </v-card>  
                        </v-menu>  

                        <v-menu  
                            v-model="menu_import_excel"
                            :close-on-content-click="true" 
                            offset-y  offset-overflow
                            :nudge-width="90" rounded="xl" >
                            <template v-slot:activator="{ on, attrs }">  
                                <v-btn  v-on="on" class=" ml-2" :loading="excel_loading"
                                    ref="btn_selling_mode" :disabled="!WarehouseSession?true:false"  
                                    color="secondary">
                                    <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                                        {{selling_model?selling_model:'Select'}}
                                    <v-icon class="ml-2">arrow_drop_down</v-icon>
                                </v-btn> 
                            </template> 

                            <v-card  v-if="ud"  
                                color="lighten-1" class="">  
                                <v-card-title class="secondary white--text py-" dark>
                                    Select 
                                </v-card-title> 
                                <v-list dense> 
                                    <div v-for="(model,index) in menuImportExcel" :key="index">
                                        <v-list-item 
                                            :class="model == selling_model?'secondary--text':''" 
                                            @click="SELECT_ACTION(model)">
                                            <v-list-item-action class="ma-2">
                                                <v-icon>{{model.icon}}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                {{model.name}}
                                            </v-list-item-content>
                                        </v-list-item> 
                                    </div>
                                </v-list>  
                            </v-card>  
                        </v-menu>  

                    </v-layout>
                </v-toolbar>  
            </div>

            <!-- //LIST -->
            <v-layout   class="ma-0 " >
                <!-- //--------------------------[ACTION SIDE]--------------------- -->
                <!-- CLICK MODE --> 
                <v-col v-if="click_mode && WarehouseSession" cols="12" sm="6"   class="ma-0 py-0 pl-0 pr-1">
                    <mbs-click-select-items
                        @click_mode="click_mode=!click_mode"
                        @item_action="ITEM_ACTION"
                        @select_item="value=>value?ADD_ITEM(value.key):''"
                        :allow_changing_department="true"
                        :action_items="AllActionItems"/>
                </v-col>

                <!-- SEARCH MODE --> 
                <v-card v-else flat color="grey lighten-2" :height="vsh-190" class="py- mr-1">
                    <!-- <v-card :height="'100%'" flat color="grey lighten-2" class="mbs-scroll-y-hide ">   -->
                    <v-card :height="'100%'" flat outlined class="mbs-scroll-y ">  
                        <mbs-actions-field  class="my-1" 
                            :items="ActionItems"
                            :data="WarehouseSession"
                            @ITEM_ACTION="ITEM_ACTION"
                            />    
                    </v-card>
                </v-card>  
    
                <!-- //----------------------[ITEM SIDE]------------------------ -->
                <!-- //RIGHT SIDE -->
                <v-col cols="12" :sm="click_mode&& WarehouseSession?'6':'7'" :md="click_mode&& WarehouseSession?'6':'8'" class="ma-0 pa-0">
                    <v-card :height="click_mode?vsh-140:vsh-190" class="" min-height="300" outlined>
                        <!-- SEARCH IF CLICK -->
                        <v-row class="ma-0" v-if="click_mode">
                            <v-col cols="12" sm="12" md="12" class="ma-0 py-0 pl-0 pr-0" >
                                <div :class="vs.smAndDown?'':''">
                                    <v-toolbar 
                                        @click="ON_SEARCH(warehouse_model)" rounded="" dark flat 
                                        :color="WarehouseSession?'primary':'grey darken-1'" 
                                         
                                        class="ma-">
                                        <v-autocomplete  class="mr-2"
                                            v-model="search_item"
                                            :disabled="inputLoading || !WarehouseSession|| !warehouse_model"
                                            :items="ItemSorted" dense
                                            append-icon="search"
                                            outlined :rounded="false"
                                            :label="warehouse_model?'Search for Item':'You Must Select Warehouse Action First'"
                                            color="blue-grey lighten-2" 
                                            item-text="name"
                                            item-value="key" 
                                            :filter="(item, queryText, itemText) => { 
                                                let index = (a,b)=>{
                                                        if(!a||!b){return false}
                                                        a = ''+a; b = ''+b
                                                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                    } 
                                                    let first = index(itemText,queryText)
                                                    let second = item?index(item.code,queryText):false
                                                    let third = item?index(item.brand_name,queryText):false
                                                    let forth = item?index(item.category_name,queryText):false
                                                    if(!item){return first}  
                                                    return first || second || third || forth 
                                            }"
                                            >
                                            <template v-slot:selection="data">
                                                {{ data.item.name }}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !=='object')" >
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                <v-list-item-avatar tile color="grey">
                                                    <img :src="data.item.image" v-if="data.item.image">
                                                    <v-icon v-else>local_offer</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-html="data.item.category_name"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-html="data.item.brand_name"></v-list-item-subtitle>
                                                </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>  
    
                                       
                                        <v-menu  
                                            v-model="menu_warehouse_mode"
                                            :close-on-content-click="true" 
                                            offset-y  offset-overflow
                                            :nudge-width="90" rounded="xl"
                                            >
                                            <template v-slot:activator="{ on, attrs }">  
                                                <v-btn  height="38"  v-on="on" class=" m-1"
                                                    ref="btn_selling_mode"
                                                    :disabled="!WarehouseSession?true:false"  
                                                    color="secondary">
                                                    <!-- {{warehouse_model?warehouse_model.name:'Select  Action'}} -->
                                                    <span v-if="warehouse_model"> 
                                                        <v-icon>{{warehouse_model.icon}}</v-icon> 
                                                        <!-- {{ warehouse_model.name }} -->
                                                    </span>
                                                    <span v-else><v-icon>mdi-select</v-icon> Select Action</span>
                                                    <v-icon class="ml-2">arrow_drop_down</v-icon>
                                                </v-btn> 
                                            </template> 
                    
                                            <v-card  v-if="ud"  
                                                color="lighten-1" class="">  
                                                <v-card-title class="secondary white--text py-" dark>
                                                    Select Warehouse Action 
                                                </v-card-title> 
                                                <v-list dense> 
                                                    <div v-for="(model,index) in WarehouseModes" :key="index">
                                                        <v-list-item 
                                                            :class="model == menu_warehouse_mode?'secondary--text':''" 
                                                            @click="warehouse_model=model">
                                                            <v-list-item-action class="ma-3 ml-1">
                                                                <v-icon>{{model.icon}}</v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                {{model.name}}
                                                            </v-list-item-content>
                                                        </v-list-item> 
                                                    </div>
                                                </v-list>  
                                            </v-card>  
                                        </v-menu>  

                                        <v-menu  
                                            v-model="menu_import_excel"
                                            :close-on-content-click="true" 
                                            offset-y  offset-overflow
                                            :nudge-width="90" rounded="xl" >
                                            <template v-slot:activator="{ on, attrs }">  
                                                <v-btn  v-on="on" class=" ml-2" :loading="excel_loading"
                                                    ref="btn_selling_mode" :disabled="!WarehouseSession?true:false"  
                                                    color="secondary">
                                                    <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                                                        <!-- {{selling_model?selling_model:'Select'}} -->
                                                    <v-icon class="ml-2">arrow_drop_down</v-icon>
                                                </v-btn> 
                                            </template> 

                                            <v-card  v-if="ud"  
                                                color="lighten-1" class="">  
                                                <v-card-title class="secondary white--text py-" dark>
                                                    Select 
                                                </v-card-title> 
                                                <v-list dense> 
                                                    <div v-for="(model,index) in menuImportExcel" :key="index">
                                                        <v-list-item 
                                                            :class="model == selling_model?'secondary--text':''" 
                                                            @click="SELECT_ACTION(model)">
                                                            <v-list-item-action class="ma-2">
                                                                <v-icon>{{model.icon}}</v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                {{model.name}}
                                                            </v-list-item-content>
                                                        </v-list-item> 
                                                    </div>
                                                </v-list>  
                                            </v-card>  
                                        </v-menu>   

                                    </v-toolbar> 
                                </div> 
                            </v-col>  
                        </v-row> 
                        
                        <!-- //SELECTED ITEMS -->
                        <v-card :height="vsh-205" 
                                color="transparent " flat rounded="" id="printJS-form" >
                            <!-- //Sale -->
                            <v-layout fill-height column class="ma-0" v-if="WarehouseSession">   
                                <!-- //SELECTED ITEMS -->   
                                <mbs-warehouse-selected-items

                                    @checked_items="(value)=>checked_items=value"
                                    @item_multiple_select="(value)=>item_multiple_select=value"
                                    :height="vsh-310" 
                                    />  
                                <v-spacer></v-spacer>
                                <v-divider></v-divider>  
                                <!-- //PAY BUTTON -->
                                <input ref="btn_submit_order" type="submit" v-show="false">
                                <v-row 
                                    class="ma-0 pl-4 pb-2" 
                                    :class="vs.mdAndDown?'pr-4':'pr-1'" >  
                                    <v-layout v-if="CompanySettings?CompanySettings.allow_total_discount:false"
                                        class=" ml-0 mt-1 mb-0" :class="vs.mdAndDown?'mr-0':'mr-1'"  align-center justify-center> 
                                        <v-col class="pa-0 ma-0"> 
                                            <v-text-field dense 
                                                filled outlined clearable type="number"
                                                label="Total Discount Amount"
                                                v-model="input_discount_amount" 
                                                outline 
                                            ></v-text-field> 
                                        </v-col>
                                        <v-col   sm="6" class="pa-0"> 
                                            <v-text-field dense type="number"
                                                :class="vs.mdAndDown?'ml-1':'ml-1'"
                                                filled outlined  clearable
                                                label="Total Discount %" 
                                                v-model="input_discount_percentage"
                                                outline 
                                            ></v-text-field> 
                                        </v-col> 
                                    </v-layout>
                                    <v-col sm="12"  v-else class="ma-0 pa-0 b" > 
                                    </v-col >
                                    <v-col 
                                        :lg="'12'" 
                                        md="12"  
                                        cols="12"   
                                        class="pa-0 ma-0" > 
                                        <v-layout row wrap v-if="item_multiple_select">
                                            <v-flex class="py-0 pr-1 " 
                                                :class="warehouse_model?warehouse_model.code==3?'pl-1':'pl-0':'pl-0'"> 
                                                <v-card @click="CONFIRM_REMOVE_ITEM(checked_items)"  
                                                    height="50" min-height="40" width="100%"  
                                                    :disabled="MBS.actions.SIZE(SELECTED_ITEMS)<1 ||  inputLoading" 
                                                    :flat="MBS.actions.SIZE(SELECTED_ITEMS)<1" 
                                                    hover  
                                                    class="v-btn" 
                                                    :class="vs.smAndDown?'':''"
                                                    :color="'primary'" dark > 
                                                    <v-layout class="ma-0 px-4" fill-height align-center justify-center>
                                                        <div class="font-weight-bold not-f7">Remove</div>
                                                        <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(checked_items)}} Selected Items</div>
                                                        <v-spacer></v-spacer>
                                                        <div class="font-weight-bold not-f7">MK {{MBS.actions.money(CALCULATE_TOTAL_PRICE(checked_items))}}</div> 
                                                    </v-layout>  
                                                </v-card> 
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-else row wrap class="ma-0" fill-height align-cente > 
                                            <v-menu  v-if="warehouse_model?warehouse_model.code==3:false"
                                                v-model="menu_selling_mode"
                                                :close-on-content-click="true" 
                                                offset-y  offset-overflow
                                                :nudge-width="90" rounded="xl"
                                                >
                                                <template v-slot:activator="{ on, attrs }">  
                                                    <v-btn  height="50"  v-on="on" class=""
                                                        ref="btn_selling_mode"
                                                        :disabled="!WarehouseSession?true:false"  
                                                        color="secondary">
                                                        {{selling_model?selling_model:'Select'}}
                                                        <v-icon class="ml-2">arrow_drop_down</v-icon>
                                                    </v-btn> 
                                                </template> 
                        
                                                <v-card  v-if="ud"  
                                                    color="lighten-1" class="">  
                                                    <v-card-title class="secondary white--text py-" dark>
                                                        Select Selling Mode 
                                                    </v-card-title> 
                                                    <v-list dense> 
                                                        <div v-for="(model,index) in sellingModels" :key="index">
                                                            <v-list-item :class="model == selling_model?'secondary--text':''" @click="selling_model=model">{{model}}</v-list-item> 
                                                        </div>
                                                    </v-list>  
                                                </v-card>  
                                            </v-menu>  
                                            <v-flex class="py-0 pr-1 " 
                                                :class="warehouse_model?warehouse_model.code==3?'pl-1':'pl-0':'pl-0'"> 
                                                <v-card @click="warehouse_model?CREATE_ORDER():ON_SEARCH(warehouse_model)"  
                                                    height="50" min-height="40" width="100%"  
                                                    :disabled="MBS.actions.SIZE(SELECTED_ITEMS)<1 ||  inputLoading" 
                                                    :flat="MBS.actions.SIZE(SELECTED_ITEMS)<1" 
                                                    hover  
                                                    class="v-btn" 
                                                    :class="vs.smAndDown?'':''"
                                                    :color="MBS.actions.SIZE(SELECTED_ITEMS)<1 || !warehouse_model?'grey darken-1':'primary'" dark >
                                                    <v-layout class="ma-0 px-4 font-weight-bold b2--text not-f5" fill-height align-center justify-center v-if="!warehouse_model">
                                                        Select Warehouse Action first
                                                    </v-layout>
                                                    <v-layout class="ma-0 px-4" fill-height align-center justify-center v-else-if="!inputLoading">
                                                        <div class="font-weight-bold not-f7">Continue</div>
                                                        <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(SELECTED_ITEMS)}} items</div>
                                                        <v-spacer></v-spacer>
                                                        <div class="font-weight-bold not-f7">MK {{MBS.actions.money(TotalDiscountedPrice)}}</div> 
                                                    </v-layout> 
                                                    <v-layout v-else class="ma-0 pa-2" justify-center>
                                                        <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                                    </v-layout>
                                                </v-card> 
                                            </v-flex>
                                        </v-layout>
                                    </v-col> 
                                </v-row>
                            </v-layout> 
        
                            <!-- //Open register -->
                           <!-- <form @submit.prevent="OPEN_REGISTER()" v-else> -->
                           <form @submit.prevent="ON_OPENING_WAREHOUSE(input)" v-else>
                               <v-card :height="vsh-220"  flat color="transparent" outlined>
                                    <v-layout fill-height column class="ma-0 "  align-center> 
        
                                        <br>
                                        <v-icon size="100">mdi-warehouse</v-icon>
                                        <h2 class="grey--text text--darken-1  ">{{MBS.actions.TEXT_UP('Warehouse')}}</h2> 
                                        <v-card min-height="100" class="ma-2 pa-2  mbs-scroll-y" outlined width="97%">
                                            <mbs-input-fields v-if="show_input"
                                                v-model="input" 
                                                :field_items="DATA.FIELDS.open_warehouse"
                                                :select_items="SelectInputFieldsItems" 
                                                />   
                                            <mbs-page-spinner v-else
                                                :height="250" />  
                                        </v-card>  
                                        <v-spacer></v-spacer>
                                        <v-divider></v-divider> 
                                        <!-- <v-btn 
                                            type="submit" 
                                            class="my-2" 
                                            color="primary" 
                                            large>
                                            <div class="font-weight-bold not-f7 px-5">Open Register</div> 
                                        </v-btn>  -->
                                        <v-btn 
                                            type="submit" 
                                            class="my-2 mbs-z-index2" height="50"
                                            color="primary" 
                                            large>
                                            <v-layout justify-center align-center class="font-weight-bold not-f7 px-5">
                                                <v-icon size="35" class="mr-2">mdi-garage-open-variant</v-icon>
                                                OPEN WAREHOUSE
                                            </v-layout> 
                                        </v-btn>  
                                    </v-layout>  
                               </v-card>
                           </form>
                        </v-card> 
                    </v-card>
                </v-col>

                <!-- ACTIONS -->
                <v-col cols="12" sm="5" md="4" class="ma-0 py-0 pl-0" v-if="vs.xs">
                    <v-card outlined>
                        <v-card height="100%" :max-height="vsh-220" flat color="transparent" class="mbs-scroll-y">  
                            <mbs-actions-field  
                                :disabled_types="ActionTypesDisabled"
                                :items="ActionItems"
                                @ITEM_ACTION="ITEM_ACTION"/>   
                        </v-card>  
                    </v-card>
                </v-col>
            </v-layout> 
        </v-layout>

        <!-- //Pay -->
        <v-layout row wrap v-else class="mx-2 mt-2">
            <v-flex xs12 sm6 md6>
                <v-card height="100%"  flat color="transparent" >
                    <v-layout fill-height column class="ma-0">
                        <v-hover v-slot="{ hover }">
                            <v-card style="--px:-18px;" class="mbs-pull-x" 
                                flat color="transparent" @click="pay_order = null">
                                <v-layout align-center>
                                    <v-btn icon><v-icon :color="hover?'secondary':''">mdi-arrow-left-bold </v-icon></v-btn>
                                    <div class="font-weight-bold not-f8 grey--text text--darken-2 ml-"> 
                                        <span >Warehouse</span>
                                    </div> 
                                </v-layout> 
                            </v-card>   
                        </v-hover>
                        <v-divider v-if="false"></v-divider>
                        
                        <v-card  v-if="SELECTED_ITEMS" :height="'100%'" color="transparent" class="  pl- pr-4 mt-2" flat target="">
                            
                                <v-simple-table class="b1 ml-3" :height="vsh-340" fixed-header >
                                    <template v-slot:default>
                                        <thead>
                                            <tr class="font-weight-bold">
                                                <th class=" text-left">
                                                    ITEM
                                                </th>
                                                <th class="text-right">
                                                    <span v-if="warehouse_model?warehouse_model.code == 3:false"> 
                                                        <span v-if="selling_model?selling_model=='Wholesale Price':false">WHOLESALE PRICE</span>
                                                        <span v-else>RETAIL PRICE</span>
                                                    </span>
                                                    <span v-else> 
                                                        COST PRICE
                                                    </span>
                                                </th>
                                                <th class="text-center">
                                                    QTY
                                                </th>
                                                <th class="text-right" v-if="toNumber(ItemDiscounts)">
                                                    DISCOUNT
                                                </th>
                                                <th class="text-right">
                                                    TOTAL
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="item in SELECTED_ITEMS"
                                                :key="item.name" >
                                                <td class="pl- text-">{{ item.name }}</td>
                                                <td class=" text-right">
                                                    <span v-if="warehouse_model?warehouse_model.code == 3:false"> 
                                                        <span v-if="selling_model?selling_model=='Wholesale Price':false">{{ item.wholesale_price }}</span>
                                                        <span v-else>{{ item.retail_price }}</span>
                                                    </span>
                                                    <span v-else> 
                                                        {{ item.cost_price }}
                                                    </span>
                                                </td> 
                                                <td class=" text-center">{{ item.quantity }}</td>
                                                <td class=" text-right" v-if="toNumber(ItemDiscounts)">{{MBS.actions.money(item.discount)}} </td>
                                                <td class=" text-right">{{MBS.actions.money(CALCULATE_PRICE(item))}} </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            

                            <v-list color="transparent" >
                                <template v-if="false">
                                    <v-list-item   
                                        :key="index"  v-for="(item, index) in SELECTED_ITEMS">
                                        <template >  
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                     {{item.name}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                     {{item.selling_model}}: {{item.quantity}} X {{item.sold_price}}
                                                </v-list-item-subtitle> 
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                {{MBS.actions.money(CALCULATE_PRICE(item))}} 
                                            </v-list-item-action>   
                                        </template> 
                                    </v-list-item>
                                </template>

                                <!-- //show discount -->
                                <div v-if="TotalAllDiscount">
                                    <v-divider class="mx-3" ></v-divider>  
                                    <v-list-item  dense   >
                                        <v-list-item-content >
                                            <div>Sub-total</div>
                                            <div>Discount</div> 
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <div>MWK {{MBS.actions.money(toNumber(TotalPrice)+toNumber(ItemDiscounts))}}</div>
                                            <div>MWK {{MBS.actions.money(TotalAllDiscount)}}</div> 
                                        </v-list-item-action>   
                                    </v-list-item> 
                                </div>
                                
                                <v-divider class="mx-3"></v-divider>
                                <v-list-item     class="grey--text text--darken-2" >
                                    <v-list-item-content>
                                        <v-layout class="ma-0 " align-center>
                                            <div class="font-weight-bold not-f6">TOTAL PRICE:</div> 
                                        </v-layout>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-layout class="ma-0" align-center>
                                            <div class="font-weight-bold not-f3 pt-1 ">MWK </div>
                                            <div class="font-weight-bold not-f6">{{MBS.actions.money(TotalDiscountedPrice)}}</div>
                                        </v-layout> 
                                    </v-list-item-action>   
                                </v-list-item>
                                <v-divider class="mx-3"></v-divider>
                            </v-list>  
                        </v-card> 
                        <v-layout v-else-if="!SELECTED_ITEMS" fill-height align-center justify-center >
                            No Item
                        </v-layout> 
                    </v-layout> 
                </v-card>
            </v-flex>
            <v-flex xs12 sm6 md6 >
                <v-card height="100%"  rounded="lg" outlined> 
                    <v-card-title >
                        <div class="font-weight-bold not-f8 grey--text text--darken-2">{{warehouse_model.name}}</div>
                        <v-spacer></v-spacer>
                         
                    </v-card-title>
                    <v-card-text>
                        <form @submit.prevent="CONFIRM_SUBMIT_ORDER(pay_order,warehouse_model)"> 
                            <v-card outlined :height="vsh-320" class=" mbs-scroll-y"> 
                                <v-card-text> 
                                    <mbs-input-fields v-if="warehouse_model?warehouse_model.code==1?true:false:false"
                                        v-model="input"
                                        px="1" py="1"
                                        :field_items="DATA.FIELDS.warehouse_receive_stock"
                                        :select_items="SelectInputFieldsItems" 
                                        /> 
                                    <mbs-input-fields v-else-if="warehouse_model?warehouse_model.code==2?true:false:false"
                                        v-model="input"
                                        px="1" py="1"
                                        :field_items="DATA.FIELDS.warehouse_transfer_stock"
                                        :select_items="SelectInputFieldsItems" 
                                        /> 
                                    <mbs-input-fields v-else-if="warehouse_model?warehouse_model.code==3?true:false:false"
                                        v-model="input"
                                        px="1" py="1"
                                        :field_items="DATA.FIELDS.warehouse_send_quotation"
                                        :select_items="SelectInputFieldsItems" 
                                        /> 
                                </v-card-text>
                            </v-card>
                            <v-layout row wrap class="mt-5"> 
                                <v-col cols="12" sm="6" class="px-1">
                                    <v-btn disabled large color="secondary darken-4" width="100%"><v-icon class="mr-2">mdi-send-check</v-icon> Send For Review</v-btn> 
                                </v-col>
                                <v-col cols="12" sm="6" class="px-1" >
                                    <v-btn type="submit"  large color="secondary darken-2" width="100%">
                                        <v-icon class="mr-2">{{warehouse_model.icon}}</v-icon> {{warehouse_model.name}}
                                    </v-btn>
                                </v-col>
                            </v-layout> 
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-flex> 
        </v-layout> 
    </div>  

    <!-- DIALOG -->
    <div v-if="false">
        <!-- START SEELING -->
        <v-dialog
            v-model="dialog_start_selling"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="700px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_start_selling" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >START SELLING</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_start_selling=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <v-layout   justify-center> 
                        <v-icon size="150">$printer-pos-play</v-icon>
                    </v-layout>
                    <form @submit.prevent="CONFIRM_START_SELLING(input.password)"> 
                        <v-layout row wrap class="mx-0 my-6"  > 
                            <v-flex xs12 >
                                <div class="mb-2 ml-">Enter your password to confirm opening this register</div>
                                <v-text-field required prepend-inner-icon="lock"
                                    v-model="input.password"
                                    filled outlined
                                    label="" autofocus clearable
                                    type="password" autocomplete="one-time-code"  
                                    hint="0.00" class="mr-1"  />  
                            </v-flex> 
                            <v-flex xs12   class="my-3">
                                <v-layout row wrap class="ma-0 pr-1">
                                    
                                    <v-btn   @click="dialog_start_selling=false"
                                        :loading="inputLoading" class="mr-2"
                                           color="primary" height="50"  >
                                        <div class="not-f5">CANCEL</div>
                                    </v-btn> 
                                    <v-flex  >
                                        <v-btn   
                                            type="submit"   
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%" >
                                            <div class="not-f5">
                                                <!-- <v-icon class="pr-1 pb-1">mdi-paperclip-plus</v-icon> -->
                                                CONFIRM PASSWORD
                                            </div>
                                        </v-btn>      
                                    </v-flex>    
                                </v-layout>
                            </v-flex> 
                        </v-layout>  
                    </form>
                </v-card-text>  
            </v-card> 
        </v-dialog> 

        <!-- DRAWER BEFORE SEELING -->
        <v-dialog
            v-model="dialog_open_drawer_before_selling"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="700px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_open_drawer_before_selling" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >CASHUP</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_open_drawer_before_selling=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <v-layout   justify-center> 
                        <v-btn :loading="inputLoading" icon class="my-9" @click="OPEN_DRAWER()">
                            <v-icon size="150">mdi-archive-arrow-down-outline</v-icon> 
                        </v-btn>
                    </v-layout>
                    <form @submit.prevent="CONFIRM_OPENING_REGISTER()"> 
                        <v-layout row wrap class="mx-0 my-6"  > 
                            <v-flex xs12 >
                                <div class="mb-2 ml-3">
                                    You must count cash before selling. This helps monitor the flow of cash. 
                                </div>
                                
                            </v-flex> 
                            <v-layout >
                                <v-card outlined >
                                    <v-simple-table  fixed-header   >
                                        <template v-slot:default>
                                            <thead class="mbs-one-line" > 
                                                <tr >
                                                    <th class="text-uppercase primary--text b2">
                                                    </th>
                                                    <th class="text-uppercase black--text center-text b2 " > 
                                                        Notes/Coins (CASH) 
                                                    </th>
                                                    <th class="text-uppercase text-center black--text b2">
                                                        QTY (No.)
                                                    </th>
                                                    <th class="text-uppercase text-center black--text b2 ">
                                                        AMOUNT (MWK)
                                                    </th>  
                                                </tr>  
                                                <br>
                                            </thead>
                                            <tbody class="mt-5">
                                                <tr class="mt-5"
                                                    v-for="(item,index) in currency_list"
                                                    :key="index" >
                                                     
                                                    <td> 
                                                        
                                                    </td>
                                                    <td class="text-right"> 
                                                        {{item.name}}
                                                    </td>
                                                    <td class="text-center pa-0"> 
                                                        <v-text-field   full-width
                                                            :required="false"
                                                            prepend-inner-icon="mdi-pound"
                                                            v-model="item.quantity"
                                                            filled  outlined  v-mask="currencyMask"
                                                            label=""  
                                                            type=""
                                                            hint=""   />  
                                                    </td>
                                                    <td class="pa">
                                                        <v-text-field disabled prepend-inner-icon="mdi-cash-multiple" 
                                                            filled outlined  :value="MBS.actions.money(toNumber(item.value )* toNumber(item.quantity))"
                                                            label=""   
                                                            class="mr-1"  />   
                                                    </td>  
                                                </tr>
                                                <br>
                                                <tr > 
                                                    <td class="b1 darken-1">  
                                                    </td>
                                                    <td class="text-right b1 darken-1"> 
                                                         
                                                    </td>
                                                    <td class="text-center  font-weight-bold b1 darken-1">
                                                        TOTAL AMOUNT
                                                    </td>
                                                    <td class="text-center font-weight-bold b1 darken-2">
                                                         {{ MBS.actions.money(TotalCurrency) }}
                                                    </td>  
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table> 
                                </v-card> 
                            </v-layout>
                            <v-flex xs12   class="my-3">
                                <v-layout row wrap class="ma-0 pr-1">
                                    
                                    <v-btn   @click="dialog_open_drawer_before_selling=false"
                                        :loading="inputLoading" class="mr-2"
                                           color="primary" height="50"  >
                                        <div class="not-f5">CANCEL</div>
                                    </v-btn> 
                                    <v-flex  >
                                        <v-btn   
                                            type="submit"   
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%" >
                                            <div class="not-f5">
                                                <!-- <v-icon class="pr-1 pb-1">mdi-paperclip-plus</v-icon> -->
                                                START SELLING
                                            </div>
                                        </v-btn>      
                                    </v-flex>    
                                </v-layout>
                            </v-flex> 
                        </v-layout>  
                    </form>
                </v-card-text>  
            </v-card> 
        </v-dialog>  

        <!-- OPEN DRAWER -->
        <v-dialog
            v-model="dialog_open_drawer"
            scrollable   
            :persistent="false" :overlay="false"
            max-width="700px"
            transition="dialog-transition" >
            <v-card rounded="lg" v-if="dialog_open_drawer" > 
                <v-card-title class="primary">
                    <div class="font-weight-bold b2--text"> 
                        <span >OPEN DRAWER</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="dialog_open_drawer=false" icon dark><v-icon >close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <br> 
                    <v-layout   justify-center> 
                        <v-icon size="150">mdi-archive-arrow-down-outline</v-icon>
                    </v-layout>
                    <form @submit.prevent="CONFIRM_OPEN_DRAWER(input.password)" > 
                        <v-layout row wrap class="mx-0 my-6"  > 
                            <v-flex xs12 >
                                <div class="mb-2 ml-">Enter your password to confirm opening drawer</div>
                                <v-text-field required prepend-inner-icon="lock"
                                    v-model="input.password"
                                    filled outlined
                                    label="" autofocus clearable
                                    type="password" 
                                    hint="0.00" class="mr-1"  
                                    autocomplete="one-time-code"  
                                    />    
                            </v-flex> 
                            <v-flex xs12   class="my-3">
                                <v-layout row wrap class="ma-0 pr-1">
                                    
                                    <v-btn   @click="dialog_open_drawer=false"
                                        :loading="inputLoading" class="mr-2"
                                           color="primary" height="50"  >
                                        <div class="not-f5">CANCEL</div>
                                    </v-btn> 
                                    <v-flex  >
                                        <v-btn   
                                            type="submit"   
                                            :loading="inputLoading"
                                               color="secondary" height="50" width="100%" >
                                            <div class="not-f5">
                                                <!-- <v-icon class="pr-1 pb-1">mdi-paperclip-plus</v-icon> -->
                                                OPEN CASH DRAWER
                                            </div>
                                        </v-btn>      
                                    </v-flex>    
                                </v-layout>
                            </v-flex> 
                        </v-layout>  
                    </form>
                </v-card-text>  
            </v-card> 
        </v-dialog>   
        <div v-if="printing">
            <mbs-auto-print 
                @finish_print="FINISH_PRINT"
                :show="true"  
                :auto_print="auto_print"
                :auto_printer_name="auto_printer_name"
                :com_printer="com_printer"  
                :data="print_data"
                :type="print_type"
                :printing_action="printing_action"
                 />  
        </div> 
    </div>  
    <div>
        <!--EDIT PRICE-->
        <div v-if="dialog_edit_price">
            <v-dialog 
                v-model="dialog_edit_price"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">EDIT ITEM PRICE</div> 
                        <v-spacer></v-spacer> 
                        <v-btn dark icon class="ml-5" @click="dialog_edit_price=false" ><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <v-card outlined class="ma-4 pa-2" v-if="EditingItem">
                            <v-layout class="ma-0">
                                <div> 
                                    <v-icon class="ma-2">mdi-tag</v-icon>
                                </div>
                                <div>
                                    <div>Barcode (code): {{ EditingItem.code }}</div>
                                    <div>Item Name: {{ EditingItem.name }} </div>
                                    <div>Cost Price: {{ EditingItem.cost_price }}</div>
                                    <div>Retail Price: {{ EditingItem.retail_price }} </div>
                                    <div>Wholesale Price: {{ EditingItem.wholesale_price }}</div>
                                </div>
                            </v-layout>
                        </v-card>
                        <br> 
                        <form @submit.prevent="CONFIRM_EDIT_ITEM_PRICE(input_edit_item)">
                            <div class="ma-5" >  
                                <div> 
                                    <mbs-input-fields  
                                        v-model="input_edit_item"
                                        :field_items="DATA.FIELDS.edit_item_price"
                                        :select_items="SelectInputFieldsItems" 
                                        /> 
                                </div>
                                <div class=" mt-5">Make sure you fill above form before continuing.</div>  
                                <v-layout row wrap class="ma-0 mt-3">
                                    <v-btn @click="dialog_edit_price = false" large color="primary">CANCEL</v-btn>
                                    <v-flex class="pa-0 ml-1" > 
                                        <v-btn width="100%" :loading="inputLoading" type="submit" large color="secondary">UPDATE ITEM PRICE</v-btn>
                                    </v-flex>
                                </v-layout>
                            </div> 
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>   
        </div>
        
        

        <div v-if="ADDING_ITEM">
            <mbs-item-manager 
                :show="false" 
                :add_item_action="ADDING_ITEM.add_item_action"
                :name_items="ADDING_ITEM.names"
                :name_item="ADDING_ITEM.name"
                :value_items="ADDING_ITEM.values"
                :value_item="ADDING_ITEM.value"
                :field_items="ADDING_ITEM.fields"   
                :path_item="ADDING_ITEM.path"  
                :select_items="SelectInputFieldsItems"  
                @ADDED="ADDED" 
            /> 
        </div>
    </div>
  </v-layout> 
</template>

<script> 
import DATA from '../../../plugins/DATA'
import DB from '../../../plugins/DB'
import {mapState} from "vuex" 
import { mdiAccountCreditCard } from '@mdi/js';
import { mdiPrinterPosOff  } from '@mdi/js';
import { mdiPrinterPosRemove  } from '@mdi/js';
import { mdiPrinterPosPause   } from '@mdi/js';
import { mdiReceiptTextArrowLeftOutline } from '@mdi/js';
import { mdiReceiptTextArrowRightOutline } from '@mdi/js';

// import { mdiPrinterPosAlert    } from '@mdi/js';
 
let PAGE_NAME = "WAREHOUSE"
    export default { 
        name:PAGE_NAME, 
        data() {
            return {
                click_mode:false, 
                PAGE_SHOW:false, 
                DATA:DATA,
                search_item:'', 
                search_input:null, 
                search_select:null, 
                item_multiple_select:false, 
                currentOrderOptionTab:null,  
                inputLoading:false,
                excel_loading:false,
                input:{},
                input_discount_amount:null,
                input_discount_percentage:null,
                input_currency:{},  
                show_input:true,
                mask_money:"### ### ###", 
                console:console,
                pay:false,
                pay_order:null,
                item_order:null,
                print_svg:null,
                dialog_start_selling:false, 
                dialog_open_drawer_before_selling:false, 
                dialog_selected_card:false, 
                dialog_paid_amount:false, 
                dialog_given_amount:false, 
                dialog_change_item_price:false, 
                dialog_close_register:false, 
                dialog_open_drawer:false, 
                dialog_view_orders:false, 
                dialog_put_on_order_option:false, 
                dialog_edit_price:false, 
                menu_selling_mode:false, 
                menu_warehouse_mode:false, 
                menu_selected_card:false, 
                menu_select_order_option:false, 
                menu_item_order:false, 
                menu_import_excel:false, 
                menuPaymentOptions:false, 
                dialog_z_read:false, 
                dialog_x_read:false, 
                dialog_manage_shift:false, 

                NAME_ITEMS:DATA.ITEMS.CUSTOMERS.names,
                NAME_ITEM:DATA.ITEMS.CUSTOMERS.name,
                VALUE_ITEMS:DATA.ITEMS.CUSTOMERS.values,
                VALUE_ITEM:DATA.ITEMS.CUSTOMERS.value, 
                ADDING_ITEM:null, 

                sellingModels:["Retail Price","Wholesale Price"],   
                WarehouseModes:[
                    {code:1,name:"Receive Stock",values:DATA.ITEMS.STOCK_RECEIVES.value,icon:"mdi-package-variant-plus"},
                    {code:2,name:"Transfer Stock",values:DATA.ITEMS.STOCK_TRANSFERS.value,icon:"mdi-package-variant-minus"},
                    {code:3,name:"Send Quotation",values:DATA.ITEMS.QUOTATIONS.value,icon:mdiReceiptTextArrowRightOutline},
                    // {code:4,name:"Request Quotation",icon:mdiReceiptTextArrowLeftOutline}
                ], 
                menuImportExcel:[
                    {code:1,icon:"mdi-microsoft-excel",name:"Import Excel"},
                    {code:2,icon:"mdi-download",name:"Download Excel Template"},
                ], 
                selling_model:null,
                warehouse_model:null,
                selected_warehouse_mode :null,
                checked_items :null,
                paying_option:null,
                
                printing:false, 
                print_data:null,
                print_type:null,
                com_printer:false, 
                print_title:null,
                print_data:null,
                auto_print:false,
                auto_printer_name:null,
                printing_action:null,

                this_day_shift_key:null,
                this_till_key:null, 

                SELECTED_ITEM_CATEGORY:null, 
                SELECTED_OUTLET_KEY:null,  
                excelSelector:false,
                 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);   
                this.MBS.events.$on('ON_KEY_DOWN', this.ON_KEY_DOWN);   
                this.UPDATE_K_CONNECTOR()
                // this.MBS.events.$on('CLOSE_REGISTER', this.CLOSE_REGISTER);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() { 
            this.MBS.actions.header({
                show:false,
                type:'subToolbar', 
                
            }) 
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500) 
             

            DB.deleteItems({
                name:DATA.ITEMS.DAYS_SHIFTS_SALES.values
            })
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            },  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                SelectedCompany: state=> state.items["SELECTED_COMPANY"], 
                StockTransferOptions: state=> state.app["STOCK_TRANSFER_OPTIONS"], 
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()],  
                LocalWarehouseSessions: state=> state.items[(DATA.ITEMS.LOCAL_WAREHOUSE_SESSIONS.values).toUpperCase()], 
                
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                JoinedItemCategories: state=> state.join[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                JoinedBrands: state=> state.join[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                JoinedSuppliers: state=> state.join[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()],
                JoinedSupplierCategories: state=> state.join[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 
                JoinedManufacturers: state=> state.join[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 
                TaxGroups: state=> state.app[(DATA.ITEMS.TAX_GROUPS.values).toUpperCase()], 
                 
                MoneyAccounts: state=> state.items[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()],
                JoinedMoneyAccounts: state=> state.join[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                DefaultMoneyAccount: state=> state.app[(DATA.ITEMS.DEFAULT_MONEY_ACCOUNTS.values).toUpperCase()], 
                DefaultBillAccount: state=> state.app[(DATA.ITEMS.DEFAULT_BILL_METHODS.values).toUpperCase()], 

                LocalSelectedSalesItems: state=> state.locals[(DATA.ITEMS.LOCAL_SELECTED_WAREHOUSE_ITEMS.values).toUpperCase()],
                JoinedLocalSelectedSalesItems: state=> state.join[(DATA.ITEMS.LOCAL_SELECTED_WAREHOUSE_ITEMS.values).toUpperCase()], 
                 
            }),  
            OutletData(){   
                let items = this.JoinedOutlets?this.JoinedOutlets:this.Outlets  
                return items  
            },
            WarehouseOutlets(){   
                let items = this.OutletData
                if(!items){return null}
                let filtered =  items.filter(item=>item.outlet_type_code == 'warehouse')
                return filtered  
            }, 
            ItemData(){   
                let items = this.JoinedItems?this.JoinedItems:this.Items   
                return items  
            },
            ItemSorted(){   
                let items = this.ItemData
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true))   
                return list  
            },   
            ItemCategoriesData(){   
                let items = this.JoinedItemCategories?this.JoinedItemCategories:this.ItemCategories  
                return items  
            }, 
            ItemCategorySorted(){   
                let items = this.ItemCategoriesData
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true))   
                return list  
            },  
            ItemByCategory(){  
                let category = this.SELECTED_ITEM_CATEGORY 
                let items = this.ItemData
                if(!category || !items){return null}
                let filtered = items.filter(item=>item.category_code==category.code)
                if(!filtered){return null}
                let list = [...filtered]
                list.sort(this.MBS.actions.dynamicSort("name",true))   
                return list  
            }, 

            BrandsData(){   
                let items = this.JoinedBrands?this.JoinedBrands:this.Brands  
                return items  
            }, 
            ManufacturersData(){   
                let items = this.JoinedManufacturers?this.JoinedManufacturers:this.Manufacturers  
                return items  
            }, 
            SuppliersData(){   
                let items = this.JoinedSuppliers?this.JoinedSuppliers:this.Suppliers  
                return items  
            }, 
            SupplierCategoriesData(){   
                let items = this.JoinedSupplierCategories?this.JoinedSupplierCategories:this.SupplierCategories  
                return items  
            }, 
            PaymentMethodsData(){
                let items = []
                let DefaultBillAccount = this.DefaultBillAccount  
                let DefaultPaymentMethod = this.DefaultPaymentMethod 
                let PaymentMethods= this.JoinedPaymentMethods?this.JoinedPaymentMethods:this.PaymentMethods
                if(DefaultBillAccount){
                    items = [...DefaultBillAccount]
                }
                if(DefaultPaymentMethod){
                    items= [
                        ...items,
                        ...DefaultPaymentMethod
                    ]
                } 
                if(PaymentMethods){
                    items= [
                        ...items,
                        ...PaymentMethods
                    ]
                } 
                return items
            }, 
            BillTerms(){
                return this.$store.getters.getBillTerms
            }, 
            SelectInputFieldsItems(){
                let ItemCategories = this.ItemCategoriesData
                let Brands = this.BrandsData
                let Manufacturers = this.ManufacturersData
                let TaxGroups = this.TaxGroups

                let Suppliers = this.SuppliersData
                let SupplierCategories = this.SupplierCategoriesData
                let Outlets = this.OutletData   
                let WarehouseOutlets = this.WarehouseOutlets   
                let PaymentMethods = this.PaymentMethodsData 
                let BillTerms = this.BillTerms 
                let DayShifts = this.UNCLOSED_DayShifts  
                let Customers = this.Customers  
                let CustomerGroups = this.CustomerGroups  
                let ReceivableAccounts = this.ReceivableAccounts  
                let SellingModels = this.sellingModels  
                let StockTransferOptions = this.StockTransferOptions  
                return {
                    ItemCategories: ItemCategories,
                    Brands: Brands,
                    Manufacturers: Manufacturers, 
                    TaxGroups: TaxGroups, 

                    Suppliers:Suppliers,
                    SupplierCategories:SupplierCategories,
                    Outlets:Outlets, 
                    WarehouseOutlets:WarehouseOutlets, 
                    PaymentMethods:PaymentMethods, 
                    BillTerms:BillTerms, 
                    DayShifts:DayShifts, 
                    Customers:Customers,
                    CustomerGroups:CustomerGroups,
                    ReceivableAccounts:ReceivableAccounts,
                    SellingModels:SellingModels,
                    StockTransferOptions:StockTransferOptions,
                }
            },

            LocalSelectedWarehouseItemsData(){
                const Items = this.LocalSelectedSalesItems
                const JoinedItems = this.JoinedLocalSelectedSalesItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            ReversedLocalSelectedWarehouseItemsData(){   
                const items = this.LocalSelectedWarehouseItemsData 
                if(!items) {return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("selecting_list_key",true))
                return list.reverse()
            },
            SELECTED_ITEMS(){
                let settings = this.CompanySettings
                let selling_list_descend = settings?settings.selling_list_descend:null
                let items = this.LocalSelectedWarehouseItemsData 
                let reversed = this.ReversedLocalSelectedWarehouseItemsData  
                return selling_list_descend?reversed:items
            },

            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            WarehouseSession(){   
                let sessions = this.LocalWarehouseSessions
                let company = this.SelectedCompany 
                if(!sessions || !company){return null}
                let local_company = sessions[0]
                let local_company_key = local_company?local_company.company_key:null
                let company_key = company?company.company_key:null
                if (local_company_key && company_key!=local_company_key) {
                    console.log('close........');
                    this.CLOSE_WAREHOUSE()
                }
                console.log(sessions,'sessions....');
                console.log(company,'company....');
                return sessions[0]
            }, 
            AllActionItems(){  
                return [ 
                    {
                        icon:"mdi-gesture-tap-hold",
                        action:"click_mode", 
                        name:"Click Mode",
                        color:"green", 
                        show_if:(data)=>data?true:false
                    },{ 
                        icon:"remove_shopping_cart",
                        action:"clear_sales", 
                        name:"Clear",
                        color:"red", 
                        show_if:(data)=>data?true:false
                    },{
                        icon:"mdi-refresh",
                        action:"refresh_data", 
                        name:"Refresh",
                        color:"",
                        type:"",
                        disable:false

                    },{
                        icon:"mdi-garage-variant-lock",
                        action:"close_warehouse", 
                        name:"Close Warehouse",
                        color:"",
                        type:"",
                        disable:data=>!data?true:false
                    },
                    { 
                        // icon:"mdi-plus-box",
                        icon:"mdi-plus-box",
                        action:"create_item_", 
                        name:"Create / Add",
                        color:"",
                        type:"sales",
                        disable:data=>!data?true:false,
                        menu:{
                            name:"Create / Add",
                            items:[
                                {icon:"mdi-tag-plus",action:"create_item",name:"Create New Item"},
                                {icon:"mdi-group",action:"create_item_kit",name:"New Item Kit / Service"},
                                {icon:"mdi-human-dolly",action:"add_supplier",name:"Add New Supplier"},
                                {icon:"mdi-basket",action:"add_customer",name:"Add New Customer"},
                            ]
                        }
                    },{ 
                        icon:"mdi-table-search",
                        action:"", 
                        name:"Manage",
                        color:"",
                        disable:data=>!data?true:false,
                        menu:{
                            name:"Manage",
                            items:[
                                {icon:"mdi-tag",action:"manage_items",name:"Manage Item"},
                                {icon:"mdi-group",action:"manage_item_kits",name:"Manage Item Kit / Service"},
                                {icon:"mdi-human-dolly",action:"manage_suppliers",name:"Manage Supplier"},
                                {icon:"mdi-basket",action:"manage_customers",name:"Manage Customer"},
                            ]
                        }
                    }, 
                    
                    { 
                        icon:"mdi-package-variant-plus",
                        action:"received_stock", 
                        name:"Received Stock", 
                        color:"", 
                        disable:data=>!data?true:false
                    },
                    { 
                        icon:"mdi-package-variant-minus",
                        action:"transferred_stock", 
                        name:"Transferred Stock", 
                        color:"", 
                        disable:data=>!data?true:false
                    }, { 
                        icon:"mdi-package-variant-remove",
                        action:"returned_stock", 
                        name:"Returned Stock", 
                        color:"", 
                        disable:data=>!data?true:false
                    }, 
                    { 
                        icon:"mdi-tag-faces",
                        action:"adjust_stock", 
                        name:"Adjust Stock", 
                        color:"", 
                        disable:data=>!data?true:false
                    }, 
                    { 
                        icon:"mdi-tag-search",
                        action:"stock_taking", 
                        name:"Stock Taking", 
                        color:"", 
                        disable:data=>!data?true:false
                    },{ 
                        icon:"mdi-tag-heart",
                        action:"expiries", 
                        name:"Expiries", 
                        color:"", 
                        disable:data=>!data?true:false
                    },  
                ]
            },
            ActionItems(){ 
                let actions = this.AllActionItems
                let printer = this.CurrentReceiptPrinter 
                let isPrinter = printer?true:false
                if(!actions){return null}
                let newActions = actions.filter(item=>{
                    // if (isPrinter) {
                    //     return !item.no_fiscal_printer
                    // }
                    return true
                })
                return newActions
            },
            DisabledFields(){
                let order_code = this.order_code  
                if (order_code) {
                    return ['supplier_code','payment_method']
                }
                return null
            }, 
            DisabledCashupFields(){ 
                let settings = this.CompanySettings  
                let edit_opening_cash = settings?settings.edit_opening_cash:null
                if (!edit_opening_cash) {
                    return ['opening_cash','expected_cash']
                }
                return null
            },   

            EditingItem(){ 
                let items = this.ItemData 
                let selected = this.select_to_edit_item  
                let item_key = selected?selected.key:null
                if(!items || !item_key){return null}
                let filtered_item = items.find(item=>item.key == item_key)
                let item_stock_settings = this.MBS.actions.ITEM_STOCK_SETTINGS(filtered_item,null,null,false)
                if (item_stock_settings) {
                    filtered_item = {
                        ...filtered_item,
                        active_settings:item_stock_settings.active_settings,
                        item:item_stock_settings.item
                    }
                } 
                console.log(filtered_item,'item_stock_settings......');  
                return filtered_item
            },
            TotalPrice(){
                try { 
                    if (false) {  
                    } else {
                        let items = this.SELECTED_ITEMS
                        let total_price = 0 
                        if (!items) {return 0}
                        items.forEach(item => {
                            let sold_price = this.toNumber(item.sold_price)
                            let discount = this.toNumber(item.discount)
                            let quantity = this.toNumber(item.quantity)
                            total_price = total_price + (quantity*(sold_price-discount))
                        });  
                        // return total_price
                        return total_price.toFixed(2) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            ItemDiscounts(){
                try { 
                    if (false) {  
                    } else {
                        let items = this.SELECTED_ITEMS
                        let total_price = 0 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let discount = this.toNumber(item.discount)
                            let quantity = this.toNumber(item.quantity)
                            total_price = total_price + (quantity*(discount))
                        });  
                        // return total_price
                        return total_price.toFixed(2) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            TotalDiscount(){
                try { 
                    let amount = this.toNumber(this.input_discount_amount)
                    let percentage = this.toNumber(this.input_discount_percentage)
                    let total = this.toNumber(this.TotalPrice)
                    if (percentage) {
                        return (total*percentage/100)
                    } 
                    return amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },
            TotalAllDiscount(){
                try {  
                    let discount = this.toNumber(this.TotalDiscount)
                    let item_discounts = this.toNumber(this.ItemDiscounts)
                     
                    return item_discounts+discount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },
            TotalDiscountedPrice(){
                try {  
                    let discount = this.toNumber(this.TotalDiscount)
                    let total = this.toNumber(this.TotalPrice)
                     
                    return total-discount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
        },
        methods: {   
            async ITEM_ACTION(action,item){
                try {     
                    if (action == "clear_sales") {
                        let mbs_text = this.MBS.text.item_action("Register Items","Clear")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=CLEAR-SALES"
                            }
                        })
                    }else if (action == "refresh_data") {
                        this.UPDATE_K_CONNECTOR()
                        let mbs_text = this.MBS.text.item_action("DATA","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-DATA", 
                            } 
                        })
                    }else if (action == "close_warehouse") { 
                        let mbs_text = this.MBS.text.item_action("Warehouse","Close")
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            icon:"mdi-garage-variant-lock",
                            title:mbs_text.title,
                            text:mbs_text.text+" Enter your password to confirm closing warehouse.",
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=CLOSE-WAREHOUSE", 
                            } 
                        })
                    }else if (action == "click_mode") {
                        this.click_mode=true
                    }else if (action == "create_item") {
                        this.CREATE_ITEM()
                    }else if (action == "create_item_kit") {
                        this.CREATE_ITEM_KIT()
                    }else if (action == "add_supplier") {
                        this.ADD_SUPPLIER()
                    }else if (action == "manage_items") {
                         let link = "/stock/items/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "manage_item_kit") {
                         let link = "/stock/item-kits/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "manage_suppliers") {
                         let link = "/office/people/suppliers/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "manage_customers") {
                         let link = "/office/people/customers/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "receive_stock") {
                        let link = "/stock/stock-control/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "transferred_stock") {
                        let link = "/stock/stock-control/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "returned_stock") {
                        let link = "/stock/stock-control/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "adjust_stock") {
                         let link = "/stock/stock-control/adjust-stock"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "stock_taking") {
                         let link = "/stock/taking/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else if (action == "expiries") {
                         let link = "/stock/expiries/"
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path) 
                    }else {
                        this.MBS.actions.dialog(null,true,false,"Coming Soon!..","Current not working. Try again later.",null,"OK") 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADDED(res){
                try { 
                    this.RELOAD_DATA()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            SELECT_ACTION(action,item){
                try {
                    console.log(action,111111);
                    if (!action) {
                        
                    }else if (action.code == 1) {
                        this.SELECT_EXCEL() 
                    }else if (action.code == 2) {
                        this.EXPORT_EXCEL() 
                    }else {
                        
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            
            FINISH_PRINT(action,payload){
                try {  
                    let canceled = payload?payload.canceled:null
                    if(!action){return}
                    if (action.code == PAGE_NAME+"=OPEN-CASHUP") {
                        this.OPEN_CASHUP(action.data)
                        if (canceled) {
                            
                        }else{
                             
                        }  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RESET_ALL_DATA(){ 
                try { 
                    Object.assign(this.$data, this.$options.data()) 
                    this.FEEDBACKS(500)  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RESET_ALL_DATA',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async CLEAR_ALL_DATA(){ 
                try { 
                    this.RESET_ALL_DATA()
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLEAR_ALL_DATA',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async UPDATE_K_CONNECTOR(){ 
                return new Promise(async(resolve,reject)=>{
                    try { 
                        let res = await this.MBS.actions.K_CONNECT() 
                        resolve(res)
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'UPDATE_K_CONNECTOR',
                            page:PAGE_NAME, 
                        }) 
                        reject(error)
                    } 
                })
            }, 
            
            
            ADD_CUSTOMER(input){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.CUSTOMERS.name,
                        action:"add_item",
                        input:{name:input}
                    }) 

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_CUSTOMER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CREATE_ITEM(input){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"add_item",
                            input:{name:input}
                        }) 
                        console.log(this.ADDING_ITEM,'create item,,,.......'); 
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CREATE_ITEM_KIT(input){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEM_KITS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items_kit
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEM_KITS.name,
                            action:"add_item",
                            input:{name:input}
                        }) 
                        console.log(this.ADDING_ITEM,'create item,,,.......'); 
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADD_SUPPLIER(input){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.SUPPLIERS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.suppliers,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.suppliers
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Add',
                        action_doing:'Adding'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.SUPPLIERS.name,
                            action:"add_item",
                            input:{name:input}
                        }) 
                        console.log(this.ADDING_ITEM,'create item,,,.......'); 
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_SUPPLIER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            PRINT(order){
                try {   
                    this.print_svg = this.CREATE_RECEIPT(order); 
                    setTimeout(() => {
                        this.printJS('receipt_print',"html" ) 
                        setTimeout(() => {
                            this.print_svg = null
                        }, 500);
                        
                    }, 1);

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'receiptline',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_SEARCH(warehouse_model){
                try {    
                    if (!warehouse_model) { 
                        this.menu_warehouse_mode = true
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SEARCH',
                        page:PAGE_NAME, 
                    }) 
                } 
            },

            EDIT_PRICE(item){
                try {    
                    this.select_to_edit_item = item 
                    this.input_edit_item = {
                        key:item.key,
                        cost_price:item?item.cost_price:0,
                        retail_price:item?item.retail_price:0,
                        wholesale_price:item?item.wholesale_price:0,
                    }
                    this.dialog_edit_price = true 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_PRICE',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_EDIT_ITEM_PRICE(input){
                try { 
                    if (input) {
                        let data = {
                            key:input.key,
                            cost_price : this.toNumber(input.cost_price),
                            retail_price : this.toNumber(input.retail_price),
                            wholesale_price : this.toNumber(input.wholesale_price),
                        }
                        let mbs_text = this.MBS.text.item_action("Pricing","Update")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            action:{
                                code:PAGE_NAME+"=UPDATE-PRICING",
                                data:data
                            }
                        })
                        console.log(data,'input........');
                    }   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_EDIT_ITEM_PRICE',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            SUBMIT_PRICING(data){
                try {  
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true) 
                    if (!data || !path) {
                        console.log(data,'order error');
                    } 
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    this.$store.dispatch("fi_update",{
                        action:DATA.ITEMS.ITEMS.values,
                        data:data,
                        path:path+data.key
                    })
                    .then(res=>{
                        return  this.$store.dispatch('fi_tracker',{
                        company:this.selectedCompany,
                        names:[
                            {data_save:false,on:false,get:false,cash:true,name:DATA.ITEMS.ITEMS.values}, 
                        ]
                        }) 
                    })
                    .then(res=>{
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        this.dialog_edit_price = false   
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_PRICING',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_fail("Pricing","Update")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_PRICING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },

            //SELLING
            ON_OPENING_WAREHOUSE(){
                try {    
                    // this.RESET_ALL_DATA()
                    let input = this.input
                    let outlet_code = input?input.outlet_code:null 
                    let description = input?input.description:null 
 
                    if(!outlet_code) {
                        let mbs_text = this.MBS.text.error_0
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title, 
                            text:mbs_text.text, 
                            btnYes:mbs_text.btnYes, 
                        })
                        this.CLEAR_ALL_DATA()
                    } else { 
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-garage-open-variant",
                            title:"OPEN WAREHOUSE", 
                            text:"Enter your password to confirm opening this warehouse", 
                            btnYes:"CONFIRM PASSWORD", 
                            btnNo:"CANCEL", 
                            action:{
                                code:PAGE_NAME+"=OPEN-WAREHOUSE",
                                data:this.input
                            }
                        })
                        
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_OPENING_WAREHOUSE',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            async OPEN_WAREHOUSE(input){
                try { 
                    input = input?input:this.input
                    let selectedCompany = this.selectedCompany
                    let company_key = selectedCompany?selectedCompany.key:null
                    let us = this.us
                    let uid = us?us.uid:null
                    let outlet_code = input?input.outlet_code:null
                    let description = input?input.description:'' 
                    let moment = this.MBS.date.moment
                    let key = moment?moment().valueOf():null
                    let data = {
                        key:key,
                        outlet_code:outlet_code, 
                        local_created_at:key,
                        uid:uid,
                        company_key:company_key, 
                    }  
                    if(description){
                        data.description = description
                    }  
                    console.log(selectedCompany,'selectedCompany...........');
                    console.log(data,'k...........');
                    this.inputLoading = true
                    if (data) {
                        let res = await DB.addItems({
                            name:DATA.ITEMS.LOCAL_WAREHOUSE_SESSIONS.values,
                            data:data
                        }) 
                        console.log(res,'res...........');
                        this.inputLoading = false
                        this.MBS.events.$emit("SUBMIT_WAREHOUSE_SESSIONS") 
                    }  
                } catch (error) {
                    this.inputLoading = false
                    this.MBS.actions.error({
                        error:error,
                        from:'OPEN_WAREHOUSE',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            async CLOSE_WAREHOUSE(){
                try {  
                    this.inputLoading = true
                    let res = await DB.deleteItems({
                        name:DATA.ITEMS.LOCAL_WAREHOUSE_SESSIONS.values
                    }) 
                    console.log(res,'res...........');
                    this.inputLoading = false
                    this.MBS.events.$emit("SUBMIT_WAREHOUSE_SESSIONS") 
                    
                } catch (error) {
                    this.inputLoading = false
                    this.MBS.actions.error({
                        error:error,
                        from:'OPEN_WAREHOUSE',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            
           
             
            async DISPLAY_TO_VFD(payload){
                let response = {
                    from:'DISPLAY_TO_VFD',
                    page:PAGE_NAME,
                    payload:payload
                }
                return new Promise((resolve,reject)=>{
                    try { 
                        let vfd_display = this.CurrentVFT_Display
                        let text = payload?payload.text:false
                        let text2 = payload?payload.text2:null 
                        if (vfd_display) {
                            let com_port = vfd_display.com_port
                            let baud_rate = vfd_display.baud_rate
                            let url = vfd_display.url
                            let params = {
                                action:"sales",
                                com_port:com_port,
                                baud_rate:baud_rate,
                                url:url,
                                text:text,
                                text2:text2,
                            }   
                            this.MBS.actions.VFD_DISPLAY_TEXT(params)
                            .then(res=>{
                                let data = res?res.data:null 
                                resolve({
                                    success:true, 
                                })
                            }).catch(error=>{
                                let message = error.message  
                                response = {
                                    ...response,
                                    message:message,
                                    error:error
                                }
                                reject(response)
                            }) 
                        } else {  
                            resolve({success:true}) 
                        }  
                    } catch (error) {
                        response = {
                            ...response,
                            error:error
                        }
                        this.MBS.actions.error(response) 
                        reject(response)
                    }  
                }) 
            },
            async RECEIPT_PRINTER(payload){
                let response = {
                    from:'RECEIPT_PRINTER',
                    page:PAGE_NAME,
                    payload:payload
                }
                return new Promise((resolve,reject)=>{
                    try { 
                        let printer = this.CurrentReceiptPrinter
                        let duplicate = payload?payload.duplicate:false
                        let receipt_title = payload?payload.title:null
                        let order = payload?payload.order:null
                        let order_account_type = order?order.account_type:null
                        let joined_order = this.MBS.actions.JOIN_SALE_ORDER(order)
                        let submit_order = payload?payload.submit_order:null 
                        if (printer) {
                            let printer_code = printer.code
                            let printer_name = printer.name
                            let printer_type_code = printer.printer_type_code
                            let paper_size = printer.paper_size
                            let com_port = printer.com_port
                            let baud_rate = printer.baud_rate
                            let url = printer.url
                            let params = {
                                printer:printer,
                                printer_code:printer_code,
                                printer_name:printer_name,
                                printer_type_code:printer_type_code,
                                paper_size:paper_size,

                                action:"sales",
                                SaleOrder:order,
                                duplicate:duplicate,
                                com_port:com_port,
                                baud_rate:baud_rate,
                                url:url,
                            }  
                            if (!submit_order&&!duplicate) {
                                resolve({success:false})
                                return
                            }

                            // fiscal printers
                            if (this.toNumber(printer_type_code) == 1) {
                                this.MBS.actions.FISCAL_PRINT(params)
                                .then(res=>{
                                    let data = res?res.data:null
                                    let allReceiptCount = data?data.allReceiptCount:null
                                    let fiscalReceiptCount = data?data.fiscalReceiptCount:null
                                    let order_key =allReceiptCount?allReceiptCount+"/"+fiscalReceiptCount:null
                                    if (order_key && order) {
                                        order.order_key = order_key
                                    } 
                                    resolve({
                                        success:true,
                                        fiscalReceiptCount:fiscalReceiptCount,
                                        allReceiptCount:allReceiptCount,
                                        order_key:order_key,
                                        order:order,
                                    })
                                }).catch(error=>{
                                    let message = error.message 
                                    console.log(error,'error...ZZZ ');
                                    if (message) {
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:"PRINTING ERROR",
                                            text:message,
                                            btnYes:"OK", 
                                            action:{
                                                // code:PAGE_NAME+"=CLEAR-SALES"
                                            }
                                        }) 
                                    } else {
                                        message = 'Kipcount Connecter is offline. Please make to start the connecter and try again.'
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:"PRINTING ERROR",
                                            text:message,
                                            btnYes:"OK", 
                                            action:{
                                                // code:PAGE_NAME+"=CLEAR-SALES"
                                            }
                                        }) 
                                    }
                                    response = {
                                        ...response,
                                        message:message,
                                        error:error
                                    }
                                    reject(response)
                                })  
                            } else {
                                this.MBS.actions.EPSON_SALE_ORDER_PRINT_REQUEST({
                                    ...params,
                                    SaleOrder:joined_order
                                })
                                .then(res=>{ 
                                    let print_request = res?res.data:null 
                                    // console.log(joined_order,'joined_order....');
                                    // console.log(print_request,'print_request....');
                                    // console.log(order_account_type,'order_account_type....');
                                    // reject()
                                    // return
                                    if (duplicate) {
                                        let title = "DUPLICATE"
                                        if (order_account_type=="on_account") {
                                            title = "DUPLICATE INVOICE"
                                        } else {
                                            title = "DUPLICATE RECEIPT" 
                                        }
                                        return this.MBS.actions.EPSON_PRINT({...print_request,title:receipt_title?receipt_title:title})
                                    }else if(order_account_type=="on_account") {
                                        return this.MBS.actions.EPSON_PRINT({...print_request,title:receipt_title?receipt_title:"INVOICE"})
                                    }else if(order_account_type=="payment_option") {
                                        return this.MBS.actions.EPSON_PRINT({...print_request})
                                    }else {
                                        return this.MBS.actions.EPSON_PRINT({...print_request,open_drawer:true})
                                        
                                    }
                                })
                                .then(res=>{
                                    let data = res?res.data:null
                                    let allReceiptCount = data?data.allReceiptCount:null
                                    let fiscalReceiptCount = data?data.fiscalReceiptCount:null
                                    let order_key =allReceiptCount?allReceiptCount+"/"+fiscalReceiptCount:null
                                    if (order_key && order) {
                                        order.order_key = order_key
                                    } 
                                    resolve({
                                        success:true,
                                        fiscalReceiptCount:fiscalReceiptCount,
                                        allReceiptCount:allReceiptCount,
                                        order_key:order_key,
                                        order:order,
                                    })
                                }).catch(error=>{
                                    let message = error.message 
                                    console.log(error,'error...ZZZ ');
                                    if (message) {
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:"PRINTING ERROR",
                                            text:message,
                                            btnYes:"OK", 
                                            action:{
                                                // code:PAGE_NAME+"=CLEAR-SALES"
                                            }
                                        }) 
                                    } else {
                                        message = 'Kipcount Connecter is offline. Please make to start the connecter and try again.'
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:"PRINTING ERROR",
                                            text:message,
                                            btnYes:"OK", 
                                            action:{
                                                // code:PAGE_NAME+"=CLEAR-SALES"
                                            }
                                        }) 
                                    }
                                    response = {
                                        ...response,
                                        message:message,
                                        error:error
                                    }
                                    reject(response)
                                })  
                                
                            }
                        } else {
                            // reject({success:false}) 
                            if (submit_order) {
                                resolve({success:true,order:order}) 
                            } else {
                                this.printing = false
                                setTimeout(() => {
                                    this.print_data = order
                                    this.print_type = 'sale_order'
                                    this.printing = true  
                                }, 5); 
                                this.com_printer = true 
                                resolve({success:true}) 
                            }
                        } 
                        //upload sales
                        this.MBS.events.$emit("SUBMIT_SALES") 
                    } catch (error) {
                        response = {
                            ...response,
                            error:error
                        }
                        this.MBS.actions.error(response) 
                        reject(response)
                    }  
                }) 
            }, 
            CONNECT_K(){
                return new Promise((resolve,reject)=>{
                    try { 
                        let K_CONNECTOR = this.K_CONNECTOR
                        let settings = this.COMPANY_SETTINGS
                        let Sell_without_printer = settings?settings.Sell_without_printer:null
                        let printer = this.CurrentReceiptPrinter

                        if (K_CONNECTOR||Sell_without_printer) {
                            
                        } else {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"CONNECTOR IS OFF",
                                text:'Kipcount Connecter is offline. Please make to start the K-Connecter and try again.',
                                btnYes:"TRY AGAIN",  
                                btnYes:"CANCEL",  
                                action:{
                                    code:PAGE_NAME+""
                                }
                            })  
                        }

                         
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'TEST_PRINTER',
                            page:PAGE_NAME, 
                        }) 
                        reject({success:false,error:error})
                    }  
                })
            },
            CREATE_RECEIPT(order){
                try {  
                    order = this.MBS.actions.JOIN_SALE_ORDER(order)
                    let receiptline = this.receiptline 
                    let doc = '' 
                    if(!order){

                    }else{
                        let items = order?order.joined_items:null
                        let receipt_header = `

                            ^^^^ 
                            ^^^`+order.outlet_name+`
                            ^^Served By: `+order.teller_name+`
                            -

                            ^^^Receipt Number: "`+order.key+`"
                            ^^Date
                            _^^`+order.local_created_at_+`_

                            - 

                        `
                        let receipt_items=""
                        if (items) {
                            items.forEach(item => {
                                receipt_items = receipt_items+`
                                    ^^^`+item.item_name+` | ^^^mk"`+item.sold_price_+`"
                                `
                            });

                            receipt_items = receipt_items+`
                                ^^^TOTAL (4ITEM) | \`^^^"`+order.total_sold_price_+`"^^
                                -
                            ` 
                        }else{
                            receipt_items="No items"
                        }

                        let receipt_footer=`
                            {code:`+order.key+`; option:code128,4,100,hri}


                            ^^Powered by Mbera Solutions

                            =

                        `
                        doc = receipt_header+receipt_items+receipt_footer
                    }

                    const docs = ` 
                        ^^^^ 
                        ^^^Main Outlet
                        ^^Served By: User name
                        -

                        ^^^Receipt Number: "1000"
                        ^^Date
                        _^^11/1/2022_

                        - 

                        ^^^Items | ^^^mk"99.00"
                        ^^^Items | ^^^mk"99.00"
                        ^^^Items | ^^^mk"99.00"
                        ^^^Items | ^^^mk"99.00"

                        ^^^TOTAL (4ITEM) | \`^^^"199,000.00"^^
                        -


                        {code:1234; option:code128,4,100,hri}


                        ^^Powered by Mbera Solutions

                        =`
                    // printer example
                    const printer = {
                        cpl: 42,
                        encoding: 'multilingual',
                        upsideDown: false,
                        gamma: 1.8,
                        command: 'escpos'
                    };

                    // display example
                    const display = {
                        cpl: 42,
                        encoding: 'multilingual'
                    };
                    let receipt = receiptline.transform(doc, display);
                    return receipt  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'receiptline',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  

            ON_SEARCH_AND_SELECT(items){
                try { 
                    console.log(items,'item.....*****')
                    this.dialog_search_and_select_items = false
                    if(!items){return null}
                    items.forEach(item => {
                        this.ADD_ITEM(item.key,null,item.quantity)
                    });
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SEARCH_AND_SELECT',
                        page:PAGE_NAME, 
                    })  
                }  
            },
            
            ADD_ITEM(item_key, item_code,quantity){
                try { 
                    this.FULLSCREEN()
                    let company_key = this.selectedCompany?this.selectedCompany.key:null
                    const settings = this.CompanySettings 
                    const activate_stock_level = settings?settings.activate_stock_level:null
                    const duplicate_selling_item = settings?settings.duplicate_selling_item:null

                     
                    let retail_price = null
                    let wholesale_price = null
                    let scales = this.Scales  

                    let items = this.ItemData
                    let selling_model = this.selling_model
                    let warehouse_model = this.warehouse_model

                    if (!warehouse_model) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            icon:"mdi-select",
                            title:"SELECT WAREHOUSE ACTION",
                            text:"You must select warehouse action first",
                            btnYes:"Select Action",
                            action:{
                                code:PAGE_NAME+"=SELECT-ACTION"
                            }
                        })
                       return 
                    }
                    
                    let moment = this.MBS.date.moment
                    let selecting_list_key = moment?moment().valueOf():null 
                    if (items) {
                        setTimeout(() => {
                            this.search_item=null
                        }, 500);
                        let item_selected = items.find(item=>{
                            return  item_key?item.key == item_key:item.code == item_code
                        })
                        // scale items
                        if (!item_selected && item_code && scales) {
                            scales.forEach(scale => {
                                let prefix = scale?scale.barcode_prefix:null
                                let code_prefix = item_code.slice(0,2)

                                if (prefix===code_prefix) {
                                    item_code = ""+item_code
                                    let abc = item_code.slice(2)
                                    let c = this.toNumber(item_code.slice(-2) )
                                    let ab = abc.slice(0,-2)
                                    let a = null
                                    let b = null
                                    if (ab) {
                                        let cut_start = 0
                                        let first_number = 0
                                        while (first_number === 0) {
                                            first_number = this.toNumber(ab.slice(cut_start,cut_start+1))
                                            cut_start++
                                        }
                                        if (first_number) {
                                            a = this.toNumber(ab.slice(cut_start-1,cut_start+2))
                                        }
                                        if (a) {
                                            b = this.toNumber(ab.slice(cut_start+2))
                                        } 
                                    }

                                    if (a) {
                                        item_selected = items.find(item=>{
                                            return  this.toNumber(item.code) == a
                                        }) 
                                        retail_price = item_selected?item_selected.retail_price:null
                                        wholesale_price = item_selected?item_selected.wholesale_price:null
                                    }

                                    if (b && item_selected ) { 
                                        let price = selling_model=='Retail Price'?retail_price:wholesale_price
                                        let bc = this.toNumber(b+"."+c)
                                        // console.log(b,"b........******");
                                        // console.log(bc,"bc........******");
                                        quantity = bc/this.toNumber(price)
                                        quantity = quantity.toFixed(3)
                                    }   
                                } 
                            });
                        }

                        if (!item_selected) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:'ERROR',
                                text:"Item ("+item_code+") not found",
                                btnYes:"OKAY"
                            })
                            console.log(item_key,'not found');
                            return 
                        }
                        
                        
                        //check stock level
                        let levels = this.CALCULATE_STOCK_LEVELS(item_selected)
                        let allow_sell = levels?levels.allow_sell:null
                        console.log(levels,'levels.......');
                        if (!allow_sell) {
                            return
                        }


                        let pricing_model = null
                        let sold_price = 0
                        if (warehouse_model.values == DATA.ITEMS.QUOTATIONS.value ) {
                            pricing_model = selling_model
                            sold_price = selling_model == 'Retail Price'?item_selected.retail_price:item_selected.wholesale_price
                        } else {
                            pricing_model = "cost_price"
                            sold_price = item_selected.cost_price
                        }

                        /// if array
                        let newItem = {
                            ...item_selected, 
                            warehouse_model:warehouse_model,
                            pricing_model:pricing_model,
                            sold_price:sold_price,
                            // discount:0,
                            quantity:quantity?quantity:1,
                        }

                        //display selected item
                        try {
                            let displayName = newItem.name
                            displayName = displayName.slice(0,12)
                            this.DISPLAY_TO_VFD({
                                text:"ITEM: "+displayName,
                                text2:"PRICE: MWK"+newItem.sold_price,
                            }) 
                        } catch (error) {
                            
                        }


                        console.log(newItem,'newItem.........');

                        this.MBS.events.$emit("ADD_SELECTED_SELLING_ITEMS",{
                            item_key:item_key,
                            item_code:item_code,
                            duplicate_selling_item:duplicate_selling_item,
                            company_key:company_key,
                            selected_item:newItem,
                        }) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CONFIRM_REMOVE_ITEM(selected_data,index){
                try {  
                    let mbs_text = this.MBS.text.item_action("Selected Item(s)","Delete")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        icon:"mdi-delete",
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes, 
                        btnNo:mbs_text.btnNo, 
                        action:{
                            code:PAGE_NAME+'=REMOVE-ITEMS',
                            data:selected_data,
                            all_users:true, 
                        }
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            async REMOVE_ITEMS(selected_data){ 
                return new Promise(async (resolve,reject)=>{
                    try {   
                       let res = await DB.deleteItems({
                            name:DATA.ITEMS.LOCAL_SELECTED_WAREHOUSE_ITEMS.values,
                            items:selected_data,
                            update_locals:true,
                        })  
                        this.checked_items=null
                        resolve(res)
                    } catch (error) { 
                        this.MBS.actions.error({
                            error:error,
                            from:'REMOVE_ITEMS',
                            page:PAGE_NAME, 
                        }) 
                        reject(error)
                    } 
                })
                
            },  

             
             
            CREATE_ORDER(return_order){
                try {
                    let WarehouseSession = this.WarehouseSession
                    let warehouse_session_key = WarehouseSession?WarehouseSession.key:null
                    let outlet_code = WarehouseSession?WarehouseSession.outlet_code:null
                    let company_key = WarehouseSession?WarehouseSession.company_key:null
                    let order_type = this.warehouse_model?this.warehouse_model.values:null   
 
                    let item_order = this.item_order
                   
                    let TotalPrice = this.TotalPrice
                    let TotalDiscount = this.TotalDiscount
                    let TotalDiscountedPrice = this.TotalDiscountedPrice
                    let us = this.us
                    let uid = us?us.uid:null
                    let SELECTED_ITEMS = this.SELECTED_ITEMS
                    let items_size = this.MBS.actions.SIZE(SELECTED_ITEMS)
                    let moment = this.MBS.date.moment
                    let key = item_order?item_order.key: moment?moment().valueOf():null

                    if (order_type == DATA.ITEMS.STOCK_RECEIVES.value) {
                        this.input.to_outlet_code = outlet_code 
                    } else {
                        this.input.from_outlet_code = outlet_code 
                    }

                    if (items_size<1) {
                        console.log("error..");
                    }
                    let order_details = { 
                        key:key, 
                        warehouse_session_key:warehouse_session_key, 
                        // to_outlet_code:outlet_code, 
                        company_key:company_key, 

                        total_price:TotalPrice,
                        total_discount:TotalDiscount,
                        local_created_at:key,  
                        created_uid:uid,
                        
                    }
                    if (item_order) {
                        order_details = {
                            key:item_order.key,
                            uploaded:false,
                            ...order_details
                        }
                    }
                   
                    let order_items = []
                    SELECTED_ITEMS.forEach(element => {
                        let average_cost_price = this.toNumber(element.average_cost_price)
                        let cost_price = this.toNumber(element.cost_price)
                        let sold_cost_price = average_cost_price?average_cost_price:cost_price
                        order_items.push({ 
                            sold_price:this.toNumber(element.sold_price),
                            sold_cost_price:sold_cost_price,
                            quantity:this.toNumber(element.quantity),
                            discount:this.toNumber(element.discount),
                            order_key:key,
                            selling_model:element.selling_model?element.selling_model:null,
                            item_key:element._key,
                            item_code:element.code
                        })
                    }); 
                    order_details.items = order_items

                    //check connector
                    let K_CONNECTOR = this.K_CONNECTOR
                    let settings = this.CompanySettings
                    let Sell_without_printer = settings?settings.Sell_without_printer:null
   
                    if (return_order) {
                        return order_details 
                    } else if (true) {
                        this.pay_order = order_details 
                    }else if (K_CONNECTOR||Sell_without_printer) {
                        this.pay_order = order_details 
                    } else{
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"CONNECTOR IS OFF",
                            text:'Kipcount Connector is offline. Please make to start the K-Connector and try again.',
                            btnYes:"TRY AGAIN",  
                            btnNo:"CANCEL",  
                            action:{
                                code:PAGE_NAME+"=K-CONNECT-CREATE-ORDER",
                                return_order:return_order
                            }
                        })  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            CONFIRM_SUBMIT_ORDER(order,warehouse_model){
                try { 
                    if (!order||!warehouse_model) {
                        console.log(order,'order error');
                        return
                    } 
                    let order_type = warehouse_model?warehouse_model.values:null  
                    let input = this.input  
                    let to_outlet_code = input?input.to_outlet_code:null 
                    let from_outlet_code = input?input.from_outlet_code:null 
                    let from_supplier_key = input?input.from_supplier_key:null 
                    let payment_method_key = input?input.payment_method_key:null 
                    let delivery_date = input?input.delivery_date:null 
                    let transferring_date = input?input.transferring_date:null 
                    let bill_terms = input?input.bill_terms:null 
                    let bill_due_date = input?input.bill_due_date:null 
                    let code = input?input.code:null 
                    let invoice_number = input?input.invoice_number:null 
                    let description = input?input.description:null
                    let moment = this.MBS.date.moment
                    let key = moment?moment().valueOf():null
                    
                    order = {
                        ...order,
                        code:code,
                        order_type:order_type,  
                        key:key,  
                        local_created_at:key,  
                    } 
                
                    if (to_outlet_code) {
                        order.to_outlet_code = to_outlet_code
                    }if (from_outlet_code) {
                        order.from_outlet_code = from_outlet_code
                    }if (from_supplier_key) {
                        order.from_supplier_key = from_supplier_key
                    }if (delivery_date) {
                        order.delivery_date = delivery_date
                    }if (transferring_date) {
                        order.transferring_date = transferring_date
                    }if (bill_terms) {
                        order.bill_terms = bill_terms
                    }if (bill_due_date) {
                        order.bill_due_date = bill_due_date
                    }if (description) {
                        order.description = description
                    }if (invoice_number) {
                        order.invoice_number = invoice_number
                    }if (payment_method_key) {
                        order.payment_method_key = payment_method_key
                    } 

                    console.log(order,'order');
                    console.log(this.input,'this.input');
                    if (!invoice_number) {
                        let mbs_text = this.MBS.text.item_action("Order Without Invoice Number","Submit")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            icon:"warning",
                            title:"NO INVOICE NUMBER",
                            text:mbs_text.text,
                            btnYes:"ADD INVOICE N0.",
                            btnNo:"SUBMIT WITHOUT",
                            action:{
                                code:PAGE_NAME+"=SUBMIT-ORDER-WITHOUT-INVOICE",
                                silent_btn_no:true,
                                data:{
                                    order:order,
                                    warehouse_model:warehouse_model,
                                }
                            }
                        }) 
                    } else {
                        let mbs_text = this.MBS.text.item_action("Order","Submit")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            icon:"mdi-upload",
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=SUBMIT-ORDER",
                                silent_btn_no:true,
                                data:{
                                    order:order,
                                    warehouse_model:warehouse_model,
                                }
                            }
                        }) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            SUBMIT_ORDER(order){
                try {  
                    if (!order) {
                        console.log(order,'order error');
                    } 
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_orders+order.key,true) 
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 

                    console.log(order,'o sub-------------------'); 
                    this.$store.dispatch("fi_update",{
                        action:DATA.ITEMS.STOCK_ORDERS.values,
                        data:order,  
                        path:path,
                    })
                    .then(async res=>{
                        this.inputLoading = false
                        await this.CLEAR_ALL_DATA()
                        await this.REMOVE_ITEMS()
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.STOCK_ORDERS.value,"Received")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        }) 
                        
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.QUOTATIONS.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_SELECT_SHIFT(value){
                try {  
                    this.show_input = false  
                    let TotalPayments = this.TotalPayments    
                    let cashups = this.thisCashupsData  
                    let lastCashup = cashups?cashups.slice(-1)[0]:null
                    let till = this.getDayShiftTill(value.day_shift_and_till_key) 

                    let closing_cash = lastCashup?lastCashup.closing_cash:null
                    let floating_cash = till?till.floating_cash:null  
                    
                    this.input = { 
                        ...this.input, 
                        expected_cash:closing_cash?(closing_cash+TotalPayments):floating_cash,
                        opening_cash:closing_cash?closing_cash:floating_cash,
                    } 
                    setTimeout(() => {
                        this.show_input = true  
                    }, 1000); 
                } catch (error) {
                    this.show_input = true
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT_SHIFT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            //---------------[SALES CALCULATIONS]------------ 
            CALCULATE_STOCK_LEVELS(item_selected,selling_quantity=1,notify=true){
                try { 
                    let response = {
                        allow_sell:false,
                        quantity:null,
                        stop_selling_level:null,
                        ordering_remind_level:null,
                        active_levels:null,
                        item_selected:item_selected
                    } 
                    // let local_sales = this.AllLocalSaleOrdersData
                    // console.log(local_sales,'local_sales....');
                    let till = this.CurrentTill
                    let outlet_code = till?till.outlet_code:null
                    let settings = this.CompanySettings
                    let stock_levels = settings?settings.stock_levels:null
                    let stock_outlets = stock_levels?stock_levels.stock_outlets:null
                    let outlet_stock_levels = stock_outlets && outlet_code?stock_outlets[outlet_code]:null
                    let item_stock_levels = item_selected?item_selected.stock_levels:null 
                    let active_levels = item_stock_levels?item_stock_levels:outlet_stock_levels?outlet_stock_levels:stock_levels
                    response.active_levels = active_levels 

                    if (active_levels && item_selected) {
                        let stop_selling_level = this.toNumber(active_levels.stop_selling_level)
                        let ordering_remind_level = this.toNumber(active_levels.ordering_remind_level)
                        let quantity = this.toNumber(item_selected[outlet_code+'_number_quantity'])-this.toNumber(selling_quantity)
                        response.quantity = quantity
                        response.stop_selling_level = stop_selling_level
                        response.ordering_remind_level = ordering_remind_level
                        
                        if (quantity < stop_selling_level) {
                            response.allow_sell = false
                            if (notify) {
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:"OUT OF STOCK",
                                    text:"The selected item is below stop selling level.",
                                    btnYes:"OKAY"
                                })  
                            }
                        }else if (quantity < ordering_remind_level) {
                            response.allow_sell = true
                            if (notify) {
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:"LOW ON STOCK LEVEL",
                                    text:"The selected item is below ordering level.",
                                    btnYes:"OKAY"
                                }) 
                            }
                        }else{
                            response.allow_sell = true
                        }  
                    }
                    if (!active_levels) {
                        response.allow_sell = true
                    }
                    return response
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[SALES CALCULATIONS]------------ 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let warehouse_model = this.warehouse_model
                    let seeling_model = this.seeling_model
                    let pricing = "cost_price"
                    if(warehouse_model?warehouse_model.code==3:null){
                        pricing = seeling_model=="Wholesale Price"?"wholesale_price":"retail_price"
                    }
                    let sold_price = this.toNumber(item[pricing])
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            CALCULATE_TOTAL_PRICE(items){
                try { 
                    if (!items) {return 0} 
                    let price = 0
                    items.forEach(item => {
                        price = price+this.CALCULATE_PRICE(item)
                    });
                    return price
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_TOTAL_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            ORDER_ITEMS(orders){  
                try { 
                    if(!orders){return null} 
                    let items = []
                    orders.forEach(element => {
                        let joined_items = element.joined_items
                        if (joined_items) {
                            joined_items.forEach(item => {
                                items.push({
                                    ...item,
                                    account_type:element.account_type
                                }) 
                            });
                            
                        }
                    }); 
                    return items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ORDER_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RETAIL_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("retail price")
                    });
                    return filtered_items
                } catch (error) { 
                    this.MBS.actions.error({
                        error:error,
                        from:'RETAIL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            WHOLESALE_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("wholesale price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'WHOLESALE_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CASH_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "cash"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CASH_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_ACCOUNT_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "on_account"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ACCOUNT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            TOTAL_SOLD(items){
                try {  
                    let total = 0
                    if (items) {
                        items.forEach(element => {
                            let sold_price = this.toNumber(element.sold_price)
                            let quantity = this.toNumber(element.quantity)
                            let discount = this.toNumber(element.discount)
                            total = total+((sold_price-discount)*quantity)
                        }); 
                    }
                    return total
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_SOLD',
                        page:PAGE_NAME, 
                    })
                }  
            },   
            async RELOAD_DATA(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    let res_item = await this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEMS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }) 
                    // let res_sales = await this.$store.dispatch('fi_get_item',{
                    //     name:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                    //     on:false, 
                    //     cash:true, 
                    //     get:false, 
                    //     company_key:company_key, 
                    // })  
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_success("DATA","Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                } catch (error) {
                    console.log(error,'error.......');
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_fail("DATA","Refresh")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    }) 
                }

            }, 
            DISPLAY_TOTAL_PRICE(){  
                try {  
                    let input = this.input
                    let pay_order = this.pay_order
                    if(!pay_order){return }
                    let paid_amount = this.toNumber(pay_order?pay_order.paid_amount:null)
                    let total = this.MBS.actions.money(paid_amount)
                    let total_size = total.length

                    let given_amount = this.toNumber(input.given_amount)
                    let change = given_amount-paid_amount
                    let balance = this.MBS.actions.money(change)
                    let balance_size = balance.length
                    
                    let total_initial = "TOTAL: MWK"
                    let balance_initial = "BALANCE: MWK"

                    if (total_size) {  
                        if(total_size > 10){
                            total_initial = "T: MK"
                        }else if(total_size > 9){
                            total_initial = "TOTAL: MK"
                        }
                    }if (balance_size) {  
                        if(balance_size > 10){
                            balance_initial = "B: MK"
                        }else if(balance_size > 9){
                            balance_initial = "BALANCE:MK"
                        }else if(balance_size > 8){
                            balance_initial = "BALANCE: MK"
                        }
                    } 

                    this.DISPLAY_TO_VFD({
                        text:total_initial+total,
                        text2:change>0?balance_initial+balance:'BALANCE:',
                    }).then(res=>{

                    }).catch(error=>{

                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DISPLAY_TOTAL_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            DISPLAY_KIPCOUNT(){  
                try {    
                    this.DISPLAY_TO_VFD({
                        text:"KIPCOUNT",
                        text2:"www.kipcount.com",
                    }).then(res=>{

                    }).catch(error=>{

                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DISPLAY_TOTAL_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            FULLSCREEN(activate=true){  
                try {    
                    let always_on_fullscreen = true
                    let fullscreen = this.fullscreen
                    if (always_on_fullscreen) {
                        this.MBS.actions.SCREEN_FULL_MODE(true) 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FULLSCREEN',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 

            //---------------[MAIN FUNCTIONS]------------  
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=OPEN-CASHUP') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.input.check = false
                                    let password = action.password
                                    password = password?this.MBS.crypt.encrypt(password):null 
                                    if (!password) { 
                                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                    }else if (password!==this.ud.p_uid) { 
                                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                    } else {
                                        this.OPEN_CASHUP(action.data)
                                    } 
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }  
                        if (action.code ===PAGE_NAME+'=OPEN-WAREHOUSE') {
                            if (action.YES) {
                                setTimeout(() => {   
                                    this.OPEN_WAREHOUSE(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }
                        if (action.code ===PAGE_NAME+'=CLOSE-WAREHOUSE') {
                            if (action.YES) {
                                setTimeout(() => {   
                                    this.CLOSE_WAREHOUSE(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=UPDATE-PRICING') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SUBMIT_PRICING(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=SUBMIT-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.SUBMIT_ORDER(action.data?action.data.order:null)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=SUBMIT-ORDER-WITHOUT-INVOICE') {
                            if (action.YES) {
                            } else {
                                setTimeout(() => {  
                                    this.SUBMIT_ORDER(action.data?action.data.order:null)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=K-CONNECT') {
                            if (action.YES) {
                                setTimeout(() => {   
                                    this.UPDATE_K_CONNECTOR()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=K-CONNECT-CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(async() => {    
                                    this.inputLoading = true
                                    try {
                                        await this.UPDATE_K_CONNECTOR()
                                        this.CREATE_ORDER(action.return_order)
                                        this.inputLoading = false
                                    } catch (error) {
                                        this.inputLoading = false
                                    }
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=CLEAR-SALES') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.REMOVE_ITEMS().then(res=>{ 
                                    }).catch(error=>{
                                        console.log(error,'error.....');
                                    })
                                    this.CLEAR_ALL_DATA()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }  
                        if (action.code ===PAGE_NAME+'=SELECT-ACTION') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.menu_warehouse_mode = true
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }  
                        if (action.code ===PAGE_NAME+'=REMOVE-ITEMS') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.REMOVE_ITEMS(action.data).then(res=>{ 
                                    }).catch(error=>{
                                        console.log(error,'error.....');
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }  
                        if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEM-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_ALL_ITEM_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }   
                        if (action.code ===PAGE_NAME+'=DELETE-ITEM-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_ITEM_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }   
                        if (action.code ===PAGE_NAME+'=RELOAD-DATA') {
                            if (action.YES) {
                                this.RELOAD_DATA()
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=CARD-ATTACH-CUSTOMER') {
                            if (action.YES) {
                                    setTimeout(() => {  
                                        let link = DATA.PATHS.cards+action.data.key+"/"
                                        let f_detail = {
                                            action:DATA.ITEMS.ITEMS.values,
                                            path:this.MBS.actions.COMPANY_LINK(link,true),
                                            data:action.data,
                                            us:this.us
                                        } 
                                        this.input.loading = true 
                                        this.inputLoading = true 
                                        this.$store.dispatch("fi_update",f_detail)
                                        .then(res=>{ 
                                            let mbs_text = this.MBS.text.update_item_success(DATA.ITEMS.CARDS.name)
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes,  
                                            }) 
                                            this.input.loading = false 
                                            this.inputLoading = false 
                                            this.MBS.actions.progressDialog() 
                                            this.MBS.actions.restartResponses() 
                                        })
                                        .catch(error=>{ 
                                            let mbs_text = this.MBS.text.update_item_fail(DATA.ITEMS.CARDS.name)
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes, 
                                            })  
                                            this.input.loading = false
                                            this.inputLoading = false
                                            this.MBS.actions.progressDialog() 
                                            this.MBS.actions.restartResponses()

                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);     
                                } else {
                                    
                                }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            ON_QR_SCAN(code){
                try {
                    if (code) { 
                        //check cashup
                        // if (!this.LocalCashup) { 
                        //     this.MBS.actions.dialog({
                        //         show:true,
                        //         fixed:true,
                        //         title:"OPEN REGISTER FIRST",
                        //         text:"You must open the Register first before you can scan.",
                        //         btnYes:"OK"
                        //     })
                        //     return
                        // }
                        
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{
                            this.MBS.actions.dialog()
                            // if (this.selling_model) {
                                let band1 = code.indexOf("%")!=-1?true:false
                                let band2 = code.indexOf(";")!=-1?true:false
                                let band3 = code.indexOf("+")!=-1?true:false
                                let close = code.slice(-1)
                                // let first = code.slice(0,1)
                                // let last = code.slice(-1)
                                // let mid = code.slice(1,-1) 
                                
                                
                                if ((band1||band2||band3) && close=="?") {
                                    let first_string = code.split("?")[0]  
                                    code = first_string.slice(1)  
                                     
                                } else { 
                                    this.ADD_ITEM(null,(code)) 
                                }  
                            // } else {
                            //     this.MBS.actions.dialog({
                            //         show:true,
                            //         fixed:true,
                            //         title:"SELECT SELLING MODEL",
                            //         text:"You must select selling mode before scanning items.",
                            //         btnYes:"OK"
                            //     })
                            // }
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            ON_KEY_DOWN(key,time){
                try {
                    this.FULLSCREEN()
                    if (key=='F1') {
                        this.dialog_search_and_select_items = true
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_KEY_DOWN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            EXIT_PAGE(){
                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    this.PAGE_SHOW = true
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);
                this.MBS.events.$off('ON_KEY_DOWN', this.ON_KEY_DOWN);    
                this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch: {
            search_select(value){ 
                if (value) {
                    this.selling_model = value.selling_model
                    this.warehouse_model = value.warehouse_model
                    this.import_model = value.import_model
                }
                console.log(value,'v..........');
            }, 
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            }, 
            input(value){    
                if (value) { 
                    // get day shift key
                    let day_shift_and_till_key = value.day_shift_and_till_key
                    let day_shift_key = null
                    let till_key = null
                    if (day_shift_and_till_key) {
                        let day_shift_and_till_key_ = day_shift_and_till_key?day_shift_and_till_key.split('/'):null
                        day_shift_key = day_shift_and_till_key_?day_shift_and_till_key_[0]:null
                        till_key = day_shift_and_till_key_?day_shift_and_till_key_[1]:null
                    }
                    if (day_shift_key != this.this_day_shift_key || till_key != this.this_till_key) {
                        this.this_day_shift_key = day_shift_key
                        this.this_till_key = till_key
                        this.ON_SELECT_SHIFT({
                            day_shift_and_till_key:day_shift_and_till_key,
                            day_shift_key:day_shift_key,
                            till_key:till_key
                        })
                    }  
                } 
            },
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            selectedCompany(value){
                if (value) { 
                }
            }, 
            CompanySettings(value){
                if (value) {   
                }
            },  
            CurrentVFT_Display(value){
                if (value) {
                    this.DISPLAY_TO_VFD({
                        text:"KIPCOUNT",
                        text2:"www.kipcount.com",
                    }).then(res=>{}).catch(error=>{})  
                }
            }, 
            pay_order(value){
                if (value) { 
                    if (value.card_load_money) { 
                    } else {
                        this.DISPLAY_TOTAL_PRICE()   
                    }
                }else{
                    this.DISPLAY_KIPCOUNT()  
                }
            }, 
          
             
             
        },
    }
</script>
 
